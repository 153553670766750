import React, {useState, useContext, useEffect} from 'react'
import { Link } from "react-router-dom";
import { ConvertStringToDollarsCents } from '../components/Helper'
import CloseIcon from '@material-ui/icons/Close';
import ButtonAddToCart from './ButtonAddToCart';
import { isMobile } from '../components/Helper'
import UiContext from '../context/ui';
import moment from 'moment';
import Storage from "@aws-amplify/storage";
import aws_config from "../aws-exports";

const cardImageLocationPrefix = "/images/cards/";


function ProductQuickView(props) {
   
    const product = props.product;
    const [normalImage, setNormalImage] = useState(null);
    const [loadingImage, setLoadingImage] = useState("");
    const {alertMessage} = useContext(UiContext);

    useEffect(() => {
        getNormalImage(product);
      }
    );

    const getNormalImage = async (product) => {

        if (product) {
            if (product.imageAsset) {
                var re = /(?:\.([^.]+))?$/;
                let extensionString = re.exec(product.imageAsset);
                let imageKey = product.image.replace(extensionString[0],"") + "_200.png";
                let result = await Storage.get(imageKey, { level: 'public', bucket: aws_config.aws_user_files_s3_bucket, 
                    region:aws_config.aws_user_files_s3_bucket_region});     
                if (result) {
                    setNormalImage(result);
                }      
            } else {
    
                if (product.imageUrlKey) {
                    setNormalImage(cardImageLocationPrefix + product.imageUrlKey);
                  }  
                  if (product.imageUrl) 
                  {
                    setNormalImage(product.imageUrl);
                  } else  {
                    setNormalImage(product.image);
                  }
    
            }
        }
        
        
    }

    function showSpecialTag(product) {

        if (moment().valueOf() < moment(new Date(product.releaseDate)).valueOf()) {
          if (product.allowPreorder) {
            if (product.quantity <= 0)
            {
            return <span className="product-badge">Pre Orders Sold Out</span>
            }else {
                return <span className="product-badge preorder">Pre Order</span>
            }
           
          }else {
           return <span className="product-badge">Coming Soon</span>
          }         
        }else {
      
         if (product.quantity <= 0)
          {
             return <span className="product-badge">Sold Out</span>
          } else if (product.quantity <= 3) {
             return <span className="product-badge low">Low Stock</span>
          }
         return <></>
        }       
      }


      const isNotReleased = ()=> {
        return moment().valueOf() < moment(props.product.releaseDate).valueOf();
      }

      
const displayPrice = ()=>{

    if (isNotReleased() && !props.product.allowPreorder) {
      return <></>;
    }
  
    return(<>
      {props.product.salePrice ? <> <span>{ConvertStringToDollarsCents(props.product.salePrice)}</span> <span className="price-strike">{ConvertStringToDollarsCents(props.product.price)}</span> </> :<> {ConvertStringToDollarsCents(props.product.price)} </>}
    </>)
  }


      const getImageData = ()=>{
        if (normalImage) {
            return normalImage;
        }
        if (props.product.imageThumbBase64) {            
            return "data:image/png;base64," + props.product.imageThumbBase64;
        }
        // Loading image
        return "";
    }

    return (<>
        { props.product ?
            <>
                <div style={{ float: "right" }} onClick={() => {
                }}>
                    <div className="close-header-icon">
                        {/* <CloseIcon style={{ scale: "2" }} onClick={() => {
                            props.endQuickViewProduct();
                        }} /> */}
                    </div>
                </div>
                <div className="product-modal">
                    <div className={isMobile() ? "container-column-left mobile" : "container-column-left"} >
                        <img alt="product-image" className={normalImage ? "product-quickview-image" : "product-quickview-image thumb"} src={getImageData()} ></img>
                    </div>
                    <div className={isMobile() ? "container-column-right mobile" : "container-column-right"}>
                        <p className="product-title"><Link  onClick={()=>{
                            if (props.endQuickViewProduct) {
                                props.endQuickViewProduct()
                            }
 
                        }} to={`/cards/${product.id}`}>{product.title}</Link></p>
                        <p>
                        {
                          product.cardName != null ? <></> : showSpecialTag(product)
                        }
                        </p>
                        <p className="product-price">
                         
                            {
                                product.price <= 0 ? <></>: displayPrice()
                            }


                        </p>
                        <ButtonAddToCart endQuickViewProduct={props.endQuickViewProduct} product={props.product} />
                        {
                            alertMessage && alertMessage != "" ? <p>{alertMessage}</p> : <></>
                        }
                        
                        {/* <div style={{textAlign:"left", paddingTop:"1em"}} dangerouslySetInnerHTML={{ __html:product.description }} /> */}

                    </div>
                    <div className="container-flex-space">&nbsp;</div>
                </div> </> : <></>
            }
    </>);
}

export default ProductQuickView