import React, { useState, useContext, useEffect } from 'react';
import UserContext from "../context/user";
import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox';

const Styles = styled.div`

`

function NotifyButton(props) {

    const { signedIn} = useContext(UserContext);
    const [ isSignedUp] = useState(false);
    
    useEffect(()=>{

        
    }, [])
    
    const checkIfSignedUp = ()=>{
       return isSignedUp;
    }


    const drawNotification = ()=>{
        return (
            <>
            {signedIn ?   <>
                <Checkbox
              defaultChecked
              color="primary"
              checked={checkIfSignedUp()}
              inputProps={{ 'aria-label': 'sign-up' }}
              onChange={e => { 
                    console.log("On Change")
              }}
            /> &nbsp;
            <span style={{padding:"1em", paddingLeft:"0em"}}>Notify me when this item is in stock.</span>
            </> : <p>"Sign in to get notified when in this item is back in stock."</p>}
            </>
        )

    }

return (
    <Styles>
        {
            drawNotification()
        }
    </Styles>
)


}

export default NotifyButton
