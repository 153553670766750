import { useState, useEffect, useCallback, useContext } from 'react';
import throttle from 'lodash/throttle';
import UiContext from "../context/ui";

const timing = (1 / 60) * 1000;
const decay = v => -0.005 * ((1 / timing) ^ 4) + v;

function useScrollBox(scrollRef) {

  const { sendAlertMessage, clearMessages } = useContext(UiContext);

  const [clickStartX, setClickStartX] = useState();
  const [scrollStartX, setScrollStartX] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [direction, setDirection] = useState(0);
  const [momentum, setMomentum] = useState(0);
  const [lastScrollX, setLastScrollX] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [isAtEnd, setIsAtEnd]  = useState(false);
  const scrollWrapperCurrent = scrollRef.current;
  const handleLastScrollX = useCallback(
    throttle(screenX => {
      setLastScrollX(screenX);
    }, timing),
    []
  );

  // formula     http://easings.net/
// description https://stackoverflow.com/questions/8316882/what-is-an-easing-function
// x: percent
// t: current time,
// b: beginning value,
// c: change in value,
// d: duration  

  function easeInOutQuad(x, t, b, c, d) {
    if ((t /= d / 2) < 1) {
        return c / 2 * t * t + b;
    } else {
        return -c / 2 * ((--t) * (t - 2) - 1) + b;
    }
}
  const handleMomentum = useCallback(

    throttle(nextMomentum => {

      setMomentum(nextMomentum);
      
      if (scrollRef.current) {

        scrollRef.current.scrollLeft = scrollRef.current.scrollLeft + nextMomentum * timing * direction;
        let finalPosition = 0;
        let scrollerWindoWidth = scrollRef.current.getBoundingClientRect().width;
        let finalChildPositionX = 0;
        let totalItems = scrollRef.current.firstChild.children.length;

        let scrollerWindowX = scrollRef.current.getBoundingClientRect().x;

        for (let i = 0; i < scrollRef.current.firstChild.children.length; i++) {
          let child  = scrollRef.current.firstChild.children[i];
          if (child) {
            let childRect = child.getBoundingClientRect();
            if (childRect.x > finalPosition) {
              if (childRect.x > finalPosition) {
                finalPosition = childRect.x;
              }
            }
          }
        }
      
        if ((finalPosition - scrollerWindowX) <= scrollerWindoWidth) {  
          setIsAtEnd(true);
        } else {
          setIsAtEnd(false);
        }
  
      }
      


    }, timing),
    [scrollWrapperCurrent, direction]
  );

  useEffect(() => {
    //   console.log("direction:", direction);
    if (direction !== 0) {
        // console.log("momentum:", momentum);
        // console.log("isDragging:", isDragging);
        // console.log("speed:", speed);
      if (momentum > 0.01 && !isDragging) {
        handleMomentum(Math.min(3, decay(momentum)));
      } else if (isDragging) {
        setMomentum(Math.min(3, speed));
      } else {
        setDirection(0);
      }
    }
  }, [momentum, isDragging, speed, direction, handleMomentum]);


  useEffect(() => {


    if (scrollRef.current) {
        const handleDragStart = e => { 
            if (e.touches) {
                var touch = e.touches[0];
                var x = touch.pageX;
                setClickStartX(x);
                setScrollStartX(scrollRef.current.scrollLeft);
                setDirection(0);

                
            } else {
                setClickStartX(e.screenX);
                setScrollStartX(scrollRef.current.scrollLeft);
                setDirection(0);
            }
      };

    
    const handleDragMove = e => {

        // e.preventDefault();
        // e.stopPropagation();        

        if (clickStartX !== undefined && scrollStartX !== undefined) {

            let lastXPosition = e.screenX;
            if (e.touches) {
                var touch = e.touches[0];
                lastXPosition = touch.pageX;
            }             
            const touchDelta = clickStartX - lastXPosition;
            scrollRef.current.scrollLeft = scrollStartX + touchDelta;            
            if (Math.abs(touchDelta) > 1) {
              setIsDragging(true);
              setDirection(touchDelta / Math.abs(touchDelta));
              setSpeed(Math.abs((lastScrollX - lastXPosition) / timing));
              handleLastScrollX(lastXPosition);
            }
        }else {
            // var target = e.currentTarget;
            // console.log("e:", e);
            // sendAlertMessage(JSON.stringify(target));
            // sendAlertMessage("handleDragMove:" + "clickStartX undefined");
        }
      };

        const handleDragEnd = () => {
        // sendAlertMessage("handleDragEnd:" + isDragging)
        if (clickStartX !== undefined) {
          setClickStartX(undefined);
          setScrollStartX(undefined);
          setIsDragging(false);
        }
      };

      if (scrollRef.current.ontouchstart === undefined) {
        // sendAlertMessage("scrollRef.current.ontouchstart NOT found:");
        scrollRef.current.onmousedown = handleDragStart;
        scrollRef.current.onmousemove = handleDragMove;
        scrollRef.current.onmouseup = handleDragEnd;
        scrollRef.current.onmouseleave = handleDragEnd;
      } else {
        // sendAlertMessage("scrollRef.current.ontouchstart Found:");
        scrollRef.current.ontouchstart = handleDragStart;
        scrollRef.current.ontouchmove = handleDragMove;
        scrollRef.current.ontouchend = handleDragEnd;
        scrollRef.current.ontouchcancel = handleDragEnd;
      }
    }else {
        sendAlertMessage("scrollRef.current NOT found:");
    }
  }, [scrollWrapperCurrent, clickStartX, isDragging, scrollStartX, handleLastScrollX, lastScrollX]);

  return { clickStartX, scrollStartX, isDragging, isAtEnd, direction, momentum, lastScrollX, speed };
}

export default useScrollBox;
