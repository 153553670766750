
import React from 'react';
import styled from 'styled-components'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import { API } from 'aws-amplify';

import { CircularProgress } from '@material-ui/core';


const Styles = styled.div`
    padding:40px;
`

let companyInfoObject = {};
let loadingQuickbooks = false;

function Quickbooks(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    async function getAccessToken() {
        
        let startedAccessToken = false;

        const result = await API.get("yayfunthingsadmin", "/app/authUri", {response: true}).then(async response => {        
                   
            if (response.data.authUri) {
                console.log(response.data.authUri);
                console.log("Found response.data.authUri")           
              
                var parameters = "location=1,width=800,height=650";
                parameters += ",left=" + (window.screen.width - 800) / 2 + ",top=" + (window.screen.height - 650) / 2;
                var win = window.open(response.data.authUri, 'connectPopup', parameters);
                
                var pollOAuth = window.setInterval(async function () {
                
                    try {
                        if (win.document.URL.indexOf("code") != -1 && !startedAccessToken) {           
                           
                            let codeURL =  win.document.URL;                
                            const urlParams = new URLSearchParams(codeURL);
                            let code = urlParams.get('code');
                            let state = urlParams.get('state');
                            let realmId = urlParams.get('realmId');

                            if (state == response.data.state) {                                
                              
                                window.localStorage.setItem("quickBooksCode", code);
                                window.localStorage.setItem("quickBooksRealmId", realmId);

                                console.log("yayfunthingsadmin callback url=" + codeURL);
                                startedAccessToken = true;

                                console.log("COOKIE:");
                                console.log(state);

                                let postMsg = {
                                    body: {
                                        url:codeURL,
                                        _csrf:state
                                    },
                                    headers: 
                                    {
                                        // 'csrf-token':state
                                        // 'Access-Control-Allow-Headers': 'X-Auth-Token',
                                        // 'Content-Type': 'application/json',
                                        // 'Accept': 'application/json',
                                        // 'X-Auth-Token': state
                                    }   
                                };

                                let codeURLString = encodeURIComponent(codeURL)
                                const result2 = await API.get("yayfunthingsadmin", "/app/callbackAuth?url="+codeURLString).then(async response2 => { 
                                    
                                    console.log("callback?url= RESPONSE:");                                        
                                    console.log(response2);
                                    console.log(response2.token);

                                    let tokenString = JSON.stringify(response2.token);

                                    console.group("tokenString:");
                                    console.group(tokenString);

                                    if (response2.token)  {
                                        window.localStorage.setItem("quickBooksAccessToken", response2.token.access_token);
                                        window.localStorage.setItem("quickBooksRefreshToken", response2.token.refresh_token);    
                                        window.localStorage.setItem("quickBooksToken", JSON.stringify(response2.token));
                                    }

                                    // save access token
                                    //window.localStorage.setItem("quickBooksCode", JSON.stringify(code));

                                }).catch(e=> {
                                    
                                    console.log(e);

                                });

                            }

                            window.clearInterval(pollOAuth);
                            win.close();
                            // window.location.reload();
                        }
                    } catch (e) {
                        // console.log(e)
                    }
                    if (win.closed !== false) { 
                        loadingQuickbooks = false;
                        forceUpdate();
                    }

                }, 100);
                
                forceUpdate();
            }
        }).catch(e=>{console.log(e)});
    }


    async function createInvoice(invoice) {
 
        console.log("createInvoice:", invoice);

        if (invoice) {
            let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
            let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
            
            if (quickBooksToken && quickBooksToken != undefined && quickBooksToken != "undefined" && quickBooksToken.length > 1) {
                quickBooksRealmId = quickBooksRealmId.replace(/\"/g,"");           
                
                // let itemData = JSON.stringify(item);
                // let postMsg  = {           
                //     body: {
                //         token:quickBooksToken,
                //         realmId:quickBooksRealmId,
                //         invoice:JSON.stringify(invoice)
                //     }      
                // };
    
    
            await API.get("yayfunthingsadmin","/app/createInvoice?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&invoice="+JSON.stringify(invoice)).then(async response => {
    
                    console.log("app/createInvoice:");
                    console.log(response);                
                    if (response.error) {
                        if (response.error && response.error.error == "Unauthorized") {
                            getAccessToken();    
                        }
                        if (response.error && response.error.error == "Forbidden") {
                            getAccessToken();    
                        }
                    }
                    // if (response.CompanyInfo) {
                    //     companyInfoObject = response.CompanyInfo;
                    //     window.localStorage.setItem("companyInfo", JSON.stringify(companyInfoObject))
                    //     forceUpdate();
                    // }
                }).catch(e=>{
                    console.log("ERROR FOUND:",e);            
                });
            }else {
                getAccessToken();    
            }
        }      
    }

    async function createItem(item) {


        console.log("createItem:", item)
 
        let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
        let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
        
        console.log("quickBooksToken:",quickBooksToken);

        if (quickBooksToken && quickBooksToken != undefined && quickBooksToken != "undefined" && quickBooksToken.length > 1) {
            quickBooksRealmId = quickBooksRealmId.replace(/\"/g,"");           

            // let postMsg = {
            //     body:{
            //         token:quickBooksToken,
            //         realmId:quickBooksRealmId
            //     }
            // }
            let itemData = JSON.stringify(item);
            await API.get("yayfunthingsadmin","/app/createItem?token=" + quickBooksToken + "&realmId="+quickBooksRealmId).then(async response => {                            
                
                console.log("app/createItem:");
                console.log(response);
            
                if (response.error) {
                    if (response.error && response.error.error == "Unauthorized") {
                        getAccessToken();    
                    }
                    if (response.error && response.error.error == "Forbidden") {
                        getAccessToken();    
                    }
                }

                // if (response.CompanyInfo) {
                //     companyInfoObject = response.CompanyInfo;
                //     window.localStorage.setItem("companyInfo", JSON.stringify(companyInfoObject))
                //     forceUpdate();
                // }

            }).catch(e=>{
                
                console.log("ERROR FOUND:",e);
            
            });
        }else {
            getAccessToken();    
        }  
    }

    async function queryCompanyInfo() {
        let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
        let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
        
        console.log("quickBooksToken:",quickBooksToken);
        console.log("quickBooksRealmId:",quickBooksRealmId);

        if (quickBooksToken && quickBooksToken != undefined && quickBooksToken != "undefined" && quickBooksToken.length > 1) {
            quickBooksRealmId = quickBooksRealmId.replace(/\"/g,"");           

            await API.get("yayfunthingsadmin","/app/getCompanyInfo?token=" + quickBooksToken + "&realmId="+quickBooksRealmId).then(async response => {                            
                console.log("app/getCompanyInfo:");
                console.log(response);
            
                if (response.error) {
                    if (response.error && response.error.error == "Unauthorized") {
                        // getAccessToken();    
                    }
                    if (response.error && response.error.error == "Forbidden") {
                        // getAccessToken();    
                    }
                }

                if (response.CompanyInfo) {
                    companyInfoObject = response.CompanyInfo;
                    window.localStorage.setItem("companyInfo", JSON.stringify(companyInfoObject))
                    forceUpdate();
                }

            }).catch(e=>{console.log(e)});
        }else {
            // getAccessToken();    
        }  

    }

    async function getCompanyInfo(forceFetch) {
        
         let companyInfoObjectString = window.localStorage.getItem("companyInfo");

         console.log("companyInfoObjectString:", companyInfoObjectString);

         let shouldFetch = false;

         if (companyInfoObjectString && companyInfoObjectString.length > 0) {
            companyInfoObject = JSON.parse(companyInfoObjectString);
            if (companyInfoObject) {
                forceUpdate();
            }else {
                shouldFetch = true;
            }
         } else  {
            shouldFetch = true;
         }

         if (shouldFetch || forceFetch) {
            
            let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
            let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
            
            console.log("quickBooksToken:",quickBooksToken);

            if (quickBooksToken && quickBooksToken != undefined && quickBooksToken != "undefined" && quickBooksToken.length > 1) {
                quickBooksRealmId = quickBooksRealmId.replace(/\"/g,"");           

                await API.get("yayfunthingsadmin","/app/getCompanyInfo?token=" + quickBooksToken + "&realmId="+quickBooksRealmId).then(async response => {                            
                    console.log("app/getCompanyInfo:");
                    console.log(response);
                
                    if (response.error) {
                        if (response.error && response.error.error == "Unauthorized") {
                            getAccessToken();    
                        }
                        if (response.error && response.error.error == "Forbidden") {
                            getAccessToken();    
                        }
                    }
    
                    if (response.CompanyInfo) {
                        companyInfoObject = response.CompanyInfo;
                        window.localStorage.setItem("companyInfo", JSON.stringify(companyInfoObject))
                        forceUpdate();
                    }
    
                }).catch(e=>{console.log(e)});
            }else {
                getAccessToken();    
            }            
         }
    }

    function displayCompanyInfo() {
        return(<>
        
            {
                
            companyInfoObject && companyInfoObject.CompanyAddr? <>                
            <p>{companyInfoObject.CompanyName}</p>
            <p>{companyInfoObject.CompanyAddr.Line1}</p>
                </> : <></>

            }
        </>)
    }

    function displayConnectToQuickbooks() {
        return (<>
            <Card className="standard-card">
            <CardHeader title="Quickbooks Online" />
            <CardContent>                
                {/* <h3>Connect now with Quickbooks to get started.</h3> */}
                {/* <span className="text-space">&nbsp;</span> */}
                <p className="text-card">With Quickbooks integration start uploading Amazon settlement report data by mapping your data to quickbooks.</p>
                <p className="text-card">Inventory cost of goods can be sent to Quickbooks on a FIFO basis.</p>
                <span className="text-space">&nbsp;</span>                
                 {
                loadingQuickbooks ? <>                
                <div  className="text-center">
                <CircularProgress size={44} color="primary" />
                </div>
                <p className="text-tiny-info text-center">Please log in to Quickbooks using the pop up window.</p>
                <br/>
                </>:<CardActions><img style={{maxHeight:"40px"}} className="qb-card no-drag" src="images/c2qb.png"
                onMouseOver={e => {e.currentTarget.src = "images/c2qb.png";                 
                console.log("Hover");}}
                onMouseOut={e => {e.currentTarget.src = "images/c2qb.png"; }}
                onDragStart={"return false"}
                onClick={()=>{
                    loadingQuickbooks = true;
                    forceUpdate();
                    getCompanyInfo(false);

                }}
            />
            {/* <Button onClick={()=>{
                createItem({
                    TrackQtyOnHand: true,
                    Name: 'Garden Supplies Product 7',
                    QtyOnHand: 0,
                    InvStartDate: '2020-01-01',
                    Type: 'Inventory',
                    IncomeAccountRef: {
                    name: 'Sales of Product Income',
                    value: '79',
                    },
                    AssetAccountRef: {
                    name: 'Inventory Asset',
                    value: '81',
                    },
                    ExpenseAccountRef: {
                    name: 'Cost of Goods Sold',
                    value: '80',
                    },
                })
            }}>
                Create Item
            </Button> */}


            <Button onClick={()=>{
                getCompanyInfo(true);
            }}>
                Get Company Info
            </Button>

            <Button onClick={()=>{
                queryCompanyInfo();
            }}>
                Query Company Info
            </Button>



            <Button onClick={()=>{
                createInvoice(props.invoice);
            }}>
                Create Invoice
            </Button>

            </CardActions>
            }
            </CardContent>  


            <CardActions className="no-drag">            
            {
                loadingQuickbooks ? <>
                <Button color="primary" onClick={()=>{loadingQuickbooks = false; forceUpdate();}}>Cancel</Button>
                <Button color="primary" onClick={()=>{
                    
                    
                    loadingQuickbooks = false;  
                
                    window.localStorage.setItem("quickBooksRealmId", "");
                    window.localStorage.setItem("quickBooksToken", "");    
                    window.localStorage.setItem("companyInfo","");
                
                forceUpdate();
                
                
                }}>Clear Token</Button>
                </>:<></>
            }
            
{/* 
            <img className="qb-card" src={'images/buttons/C2QB_white_btn_lg_default.png'}
                onMouseOver={'images/buttons/C2QB_white_btn_lg_hover.png'}
                onMouseOut={'images/buttons/C2QB_white_btn_lg_default.png'}/> */}
                {/* <img className="qb-card" style={{width:"200px"}}src="/images/buttons/C2QB_white_btn_lg_default.png"></img> */}
                
                </CardActions>
            </Card>

        </>)
    }
return (
    <Styles>
        {
            displayConnectToQuickbooks()
        }
        {
            displayCompanyInfo()
        }     
    </Styles>)
}

export default Quickbooks