

import React, { useContext, useState, useEffect } from "react";
import aws_config from "../aws-exports";
import Storage from "@aws-amplify/storage";

function ProductImage(props) {

    const [url, setUrl] = useState("");    
    
    const getImageForProduct = async (product) => {                
        if (product.imageAsset) {
            if (product.imageAsset && url == "") {
                var re = /(?:\.([^.]+))?$/;
                let extensionString = re.exec(product.imageAsset);
                let imageKey = product.imageAsset.replace(extensionString[0],""); 
                imageKey = imageKey + (props.suffix ? props.suffix : ".png");
                let result = await Storage.get(imageKey, { level: 'public', bucket: aws_config.aws_user_files_s3_bucket, 
                  region:aws_config.aws_user_files_s3_bucket_region});                       
                  if (result) {
                      setUrl(result);
                  } else {
                      setUrl(product.image);
                  } 
              }                    
        }else if (product.image) {
            setUrl(product.image);
        }        
      }
  
    const getImageData = ()=>{
        if (url) {
            return url;
        }
        if (props.product.imageThumbBase64) {            
            return "data:image/png;base64," + props.product.imageThumbBase64;
        }
        // Loading image
        return "";
    }


    useEffect(()=>{
        getImageForProduct(props.product)
    });

    return <>
        <img className={props.customClassName ? props.customClassName : "cart-image"} src={getImageData()} alt={""}/>                        
    </>
}

export default ProductImage;