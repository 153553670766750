import React , {useContext, useEffect, useState} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

import ProductContext from "../context/products";


import Select from '@material-ui/core/Select';
import {GetSeriesImageByName, GetSeriesKeys, GetSetForSeries, GetSetImageByName, isMobile, GetIsExcludedFromSeries} from './Helper';
import { makeStyles } from "@material-ui/core/styles";

import { Header, Popup, Grid } from 'semantic-ui-react'
import { Paper } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles({
  root:{
    paddingTop: "0.5px",
    paddingBottom: "0.5px",
    paddingRight:"1em"
  },
  rootCheckbox:{
    paddingTop: "0.5px",
    paddingBottom: "0.5px",
    paddingRight:"0.1em",
    paddingLeft:"0.4em"
  },

  MuiInputBase:{
    padding: 0
  },
  
  MuiButtonLabel:{
    fontFamily:"Roboto",
    fontSize:"1em",
    fontWeight:"400",
    color:"#3e4244"
    // textShadow:"0 0 5px #fff, 0 0 5px #fff, 0 0 5px #0073e6, 0 0 5px #0073e6, 0 0 5px #0073e6, 0 0 5px #0073e6, 0 0 5px #0073e6"
  },

  MuiButtonLabelGlow:{
    fontFamily:"Roboto",
    fontSize:"1em",
    fontWeight:"400",
    color:"#3e4244"
    // textShadow:"0 0 5px #fff, 0 0 5px #fff, 0 0 5px #0073e6, 0 0 5px #0073e6, 0 0 5px #0073e6, 0 0 5px #0073e6, 0 0 5px #0073e6"
  },
  MuiIconButton: {
    padding:"0em",
    paddingRight:"0.1em",
    margin:"0em"
  },
  MuiButtonLabelDisabled:{
    fontFamily:"Roboto",
    fontSize:"1em",
    fontWeight:"400",
    color:"#ccc"

  }

});

function NavigatorSelector(props) {

    const [selectedSetIndex, setSelectedSetIndex] = useState(0);
    const {showInStockOnly, setShowInStockOnly, seriesNameSelected, updateSeriesNameSelected, singlesFilters, updateSinglesFilters, builtFilters, getSetCodeByName } = useContext(ProductContext);
    const classes = useStyles();    
  
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const history = useHistory();

    useEffect(() => {
      let seriesKeys = GetSeriesKeys(false, null);        
      for (let i = 0; i < seriesKeys.length; i++) {
        if (seriesKeys[i] == seriesNameSelected) {
          setSelectedSetIndex(i);
        }
      }
    },[]);

    const handleChange = (event) => {    
        let selectionIndex = event.target.value;         
        setSelectedSetIndex(selectionIndex);        
        let setSeriesArray = getSetAndSeriesIndexValues();
        for (let i = 0; i < setSeriesArray.length; i++ ){
            if (setSeriesArray[i].key == selectionIndex) {
                if (setSeriesArray[i].type == "series") {
                updateSeriesNameSelected(setSeriesArray[i].name);        
                }else if (setSeriesArray[i].type == "set") {
                history.push('/s?k=' + setSeriesArray[i].name);
                }
            }
        }        
      };    

      const handleChangeCheckbox = (event) => {
        setShowInStockOnly(event.target.checked);
      }

      const getSetAndSeriesIndexValues = ()=>{
        let offset = 0;
        let nodeArray = [];

         GetSeriesKeys(false, null).map((series, index) => {
          nodeArray.push({name:series, key:index+offset, type:"series", image:GetSeriesImageByName(series), height:"50px", style:{padding:"0px",paddingLeft:"0px"}, })                        
          GetSetForSeries(series).map((set, indexTwo) =>{
              if (GetIsExcludedFromSeries(set)) {
                offset = offset + 1;              
                nodeArray.push({name:set, key:index+offset, type:"set", image:GetSetImageByName(set), height:"30px", style:{padding:"0px",paddingLeft:"20px"}, setCode:getSetCodeByName(set)})              
              }
              });              
              return (nodeArray)
          })

          return nodeArray;
      }
      
      const displaySetMenuItems = ()=> {
        let setSeriesArray = getSetAndSeriesIndexValues();
        return setSeriesArray.map(item =>{
          return(<MenuItem
                style={item.style}
                  key={item.key}
                  value={item.key}>
                      {item.setCode ? <p className="selector-setcode">{item.setCode}</p> : <></>}
                      <img height={item.height} src={item.image}/>
                  </MenuItem>)
        })
        
        // return GetSeriesKeys(false, null).map((series, index) => {
        //     let nodeArray = [];
        //     nodeArray.push(<MenuItem
        //       key={index + offset}
        //       value={index + offset}>
        //           <img height="50px" src={GetSeriesImageByName(series)}/>
        //       </MenuItem>)              
        //       GetSetForSeries(series).map((set, indexTwo) =>{
        //         offset = offset + 1;
        //         nodeArray.push(<MenuItem 
        //       key={index + offset}
        //       value={index + offset}>
        //         <img height="40px" src={GetSetImageByName(set)}/>
        //       </MenuItem>) });
        //         return (nodeArray)
        //     })
    }

    const flipFilters = (filternames) => {

      let allOn = true;
      let allOff = true;

      for (let i = 0; i < filternames.length; i++) {
          let filtername = filternames[i];
          if (singlesFilters[filtername]) {
            allOff = false;            
          } else {
            allOn = false;
            singlesFilters[filtername] = false;
          }
      }

      let updatefilter = {...singlesFilters};

      if (allOff) {
        for (let i = 0; i < filternames.length; i++) {
            let filtername = filternames[i];
            updatefilter[filtername] = true;
        }  
      } else if (allOn) {
        for (let i = 0; i < filternames.length; i++) {
          let filtername = filternames[i];
          updatefilter[filtername] = false;
        }  

      } else {
        for (let i = 0; i < filternames.length; i++) {
          let filtername = filternames[i];
          updatefilter[filtername] = true;
        }  
      }

      updateSinglesFilters({...updatefilter});
      
      if (props.updateFilters) {
        props.updateFilters(updatefilter);
     }
    }


    const anyFiltersAvailable = ()=>{
      let filternames = Object.keys(builtFilters);
      if (filternames && filternames.length > 0) {
        for (let i = 0; i < filternames.length; i++) {
          let filtername = filternames[i];
          if (builtFilters[filtername] && Object.keys(builtFilters[filtername]).length > 0) {
            return true;   
          }
        }        
      }
      return false;
    }

    const getFilterChecked = (name)=>{
      if (singlesFilters[name]){
        return singlesFilters[name];  
      }
      return false;
    }

    const displayCheckbox = (name)=>{
      return(<p style={{textAlign:"left"}} key={name} ><FormControlLabel
              control={
                  <Checkbox    
                  color="primary"
                  checked={getFilterChecked(name)}
                  inputProps={{ 'aria-label': 'filter active' }}
                  classes = {{
                    root:classes.MuiIconButton
                  }}
                  onChange={e => { 
                      let updatefilter = {...singlesFilters};
                      updatefilter[name] = e.target.checked;
                      updateSinglesFilters(updatefilter);
                      if (props.updateFilters) {
                          props.updateFilters(updatefilter);
                      }
                    }}
                  />}     
                  label={name}/></p>)
          };


          const buildSuperTypeCheckboxes = ()=>{

            if (builtFilters && builtFilters.SuperType) {
               let filterKeys = Object.keys(builtFilters.SuperType);  
               return filterKeys.map(filterKey=>{
                return displayCheckbox(filterKey);
               })      
            }   
          }


          const buildEnergyTypeCheckboxes = ()=>{

            if (builtFilters && builtFilters.EnergyType) {
               let filterKeys = Object.keys(builtFilters.EnergyType);  
               return filterKeys.map(filterKey=>{
                return displayCheckbox(filterKey);
               })      
            }   
          }


          const buildRarityCheckboxes = ()=>{ 

            if (builtFilters && builtFilters.Rarity) {
               let filterKeys = Object.keys(builtFilters.Rarity);  
               return filterKeys.map(filterKey=>{
                return displayCheckbox(filterKey);
               })      
            }   
          }
                
          const drawFilterSingles = ()=>{

            return <div style={{paddingLeft:"0em",paddingTop:"0.7em", display:"inline-block"}}> 
           
            <Popup                 
                flowing
                on='click'
                pinned
                trigger={                             
                  <Button 
                  classes = {{
                    root:anyFiltersAvailable() ? classes.MuiButtonLabel : classes.MuiButtonLabelDisabled
                  }}
                  variant="contained" content='Button'> { isMobile() ? <FilterListIcon/> : <>Filter Singles</> }</Button>
                }
                content={<>                    
                  <Grid centered divided columns={3}>
                    <Grid.Column textAlign='center'>
                      <Header className="popup-header .noselect" as='h4' onClick={()=>{
                        flipFilters(Object.keys(builtFilters.SuperType));
                      }}>Card Type</Header>
                      {buildSuperTypeCheckboxes()}
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                      <Header className="popup-header" as='h4' onClick={()=>{
                        flipFilters(Object.keys(builtFilters.EnergyType));
                      }}>Energy Type</Header>
                      {buildEnergyTypeCheckboxes()}
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                      <Header className="popup-header" as='h4' onClick={()=>{
                        flipFilters(Object.keys(builtFilters.Rarity));
                      }}>Rarity Name</Header>
                      {buildRarityCheckboxes()}
                    </Grid.Column>
                  </Grid>
                  </>}
                position='bottom center'
              />
      
            </div>      
          }



      const displaySetSelector = (products) => {
        return (<div className="card-set-dropdown">
        <FormGroup row>
          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">Choose a Set</InputLabel>        
            <Select
              classes={{
                root: classes.root
              }}
              labelId="demo-simple-select-outlined-label"
              value={selectedSetIndex}  
              onChange={handleChange}
              label="Series Selection">        
              {
                displaySetMenuItems()
              }
            </Select>     
          </FormControl>
          <FormControlLabel
          classes={{
              root:classes.rootCheckbox
          }}
        control={<Checkbox checked={showInStockOnly} onChange={handleChangeCheckbox} name="showInStockOnly" />}
        label="In Stock"
      />
          
        {
          drawFilterSingles()
        }
        </FormGroup>
        </div>);        
      }
    

    return<>     
    <div className="set-selector">
        
        <div className="set-selector-dropdown">
            {
            displaySetSelector(null)
            }       
        </div>
    </div>    
    </>
}


export default NavigatorSelector;