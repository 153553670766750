import React, {useContext, useEffect, useState} from 'react';
import {API} from "@aws-amplify/api";
import ReactDOM from 'react-dom';
import UserContext from "../context/user";
import UiContext from "../context/ui";

function PayPalButton(props) {

    let PayPalButtonDriver = window.paypal.Buttons.driver('react', { React, ReactDOM });

    const { user, fetchUserItem, addUserItem, updateCreateUserItem} = useContext(UserContext);
    const { setBusy } = useContext(UiContext);

    const [orderDetails, setOrderDetails] = useState({...props.orderDetails});
      
      const createOrder = async (data, actions) => {

        if (props && props.guestCheckout) {
            console.log("IS GUEST CHECKOUT");
        } else {

        }
        let userSub = props.guestCheckout ? orderDetails.email : user.sub;

        // Use stripe to store customer Id , paypal does not have a customer list
        let customerIdResult = await fetchUserItem({id:"CustomerId", name:"stripe-customer-id",type:"Stripe"}, userSub);
        let orderNumberResult = "";

        setBusy(true);

        let customerId = null;
        if (customerIdResult && customerIdResult.data && customerIdResult.data.getUserItem && customerIdResult.data.getUserItem.data) {
          customerId = customerIdResult.data.getUserItem.data;
        }


        let intent = await API.post("yayfunthingsapi", "/items/createPaymentIntent", 
        {
         body:{
            user:userSub, 
            amount:orderDetails.total,
            taxCost:orderDetails.taxCost, 
            coupon:orderDetails.coupon,
            cart:orderDetails.cart, 
            customerId:customerId, 
            customer:orderDetails.email, 
            shippingAddress:orderDetails.shippingAddress, 
            shippingCost:orderDetails.shippingCost,
            billingAddress:orderDetails.billingAddress,
            paymentProcessor:"PayPal"
          }
          }).then( async result => {        
            
            if (result.error) {
                
            }else {
                  
                if (customerId == null && !props.guestCheckout) {
                    await addUserItem({type:"Stripe", id:"CustomerId", name:"stripe-customer-id", data:result.customer}, userSub);
                  }
                  
                  let updatedOrderDetails = {
                    ...orderDetails,
                    paymentIntentId:result.id,
                    orderNumber:result.metadata.order_number,
                    paymentMethod:"PayPal"
                  };

                  setOrderDetails(updatedOrderDetails);
                  props.updateOrderDetails(updatedOrderDetails);
                  orderNumberResult = result.metadata.order_number;

                return result;
            }
        }).catch(e=>{console.log(e)});

        let items = [];
        
        for (let i = 0; i < orderDetails.cart.length; i++) {
            let item  = orderDetails.cart[i];
            items.push(
                {   
                    name:item.title, 
                    sku:item.id,
                    unit_amount: {
                        currency_code: "USD",
                        value: item.price
                    },                        
                    quantity:item.amount
                })            
        }

        
        let purchaseUnits = [];
        
        let taxCost = (parseFloat(orderDetails.total) + parseFloat(orderDetails.shippingCost)) * parseFloat(orderDetails.taxCost);
       
        if (taxCost > 0) {
            taxCost = parseFloat(taxCost).toFixed(2);
        }
        let totalCost = parseFloat(orderDetails.total) + parseFloat(orderDetails.shippingCost) + parseFloat(taxCost);

        purchaseUnits.push({
            amount: {
                currency_code: "USD",
                value:totalCost.toFixed(2),
                breakdown: {
                    item_total: {
                        currency_code: "USD",
                        value:props.orderDetails.total
                    },
                    shipping: {
                        currency_code: "USD",
                        value:props.orderDetails.shippingCost
                    },
                    tax_total: {
                        currency_code: "USD",
                        value:taxCost
                    }
                }
            },
            reference_id: intent.metadata.order_number,
            invoice_id:intent.metadata.order_number,
            items: items,
            shipping: {
                method: "USPS",
                address: {
                    name: {
                        full_name: props.orderDetails.shippingAddress.firstName,
                        surname: props.orderDetails.shippingAddress.lastName
                    },
                    address_line_1: props.orderDetails.shippingAddress.addressLine1,
                    address_line_2: props.orderDetails.shippingAddress.addressLine2,
                    admin_area_2: props.orderDetails.shippingAddress.city,
                    admin_area_1: props.orderDetails.shippingAddress.state,
                    postal_code: props.orderDetails.shippingAddress.postalCode,
                    country_code: props.orderDetails.shippingAddress.country
                }
            }
        });
    
        let result =  await actions.order.create({
            purchase_units:purchaseUnits
        });
        
          if (!props.guestCheckout) {
            await updateCreateUserItem({type:"PaymentIntent", id:result, name:"payment-intent", data:orderNumberResult, status:"Pending"}, true);
        }

        return result;
    }

    const onApprove = async (data,actions) => {

        let userItemResult = await fetchUserItem({id:data.orderID, type:"PaymentIntent"}, props.guestCheckout ? orderDetails.email : user.sub);
 
        if (userItemResult) {

            let orderNumber = userItemResult.data.getUserItem.data;
            let order = actions.order.get().then(async function(data) {

            if (data.status == "APPROVED" && data.intent == "AUTHORIZE") 
            {
                await API.post("yayfunthingsapi", "/items/authorizePayPalOrder", { 
                    body:{orderId:data.id}
                }).then(async result => { 
                        if (result && result.result && result.result.purchase_units && result.result.purchase_units[0].payments.authorizations[0].id) {
                            let authorizationId = result.result.purchase_units[0].payments.authorizations[0].id;
                            props.startCheckout(data.id, orderNumber, authorizationId);
                        }                    
                    
                }).catch(e=>{console.log(e)});
            }
            if (data.status == "APPROVED" && data.intent == "CAPTURE") 
               {
                    props.startCheckout(data.id, orderNumber);    
                }                               
            }).catch(e => {console.log(e)});
         }
        
         setBusy(false);
    }
    

    return (<>
             <PayPalButtonDriver
                        createOrder={ (data, actions) => createOrder(data, actions) }
                        onApprove={ (data, actions) => onApprove(data, actions) }
                    />
    </>)
}

export default PayPalButton