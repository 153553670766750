import React, { useContext, useState, useEffect } from "react";
import ProductContext from "../context/products";
import ProductCard from '../components/ProductCard';
import ProductSingles from './ProductSingles';
import ProductSeriesHeader from "./ProductSeriesHeader";
import ProductSetHeader from "./ProductSetHeader";
import {GetSetForSeries, GetSeriesImageByName} from './Helper';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";
import Banner from './Banner';
import ConsoleLog from '../components/yayfun/ConsoleLog';
import Skeleton from '@material-ui/lab/Skeleton';
import { isMobile } from '../components/Helper'

const useStyles = makeStyles({
  root:{
    paddingTop: "0.5px",
    paddingBottom: "0.5px"
  },
  MuiInputBase:{
    padding: 0
  }
});

function ProductDraw(props) {

  const {showInStockOnly, seriesNameSelected, setItems, getSetIsHiddenByName} = useContext(ProductContext);

  console.log("seriesNameSelected:", seriesNameSelected);

  const classes = useStyles();

  const [selectedSetIndex, setSelectedSetIndex] = useState(1);

  const getSetsForSeries = (products, isSearch, seriesName)=>{
    return isSearch ? products[seriesName] ? Object.keys(products[seriesName]) : [] : GetSetForSeries(seriesName);
  }

  const seriesKeys = (isSearch, products)=> {    
    return isSearch ? Object.keys(products) : ["Scarlet & Violet", "Sword & Shield", "Sun and Moon", "XY"];
  }

  const handleChange = (event) => {    
    let selectionIndex = event.target.value;        
    setSelectedSetIndex(selectionIndex);      
  };    

  const displaySetMenuItems = (products)=> {
    return seriesKeys(false, products).map((set, index) => {
            return (
            <MenuItem     inputProps={{
              style: {
                padding:"5px"
              }
           }} value={index + 1}>
              <img height="50px" src={GetSeriesImageByName(set)}/>
            </MenuItem>
            )
        })
    }


  const displaySetSelector = (products)=> {
    return (<div className="card-set-dropdown">
      <FormControl variant="outlined" inputProps={{
              style: {
                padding:"5px"
              }
           }} >
        <InputLabel id="demo-simple-select-outlined-label">Choose a Series</InputLabel>
        <Select
          classes={{
            root: classes.root
          }}
          labelId="demo-simple-select-outlined-label"
          value={selectedSetIndex}
          onChange={handleChange}
          label="Series Selection">        
          {
            displaySetMenuItems(products)
          }
        </Select>     
      </FormControl>
    </div>);}


const drawSetItems = (items )=>{

  let itemInStockCount = 0;
  if (items) {
    let result = items.map((item, index) =>  {
      if ((item.quantity > 0 && showInStockOnly) ||  !showInStockOnly) {
        itemInStockCount++;
        return (<ProductCard key={index} product={item} quickViewProduct={props.quickViewProduct} />)
      }                    
    });

    if (itemInStockCount == 0 && showInStockOnly) {
      return <p className="fun-title"></p>
    }  else {
      return result;
    }
  }

}
  const drawProductsBySeriesForHomePage = (products)=> {
    

    console.log("drawProductsBySeriesForHomePage:", products);

    return seriesKeys(props.isSearch, products).map((seriesKey, index) => {    

      if (seriesKey == seriesNameSelected) {
        let setKeys = getSetsForSeries(products, props.isSearch, seriesKey);  

        return <div key={seriesKey + "-" + index} className="product-series-set-group">
        { 
         setKeys && setKeys.map((setKey, index) => {  
            let items = products[seriesKey] ? products[seriesKey][setKey] ? products[seriesKey][setKey] : [] : [];            
            return < div key={seriesKey+setKey+index}>                 
            {
              getSetIsHiddenByName(setKey) ? <></> :<>
              
              <div className="product-group-container" key={setKey + "-" + index} style={{marginLeft:"0.25em", marginRight:"0.25em", paddingTop:index > 0 ? "2em":"0em", paddingBottom:"2em" }}>                
                  
                  <ProductSetHeader series={seriesKey} setKey={setKey} items={items} products={products}/>                
                  {
                    items && items.length > 0 ? <></> :<>
                    {
                      [0,1,2,3,4].map(i => {
                        return  <Skeleton key={"skel_"+i} variant="rect" animation="wave" height={"250px"} width={"13em"} style={{borderRadius:"4px", display:"inline-block", marginLeft:"auto", marginRight:"auto", padding:"0em", margin:isMobile() ? "0.1em":"1em", marginTop:"0em"}} />
                      })
                    }
                      </>
                    }
                    { 
                     drawSetItems(items, index)
                    }                  
                    {
                      props.hideSetCards ? <></> : <>
                      <div className="product-card-singles">     
                      <ProductSingles products={products} setKey={setKey} search={true} quickViewProduct={props.quickViewProduct} showInStockOnly={showInStockOnly}/>                   
                      </div> </>
                  }
                </div>
              </>
            }
            </div>
          })             
         }
        </div>
      }

  })
}

const drawProductsForProductDetailsPage = (seriesKey, setKey, products)=> {

        return(<div className="product-group-container" style={{marginLeft:"0.25em", marginRight:"0.25em", paddingTop:"0em", paddingBottom:"2em" }}>                
                  
                  <ProductSetHeader series={seriesKey} setKey={setKey} />                
                  {
                    products && products.length > 0 ? <></> :<>
                    {
                      [0,1,2,3,4].map(i => {
                        return  <Skeleton variant="rect" animation="wave" height={"250px"} width={"13em"} style={{borderRadius:"4px", display:"inline-block", marginLeft:"auto", marginRight:"auto", padding:"0em", margin:isMobile() ? "0.1em":"1em", marginTop:"0em"}} />
                      })
                    }
                      </>
                    }
                    { 
                     drawSetItems(products)
                    }                  
                    {
                      props.hideSetCards ? <></> : <>
                      <div className="product-card-singles">     
                      <ProductSingles products={products} setKey={setKey} search={true} quickViewProduct={props.quickViewProduct} showInStockOnly={showInStockOnly}/>                   
                      </div> </>
                  }
                </div>)

}


const drawProductsBySeriesForSearch = (products)=> {
    
  return seriesKeys(props.isSearch, products).map((seriesKey, index) => {
    
    let setKeys = getSetsForSeries(products, props.isSearch, seriesKey);  
             
    return <div key={seriesKey + "-" + index} className="product-series-set-group">              
   
    <ProductSeriesHeader series={seriesKey} imgSrc={GetSeriesImageByName(seriesKey)} isSearch={props.isSearch}/>        
    { 
     setKeys && setKeys && setKeys.map((setKey, index) => {
     
      let items = products[seriesKey] ? products[seriesKey][setKey] ? products[seriesKey][setKey]['Sealed'] ? products[seriesKey][setKey]['Sealed'] : [] : [] : [];            
      let singles = products[seriesKey] ? products[seriesKey][setKey] ? products[seriesKey][setKey]['Single'] ? products[seriesKey][setKey]['Single'] : [] : [] : [];            

     return <div key={setKey + "-" + index} style={{minHeight:"400px", paddingTop:index > 0 ? "2em":"0em" }}>                
           <ProductSetHeader series={seriesKey} setKey={setKey} products={products} isSearch={props.isSearch}/>
            { 
              items.map((item, index) =>  {
                  return (<ProductCard key={index} product={item} quickViewProduct={props.quickViewProduct} />)
            })
            }
            {
              singles.length == 0 ? <></> : <>
              <div className="product-card-singles">     
              <ProductSingles products={singles} setKey={setKey} quickViewProduct={props.quickViewProduct}/>
              </div> </>
           }

        </div>
      })             
     }
    </div>
})
}


  const drawProductsBySeries = (products) => {    
    
    console.log("drawProductsBySeries:", products);
    if (products) {
      return <div style={{minWidth:"100%"}}>              
       { props.isSearch ?  drawProductsBySeriesForSearch(products) : props.detailsPage ? drawProductsForProductDetailsPage(products[0].series, products[0].set, products) : drawProductsBySeriesForHomePage(products)  }
      </div>
    } else {
      return <>
  {
    console.log("drawProductsBySeries products:", products)
  }
      {
        

        seriesKeys(props.isSearch, products).map((seriesKey, index) => {
          return <div key={seriesKey + "-" + index}>
              <ProductSeriesHeader series={seriesKey} imgSrc={GetSeriesImageByName(seriesKey)} isSearch={props.isSearch}/>
            </div>
        })
      }
      </>
    
    }
    return (<></>);
  }
  if (setItems && setItems.length <= 0) {
    return (<></>);
  }
  return (drawProductsBySeries(props.products))
}

export default ProductDraw