
import React, { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';

import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-blocks-basic';
import 'grapesjs-preset-newsletter';
import 'grapesjs-preset-newsletter/dist/grapesjs-preset-newsletter.css';

// editor = grapesjs.init({
//     container: "#editor-portal",
//     allowScripts: 1,
//     height: "100vh",
//     canvas: {
//        styles: selectedTemplateExternalStyles,
//        scripts: selectedTemplateExternalScripts
//     },
//     plugins: ["gjs-preset-webpage", "grapesjs-custom-code"],
//     pluginsOpts: {
//        "gjs-preset-webpage": {
//           exportOpts: {
//              filenamePfx: "hc",
//              filename: null,
//              addExportBtn: 1,
//              btnLabel: "Export to ZIP"
//           }
//        },
//        "grapesjs-custom-code": {}
//     },
//     storageManager: {
//        id: "gjs-",
//        type: "remote",
//        autosave: 1,
//        autoload: 1,
//        stepsBeforeSave: 1, // If autosave enabled, indicates how many steps (general changes to structure), need to be done before save. Useful with remoteStorage to reduce remote calls
//        urlLoad: API_ROOT + "/template/get/" + this.state.editorType + "/" + this.props.templateId, // Endpoint where to fetch data
//        urlStore: API_ROOT + "/template/save/" + this.props.templateId, // Endpoint where to save all stuff
//        headers: {
//           "x-apiKey": "XXXXXXXXXXXX"
//        },
//        contentTypeJson: true // false: 'x-www-form-urlencoded' // true: application/json; charset=utf-8'   // set contentType paramater of $.ajax
//     }
//  });

// editor.on('component:update', onUpdate); and then

//   handleOnUpdate = () => {
//     this.setState({
//       html: this.editor.runCommand('gjs-get-inlined-html'),
//     });
//   };


let editor = null;


function AdminGrape (props) {

    const handleOnUpdate = () => {
    
        try {

          let result = editor.runCommand('gjs-get-inlined-html');
          
          if (props.onHandleUpdate) {
            props.onHandleUpdate(result);
          }
        }
        catch (e) {
          console.log(e);
        }
        
    
    };

    
useEffect(()=>{

  editor = grapesjs.init ({
    container:'#gjs',
    // components:props.html,
    fromElement: 1,
    storageManager: { type: null },
    plugins: ['gjs-blocks-basic','gjs-preset-newsletter'],
    pluginsOpts: {
      'gjs-preset-newsletter': {
        modalTitleImport: 'Import template',
        inlineCss: true
        // ... other options
      }
    }
});

editor.on('component:update', handleOnUpdate);

}, [props.html])


// const domc = editor.DomComponents;
// const defaultType = domc.getType('default');
// const defaultModel = defaultType.model;
// const defaultView = defaultType.view;
// const textType = domc.getType('text');

// let bm = editor.BlockManager;
//  // Block C
//       bm.add('ev-name', {
//           label: 'Block C',
//           category: 'Basic',
//           content: {
//             content:'<span  data-gjs-type="default" ><#assign currentDate=. now></#assign>\n' +
//               '        <#list order.getCompletedOrderItemsGroups()?sort_by( "productId") as orderItemGroup>\n' +
//               '            <#assign event=orderItemGroup.event></#assign>\n' +
//               '            <#assign product=orderItemGroup.product></#assign>\n' +
//               '            <#assign size=orderItemGroup.orderItems?size></#assign>\n' +
//               '            <#assign eventDate=orderItemGroup.eventDate.getStartDateTz()?string( "EEEE dd MMMM, yyyy hh:mm a")></#assign>\n' +
//               '        </#list>\n' +
//               '        <#assign x=0></#assign></span>',
//             editable: false,
//             draggable: true,
//             stylable: true,
//             selectable: true
//           }
//       });


    // const editor = grapesjs.init({
    //     // Indicate where to init the editor. You can also pass an HTMLElement
    //     container: '#gjs',
    //     // Get the content for the canvas directly from the element
    //     // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
    //     fromElement: true,
    //     // Size of the editor
    //     height: '300px',
    //     width: 'auto',
    //     // Disable the storage manager for the moment
    //     storageManager: false,
    //     // Avoid any default panel
    //     panels: { defaults: [] },
    //     blockManager: {
    //         appendTo: '#blocks',
    //         blocks: [
    //           {
    //             id: 'section', // id is mandatory
    //             label: '<b>Section</b>', // You can use HTML/SVG inside labels
    //             attributes: { class:'gjs-block-section' },
    //             content: `<section>
    //               <h1>This is a simple title</h1>
    //               <div>This is just a Lorem text: Lorem ipsum dolor sit amet</div>
    //             </section>`,
    //           }, {
    //             id: 'text',
    //             label: 'Text',
    //             content: '<div data-gjs-type="text">Insert your text here</div>',
    //           }, {
    //             id: 'image',
    //             label: 'Image',
    //             // Select the component once it's dropped
    //             select: true,
    //             // You can pass components as a JSON instead of a simple HTML string,
    //             // in this case we also use a defined component type `image`
    //             content: { type: 'image' },
    //             // This triggers `active` event on dropped components and the `image`
    //             // reacts by opening the AssetManager
    //             activate: true,
    //           }
    //         ]
    //       },
    //   });

    // useEffect(() => {
    //     var editor = grapesjs.init({
    //       container: "#gjs2",
    //       height: "100%",
    //       fromElement: true,
    //       storageManager: { type: 0 },
    //       plugins: [ckeditor, "gjs-blocks-basic"],
    //       layerManager: {
    //         appendTo: "#layers-container"
    //       },
    //       blockManager: {
    //         appendTo: "#blocks"
    //       },
    //       styleManager: {
    //         appendTo: "#style-manager-container",
    //         sectors: [
    //           {
    //             name: "General",
    //             open: false,
    //             buildProps: [
    //               "float",
    //               "display",
    //               "position",
    //               "top",
    //               "right",
    //               "left",
    //               "bottom"
    //             ]
    //           },
    //           {
    //             name: "Dimension",
    //             open: false,
    //             buildProps: [
    //               "width",
    //               "height",
    //               "max-width",
    //               "min-height",
    //               "margin",
    //               "padding"
    //             ]
    //           },
    //           {
    //             name: "Typography",
    //             open: false,
    //             buildProps: [
    //               "font-family",
    //               "font-size",
    //               "font-weight",
    //               "letter-spacing",
    //               "color",
    //               "line-height",
    //               "text-align",
    //               "text-shadow"
    //             ]
    //           },
    //           {
    //             name: "Decorations",
    //             open: false,
    //             buildProps: [
    //               "border-radius-c",
    //               "background-color",
    //               "border-radius",
    //               "border",
    //               "box-shadow",
    //               "background"
    //             ]
    //           },
    //           {
    //             name: "Extra",
    //             open: false,
    //             buildProps: ["opacity", "transition", "perspective", "transform"],
    //             properties: [
    //               {
    //                 type: "slider",
    //                 property: "opacity",
    //                 defaults: 1,
    //                 step: 0.01,
    //                 max: 1,
    //                 min: 0
    //               }
    //             ]
    //           }
    //         ]
    //       },
    //       selectorManager: {
    //         appendTo: "#selectors-container"
    //       },
    //       traitManager: {
    //         appendTo: "#traits-container"
    //       },
    //       panels: {
    //         defaults: [
    //           {
    //             id: "layers",
    //             el: "#layers",
    //             resizable: {
    //               tc: 0,
    //               cr: 1,
    //               bc: 0,
    //               keyWidth: "flex-basis"
    //             }
    //           },
    //           {
    //             id: "styles",
    //             el: "#style-manager",
    //             resizable: {
    //               tc: 0,
    //               cr: 0,
    //               cl: 1,
    //               bc: 0,
    //               keyWidth: "flex-basis"
    //             }
    //           }
    //         ]
    //       }
    //     });
    
    //     const bm = editor.BlockManager;
    //     editor.on("load", () => {
    //       editor.BlockManager.render([
    //         bm.get("column1").set("category", ""),
    //         bm.get("column2").set("category", ""),
    //         bm.get("column3").set("category", ""),
    //         bm.get("text").set("category", ""),
    //         bm.get("image").set("category", "")
    //       ]);
    //     });
    
    //     editor.runCommand("sw-visibility");
    //   }, []);

    return (<>
    <div id="gjs" dangerouslySetInnerHTML={{__html:props.html}}>      

    
    </div>
    </>)
}

export default AdminGrape