import React, {useEffect, useContext} from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from '../context/user';

function Unsubscribe() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);    
    const email = params.get('email');
    const secret = params.get('secret');    

    const { unsubscribeFromEmail } = useContext(UserContext);

    
    const runUnsubscribeFromEmail = async ()=> {
        let result = unsubscribeFromEmail(email, secret);        
    }

    useEffect(()=>{
        runUnsubscribeFromEmail();
    },[]);

    return (<>
        <div className="container-inner">
            <div className="container-page">
            <h3 className="">Unsubscribed</h3>
            <p>{email}</p>
            </div>
        </div>
    </>)
}

export default Unsubscribe