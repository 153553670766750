/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      set
      title
      asin
      description
      image
      author
      brand
      featured
      price
      cost
      weight
      units
      unitsRemaining
      limit
      quantity
      variation {
        name
        quantity
        price
      }
      type
      subtype
      status
      allowPreorder
      salePrice
      salePriceEndDate
      moreDetails
      keyword
      releaseDate
      packs
      updatedAt
      msku
      upc
      condition
      cardId
      cardName
      cardType
      cardSubtype
      cardSubtypes
      ability {
        name
        text
        type
      }
      abilities {
        name
        text
        type
      }
      artist
      attacks {
        convertedEnergyCost
        cost
        damage
        name
        text
      }
      evolvesFrom
      evolvesTo
      ancientTrait
      level
      hp
      legalities {
        expanded
        standard
        unlimited
      }
      number
      text
      flavorText
      convertedRetreatCost
      imageUrl
      imageUrlHiRes
      imageUrlKey
      imageUrlHiResKey
      imageAsset
      imageThumbBase64
      nationalPokedexNumber
      nationalPokedexNumbers
      rarity
      regulationMark
      resistances {
        type
        value
      }
      retreatCost
      rules
      series
      setTotal
      setPrintedTotal
      setCode
      subsetCode
      supertype
      types
      weaknesses {
        type
        value
      }
      marketPrice
      marketPriceTimestamp
      medianPrice
      minPrice
      maxPrice
      autoPricing
      profit
      refunds {
        id
        paymentIntentId
        transactionId
        chargeId
        amount
        created
        status
      }
      liveOnAmazon
      liveOnWalmart
      liveOnTCGPlayer
      tcgplayer {
        updatedAt
        url
      }
      cardmarket {
        prices {
          averageSellPrice
          avg1
          avg30
          avg7
          germanProLow
          lowPrice
          lowPriceExPlus
          reverseHoloAvg1
          reverseHoloAvg30
          reverseHoloAvg7
          reverseHoloLow
          reverseHoloSell
          reverseHoloTrend
          suggestedPrice
          trendPrice
        }
        updatedAt
        url
      }
      createdAt
      orders {
        items {
          id
          product_id
          order_id
          price
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        set
        title
        asin
        description
        image
        author
        brand
        featured
        price
        cost
        weight
        units
        unitsRemaining
        limit
        quantity
        variation {
          name
          quantity
          price
        }
        type
        subtype
        status
        allowPreorder
        salePrice
        salePriceEndDate
        moreDetails
        keyword
        releaseDate
        packs
        updatedAt
        msku
        upc
        condition
        cardId
        cardName
        cardType
        cardSubtype
        cardSubtypes
        ability {
          name
          text
          type
        }
        abilities {
          name
          text
          type
        }
        artist
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }
        evolvesFrom
        evolvesTo
        ancientTrait
        level
        hp
        legalities {
          expanded
          standard
          unlimited
        }
        number
        text
        flavorText
        convertedRetreatCost
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        imageAsset
        imageThumbBase64
        nationalPokedexNumber
        nationalPokedexNumbers
        rarity
        regulationMark
        resistances {
          type
          value
        }
        retreatCost
        rules
        series
        setTotal
        setPrintedTotal
        setCode
        subsetCode
        supertype
        types
        weaknesses {
          type
          value
        }
        marketPrice
        marketPriceTimestamp
        medianPrice
        minPrice
        maxPrice
        autoPricing
        profit
        refunds {
          id
          paymentIntentId
          transactionId
          chargeId
          amount
          created
          status
        }
        liveOnAmazon
        liveOnWalmart
        liveOnTCGPlayer
        tcgplayer {
          updatedAt
          url
        }
        cardmarket {
          updatedAt
          url
        }
        createdAt
        orders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const productsBySet = /* GraphQL */ `
  query ProductsBySet(
    $set: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsBySet(
      set: $set
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        set
        title
        asin
        description
        image
        author
        brand
        featured
        price
        cost
        weight
        units
        unitsRemaining
        limit
        quantity
        variation {
          name
          quantity
          price
        }
        type
        subtype
        status
        allowPreorder
        salePrice
        salePriceEndDate
        moreDetails
        keyword
        releaseDate
        packs
        updatedAt
        msku
        upc
        condition
        cardId
        cardName
        cardType
        cardSubtype
        cardSubtypes
        ability {
          name
          text
          type
        }
        abilities {
          name
          text
          type
        }
        artist
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }
        evolvesFrom
        evolvesTo
        ancientTrait
        level
        hp
        legalities {
          expanded
          standard
          unlimited
        }
        number
        text
        flavorText
        convertedRetreatCost
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        imageAsset
        imageThumbBase64
        nationalPokedexNumber
        nationalPokedexNumbers
        rarity
        regulationMark
        resistances {
          type
          value
        }
        retreatCost
        rules
        series
        setTotal
        setPrintedTotal
        setCode
        subsetCode
        supertype
        types
        weaknesses {
          type
          value
        }
        marketPrice
        marketPriceTimestamp
        medianPrice
        minPrice
        maxPrice
        autoPricing
        profit
        refunds {
          id
          paymentIntentId
          transactionId
          chargeId
          amount
          created
          status
        }
        liveOnAmazon
        liveOnWalmart
        liveOnTCGPlayer
        tcgplayer {
          updatedAt
          url
        }
        cardmarket {
          updatedAt
          url
        }
        createdAt
        orders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const productsBySetType = /* GraphQL */ `
  query ProductsBySetType(
    $set: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsBySetType(
      set: $set
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        set
        title
        asin
        description
        image
        author
        brand
        featured
        price
        cost
        weight
        units
        unitsRemaining
        limit
        quantity
        variation {
          name
          quantity
          price
        }
        type
        subtype
        status
        allowPreorder
        salePrice
        salePriceEndDate
        moreDetails
        keyword
        releaseDate
        packs
        updatedAt
        msku
        upc
        condition
        cardId
        cardName
        cardType
        cardSubtype
        cardSubtypes
        ability {
          name
          text
          type
        }
        abilities {
          name
          text
          type
        }
        artist
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }
        evolvesFrom
        evolvesTo
        ancientTrait
        level
        hp
        legalities {
          expanded
          standard
          unlimited
        }
        number
        text
        flavorText
        convertedRetreatCost
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        imageAsset
        imageThumbBase64
        nationalPokedexNumber
        nationalPokedexNumbers
        rarity
        regulationMark
        resistances {
          type
          value
        }
        retreatCost
        rules
        series
        setTotal
        setPrintedTotal
        setCode
        subsetCode
        supertype
        types
        weaknesses {
          type
          value
        }
        marketPrice
        marketPriceTimestamp
        medianPrice
        minPrice
        maxPrice
        autoPricing
        profit
        refunds {
          id
          paymentIntentId
          transactionId
          chargeId
          amount
          created
          status
        }
        liveOnAmazon
        liveOnWalmart
        liveOnTCGPlayer
        tcgplayer {
          updatedAt
          url
        }
        cardmarket {
          updatedAt
          url
        }
        createdAt
        orders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSubscriber = /* GraphQL */ `
  query GetSubscriber($id: ID!) {
    getSubscriber(id: $id) {
      id
      name
      email
      subscribed
      secret
      usedCoupon
      rejected
      verified
      createdAt
      updatedAt
    }
  }
`;
export const listSubscribers = /* GraphQL */ `
  query ListSubscribers(
    $filter: ModelSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscribers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        subscribed
        secret
        usedCoupon
        rejected
        verified
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      type
      id
      user
      owner
      date
      coupon
      customer
      number
      total
      discount
      subtotal
      shippingCost
      shippingPaid
      taxCost
      status
      shippingMethod
      authorizationId
      paymentMethod
      paymentIntentId
      paypalOrderId
      paymentType
      billingAddress {
        firstName
        lastName
        name
        addressLine1
        addressLine2
        country
        postalCode
        companyName
        phoneNumber
        city
        state
        default
      }
      shippingAddress {
        firstName
        lastName
        name
        addressLine1
        addressLine2
        country
        postalCode
        companyName
        phoneNumber
        city
        state
        default
      }
      codes {
        id
        value
        name
        product_id
        notes
        emailed
      }
      packages {
        id
        status
        trackingNumber
        carrier
        products {
          amount
          product_id
        }
      }
      search
      promiseDateMin
      promiseDateMax
      history {
        createdAt
        description
      }
      createdAt
      updatedAt
      products {
        items {
          id
          product_id
          order_id
          price
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        type
        id
        user
        owner
        date
        coupon
        customer
        number
        total
        discount
        subtotal
        shippingCost
        shippingPaid
        taxCost
        status
        shippingMethod
        authorizationId
        paymentMethod
        paymentIntentId
        paypalOrderId
        paymentType
        billingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        codes {
          id
          value
          name
          product_id
          notes
          emailed
        }
        packages {
          id
          status
          trackingNumber
          carrier
        }
        search
        promiseDateMin
        promiseDateMax
        history {
          createdAt
          description
        }
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOrderByNumber = /* GraphQL */ `
  query GetOrderByNumber(
    $number: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByNumber(
      number: $number
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        type
        id
        user
        owner
        date
        coupon
        customer
        number
        total
        discount
        subtotal
        shippingCost
        shippingPaid
        taxCost
        status
        shippingMethod
        authorizationId
        paymentMethod
        paymentIntentId
        paypalOrderId
        paymentType
        billingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        codes {
          id
          value
          name
          product_id
          notes
          emailed
        }
        packages {
          id
          status
          trackingNumber
          carrier
        }
        search
        promiseDateMin
        promiseDateMax
        history {
          createdAt
          description
        }
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listOrdersByDate = /* GraphQL */ `
  query ListOrdersByDate(
    $type: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByDate(
      type: $type
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        type
        id
        user
        owner
        date
        coupon
        customer
        number
        total
        discount
        subtotal
        shippingCost
        shippingPaid
        taxCost
        status
        shippingMethod
        authorizationId
        paymentMethod
        paymentIntentId
        paypalOrderId
        paymentType
        billingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        codes {
          id
          value
          name
          product_id
          notes
          emailed
        }
        packages {
          id
          status
          trackingNumber
          carrier
        }
        search
        promiseDateMin
        promiseDateMax
        history {
          createdAt
          description
        }
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listOrdersByOwnerDate = /* GraphQL */ `
  query ListOrdersByOwnerDate(
    $owner: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByOwnerDate(
      owner: $owner
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        type
        id
        user
        owner
        date
        coupon
        customer
        number
        total
        discount
        subtotal
        shippingCost
        shippingPaid
        taxCost
        status
        shippingMethod
        authorizationId
        paymentMethod
        paymentIntentId
        paypalOrderId
        paymentType
        billingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        codes {
          id
          value
          name
          product_id
          notes
          emailed
        }
        packages {
          id
          status
          trackingNumber
          carrier
        }
        search
        promiseDateMin
        promiseDateMax
        history {
          createdAt
          description
        }
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSiteItem = /* GraphQL */ `
  query GetSiteItem($id: ID!) {
    getSiteItem(id: $id) {
      type
      id
      data
      status
      createdAt
      updatedAt
    }
  }
`;
export const listSiteItems = /* GraphQL */ `
  query ListSiteItems(
    $filter: ModelSiteItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        type
        id
        data
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByType = /* GraphQL */ `
  query ItemsByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelSiteItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        type
        id
        data
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserItem = /* GraphQL */ `
  query GetUserItem($owner: String!, $type: String!, $id: String!) {
    getUserItem(owner: $owner, type: $type, id: $id) {
      owner
      type
      id
      name
      data
      createdAt
      status
      history {
        createdAt
        description
      }
      shippingAddress {
        firstName
        lastName
        name
        addressLine1
        addressLine2
        country
        postalCode
        companyName
        phoneNumber
        city
        state
        default
      }
      updatedAt
      links {
        items {
          owner
          type
          id
          name
          data
          createdAt
          status
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUserItems = /* GraphQL */ `
  query ListUserItems(
    $owner: String
    $typeId: ModelUserItemPrimaryCompositeKeyConditionInput
    $filter: ModelUserItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserItems(
      owner: $owner
      typeId: $typeId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        type
        id
        name
        data
        createdAt
        status
        history {
          createdAt
          description
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        updatedAt
        links {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listUserItemByOwnerType = /* GraphQL */ `
  query ListUserItemByOwnerType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserItemByOwnerType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        type
        id
        name
        data
        createdAt
        status
        history {
          createdAt
          description
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        updatedAt
        links {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAnalytics = /* GraphQL */ `
  query GetAnalytics(
    $owner: String!
    $type: String!
    $createdAt: AWSDateTime!
    $id: ID!
  ) {
    getAnalytics(owner: $owner, type: $type, createdAt: $createdAt, id: $id) {
      owner
      type
      id
      createdAt
      updatedAt
      amazonPrice
      instockQty
      soldQty
      data
      product {
        id
        set
        title
        asin
        description
        image
        author
        brand
        featured
        price
        cost
        weight
        units
        unitsRemaining
        limit
        quantity
        variation {
          name
          quantity
          price
        }
        type
        subtype
        status
        allowPreorder
        salePrice
        salePriceEndDate
        moreDetails
        keyword
        releaseDate
        packs
        updatedAt
        msku
        upc
        condition
        cardId
        cardName
        cardType
        cardSubtype
        cardSubtypes
        ability {
          name
          text
          type
        }
        abilities {
          name
          text
          type
        }
        artist
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }
        evolvesFrom
        evolvesTo
        ancientTrait
        level
        hp
        legalities {
          expanded
          standard
          unlimited
        }
        number
        text
        flavorText
        convertedRetreatCost
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        imageAsset
        imageThumbBase64
        nationalPokedexNumber
        nationalPokedexNumbers
        rarity
        regulationMark
        resistances {
          type
          value
        }
        retreatCost
        rules
        series
        setTotal
        setPrintedTotal
        setCode
        subsetCode
        supertype
        types
        weaknesses {
          type
          value
        }
        marketPrice
        marketPriceTimestamp
        medianPrice
        minPrice
        maxPrice
        autoPricing
        profit
        refunds {
          id
          paymentIntentId
          transactionId
          chargeId
          amount
          created
          status
        }
        liveOnAmazon
        liveOnWalmart
        liveOnTCGPlayer
        tcgplayer {
          updatedAt
          url
        }
        cardmarket {
          updatedAt
          url
        }
        createdAt
        orders {
          nextToken
        }
      }
    }
  }
`;
export const listAnalyticss = /* GraphQL */ `
  query ListAnalyticss(
    $owner: String
    $typeCreatedAtId: ModelAnalyticsPrimaryCompositeKeyConditionInput
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnalyticss(
      owner: $owner
      typeCreatedAtId: $typeCreatedAtId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        type
        id
        createdAt
        updatedAt
        amazonPrice
        instockQty
        soldQty
        data
        product {
          id
          set
          title
          asin
          description
          image
          author
          brand
          featured
          price
          cost
          weight
          units
          unitsRemaining
          limit
          quantity
          type
          subtype
          status
          allowPreorder
          salePrice
          salePriceEndDate
          moreDetails
          keyword
          releaseDate
          packs
          updatedAt
          msku
          upc
          condition
          cardId
          cardName
          cardType
          cardSubtype
          cardSubtypes
          artist
          evolvesFrom
          evolvesTo
          ancientTrait
          level
          hp
          number
          text
          flavorText
          convertedRetreatCost
          imageUrl
          imageUrlHiRes
          imageUrlKey
          imageUrlHiResKey
          imageAsset
          imageThumbBase64
          nationalPokedexNumber
          nationalPokedexNumbers
          rarity
          regulationMark
          retreatCost
          rules
          series
          setTotal
          setPrintedTotal
          setCode
          subsetCode
          supertype
          types
          marketPrice
          marketPriceTimestamp
          medianPrice
          minPrice
          maxPrice
          autoPricing
          profit
          liveOnAmazon
          liveOnWalmart
          liveOnTCGPlayer
          createdAt
        }
      }
      nextToken
    }
  }
`;
export const listingsByOwnerType = /* GraphQL */ `
  query ListingsByOwnerType(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listingsByOwnerType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        type
        id
        createdAt
        updatedAt
        amazonPrice
        instockQty
        soldQty
        data
        product {
          id
          set
          title
          asin
          description
          image
          author
          brand
          featured
          price
          cost
          weight
          units
          unitsRemaining
          limit
          quantity
          type
          subtype
          status
          allowPreorder
          salePrice
          salePriceEndDate
          moreDetails
          keyword
          releaseDate
          packs
          updatedAt
          msku
          upc
          condition
          cardId
          cardName
          cardType
          cardSubtype
          cardSubtypes
          artist
          evolvesFrom
          evolvesTo
          ancientTrait
          level
          hp
          number
          text
          flavorText
          convertedRetreatCost
          imageUrl
          imageUrlHiRes
          imageUrlKey
          imageUrlHiResKey
          imageAsset
          imageThumbBase64
          nationalPokedexNumber
          nationalPokedexNumbers
          rarity
          regulationMark
          retreatCost
          rules
          series
          setTotal
          setPrintedTotal
          setCode
          subsetCode
          supertype
          types
          marketPrice
          marketPriceTimestamp
          medianPrice
          minPrice
          maxPrice
          autoPricing
          profit
          liveOnAmazon
          liveOnWalmart
          liveOnTCGPlayer
          createdAt
        }
      }
      nextToken
    }
  }
`;
export const listingsByOwnerId = /* GraphQL */ `
  query ListingsByOwnerId(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listingsByOwnerId(
      id: $id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        type
        id
        createdAt
        updatedAt
        amazonPrice
        instockQty
        soldQty
        data
        product {
          id
          set
          title
          asin
          description
          image
          author
          brand
          featured
          price
          cost
          weight
          units
          unitsRemaining
          limit
          quantity
          type
          subtype
          status
          allowPreorder
          salePrice
          salePriceEndDate
          moreDetails
          keyword
          releaseDate
          packs
          updatedAt
          msku
          upc
          condition
          cardId
          cardName
          cardType
          cardSubtype
          cardSubtypes
          artist
          evolvesFrom
          evolvesTo
          ancientTrait
          level
          hp
          number
          text
          flavorText
          convertedRetreatCost
          imageUrl
          imageUrlHiRes
          imageUrlKey
          imageUrlHiResKey
          imageAsset
          imageThumbBase64
          nationalPokedexNumber
          nationalPokedexNumbers
          rarity
          regulationMark
          retreatCost
          rules
          series
          setTotal
          setPrintedTotal
          setCode
          subsetCode
          supertype
          types
          marketPrice
          marketPriceTimestamp
          medianPrice
          minPrice
          maxPrice
          autoPricing
          profit
          liveOnAmazon
          liveOnWalmart
          liveOnTCGPlayer
          createdAt
        }
      }
      nextToken
    }
  }
`;
