import React, { useState, useEffect, useContext } from "react";
import { listUserItems, getOrder, getUserItem} from "../graphql/queries";
import { listUserItemByOwnerType } from "../api/public/queries";
import { listOrdersByOwnerDate } from "../api/queries";
import { createUserItem, deleteUserItem, updateUserItem, updateOrder } from "../graphql/mutations";
import {createSubscriber} from "../api/public/mutations";

import { API } from "@aws-amplify/api";
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import isoCountries from '../config/isoCountries'
import UiContext from "../context/ui";
import { SignUp, ConfirmSignUp, SendSubscribeConversionEvent } from "../components/yayfun/AnalyticsEvent";
import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs"; // ES Modules import


const UserContext = React.createContext({});
const UserProvider = ({ children }) => {
  
  const [signedIn, setSignedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [orders, setOrders] = useState([]);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [nextToken, setNextToken] = useState('');
  const [shippingNextToken, setShippingNextToken] = useState('');

  const [userId, setUserId] = useState('empty');
  const { sendAlertMessage } = useContext(UiContext);
  
  //public key
  const sqs = new SQSClient({region:"us-west-2", credentials:{accessKeyId:process.env.ACCESS_KEY_ID, secretAccessKey:process.env.ACCESS_KEY}});

  const createSubscriberInput = async (input)=> {  
    let finalResult = null;
    try {      
      finalResult =  await API.graphql({
        authMode:"API_KEY",
        query: createSubscriber,
        variables:{input:{...input}}
          } ).then(async result=>{          
            return result;
      }).catch(e=>{return e;});            
      return finalResult;
    } catch (err) {
        console.log(err);  
        return err;
    }
  }

  const unsubscribeFromEmail = async (email, secret)=>{
      const runMessage = new SendMessageCommand(
          {
              MessageGroupId:"UnsubscribeMessage",
              MessageDeduplicationId:"deup",
              MessageBody:JSON.stringify({
                  type:"UnsubscribeMessage",
                  email: email,
                  secret:secret
              }),
              QueueUrl: "https://sqs.us-west-2.amazonaws.com/538231090839/yay-server-SQSQueueAP-2LU3298ULBEJ.fifo"
          }
      );
      const r = await sqs.send(runMessage);
  }

  const subscribeToEmail = async (email)=>{
    const runMessage = new SendMessageCommand(
        {
            MessageGroupId:"SubscribeMessage",
            MessageDeduplicationId:"dedup",
            MessageBody:JSON.stringify({
                type:"SubscribeMessage",
                email: email
            }),
            QueueUrl: "https://sqs.us-west-2.amazonaws.com/538231090839/yay-server-SQSQueueAP-2LU3298ULBEJ.fifo"
        }
    );
    const r = await sqs.send(runMessage);

    SendSubscribeConversionEvent();
  }

  const sendUnsubscribeEmail = async (email)=>{
    const runMessage = new SendMessageCommand(
        {
            MessageGroupId:"SendUnsubscribeEmail",
            MessageDeduplicationId:"dedup",
            MessageBody:JSON.stringify({
                type:"SendUnsubscribeEmail",
                email: email
            }),
            QueueUrl: "https://sqs.us-west-2.amazonaws.com/538231090839/yay-server-SQSQueueAP-2LU3298ULBEJ.fifo"
        }
    );
    const r = await sqs.send(runMessage);
  }


  const sendTrackingRequest = async (email, orderNumber)=>{
    const runMessage = new SendMessageCommand(
        {
            MessageGroupId:"SendTrackingRequest",
            MessageDeduplicationId:"dedup",
            MessageBody:JSON.stringify({
                type:"SendTrackingRequest",
                email: email,
                orderNumber:orderNumber
            }),
            QueueUrl: "https://sqs.us-west-2.amazonaws.com/538231090839/yay-server-SQSQueueAP-2LU3298ULBEJ.fifo"
        }
    );
    const r = await sqs.send(runMessage);
  }



  const sendUserCancelledEmail = async (order)=>{
    const runMessage = new SendMessageCommand(
        {
            MessageGroupId:"SendUserCancelledEmail",
            MessageDeduplicationId:"dedup",
            MessageBody:JSON.stringify({
                type:"SendUserCancelledEmail",
                order: order
            }),
            QueueUrl: "https://sqs.us-west-2.amazonaws.com/538231090839/yay-server-SQSQueueAP-2LU3298ULBEJ.fifo"
        }
    );
    const r = await sqs.send(runMessage);
  }



  const updateUserInfo = async ()=>{

    let userInfo = await Auth.currentUserInfo();
    checkSignIn();
    if (userInfo && userInfo.attributes && userInfo.attributes.sub) {
      setUserObject(userInfo.attributes);
      setUserId(userInfo.attributes.sub);
      setUserSignedIn(true);  
      authenticateCurrentUser();
      fetchOrders(userInfo.attributes.sub);
      fetchShippingAddresses();
    } 
  }

  useEffect(() => {
    updateUserInfo();
  }, []);

  useEffect(() => {
    if (user && user.attributes) {
      // setUserId(user.attributes.sub);
    }   
  }, [user]);


  const authenticateCurrentUser = ()=>{

    Auth.currentAuthenticatedUser().then(data => {
          
      
      if (user === null) {

          var countryCode = null

          if (isoCountries.hasOwnProperty(data.attributes['custom:country'])) {
              countryCode = isoCountries[data.attributes['custom:country']]
          }

          // REMOVED 02.13.21
          // Analytics.updateEndpoint({
          //     ChannelType: 'EMAIL',
          //     Address: data.attributes.email,
          //     location: {
          //         city: data.attributes['custom:city'],
          //         country: countryCode,
          //         postalCode: data.attributes['custom:postcode'],
          //         region: data.attributes['custom:state']
          //     },
          //     optOut: 'NONE',
          //     attributes: {
          //         firstName: [data.attributes.given_name],
          //         hasShoppingCart: ['false'],
          //         completedOrder: ['false']
          //     },
          //     metrics: {
          //         itemsInCart: 0,
          //         orderNumber: "0"
          //     }
          // })
        
          data.attributes["cognito:groups"] = data.signInUserSession.accessToken.payload["cognito:groups"];
          setUserObject(data.attributes)
          setUserId(data.attributes.sub);
          setUserSignedIn(true);
          fetchOrders(data.attributes.sub);
          fetchShippingAddresses();      
      }

      if (user != null) {
              
          // let id = "anon" + uuid();
          // Analytics.updateEndpoint({
          //     ChannelType: 'EMAIL',
          //     Address: `anon-${id}@yayfuninc.com`,
          //     location: {
          //         city: "city",
          //         country: "country",
          //         postalCode: "postal",
          //         region: "region"
          //     },
          //     optOut: 'NONE',
          //     attributes: {
          //         firstName: "name",
          //         hasShoppingCart: ['false'],
          //         completedOrder: ['false']
          //     },
          //     metrics: {
          //         itemsInCart: 0,
          //         orderNumber: "0"
          //     }
          // })

      }

  }).catch(e=>{
  });
  }

 const checkSignIn = (() => {


    Hub.listen('auth', data => {

        switch (data.payload.event) {
          case 'signIn': {                   
            authenticateCurrentUser();            
            break;
          }
          case 'signUp':
            SignUp();
            break;
          case 'confirmSignUp':
            ConfirmSignUp();
            break;              
          case 'signOut':{    
            setUserSignedIn(false);
            setUserId('');
            setUserObject({});
            break;
          }
          case 'signIn_failure': {
            sendAlertMessage(data.payload.data.message);
            break;
          }
          case 'signUp_failure': {
            sendAlertMessage("There was an error signing up. Please try again.")            
            break;
          }
          case 'forgotPassword_failure':
            sendAlertMessage(data.payload.data.message)            
            break;
          case 'forgotPassword_failure':
            sendAlertMessage(data.payload.message)            
          break;
        }
    });
    
    // Auth.currentUserInfo();   

    

      });
  
      
  const isAdmin = () => {    
    if (user) {
      let groups = user["cognito:groups"];
      if (groups) {
        for (let i = 0; i < groups.length; i++) {
          let group = groups[i];
          if (group == "Admin") {
            return true;
          }
        }
      }else {
        return false
      }
      // let groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      // console.log("GROUOPS:", groups);

      // if (user.signInUserSession) {
      // }
    }else {
      return false;
    }
  }

  const setUserSignedIn = (signedin) => {
    setSignedIn(signedin)
  };

  const setUserObject = (userObject) => {
    setUser(userObject)
  };


  const fetchOrders = async (owner)=>{
    
    if (!loading) {

      setLoading(true);
     
      try {      
        const { data } = await API.graphql({
          query: listOrdersByOwnerDate,
          variables:{owner:owner,sortDirection:"DESC"} 
        });      
        
        if (data.listOrdersByOwnerDate.nextToken) {
          setNextToken(data.listOrdersByOwnerDate.nextToken);
        } else {
          setNextToken('');
        }
        
        const ordersUpdated = data.listOrdersByOwnerDate.items;
        
        setOrders(ordersUpdated);
        setLoading(false);

      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  }


  //Fetches and updates orders
  const fetchNewOrders = () => {
    if (!loading && nextToken != '' && user != 'empty') {
      fetchOrders(user.sub);
    }
    return orders;
  }


  const fetchMoreOrders = async () => {
        
    if (!isLoadingMore && nextToken != '' && user != 'empty') {
      setIsLoadingMore(true);
      try {      
        const { data } = await API.graphql({
          query: listOrdersByOwnerDate,
          variables:{nextToken, owner:userId, sortDirection:"DESC"}
        } );      

        if (data.listOrdersByOwnerDate.nextToken) {
          setNextToken(data.listOrdersByOwnerDate.nextToken);
        } else {
          setNextToken('');
        }

        const _orders = data.listOrdersByOwnerDate.items;                
        let oldOrders = [];
        oldOrders.push(...orders)
        oldOrders.push(..._orders)
        setOrders(oldOrders);
        // setLoading(false);
      } catch (err) {                    
        setNextToken('');
        setIsLoadingMore(false);
      }
    }
  };


  const getOrderById = async (id)=>{
    if (!loading) {    
      try {      
        const { data } = await API.graphql({
          query: getOrder,
          variables:{id:id} 
        });      

        return data;
      } catch (err) {
        // console.log(err);
      }
    }
  }


  const addUserItem = async (input, owner) => {
             
    try {      
      let inputObject = {...input, owner:owner ? owner : user.sub, id:input.id, name:input.name, type:input.type};
      
      let finalResult =  await API.graphql({
        query: createUserItem,
        authMode: owner == user.sub ? "AMAZON_COGNITO_USER_POOLS" : "API_KEY",
        variables:{input:inputObject}
          }).then(async result=>{
      }).catch(e=>{});            
      return finalResult;
  } catch (err) {
      console.log(err);  
    }  
    return null;
  }


  const updateCreateUserItem = async (input, create) => {
    
   try {      
     let finalResult =  await API.graphql({
       query: updateUserItem,
       variables:{input:{...input, owner:user.sub, id:input.id, name:input.name, type:input.type}}
         } ).then(async result=>{
     }).catch(async e=>{      
      if (create) {
        
        return await addUserItem(input, user.sub);
      }
            
      });            
     return finalResult;
 } catch (err) {
    //  console.log(err);  
   }  
   return null;
 }


 const getUserItemType = async (type) => {

  console.log("getUserItemType:", type);
  
  try {
    const { data } = await API.graphql({
      query: listUserItemByOwnerType,
      variables:{type:type, limit:100}              
    });

    let items = data.listUserItemByOwnerType.items;        
    console.log("getUserItemType:", items);

    // if (data.listUserItemByOwnerType.nextToken) {
    //   await fetchShippingAddressesNextToken(data.listUserItemByOwnerType.nextToken, addresses);              
    // } 
    // if (addresses.length > 0) {
    //   setShippingAddresses(addresses);
    // }
      return items;

    } catch (err) {
      console.log(err);
    }
 }


const fetchUserItem = async (input, owner) => {
  
 try {      
   let inputObj = {owner:owner ? owner : user.sub, id:input.id, name:input.name, type:input.type};
   let finalResult =  await API.graphql({
     query: getUserItem,
     variables:inputObj
       } ).then(async result=>{
       return result;
   }).catch(e=>{
    
    });          
   return finalResult;
} catch (err) {
  //  console.log(err);  
 }  
 return null;
}

  const fetchShippingAddresses = async () => {
    
    try {
            const { data } = await API.graphql({
              query: listUserItemByOwnerType,
              variables:{type:"ShippingAddress", limit:100}              
            });

            let addresses = data.listUserItemByOwnerType.items;            
            if (data.listUserItemByOwnerType.nextToken) {
              await fetchShippingAddressesNextToken(data.listUserItemByOwnerType.nextToken, addresses);              
            } 
            if (addresses.length > 0) {
              setShippingAddresses(addresses);
            }
            return addresses;

          } catch (err) {
        console.log(err);
    }


  };

  const fetchShippingAddressesNextToken = async (nToken, currentShippingAddresses) => {
    
    try {
        if (nToken != '') {
            // setLoading(true);
            const { data } = await API.graphql({
              query: listUserItemByOwnerType,
              variables:{nextToken:nToken, type:"ShippingAddress"}              
            });

            const _addresses = data.listUserItemByOwnerType.items;                
            currentShippingAddresses.push(..._addresses);           
            if (data.listUserItemByOwnerType.nextToken) {
              await fetchShippingAddressesNextToken(data.listUserItemByOwnerType.nextToken, currentShippingAddresses);
            }
        }
    } catch (err) {
        console.log(err);
    }
  };

  const updateUserShippingAddress = async (input)=> {
            
      if (!loading) {
        setLoading(true);

        let finalResult = null;          

        try {     
          //First delete then add
          if (input.temp) {
            delete(input.temp);
            input["owner"] = user.sub;
            let result = await API.graphql({
                query: createUserItem,
                variables:{input:input}
              }).then(result=>{ 
            
              finalResult = result;      

            }
              ).catch(e=>{
                // console.log("ERR:", e);
              })              
          }else {

            let deleteItem = await API.graphql({
              query:deleteUserItem, 
              variables:{input:{owner:input.owner, id:input.id, type:input.type}}}).then(async r => {
              let result = await API.graphql({
                query: createUserItem,
                variables:{input:input}
              }).then(result=>{
                              finalResult = result;
            }).catch(e=>{console.log("ERR:", e)})                                    
            }).catch(e=>{console.log(e)});    
          }
          setLoading(false);
          return finalResult;
        } catch (err) {
          setLoading(false);
        }
      }
  }
  
const deleteUserItemWithId = async (input) => {
  
  if (!loading) {
    setLoading(true);
    try { 
      await API.graphql({
        query: deleteUserItem,
        variables:{input:{owner:input.owner, id:input.id, type:input.type}}
      } ).then(async result=>{                
        await removeShippingAddress(input.id);
    }).catch(e=>{
      // console.log(e);
    });      
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }
}




const hasMoreOrdersToLoad =  () => {
  if (nextToken && nextToken != '' && nextToken.length > 5) {      
    return true;
  }
  return false;
}

  const checkIfNotificationEnabled = async (product)=> {
      
  }

  const addTempShippingAddress = async () =>{
    let current = [];
    if (shippingAddresses && shippingAddresses.length > 0) {
      current = [...shippingAddresses];
    } 
    let newAddress = {
      owner:user,
      id:new Date().toISOString(),
      type:"ShippingAddress",
      shippingAddress: {
        firstName:"",
        lastName:"",
        name:"",
        addressLine1:"",
        addressLine2:"",
        country:"",
        postalCode:"",
        companyName:"",
        phoneNumber:"",
        city:"",
        state:""
      }
    };
    current.push(newAddress);
    setShippingAddresses(current);
    return newAddress
  }

  const removeShippingAddress = async (id)=>{
    let current = [];
    let updated = [];
    current = [...shippingAddresses];
    for (let i = 0; i < current.length; i++) {
      if (current[i].id == id) {
        //ignore
      }else {
        updated.push(current[i]);
      }
    }
    setShippingAddresses(updated);
  }


  const deleteTempShippingAddress = async (id)=>{
    let current = [];
    let updated = [];
    current = [...shippingAddresses];
    for (let i = 0; i < current.length; i++) {
      if (current[i].id == id && current[i].temp) {
        //ignore
      }else {
        updated.push(current[i]);
      }
    }
    setShippingAddresses(updated);
  }

  
  const updateOrderWithInput = async (input)=>{

    let result = await API.graphql({
      query: updateOrder,
      variables:{input:input}
    }).then(result=>{
      return result;      
    }).catch(e=>{
      console.log(e);
    });      

  return result;
  }


  return (
    <UserContext.Provider 
      value={{getUserItemType, sendUserCancelledEmail, updateOrderWithInput, createSubscriberInput, sendTrackingRequest, unsubscribeFromEmail, subscribeToEmail, sendUnsubscribeEmail, user, signedIn, orders, shippingAddresses, loading, isLoadingMore,updateOrder,  fetchShippingAddresses, checkIfNotificationEnabled, addUserItem, updateCreateUserItem, fetchUserItem, isAdmin, setUserObject, setUserSignedIn, fetchMoreOrders, fetchNewOrders, getOrderById, updateUserShippingAddress, deleteUserItemWithId, addTempShippingAddress, deleteTempShippingAddress, hasMoreOrdersToLoad }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
export {UserProvider};