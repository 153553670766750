
import React, { useContext, useState } from 'react'
import MuiSelect from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { FormControl } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import CartContext from "../context/cart";
import Button from '@material-ui/core/Button';
import {CheckDisabled} from './Helper';
import UiContext from "../context/ui";
import {SendAddToCartEvent} from "./yayfun/AnalyticsEvent";

function ButtonAddToCart(props) {

    const { addToCartQuantity, toggleDropdownOn } = useContext(CartContext);
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [ loading, setLoading ] = useState(false);
    const product = props.product;
    const { sendAlertMessage, clearMessages } = useContext(UiContext);

    const handleChange = (event) => {
        setSelectedQuantity(event.target.value);    
      };
    
     const limit = props.product  && product.limit ? parseInt(product.limit) : 1;
     let dropdownArray = [];
     for (let i = 0; i < limit; i++) {
        dropdownArray.push(i+1)
     }

    return (<><div style={{ width: "100%", textAlign:"left" }}>
            
            <div style={{ display: "inline-block", margin:"1em", marginLeft:"0em", marginRight:"0em", textAlign:"left" }}>
            <FormControl>
                <InputLabel id="selectedQuantity">Quantity</InputLabel>
                <MuiSelect
                    disabled={CheckDisabled(product)}
                    onChange={handleChange}
                    labelId="selectedQuantity"
                    value={selectedQuantity}
                    style={{ float: 'left', minWidth: '4em', marginRight: '2em' }}
                >
                    {
                        dropdownArray.map((item, index) =>{
                            return(<MuiMenuItem value={item}  key={"item_" + index}>{item}</MuiMenuItem>)
                        })
                    }                    
                </MuiSelect>
            </FormControl>
            </div>
            <div style={{ display: "block", margin:"1em", marginLeft:"0em", marginRight:"0em" }}>
                <Button
                    disabled={CheckDisabled(product)}
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async () => {
                        setLoading(true);
                        let result = await addToCartQuantity({ ...product, id: product.id }, selectedQuantity);
                        setLoading(false);
                        if (result) {
                            if (props.endQuickViewProduct) {
                                props.endQuickViewProduct();
                            }
                            clearMessages();
                            toggleDropdownOn(true);
                            SendAddToCartEvent(product);
                              
                        } else {                            
                            sendAlertMessage(`Could not add ${product.title} to your cart`);
                        }
                    }}>
                    {loading ? <CircularProgress style={{ scale: "0.8", height: "2em", width: "2em" }} /> : "Add to cart"}
                </Button>
            </div>
        </div>

    </>)
}

export default ButtonAddToCart