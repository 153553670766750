import React, {useState} from "react";
import CustomTextField from "./CustomTextField";


function FilterSinglesInput(props) {

    const [filterInput, setFilterInput] = useState("");

    return (<div style={{width:"200px", marginLeft:"1em"}}>
    
    <CustomTextField
                    className="custom-input"
                    style={{ float: "left", minWidth: "15em" }}
                    key={"search-input"}
                    color={"black"}
                    properties={
                        {
                            name: "Search",
                            labelName: "Search",
                            error: false,
                            variant: 'outlined',
                            key: "search-input",
                            onKeyPressFunction: ()=>{
                                // console.log("Key Pressed");
                            },
                            currentValue: filterInput
                        }
                    }
                    defaultValue={""}
                    value={filterInput}
                    // inputProps={{className:classes.input}}
                    // onKeyPress={(e) => {
                    //   if (e.key === 'Enter') {
                    //     console.log("Key enter ");
                    //     //TODO: End propagation
                    //     performSearch();
                    //   }
                    // }}
                    onFocus = {
                        e=>{
                            props.onFocus(e);
                        }
                    }
                    onChange={
                        e => {
                            if (e.target.value) {
                                setFilterInput(e.target.value);
                            } else {
                                setFilterInput('');
                            }

                            props.onInputChange(e.target.value);
                        }
                    } />

    </div>)
}


export default FilterSinglesInput;