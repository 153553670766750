import React, {useEffect, useState} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';


const delay = 5;

const Error = () => {

    const [show, setShow] = useState(false);
    
    useEffect(
        () => {
          let timer1 = setTimeout(() => setShow(true), delay * 1000);
    
          // this will clear Timeout
          // when component unmount like in willComponentUnmount
          // and show will not change to true
          return () => {
            clearTimeout(timer1);
          };
        },
        // useEffect will run only one time with empty []
        // if you pass a value to array,
        // like this - [data]
        // than clearTimeout will run every time
        // this value changes (useEffect re-run)
        []
      ); 
    return (<>
        {
            show ? <>
              <div className="container-inner">
            <div className="container-page">
            <h3 style={{textAlign:"center"}}>Oops. There's an error going to that page...</h3>    
            <img style={{width:"30em"}} src="/images/oops.svg"/>
            </div>
        </div>
        </> :<>
        <div style={{width:"100%", height:"400px", textAlign:"center", paddingTop:"4em"}}>
            <CircularProgress/>
            </div>
        </>
        }
      
    </>)
}

export default Error
