import React, {useState, useContext} from "react";
import CustomTextField from './yayfun/CustomTextField';
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { API } from '@aws-amplify/api';
import CartContext from "../context/cart";


function CouponInput (props) {

    const [inputValue, setInputValue] = useState({"code":props.currentValue ? props.currentValue : ""});
    const [checkingCoupon, setCheckingCoupon] = useState(false);
    const [inputError, setInputError] = useState(false);
    const { approvedCodes, updateApprovedCodes } = useContext(CartContext);

    const id = "code";
    const name = "Coupon Code";

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const checkCoupon = async ()=>{
        setCheckingCoupon(true);
        await delay(1000);                
        let inputValueString = inputValue[id].toString(); 
        if (inputValueString && inputValueString.toLowerCase() == "yay10") {
            setInputError(false);
            let result = await API.post("yayfunthingsapi", "/items/hasEmailUsedCoupon", { 
                body:{email:props.email, code:inputValueString.toLowerCase()}
            }).then(async response => { 
                console.log("RESPONSE:", response);
                updateApprovedCodes([inputValueString.toUpperCase()]);
                if (props.updateCouponUsed) {
                    props.updateCouponUsed(inputValueString.toLowerCase());
                }

                setInputValue({...inputValue,[id]:''});

            }).catch(e=>{console.log(e)});
        }else {
            setInputError(true);
        }        
        setCheckingCoupon(false);
    }


    const drawApprovedCoupon = ()=> {

        return <>
        {
        approvedCodes.map(code=>{
            return (<>
            <div className="coupon-tag">
                <Chip label={code} onDelete={()=>{
                    updateApprovedCodes([])
                    if (props.updateCouponUsed) {
                        props.updateCouponUsed(null);
                    }
                }}
                 />
            </div>
            </>)
        })
    }    
      </>        
    }

    return <>
    <div style={{marginTop:"5px", marginBottom:"20px"}}>
    
    <CustomTextField
    className="custom-input"
    style={{ float: "left", minWidth: "10em", display:"inline-block"}}
    key={"coupon-input"}
    color={"auto"}
    properties={
        {
            name: "Coupon",
            labelName:name,
            error: inputError,
            variant: 'outlined',
            currentValue: inputValue[id]
        }
    }
    defaultValue={""}
    value={inputValue[id]}
    onChange={
        e => {
            if (e.target.value) {
                setInputValue({...inputValue,[id]:e.target.value});
            } else {
                setInputValue({...inputValue,[id]:''});
            }
        }
    } />
        
    <Button disabled={props.email ? false: true} color="primary" variant="contained" style={{ marginLeft:"1em", height:"50px", display:"inline-block", marginTop: "0em" }} onClick={async () => {
                    console.log("Apply coupon")                    
                    await checkCoupon();
                }}>{checkingCoupon ? <><CircularProgress style={{scale:"0.76", padding:"0px", color:"white"}} color="inherit" /></> : <>Apply</>}</Button>
        {
            drawApprovedCoupon()
        }
        </div>

        
     </>
}


export default CouponInput;