import React, { useState } from 'react'
import moment from 'moment';
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
    KeyboardDatePicker
  } from '@material-ui/pickers';
  

//   import DateFnsUtils from '@date-io/date-fns';
  import MomentUtils from '@date-io/moment';

  
function DateRange(props) {

    const [startDateInput, setStartDateInput] = useState(moment());
    const [endDateInput, setEndDateInput] = useState(moment())
    const [focusedInput, setFocusedInput] = useState(null)

    const updateDates = (startDate, endDate)=> {
        if (props.updateDates) {
            props.updateDates(startDate, endDate);
        }
    }


    return (<>
        <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Start Date"
        //   format="MM/dd/yyyy"
          value={startDateInput}
          onChange={(date)=>{
              console.log("Date", date);
                setStartDateInput(date);
                updateDates(date, endDateInput);
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />

<KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="End Date"
        //   format="MM/dd/yyyy"
          value={endDateInput}
          onChange={(date)=>{
                setEndDateInput(date);
                updateDates(startDateInput, date);
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />

        </MuiPickersUtilsProvider>
    </>
                //  <DateRangePicker
                //     onDatesChange={({ startDate, endDate }) => {

                //                 if (startDate != startDateInput) {
                //                     setStartDateInput(startDate);
                //                     updateDates(startDate, endDate);
                //                 }
                //                 if (endDate != endDateInput) {
                //                     setEndDateInput(endDate);
                //                     updateDates(startDate, endDate);
                //                 }
                //             }}
                //                 onFocusChange={(fi) => {
                //                     setFocusedInput(fi)
                //                 }
                //             }

                //             isOutsideRange={() => false}
                //             focusedInput={focusedInput}
                //             startDate={startDateInput}
                //             endDate={endDateInput}
                //             startDateId="date_range_picker_start"
                //             endDateId="date_range_picker_end"
                //         />
    )
}

export default DateRange;