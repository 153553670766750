import React, {useContext, useState, useEffect} from 'react';
import CustomTextField from "../components/yayfun/CustomTextField";
import Button  from "@material-ui/core/Button";
import { API } from '@aws-amplify/api';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import UserContext from '../context/user';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import ConsoleLog from './yayfun/ConsoleLog';
import CloseIcon from '@material-ui/icons/Close';

import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  root:{
    margin:"0em",
    padding:"0em",
    '&:first-child': {
      padding:"0em"
    },
  }
})

function SubscribePrompt(props) {
  
    const classes = useStyles();    
    const { signedIn, subscribeToEmail, sendUnsubscribeEmail, createSubscriberInput } = useContext(UserContext);
    const [showSubscribePopup, setShowSubscribePopup] = useState(props.shouldShow);
    const [showThanks, setShowThanks] = useState(false);
    const [unsubscribing, setUnsubscribing] = useState(false);
    
    const [inputError, setInputError] = React.useState({ name: false, email: false, phone: false, orderNumber:"",message: false });
    const [formData, setFormData] = React.useState({
      email: ""
  });

  const location = useLocation();
  const params = new URLSearchParams(location.search);    
  const utm_source = params.get('utm_source');    


  useEffect(()=>{    
        setTimeout(()=>{
            let shouldShow = false;
            let lastSubscribeNotification = window.localStorage.getItem("lastSubscribeNotification");            
            let diff = moment().diff(moment(parseInt(lastSubscribeNotification)), 'days');
            if (lastSubscribeNotification) {                
                if (diff > 60) { //60 day wait
                    shouldShow = true;
                }
            } else {
                shouldShow = true;
            }            
            
            if (utm_source) {
                shouldShow = false;
            }
            if (shouldShow) {
                window.localStorage.setItem("lastSubscribeNotification", moment().valueOf());
                setShowSubscribePopup(true);    
            }
        }, 4000);
  },[]);

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const clearFormData = ()=>{
      setFormData({email:""})
    }

  
      const subscribEmail = async ()=>{

        if (formData.email) {        
          let result = await createSubscriberInput({id:formData.email.toLowerCase(), name:formData.email, email:formData.email, subscribed:true, secret:uuid()});
          if (result.errors && result.errors[0]) {
            if (result.errors[0].message && result.errors[0].message.includes("Extended Request ID")) {
              subscribeToEmail(formData.email);
            }
          }
          setShowThanks(true);
          await delay(1000);
          closePopup();
          await delay(1000);
          setShowThanks(false);
        }
      }

      const unsubscribEmail = async ()=>{
        if (formData.email) {        
          let result = await createSubscriberInput({id:formData.email.toLowerCase(), name:formData.email, email:formData.email, secret:uuid(), subscribed:false});
          if (result.errors && result.errors[0]) {
            if (result.errors[0].message && result.errors[0].message.includes("Extended Request ID")) {
              //Send email with the secret
              sendUnsubscribeEmail(formData.email);
            }
          }
          setShowThanks(true);
          await delay(1000);
          closePopup();
          await delay(1000);
          setShowThanks(false);

        }
      }

      const validateFormData = ()=>{
        let foundError = false;
        var reeamil = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
        if (!reeamil.test(formData.email)) {
          setInputError({ ...inputError, email: true });
          foundError = true;
        }
        if (formData.email == null || formData.email == "") {
            setInputError({ ...inputError, email: true });
            foundError = true;
        }      
        return !foundError;
      }


      const closePopup = ()=>{
        setShowSubscribePopup(false);
        if (props.reset) {
          props.reset();
        }
        clearFormData();
      }


    const displaySubscribePrompt = ()=>{

        return(<Dialog                
         onClose={()=>{
           closePopup();
         }} 
         aria-labelledby="simple-dialog-title" 
         open={showSubscribePopup || props.shouldShow} >
          
           <DialogContent
           classes={classes}
           >
             <div className="subscribe-dialog">

             <div className="close-sub-icon">
                <CloseIcon style={{ scale: "2", fill:"white" }} onClick={() => {closePopup();}} />
                 </div> 

             {
                  showThanks ? <div> 
            <p className="subscribe-normal">
               Thank you!
               </p>
   
           </div> :<>
                     
               <p className="subscribe-normal">
              {
                  unsubscribing ? <>Enter your email to unsubscribe, we will send you a verification email</> : <>Enter your email to subscribe to our mailing list and receive updates!</>
              } 
               </p>
   
             <CustomTextField
                 className="search-input"
                 style={{ float: "left", maxWidth: "20em" }}
                 properties={
                     {
                         name: "Email",
                         labelName:"Email",
                         variant: 'outlined',
                         error: inputError.email,
                         rows: 1
                     }
                 }
                 defaultValue={formData.email}
                 onKeyPress={(e) => {
                     if (e.key === 'Enter') {
                         console.log("Key enter email");
                     }
                 }}
                 onChange={
                     e => {
                         setFormData({ ...formData, email: e.target.value });
                         if (e.target.value) {
                             setInputError({ ...inputError, email: false });
                         }
                     }
                 } />
   
   {
       unsubscribing ? <>
          <Button variant="contained" size="large" color="primary" style={{ marginTop: "14px", marginRight:"12px" }}
       onClick={async () => {
        setUnsubscribing(false);           
       }}> Cancel</Button>
       <Button variant="contained" size="large" color="primary" style={{ marginTop: "14px" }}
       onClick={async () => {
         let validFormData = validateFormData();
         if (validFormData) {
          await unsubscribEmail();
         }               
       }}> Unsubscribe</Button>
       </> : <Button variant="contained" size="large" color="primary" style={{ marginTop: "14px" }}
       onClick={async () => {
         let validFormData = validateFormData();
         if (validFormData) {
          await subscribEmail();
         }               
       }}> Subscribe</Button>
   }
            
   
             <p className="subscribe-disclaimer">
               We do not sell, trade, or rent your email to third parties. We use your email to communicate with you for new items and deals. You can <a style={{cursor:"pointer"}} onClick={(e)=>{
                   e.preventDefault();
                   setUnsubscribing(true);
               }}>Unsubscribe</a> any time.
               </p>
           </>
         }
              
             </div>
           </DialogContent>
         </Dialog>) 
   
       }
   
    return (<>
      {
        signedIn && !props.shouldShow ? <></> : displaySubscribePrompt()                
      }
      </>);
}

export default SubscribePrompt;