import React, { useContext, useEffect } from "react";
import ProductContext from "../context/products";
import UserContext from "../context/user";

import ProductCard from '../components/ProductCard';
import moment from 'moment';
import { Link } from "react-router-dom";
import { ConvertStringToDollarsCents, GetTotalForOrder, GetTaxForOrder } from '../components/Helper'
import OrderDetails from "../pages/OrderDetails";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import QuickbooksButton, {QueryQuickbooksItem} from './yayfun/QuickbooksButton';
import { ConsoleLogger } from "@aws-amplify/core";


function OrdersDraw(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const {loading} = useContext(UserContext);
    const isLoading = props.isLoading == undefined ? false : props.isLoading;
    const cardImageLocationPrefix = "/images/cards/";

    const getImageForProduct = (product) => {

      if (product == undefined) 
        return "/images/yayfun-coming-soon.svg";
      if (product.type == "Single") {
        if (product.imageUrlKey) {
          return cardImageLocationPrefix + product.imageUrlKey
        } else {
          return "/images/yayfun-coming-soon.svg"
        }      
      }else {
        return product.image;      
      }    
    }
    
    useEffect (()=>{
      
    },[loading, isLoading])
    
    const getInvoiceDetailsForOrder = (order) => {

      let totalTax = GetTaxForOrder(order);
      let totalAmount = GetTotalForOrder(order);
      let txnDate = moment(order.date.replace(" UTC", "")).format("YYYY/MM/DD")

      let lineItemArray = [];
      let lineNumberCount = 1;

      for (let i = 0; i < order.products.items.length; i++) {
      
        let item = order.products.items[i];

        //   let item = await queryQuickbooks(`select * from Item where Name = '${id}'`, companyID);
        let lineitem = {
          "Description": item.product.title, 
          "DetailType": "SalesItemLineDetail", 
          "SalesItemLineDetail": {
            "TaxCodeRef": {
              "value": "TAX"
            }, 
            "Qty": item.amount, 
            "UnitPrice": item.price,          
            "ItemRef": { // Update on the server
              "name":item.product.title, 
              "value":item.product.id.toUpperCase(),
            }
          }, 
          "LineNum": lineNumberCount, 
          "Amount": parseFloat(item.price) * parseInt(item.amount)
          // "Id": "1"
        };
        
        lineNumberCount++;
        lineItemArray.push(lineitem);
      }    

      let customerValue = 0;

      let invoiceInput = {
        shippingCost:parseFloat(order.shippingCost ? order.shippingCost : 0), //this variable gets deleted 
        TxnDate:txnDate,      
        TotalAmt:totalAmount,
        DocNumber:order.number,
        Line: lineItemArray,  
        ShipAddr:{
          PostalCode:order.shippingAddress.postalCode,
          City:order.shippingAddress.city ,
          Country: order.shippingAddress.country,
          Line1:order.shippingAddress.addressLine1,
          Line2:order.shippingAddress.addressLine2, //#
          // Line3: ,
          // Line4: ,
          // Line5: ,
          CountrySubDivisionCode:  order.shippingAddress.state,
        },
        BillAddr:{
          PostalCode:order.billingAddress.postalCode,
          City:order.billingAddress.city ,
          Country: order.billingAddress.country,
          Line1:order.billingAddress.addressLine1,
          Line2:order.billingAddress.addressLine2,
          // Line3: ,
          // Line4: ,
          // Line5: ,
          CountrySubDivisionCode:  order.billingAddress.state,
        },
        ShipFromAddr:{
          PostalCode:"78731",
          City:"AUSTIN",
          Country: "US",
          Line1:"3616 FAR WEST BLVD 117-414",
          // Line3: ,
          // Line4: ,
          // Line5: ,
          CountrySubDivisionCode: "TX",
        },
        PrivateNote:"Yay Pokemon Order Id " + order.id + " number: " + order.number  ,
        CustomerRef: {
          value: customerValue //21 for prod Amazon customer US 55 for sandbox
        }
      }

      if (totalTax > 0) {

        invoiceInput["TxnTaxDetail"] = {
          TotalTax: totalTax, 
          TaxLine: [
            {
              DetailType: "TaxLineDetail", 
              Amount: totalTax, 
              TaxLineDetail: {
                NetAmountTaxable: totalTax, 
        //         TaxPercent: 8, 
                TaxRateRef: {
                  value: "1"
                }, 
                PercentBased: false
              }
            }
          ]    
        };
      } else {
    
        invoiceInput["TxnTaxDetail"]  = {
          //   // TxnTaxCodeRef: {
          //   //   "value": "2"
          //   // }, 
            TotalTax: totalTax, 
            TaxLine: [
              {
                DetailType: "TaxLineDetail", 
                Amount: 0, 
                TaxLineDetail: {
                  NetAmountTaxable: 0, 
          //         TaxPercent: 8, 
                  TaxRateRef: {
                    value: "1"
                  }, 
                  PercentBased: false
                }
              }
            ]    
          };
      
      }

      return invoiceInput;

    }

    
    const displayOrders = (orders) => {
        return orders.map(order=>{
            return(<>
                <div className="order-card">
                    <div className="order-card-left">
                        <div>                
                            <p>
                                Order Date:<span>{moment(new Date(order.date)).format("MMMM DD,YYYY hh:mm A")}</span> <br/>
                                Order #{order.number}             
                                <br/>                                
                                {
                                    props.viewDetailsCallback ? <>
                                    <div className="order-list-button">
                                    <Button variant="contained" color="primary"  onClick={() => {
                                           props.viewDetailsCallback(order);
                                           window.scrollTo(0,0);
                                     }}>View Details</Button> </div>


                                    {
                                      order.hasQuickbooksInvoice ? <></> : <div className="order-list-button"><QuickbooksButton invoice={getInvoiceDetailsForOrder(order)} paymentMethod={order.paymentMethod} /> </div>
                                    }  
                                     

                                     <br/>

                                     </> : <Link className="button-link" to={`/order/${order.id}`}> View Order </Link>
                                }
                            </p>
                            {
                                order.products.items.map(item=>{
                                    return(<>
                                    <img className="order-card-image" src={getImageForProduct(item.product)} />       
                                    </>)
                                })
                            }
                        </div>
                        <p>
                        </p>
                    </div>
                    <div className="order-card-right">
                            <p>Order Status: {order.status}</p>           
                            <p>Payment Method: {order.paymentMethod}</p>                            
                 
                            <div>
                            <div>
                                <div className="shipping-address">
                                    <p>Shipping Address:</p>
                                    {
                                        order.shippingAddress ?
                                        <>
                                            <p>{order.shippingAddress.name}</p>
                                            <p>{order.shippingAddress.addressLine1}</p>
                                            <p>{order.shippingAddress.addressLine2}</p>
                                            <p>{order.shippingAddress.city}, {order.shippingAddress.state}  {order.shippingAddress.postalCode} {order.shippingAddress.country}</p>
                                        </>:<></>
                                    }
                                </div>
                            </div>                  
    
                            </div>
                            {/* <Button> */}
                            {/* </Button> */}
                            <p className="order-card-total">
                            Total: {ConvertStringToDollarsCents(order.total)}
                            <br/>
                            Shipping Paid: {ConvertStringToDollarsCents(order.shippingPaid ? order.shippingPaid : 0)}
                         </p>
                    </div>                    
                </div>            
            </>)
        })
    }        
    return (        
        <> { loading  || isLoading? <>
             <div className="container-inner">  
             <div className="container-page">
              <CircularProgress /> 
              </div>
            </div>
            </>:
            <> 
            {   
            props.orders && props.orders.length > 0 ?
            displayOrders(props.orders)  :
            <div className="container-inner">
            <div className="container-page">
                <h3>No orders to show.</h3>
           </div>
             </div>
           }
         </>
        } 
        </>        
        )
}

export default OrdersDraw