import React, {useEffect, useContext} from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from '../context/user';
import CustomTextField from "../components/yayfun/CustomTextField";
import Button  from "@material-ui/core/Button";
import Seo from "../components/Seo";

function Track() {

    document.title = "Track Order";

    const location = useLocation();
    const params = new URLSearchParams(location.search);    
    const email = params.get('email');
    const secret = params.get('secret');    

    const { sendTrackingRequest } = useContext(UserContext);
    const [inputError, setInputError] = React.useState({ email: false, orderNumber:"" });
    const [formData, setFormData] = React.useState({
      email: "",
      orderNumber:""
  });
  const [showComplete, setShowComplete] = React.useState(false);

    // const runUnsubscribeFromEmail = async ()=> {
    //     let result = unsubscribeFromEmail(email, secret);        
    // }

    // useEffect(()=>{
    //     runUnsubscribeFromEmail();
    // },[]);

    const displayInput = (name, key)=>{
        return <CustomTextField
        className="search-input"
        style={{ float: "left", maxWidth: "20em" }}
        properties={
            {
                name: name,
                labelName:name,
                variant: 'outlined',
                error: inputError[key],
                rows: 1
            }
        }
        defaultValue={formData[key]}
        onKeyPress={(e) => {
            if (e.key === 'Enter') {
                console.log("Key enter email");
            }
        }}
        onChange={
            e => {
                setFormData({ ...formData, [key]: e.target.value });
                if (e.target.value) {
                    setInputError({ ...inputError, [key]: false });
                }
            }
        } />
    }


    const sendTracking = ()=>{
        sendTrackingRequest(formData.email, formData.orderNumber);
        setShowComplete(true);
    }


    return (<>

            <Seo
                  title={"Track Order"}
                  pathSlug={"track"}
                  description={"Track your order by entering your email and order number. An email will be sent with the status of your order."}
                />
        <div className="container-inner">
            <div className="container-page">
            
            <h3 className="">Track your order</h3>
            
            {
                showComplete ? <>Thank you, an email will be sent to you with updated tracking.</> :<>
                
                <p className="">To track your order please enter your Order Number in the box below and press the Track button. This was given to you on your receipt and in the confirmation email you should have received.</p>

            {
                displayInput("Email", "email")
            }

            {
                displayInput("Order Number", "orderNumber")
            }


        <Button variant="contained" size="large" color="primary" style={{ float:"left", marginRight:"12px" }}
            onClick={async () => {
                sendTracking();           
            }}> Track</Button>
                </>
            }
            
            

            </div>
        </div>
    </>)
}

export default Track