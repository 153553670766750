
import React, {useState, useContext, useMemo} from 'react'
import Button from '@material-ui/core/Button';
import CustomTextField from '../components/yayfun/CustomTextField';
import PreviewEmail from '../components/PreviewEmail';
import moment from 'moment';
import AdminContext from "../context/admin";
import UserContext from "../context/user";

import AdminQuill from './AdminQuill';
import AdminGrape from './AdminGrape';
import AdminEmailCampaign from './AdminEmailCampaign';

// const haq = require('html-add-querystring');

// Slate
// import { createEditor } from 'slate'
// import { Slate, Editable, withReact } from 'slate-react'


// Draft.js
// import { convertToHTML} from 'draft-convert';
// import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useDraftJS = false;

function AdminEmailPage(props) {

    const [formData, setFormData] = useState({emailMessage:"", shippingPaid:0, customEmail:props.email ? props.email : "", subject:"", campaignSource:"", campaignId:"",  title:"", body:""});
    const [grapeHTMLBody, setGrapeHTMLBody] = useState("");

    const [displayEmailPreview, setDisplayEmailPreview ] = useState(false);
    const [displayPreviewDetails, setDisplayPreviewDetails ] = useState(false);
    const {getSecretKeyForEmail, updateOrderWithInput, fetchSubscribers, getUserItemType, deleteOrderWithId, cardsSelected, cardSetSelected, setCardsSelected, getPrintedCardCountForSet, getTotalCardCountForSet,  getReleaseDateForSet, getSheetByTitle, cardSets, getCardSetNameFromCode , createAnalyticsInput} = useContext(AdminContext);
    const [convertedContent, setConvertedContent] = useState(null);
    const { user, fetchUserItem, addUserItem, updateCreateUserItem} = useContext(UserContext);

    const [orderDetailShown, setOrderDetailShown] = useState({});
    const [savedEmails, setSavedEmails] = useState([]);
    const [sentEmails, setSentEmails] = useState([]);
    const [sentEmailsFilter, setSentEmailsFilter] = useState("");

    const [subscribers, setSubscribers] = useState([]);
    const [selectedSubscribers, setSelectedSubscribers] = useState([]);
    const [showSelected, setShowSelected ] = useState(false);
    const [showNotSent, setShowNotSent ] = useState(false);
    
    const [displayEmailCampaign, setDisplayEmailCampaign] = useState(false);

    const grapeEditor = React.useRef({});
    //Slate
    // const editor = useMemo(() => withReact(createEditor()), [])
    // const [value, setValue] = useState([
    //     {
    //         type :'Paragraph',
    //         children : [{text:'A line '}]
    //     }
    // ])

    //DraftJS
    // const [editorState, setEditorState] = React.useState(
    //     EditorState.createEmpty()
    //   );    
    // const draftEditor = React.useRef(null);
    

    // function focusEditor() {
    // // if (editor.current) {
    // //     editor.current.focus();
    // // }
    // }
    

    React.useEffect(() => {
    // focusEditor()
    fetchInitialItems();
    }, []);


    const fetchInitialItems = async()=>{
        let items = await getUserItemType("EmailDraft");
        
        console.log("getUserItemType EmailDraft:", items)
        setSavedEmails(items);    

        let subscribersResult = await fetchSubscribers();

        console.log("subscribersResult:", subscribersResult);

        if (subscribersResult && subscribersResult.data && subscribersResult.data.listSubscribers && subscribersResult.data.listSubscribers.items) {
            
            let subscribersList = subscribersResult.data.listSubscribers.items;

            setSubscribers(subscribersList);

        }
        

    }

      const saveEmailDraft = async (details)=> {
        //   if (useDraftJS) {
        //     let convertedRaw = convertToRaw(editorState.getCurrentContent());
        //     details.raw = convertedRaw;
        //     await updateCreateUserItem({type:"EmailDraft", id:details.title, data:JSON.stringify(details)}, true);    
        //   } else {

        // let sentItems = await getUserItemType("EmailSent");
        // setSentEmails(sentItems); 

        console.log("Save email draft:", details);

        if (details.body != "" && details.campaignId != null) {
            await updateCreateUserItem({type:"EmailDraft", id:details.campaignId, name:details.campaignId, data:JSON.stringify(details)}, true);    
        }
            
        //   }
    }


    const insertOrderHistoryChange = async (order, description)=>{

        if (order.history) {
            let updatedHistory = [...order.history];    
            updatedHistory.push({
                createdAt:moment(),
                description:description
            })

            await updateOrderWithInput({id:order.id, history:updatedHistory});
            setOrderDetailShown({...orderDetailShown, history:updatedHistory});
    
        } else {
            let updatedHistory = [];
            updatedHistory.push({
                createdAt:moment().toISOString(),
                description:description
            })
    
            await updateOrderWithInput({id:order.id, history:updatedHistory});
            setOrderDetailShown({...orderDetailShown, history:updatedHistory});
    
        }
        
    }

    // const handleEditorChange = (state) => {
    //     if (useDraftJS) {
    //         setEditorState(state);
    //         convertContentToHTML();
    //     }
    //   }

    //   const convertContentToHTML = () => {
    //     let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    //     console.log("convertContentToHTML:", currentContentAsHTML);
    //     setConvertedContent(currentContentAsHTML);
    //     setFormData({...formData, ["body"] : currentContentAsHTML});
    //   }


    const displayInputField = (key, color, name, currentObject, keyPressFunc, onChangeCallback, setValue)=>{    
                  
        return (
           <CustomTextField
           className="custom-input"
           key={key}
           color={color}
           properties={
             {
               name: name,                
               labelName: name,
               error: false,
               variant: 'outlined',
               key: "search-input",
               onKeyPressFunction:()=>{console.log("Pressed Enter Key"); if (keyPressFunc) { keyPressFunc(); }},
               currentValue:currentObject[key]
             }
           }
           defaultValue={""}
           value={currentObject[key]}
           onChange={ onChangeCallback ? e=>{onChangeCallback(e)} : 
             e => {
                 console.log("On change:", e.target.value);
               if (e.target.value) {
                   console.log("currentObject[key]",key,currentObject, currentObject[key]);
                   if (setValue) {
                    setValue({...currentObject, [key] : e.target.value});
                   } else {
                    currentObject[key] = e.target.value;
                   }
                   
                
               } else {
                if (setValue) {
                    setValue({...currentObject, [key] : ''});
                   } else {
                    currentObject[key] = '';
                }
               }
             }
           } />)        
       }


    //    const sendCustomerEmail = async(order) => {
    //     console.log("Send customer email:", order);


    //     await API.post("yayfunthingsapi", "/items/sendEmail", { 
    //         body: 
    //         {  
    //            email:order.customer, 
    //            data:`<div style="border:1px solid #ccc; max-width:680px;"> 
    //            <div style="background-color:#2196f3; background-repeat:no-repeat; background-image:url(https://www.yaypokemon.com/images/yayfun-head.svg)">         
    //             <p style="text-align:center; margin:0em; color:#fff; height:3em; font-size:1.5em; padding-top:1em; font-weight:bold;"> Order Inquiry </p>                   
    //            </div>
               
    //            <div style="margin:2em">
    //                 <div>
    //                 <p style="font-weight:bold; margin-top:0.5em;margin-bottom:0.1em">Order No. <span style="font-weight:normal">${order.number}</span></p>
    //                 <p style="font-weight:bold; margin-top:0em;margin-bottom:0.5em">Order Date: <span style="font-weight:normal;">${moment(order.date).format("MMMM DD, YYYY")}</span></p>
    //                 </div>
    //                 <div style="padding-top:1em;margin-bottom:0em">                    
    //                 ${formData.emailMessage} 
    //                 </div>
    //                 <p style="font-weight:bold; padding-top:1em">Order Shipping Address</p>
    //                   <div>
    //                     <div>
    //                       <ul style="list-style-type:none;">
    //                           <li>${order.shippingAddress.name}</li>
    //                           <li>${order.shippingAddress.addressLine1}</li>
    //                           <li>${order.shippingAddress.addressLine2}</li>
    //                           <li>${order.shippingAddress.city}, ${order.shippingAddress.state}  ${order.shippingAddress.postalCode} ${order.shippingAddress.country}</li>
    //                           </>                                
    //                       </ul>
    //                     </div>
    //                   </div>                    
    //            ` +
    //            '<div style="border-top:1px solid #ccc; font-size:0.8em;margin-top:1em"><p>Thank you for shopping with us!</p>'+
    //            '<p>Need help with your order?</p><p><a href="https://www.yaypokemon.com/contact/">Contact us</a> and we will get back to you as soon as we can.</p>'+
    //            '<p>Track your order <a href="https://www.yaypokemon.com/track/">here</a>.</p>'+
    //            '<p>This email was sent by <a href="https://www.yaypokemon.com/">yaypokemon.com</a></p></div>'+
    //            '<div></div>', 
    //            subject:"Order Inquiry" } }).then(async result => {
    //                     console.log(result);
    //                     await insertOrderHistoryChange(order, "Email Sent:" + formData.emailMessage);
    //     }).catch(e=>{console.log(e)});
    // }


    const updateFormDataBody = (data)=>{

        console.log("Set grape html body:", data)
        setGrapeHTMLBody(data);

    }

    // /**
//  * @param string $body
//  * @param string $campaign
//  * @param string $medium
//  * @return mixed
//  */
// protected function add_analytics_tracking_to_urls($body, $campaign, $medium = 'email') {
//     return preg_replace_callback('#(<a.*?href=")([^"]*)("[^>]*?>)#i', function($match) use ($campaign, $medium) {
        // $url = $match[2];
        // if (strpos($url, '?') === false) {
        //     $url .= '?';
        // } else {
        //     $url .= '&';
        // }
        // $url .= 'utm_source=' . $medium . '&utm_medium=' . $medium . '&utm_campaign=' . urlencode($campaign);
        // return $match[1] . $url . $match[3];
//     }, $body);
// }\\\


const addAnalyticsTrackingToUrls  = (body)=>{

    console.log("addAnalyticsTrackingToUrls:", body);
    
            let r = /#(<a target="_blank" href=")([^"]*)("[^>]*?>)#i/;

        let result = body.replace(r,'?What');
        
    console.log("result>>>>:", result);
        

    // let result = body.replace('#(<a target="_blank" href=")([^"]*)("[^>]*?>)#i', function (match) { 

    //     console.log("Match:", match);
    //  });

    

    // let result = body.replace('#(<a href=")([^"]*)("[^>]*?>)#i', function (match) { 

    //     console.log("Match:", match);
    //  });

     return result;
}

const getTotalClicks = (history)=>{

    let totalClicks = 0;

    if (history) {
        for (let i = 0; i < history.length; i++) {            
            let description = history[i].description ? JSON.parse(history[i].description) : "Not Available" ;
            
            if (description && description.eventType) {
                if (description.eventType == "Click") {
                    totalClicks++;
                }
            }
        }
    }
    
    return totalClicks;
}

    const displaySendEmail = (order)=>{            
        return (<>
                    {              
                            displayInputField("customEmail", "black", "Email", formData, null, null, setFormData)
                    }                          
                    {              
                            displayInputField("subject", "black", "Subject", formData, null, null, setFormData)
                    }                          
                    {              
                            displayInputField("title", "black", "Title", formData, null, null, setFormData)
                    }    
                    {              
                            displayInputField("campaignId", "black", "Campaign Id", formData, null, null, setFormData)
                    }    
                    
                    {              
                            displayInputField("campaignSource", "black", "Campaign Source", formData, null, null, setFormData)
                    }    


                <div style={{minHeight:"500px"}}>
                {/* <header className="App-header">
                </header>
                */}
                {
                    <AdminGrape ref={grapeEditor} onHandleUpdate={updateFormDataBody} formData={formData} setFormData={setFormData} html={formData.body}/>
                }
                


                    {/* <Slate 
                    editor={editor}
                    value={value}
                    onChange={newValue=> setValue(newValue)}
                    >
                              <Editable />
                    </Slate> */}

                    {/* <AdminQuill /> */}


                 {/* DraftJS    */}
                {/* <Editor
                ref={editor}
                editorState={editorState}
                onEditorStateChange={handleEditorChange}                
                /> */}

            </div>

            

            <Button          
                        className="modal-button"
                        size="small" color="primary" variant="contained"
                        onClick={async ()=>{    

                                
                                if (grapeHTMLBody && grapeHTMLBody.length > 3) {
                                    saveEmailDraft(
                                        {
                                            type:"Custom",
                                            email:formData.customEmail,
                                            subject:formData.subject,
                                            body:grapeHTMLBody,
                                            title:formData.title,
                                            campaignId:formData.campaignId,
                                            campaignSource:formData.campaignSource
                                            }
                                    );
                                } else {
                                    if (window.confirm("There was an error with the html please reload the page.")) {

                                    }
                                }
                                
                            
                            
                        }}>Save</Button>
                    <Button          
                        className="modal-button"
                        size="small" color="primary" variant="contained"
                        onClick={async ()=>{          


                                let getSecretKey = await getSecretKeyForEmail(formData.customEmail);

                                
                            if (getSecretKey.data.getSubscriber) {
                                
                                var re = new RegExp("<a([^>]* )href=\"([^\"]+)\"", "g");
                                let  result = grapeHTMLBody.replace(re, `<a$1href=\"$2?utm_source=${formData.campaignSource}&utm_medium=email&utm_campaign=${formData.campaignId}\"`);
 
                                 if (getSecretKey && getSecretKey.data && getSecretKey.data.getSubscriber && getSecretKey.data.getSubscriber.secret) {    
                                     let secretKey = getSecretKey.data.getSubscriber.secret;
                                     let subscribed = getSecretKey.data.getSubscriber.subscribed;
 
                                     setDisplayPreviewDetails({
                                         type:"Custom",
                                         email:formData.customEmail,
                                         subject:formData.subject,
                                         body:result,
                                         title:formData.title,
                                         name:formData.campaignId,
                                         secret:secretKey,
                                         subscribed:subscribed
                                         }
                                     );
                                     setDisplayEmailPreview(true);
                                 }
 
                            } else {
                                console.log("Warning no subscriber")
                            }

                                // let replaceResult = AppendCampaignToString(grapeHTMLBody);
                                // console.log("replaceResult:", replaceResult);

                                // const params = {utm_source: 'email'};
                                // const result = (grapeHTMLBody);


                        }}>Email
                    </Button>


        </>)
    }

    const displaySubscriberStats = ()=>{
        let emailDomains = {};

        let totalVerified = 0;

        for (let i = 0; i < subscribers.length; i++) {
            let subscriber = subscribers[i];
            var first_split = subscriber.id.split("@")[1];
            emailDomains[first_split] = 1;
            if (subscriber.verified) {
                totalVerified++;
            }
        }

        // {
        //     index == 0 ? <span>Verified:{totalVerified}</span> :<></>
        //  }
        return <p style={{wordWrap:"anywhere"}}>{Object.keys(emailDomains).map((domain,index) =>{
            return (<>
          
             <span onClick={()=>{                 
                 console.log("Clicked:", domain);

                 let updatedSelected = []                 
                 for (let i = 0; i < subscribers.length; i++) {
                    let subscriber = subscribers[i];
                    var first_split = subscriber.id.split("@")[1];
                    if (domain == first_split) {

                        if (canAddSubscriberToCampaign(subscriber)) {
                            updatedSelected.push(subscriber)
                        }                        
                    }                   
                }
                
                setSelectedSubscribers(updatedSelected);
                
             }}>{domain}&nbsp;</span>
            </>)
        })} </p>

    }

    const isSelectedSubscriber = (subscriber) => {
        for (let i = 0; i < selectedSubscribers.length; i++) {
            if (selectedSubscribers[i] == subscriber) {
                return true;
            }
        }
        return false;
    }

    const checkHasSentSelectedCampaign = (emailAddress)=>{
        if (sentEmails) {
            for (let i = 0; i < sentEmails.length; i++) {
                let email = sentEmails[i];
                let emailData = email.emailData;
                
                if (emailData) {
                    if (emailData.email.toLowerCase() == emailAddress.toLowerCase()) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const canAddSubscriberToCampaign = (subscriber)=> {
        if (subscriber.subscribed && subscriber.rejected == null && !checkHasSentSelectedCampaign(subscriber.email.toLowerCase())) {
            return true;
        }   
        return false;
    }


    const getSelectedSubscribersSent = ()=> {
        let sentCount = 0;

        if (subscribers) {
            for (let i = 0; i < subscribers.length; i++) {
                if (checkHasSentSelectedCampaign(subscribers[i].email)) {
                    sentCount++;
                }
            }
        }

        return sentCount;
    }



    const getSubscriberListItemClass = (subscriber)=> {

        //subscriber.subscribed
        if (checkHasSentSelectedCampaign(subscriber.email)) {
            return "subscriber-item-sent"
        }

        if (subscriber.subscribed) {
            let currentString = isSelectedSubscriber(subscriber) ? "subscriber-item-selected" : "subscriber-item";
            return currentString;
        } else {
            return "subscriber-item-unsubscribed"
        }    
    }


    const showSendEmailStats = ()=> {
        let totalOpen = 0;
        let totalSent = 0;
        let totalBounce = 0;
        let totalDelivery = 0;
        let totalClicked = 0;
        let totalPending = 0;
        for (let i = 0; i < sentEmails.length; i++) {
            let sentEmail = sentEmails[i];

            if (sentEmail.status == "Open") {
                totalOpen++;
            }
            if (sentEmail.status == "Bounce") {
                totalBounce++;
            }
            if (sentEmail.status == "Delivery") {
                totalDelivery++;
            }
            if (sentEmail.status == "Pending") {
                totalPending++;
            }

            if (sentEmail.history) {
                for (let x = 0; x < sentEmail.history.length; x++) {
                    let historyItem = sentEmail.history[x];
                    if (historyItem.description) {
                        let historyData = JSON.parse(historyItem.description);
                        if (historyData.eventType == "Click") {
                            totalClicked++;
                            break;
                        }
                    }
                }
            }
        }

        return (<> <p style={{marginBottom:"1em"}}> 
                    <span onClick={()=>{
                        // setSentEmailsFilter("Sent");
                    }}>Sent {sentEmails.length} </span>
                    <span className="filter-button" onClick={()=>{
                        setSentEmailsFilter("Delivery");
                    }}>Delivery {totalDelivery} </span> 
                    <span className="filter-button" onClick={()=>{
                        setSentEmailsFilter("Open");
                    }}>Open {totalOpen}</span> 
                    <span className="filter-button" onClick={()=>{
                        setSentEmailsFilter("Bounce");
                    }}>Bounce {totalBounce}</span> 
                    <span className="filter-button" onClick={()=>{
                        setSentEmailsFilter("Clicked");
                    }}>Clicked {totalClicked} </span> 
                    <span className="filter-button" onClick={()=>{
                        setSentEmailsFilter("Pending");
                    }}>Pending {totalPending} </span> 
                 
            </p> </>)
    }

    return (<>
    <div>
    <div className="email-draft-item-group">        
        <div className="email-draft-item-list">        
        <div className="email-draft-item-list-header"><h3>Templates</h3></div>
         {
            savedEmails.map(email=>{
                let emailData = JSON.parse(email.data);
                
                return(<div className="email-draft-item" key={"email_" + email.id}>
                
                <div>
                   
                <p className="campaign-subject">
                    <b>Subject </b>{emailData.subject} - 
                    {email.id}
                    </p>

                <Button          
                        className="modal-button-small"
                        size="small" color="primary" variant="contained"
                        onClick={async ()=>{    
                                
                                setFormData({...formData, ["body"] : emailData.body, ["customEmail"] : emailData.email, ["subject"] : emailData.subject, ["title"] : emailData.title, ["campaignSource"] : emailData.campaignSource, ["campaignId"] : emailData.campaignId});                                        
                              
                                if (grapeEditor) {
                                    console.log(grapeEditor);                        
                                }
                          
                                let emailsSent = []
                                console.log("email.links:", email.links);

                                // let results = await getSentEmailsForCampaign();
                                if (email.links.items) {
                                    
                                    for (let i = 0; i < email.links.items.length; i++) {
                                        let link = email.links.items[i];
                                        if (link.type == "EmailSent") {                                            
                                            link.emailData = JSON.parse(link.data);
                                            emailsSent.push(link)
                                        }
                                    }
                                    if (email.links.nextToken) {
                                        console.log("email.links.nextToken:", email.links.nextToken)
                                    }
                                    setSentEmails(emailsSent)
                                }
                                
                                // let sentItems = await getUserItemType("EmailSent");
                                // console.log("getUserItemType EmailSent:", sentItems )
                                // setSentEmails(sentItems); 
                                
                        }}>View Campaign</Button>


                    <Button          
                        className="modal-button-small"
                        size="small" color="primary" variant="contained"
                        onClick={async ()=>{    

                                setFormData({...formData, ["body"] : emailData.body, ["customEmail"] : emailData.email, ["subject"] : emailData.subject, ["title"] : emailData.title, ["campaignSource"] : emailData.campaignSource, ["campaignId"] : emailData.campaignId});                                        
                                setDisplayEmailCampaign(true);

                        }}>Email Campaign { selectedSubscribers.length > 0 ? <>({selectedSubscribers.length})</> :<></> }</Button>

                    <Button          
                        className="modal-button-small"
                        size="small" color="primary" variant="contained"
                        onClick={async ()=>{    
                                console.log("Delete Campaign TODO");                                                                                    
                        }}>Delete</Button>
                    
                    <p>
                        {/* {emailData.email} */}
                    </p>
                    <div style={{overflow:"scroll", maxHeight:"200px"}}>
                        {/* {emailData.body} */}
                        <div dangerouslySetInnerHTML={{__html:emailData.body}}>
                     </div>
                    </div>
                    
                </div>
                </ div>)
            })    
        }
        </div>
    </div>
    <div className="email-draft-item-group">        
        <div className="email-draft-item-list">
            <div className="email-draft-item-list-header"><h3>Emails Sent</h3>
            
            <Button          
                        className="modal-button-small"
                        size="small" color="primary" variant="outlined"
                        onClick={async ()=>{    
                                setSentEmailsFilter("");
                        }}>Show All</Button>

                        {
                            showSendEmailStats()
                        }
            </div>       

                      


                  {
                    sentEmails.map(email=>{
                        
                        let emailData = email.data ? JSON.parse(email.data) : {email:"", status:"Error"};
                        
                        if (sentEmailsFilter != "")  {

                            console.log("email:", email, " sentEmailsFilter:", sentEmailsFilter);
                            if (email.status == sentEmailsFilter) {

                            }else {
                                return <></>
                            }
                        }
                        
                        return(<div className="email-draft-item" key={"email_" + email.id}>
                            <table>
                            <tr>
                                <td>
                                    Total Clicks
                                </td>
                            <td>
                                {
                                    getTotalClicks(email.history)
                                }
                                </td>
                            </tr>
                                <tr>
                                    <td>
                                    <p>{moment(email.createdAt).format("MM/DD/YY HH:mm A")}</p>
                                    </td>
                                    <td>
                                    <p>{emailData.email}</p>
                                    </td>
                                    <td>
                                    <p>{emailData.subject}</p>
                                    </td>
                                    <td>
                                    <p>{email.status}</p>
                                    </td>
                                </tr>
                                </table> 

                        </div> )
                        
                    })
            }
        </div>
    </div>

    <div className="email-draft-item-group">        
        <div className="email-draft-item-list">
            <div className="email-draft-item-list-header"><h3>Subscribers</h3>
            <p>Total {subscribers.length} Selected {selectedSubscribers.length} Sent {getSelectedSubscribersSent()}
            
            
            </p>
                    
            <Button          
                        className="modal-button-small"
                        size="small" color="primary" variant="outlined"
                        onClick={async ()=>{    
                                setShowNotSent(!showNotSent);
                        }}>{showNotSent ? "Show Not Sent" : "Show All"}</Button>

                    <Button          
                        className="modal-button-small"
                        size="small" color="primary" variant="outlined"
                        onClick={async ()=>{    
                                setShowSelected(!showSelected);
                        }}>{showSelected ? "Show All" : "Show Selected"}</Button>

<Button          
                        className="modal-button-small"
                        size="small" color="primary" variant="outlined"
                        onClick={async ()=>{    
                                setSelectedSubscribers([]);
                        }}>Clear Selected</Button>


                {
                    displaySubscriberStats(subscribers)
                }
            </div>
            
            {
                    subscribers.map(subscriber => {
                        
                        return(<>
                        
                            {
                           
                           
                           showSelected  && isSelectedSubscriber(subscriber) || !showSelected ? 

                           showNotSent ? <>  
                              <div className={getSubscriberListItemClass(subscriber)} key={"subscriber_" + subscriber.id} onClick={()=>{
                            
                            // selectedSubscribers
                            let contains = false;
                            let subscribersWithout = [];

                            if (canAddSubscriberToCampaign(subscriber)) {
 
                            for (let i = 0; i < selectedSubscribers.length; i++) {
                                if (selectedSubscribers[i] == subscriber) {
                                    contains = true;
                                }else {
                                    subscribersWithout.push(selectedSubscribers[i])
                                }
                            }
                            if (!contains) {
                                setSelectedSubscribers([...selectedSubscribers, subscriber])
                            } else {
                                setSelectedSubscribers(subscribersWithout)
                            }
                        }


                        }}>
                            
                            <table>
                            
                            <tr>                               
                                
                                <td>
                                    {checkHasSentSelectedCampaign(subscriber.email) ? "SENT" : ""}
                                </td>

                                <td>
                                    
                                </td>
                                <td>
                                    Email: {subscriber.email}
                                </td>
                                <td>
                                    {subscriber.rejected}
                                </td>
                                <td>
                                    {subscriber.verified ? "TRUE" : ""}
                                </td>
                            </tr>
                            </table> 
                            </div>  
                            </> : <></>
                            
                            :<></>
                           
                           }
                        </> 
                        )
                    }
                    
                    )
            }
        </div>
    </div>


        <div className="email-draft-item-email">
        {
            displaySendEmail()
        }

        {
            displayEmailPreview ? <>
            <PreviewEmail isCampaign={true} details={displayPreviewDetails} setDisplayPreview={setDisplayEmailPreview} insertOrderHistoryChange={insertOrderHistoryChange}/>
            </> : <></>
        }

        {
            displayEmailCampaign ? <>
                <AdminEmailCampaign  details={{
                                         type:"Custom",
                                         email:formData.customEmail,
                                         subject:formData.subject,
                                         body:formData.body,
                                         title:formData.title,
                                         name:formData.campaignId,
                                         campaignSource:formData.campaignSource,                                         
                                         }} selectedSubscribers={selectedSubscribers} setDisplayEmailCampaign={setDisplayEmailCampaign} />                
            </> :<></>
        }

        </div>
    </div>

    </>)
    
}

export default AdminEmailPage;