import React, { useState, useContext, useEffect, useRef } from 'react'
import moment from 'moment';
import Button from "@material-ui/core/Button";
import AdminContext from "../context/admin";
import ChartGraph from "../components/yayfun/ChartGraph";
import DateRange from "../components/yayfun/DateRange";
import Select from '@material-ui/core/Select';
import { ConvertStringToDollarsCents } from '../components/Helper';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from "@material-ui/core/styles";
import ProductImage from "../components/ProductImage";



const useStyles = makeStyles({
    root:{
        padding:"1em"
    },
    rootCheckbox:{
      paddingTop: "0.5px",
      paddingBottom: "0.5px",
      paddingRight:"0.1em",
      paddingLeft:"0.4em"
    }
});


function AnalyticsPage(props) {

    // Time Range Start
    // Time Range End
    // Total Profit
    // Total Inventory
    // Total Market Sales   
    const classes = useStyles();    
    const { createAnalyticsInput, analytics, orders, products, fetchMoreAnalyticsForProduct } = useContext(AdminContext);
    const [startDateInput, setStartDateInput] = useState(moment().startOf('month'));
    const [endDateInput, setEndDateInput] = useState(moment().endOf('day'))
    const [focusedInput, setFocusedInput] = useState(null)
    const [graphType, setGraphType] = useState("Orders");

    const [selectedProductIndex, setSelectedProductIndex] = useState(0);
    const [selectedProductId, setSelectedProductId] = useState(0);

    const ordersChartRef = useRef();
    const inventoryChartRef = useRef();


    const enumerateDaysBetweenDates = (startDate, endDate) => {
        var now = startDate.clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('M/D/YYYY'));
            now.add(1, 'days');
        }
        return dates;
    };

    const updateDates =(startDate, endDate) => {
        setStartDateInput(startDate);
        setEndDateInput(endDate);
    }

    const displaySelectedProductData = (items)=>{        
       
        let ordersShipped = 0;
        let ordersPending = 0;
        let ordersCancelled = 0;
        let ordersRefunded = 0;

        let total = 0;
        let totalCancelled = 0;
        let totalPending = 0;
        let totalRefunded = 0 
        let startDateValue = moment(startDateInput).valueOf();
        let endDateValue = moment(endDateInput).valueOf();
        let totalProfit = 0;
        
        
        for (let i = 0; i < orders.length; i++) {
            let order = orders[i];

            if (i < 4) {
                console.log("order.products:", order.products);
            }
            


            let orderDate = moment(order.date).valueOf();
            let containsProduct = false;
            let productCost = 0; // amount charged to customer
            let itemCost = 0; // amount the item cost the seller

            // for (let i = 0; i < order.products.items.length; i++) {

                
            // }                    

            for (let x = 0; x < order.products.items.length; x++) {
                let product = order.products.items[x].product;
                if (product.id.toUpperCase() == selectedProductId) {
                    containsProduct = true;
                    productCost = parseFloat(order.products.items[x].price);
                    itemCost = parseFloat(order.products.items[x].cost)
                }
            }

            if (containsProduct && orderDate >= startDateValue && orderDate <= endDateValue) {
                if (order.total && order.status == "Shipped") {
                    total += productCost;
                    ordersShipped++;
                } else if (order.total && order.status == "Pending" || order.status == "Awaiting Fulfillment" ) {
                    totalPending += productCost;
                    ordersPending++;
                } else if (order.total && order.status == "Cancelled" ) {
                    totalCancelled += productCost;
                    ordersCancelled++;
                } else if (order.status == "Refunded") {
                    totalRefunded += productCost;
                    ordersRefunded++;
                }else {
                    console.log("Error on order:", order);
                }     
            }
        }

        return (<div className="product-selection" style={{display:"inline-block"}}>
        <p>{selectedProductId}</p>
        <p><a target="_blank" href={"https://amazon.com/dp/"+ getAsinForProductId(items, selectedProductId)}>{getAsinForProductId(items, selectedProductId)}</a></p>
        <p>
            {
                `Total (Shipped + Pending):${ConvertStringToDollarsCents(total)} + (${ConvertStringToDollarsCents(totalPending)}) = ${ConvertStringToDollarsCents(total + totalPending)}`
            }           
        </p>
        <p>
            {`${ordersShipped} Shipped ${ordersPending} Pending  ${ordersCancelled} Cancelled`}
        </p>
        </div>)

    }
    
    const displayTotalForPeriodSelected = ()=> {
        
        let ordersShipped = 0;
        let ordersPending = 0;
        let ordersCancelled = 0;
        let ordersRefunded = 0;

        let total = 0;
        let totalCancelled = 0;
        let totalPending = 0;
        let totalProfit = 0;
        let totalShipping = 0;
        let totalRefunded = 0 
        let startDateValue = moment(startDateInput).valueOf();
        let endDateValue = moment(endDateInput).valueOf();


        
        for (let i = 0; i < orders.length; i++) {
            let order = orders[i];
            let orderDate = moment(order.date).valueOf();

            if (orderDate >= startDateValue && orderDate <= endDateValue) {
                if (order.total && order.status == "Shipped") {
                    let orderTotal = parseFloat(order.total);
                    let shippingCost = parseFloat(order.shippingCost ? order.shippingCost : 0);
                    let shippingPaid = parseFloat(order.shippingPaid ? order.shippingPaid : 0);

                    totalShipping += shippingCost;
                    total += (orderTotal - shippingCost);
                    console.log("total:", total, " orderTotal:", orderTotal, "shippingCost:", shippingCost);
                    ordersShipped++;
                                        
                    let itemCost = 0;                    
                    for (let i = 0; i < order.products.items.length; i++) {
                        itemCost += order.products.items[i].product.cost;
                    }                    

                    let profit = orderTotal - shippingPaid - itemCost;                    
                    totalProfit += profit;

                } else if (order.total && order.status == "Pending" || order.status == "Awaiting Fulfillment" ) {
                    let orderTotal = parseFloat(order.total);

                    totalPending += orderTotal;
                    let itemCost = 0;                    
                    for (let i = 0; i < order.products.items.length; i++) {
                        itemCost += order.products.items[i].product.cost;
                    }     
                    let shippingCost = 8;     // Estimated, to do go and look at past orders           
                    let profit = orderTotal - shippingCost - itemCost;                    
                    totalProfit += profit;
                    ordersPending++;
                } else if (order.total && order.status == "Cancelled" ) {
                    totalCancelled += parseFloat(order.total);
                    ordersCancelled++;
                } else if (order.status == "Refunded") {
                    totalRefunded += parseFloat(order.total);
                    ordersRefunded++;
                }else {
                    console.log("Error on order:", order);
                }     



            }
        }

        return (<>
        <h3>
            {
                `Total for period: ${ordersShipped + ordersPending} - ${ConvertStringToDollarsCents(total)} (${ConvertStringToDollarsCents(total + totalShipping)}) = ${ConvertStringToDollarsCents(total + totalPending)} , WREFUNDS:(${ConvertStringToDollarsCents(total + totalShipping)}): Est. Profit = ${ConvertStringToDollarsCents(totalProfit)}  (${(((totalProfit/(total+totalPending)) * 100)).toFixed(2)}%)`
            }                    
        </h3>
        <p>
            {`Shipped - ${ordersShipped} Pending - ${ordersPending} Cancelled - ${ordersCancelled}`}
        </p>
        </>)
    }



    
    
      const displaySetSelector = (items, productIds)=> {
        return (
          <FormControl variant="outlined" inputProps={{
                  style: {
                    padding:"5px"
                  }
               }} >
            <InputLabel id="demo-simple-select-outlined-label">Choose a product</InputLabel>
            <Select
              classes={{
                root: classes.root
              }}
              labelId="demo-simple-select-outlined-label"
              value={selectedProductIndex}
              onChange={async (e)=>{
                    setSelectedProductId(e.target.value);
                    setSelectedProductIndex(e.target.value);

                    await fetchMoreAnalyticsForProduct(e.target.value.toLowerCase());
                    
              }}
              label="Product Selection">        
              {
                displayProducts(items, productIds)
              }
            </Select>     
          </FormControl>
        );}
        
const displayImageForItem = (items, id) => {

    for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if (item && item.id.toUpperCase() == id) {
            console.log("IMAGE:", item);
            return(<ProductImage customClassName="dropdown-image" product={item} suffix="_120.png"/>)
        }
    }
    
    return (<></>)
}

const getAsinForProductId = (items, id) => {

    for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if (item.id.toUpperCase() == id) {
            return item.asin;
        }
    }
    
    return (<></>)
}


const displayProducts = (items, ids)=>{
    return ids.map(item =>{
        return(<MenuItem              
                key={item}
                value={item}>
                 
                 <div className="dropdown-image-container">
                 {displayImageForItem(items, item)}
                 </div>
                 {item}


                </MenuItem>)
    });
} 

    const getProductIdsFor = (items)=>{
        let uniqueIds = []
        if (items) {
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                let foundExisting = uniqueIds.find((findId)=> findId === item.id.toUpperCase());
                if (!foundExisting) {
                    uniqueIds.push(item.id.toUpperCase());
                }
            }    
        }    
        uniqueIds.sort((a, b) => a > b);
        return uniqueIds;
    }


    const displayProductSelectionDropdown = (items)=>{

        console.log("displayProductSelectionDropdown:", items)
        return(<div className="card-set-dropdown">
            {
                displaySetSelector(items, getProductIdsFor(items))
            }

            {
                displaySelectedProductData(items)
            } 
        </div>)
    }
    return (<>     

        <div style={{paddingTop:"0em", paddingRight:"0em"}}>
        
        <div style={{paddingBottom:"3em"}}>
        <Button color="primary" variant={graphType == "Orders" ? "contained" : "outline"} onClick={()=>{
            setGraphType("Orders")
        }}>
            Orders
        </Button>

        <Button color="primary" variant={graphType == "Inventory" ? "contained" : "outline"} onClick={()=>{
            setGraphType("Inventory")
        }}>
            Inventory
        </Button>

        <Button color="primary" variant={graphType == "Market" ? "contained" : "outline"} onClick={()=>{
            setGraphType("Market")
        }}>
            Market
        </Button>

        </div>

        <div style={{backgroundColor:"white"}}>
                <div style={{textAlign:"left", paddingLeft:"2em", marginRight:"10em"}}>
                    <DateRange updateDates={updateDates}/>
                </div>
                {
                    graphType == "Market" || graphType == "Orders" ? <>                    
                     {
                         displayProductSelectionDropdown(products)
                     }
                  
                    </> :<></>
                }

                <div style={{display:graphType == "Orders"? "block":"none"}}>
                    {
                        displayTotalForPeriodSelected()
                    }
                    <ChartGraph graphType={"Orders"} data={[]} orders={orders} startDate={startDateInput} endDate={endDateInput} seriesDays={enumerateDaysBetweenDates(startDateInput, endDateInput)} />                
                </div>
               
                <div style={{display:graphType == "Inventory"? "block":"none"}}>
                      <ChartGraph graphType={"Inventory"} data={analytics && analytics['Price'] ? analytics['Price'].items : []} orders={orders} startDate={startDateInput} endDate={endDateInput} seriesDays={enumerateDaysBetweenDates(startDateInput, endDateInput)} />
                </div>

                <div style={{display:graphType == "Market"? "block":"none"}}>                
               
                      <ChartGraph graphType={"Market"} data={analytics && analytics['Market'] ? analytics['Market'].items : []} selectedId={selectedProductId} orders={orders} startDate={startDateInput} endDate={endDateInput} seriesDays={enumerateDaysBetweenDates(startDateInput, endDateInput)} />
                </div>


        </div>            
            

        </div>
    </>)
}

export default AnalyticsPage