
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ProductProvider } from './context/products';
import { CartProvider } from './context/cart';
import { UserProvider } from './context/user';
import { UiProvider } from './context/ui';

//Needed for safari 12.0 and below
require('intersection-observer');

ReactDOM.render(<UiProvider>
                    <UserProvider>
                        <ProductProvider>
                            <CartProvider>                                
                                <App/>
                            </CartProvider>
                        </ProductProvider>
                </UserProvider>
                </UiProvider>
, document.getElementById('root'));
