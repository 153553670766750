import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import ProductContext from "../context/products";
import CartContext from "../context/cart";
import UserContext from "../context/user";

import UiContext from "../context/ui";

import { CardElement, useElements, useStripe, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import { CircularProgress } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { API } from '@aws-amplify/api';
import {PurchaseEvent, SendConversionEvent} from './yayfun/AnalyticsEvent';
import PayPalButton from './PayPalButton';

import { v4 as uuid } from 'uuid';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const CheckoutForm = ({ formData, notifySuccess, shippingCost, taxCost, guestCheckout }) => {
 
  const { cart, total, clearCart, coupon, updateCartPrice, updateTotalForCart} = useContext(CartContext);
  const {clearMessages} = useContext(UiContext);
  const { checkout, updateProductPrice } = useContext(ProductContext);
  const { fetchUserItem, addUserItem, updateCreateUserItem, user, createSubscriberInput, subscribeToEmail} = useContext(UserContext);

  const [paymentRequest, setPaymentRequest] = useState(null);

  const [orderDetails, setOrderDetails] = useState(
    { cart, 
      total:total, 
      coupon:coupon,
      shippingCost:shippingCost,
      taxCost:taxCost ? taxCost : 0,
      token: null, 
      email: formData.email,
      shippingAddress: {
        name:formData.firstName + (formData.lastName ? " " + formData.lastName : ''), 
        firstName:formData.firstName,
        lastName:formData.lastName,
        companyName: formData.companyName,
        addressLine1: formData.addressLine1, 
        addressLine2: formData.addressLine2, 
        city: formData.city, 
        country: "US", 
        postalCode: formData.postalCode, 
        state: formData.state
      },
      billingAddress: {
        name:formData.billingFirstName + (formData.billingLastName ? " " + formData.billingLastName : ''),         
        firstName:formData.billingFirstName,
        lastName:formData.billingLastName,
        addressLine1: formData.billingAddress1, 
        addressLine2: formData.billingAddress2, 
        city: formData.billingCity, 
        country: "US", 
        postalCode: formData.billingZip, 
        state: formData.billingState
      },
      paymentMethod:"Credit Card",
      guestCheckout: true, 
});


  const [orderSubmitted, setOrderSubmitted] = useState(false);
  const [orderFinished, setOrderFinished] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false) ;
  const [loading, setLoading] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState("Credit Card");

  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  let selectedPaymentMethod = 'credit_card';

  async function startCheckout(paymentIntentId, orderNumber, authorizationId) {
        
    let result = await checkout({ ...orderDetails, orderNumber, paymentMethod, token:paymentIntentId, authorizationId:authorizationId}, guestCheckout);

    clearMessages();



    if (result && result.errors == null) {

      setOrderSuccess(true);
      notifySuccess(result);
      clearCart();
      PurchaseEvent(result);
      SendConversionEvent(result.orderNumber);
      window.scrollTo(0,0);

      let resultSubscribe = await createSubscriberInput({id:orderDetails.email.toLowerCase(), name:orderDetails.email, email:formData.email, subscribed:true, secret:uuid()});
      if (resultSubscribe.errors && resultSubscribe.errors[0]) {
        if (resultSubscribe.errors[0].message && resultSubscribe.errors[0].message.includes("Extended Request ID")) {
          subscribeToEmail(formData.email);
        }
      }

      
    } else {
      
      setOrderSuccess(false);
      setOrderSubmitted(false);
      setOrderFinished(false);
      
      if (result.errors[0] && result.errors[0].message) {
        setErrorMsg(result.errors[0].message);
      } else {
        setErrorMsg("Error processing order, please try again.");
      }      
    }

    setLoading(false);

  }


  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    try {
      if (event.error) {
        setError(event.error.message);
      } else {
        setError(null);
      }
  }
  catch(e){
    console.log(e);
  }
  };


  const handlePaymentMethodChange = (event) => {    
       setPaymentMethod(event.target.value);
  };


  const checkStockAndPrices = async()=> {
  
    let foundIssue = false;
    let foundPriceIssue = false;
    let foundOutofStockIssue = false;

    let updatedCart = orderDetails.cart;

    for (let i = 0; i < orderDetails.cart.length; i++) {
      let cartItem = orderDetails.cart[i];
      await API.post("yayfunthingsapi", "/items/getItemPriceStatus", { 
        body:{id:cartItem.id, price:cartItem.price}
    }).then(async result=>{        
      
      if (result.status != "Available") {
        foundIssue = true;
        if (result.status == "Out of stock") {
          foundOutofStockIssue = true
        } else {
          foundPriceIssue = true;                 
        }
      }
      
      if (result.status == "Price Changed") {
        
        if (result.price) {
           cartItem.price = result.price;
           foundPriceIssue = true;
           updateCartPrice(cartItem.id, result.price);
           updateProductPrice(cartItem.id, result.price);
           for (let i = 0; i < updatedCart; i++) {
             if (cartItem.id == updatedCart[i].id) {
              updatedCart[i].price = result.price;
             }
           }           
         }  

         
      }
    }).catch(e =>{console.log(e);            
        console.log("Error");
        foundIssue = true;
    });            

    if (foundIssue) {

      let updatedTotal = updateTotalForCart();
      updateOrderDetails({...orderDetails, cart:updatedCart, total:updatedTotal});
      
      forceUpdate();

      break;
    }

    }


    if (!foundIssue) {
      return true;
    } 

    if (foundPriceIssue) {
      setErrorMsg("Error processing order, the price of an item in your cart has changed. Please review your order before placing it again.");
    } else if (foundOutofStockIssue) {
      setErrorMsg("Error processing order, one of your items is out of stock. Please review your order before placing it again.");
    }
    
    return false;

  }

  // Handle Stripe form submission.
  const handleSubmit = async () => {
    
    setLoading(true);
    setError("");
    setErrorMsg("");

    //First check if cart items have changed price

    let allInStockandPricesMatch = await checkStockAndPrices();

    if (allInStockandPricesMatch) {
     
      const card = elements.getElement(CardElement);
      let customerId = null;
  
      //Check if customer exists 
      let userSub = guestCheckout ? orderDetails.email : user.sub;
  
      let customerIdResult = await fetchUserItem({id:"CustomerId", name:"stripe-customer-id", type:"Stripe"}, userSub);
  
      if (customerIdResult && customerIdResult.data && customerIdResult.data.getUserItem && customerIdResult.data.getUserItem.data) {
        customerId = customerIdResult.data.getUserItem.data;
      }
      
      let intent = await API.post("yayfunthingsapi", "/items/createPaymentIntent",
      {body: {
        user:userSub, 
        amount:orderDetails.total,
        taxCost:orderDetails.taxCost, 
        cart:orderDetails.cart, 
        customerId:customerId, 
        coupon:orderDetails.coupon,
        customer:orderDetails.email, 
        shippingAddress:orderDetails.shippingAddress, 
        shippingCost:orderDetails.shippingCost,
        billingAddress:orderDetails.billingAddress,
        paymentProcessor:"Stripe"
      }}).then( async result => {        
        
        if (result.error) {
  
          setErrorMsg(result.error.message);
          setLoading(false);
          setOrderSuccess(false);
          setOrderSubmitted(false);
          setOrderFinished(false);
          
        }else {
                
        if (customerId == null && !guestCheckout) {
          await addUserItem({type:"Stripe", id:"CustomerId", name:"stripe-customer-id", data:result.customer}, userSub);
        }
        
        setOrderDetails({
          ...orderDetails,
          token:result.client_secret,
          paymentIntentId:result.id,
          orderNumber:result.metadata.order_number
        });
  
        if (!guestCheckout) {
          //Register to database;
         await updateCreateUserItem({type:"PaymentIntent", id:result.id, name:"payment-intent", data:result.metadata.order_number, status:"Pending"}, true);
        }
         
        const token = result.id;
  
          stripe.confirmCardPayment(
            result.client_secret,
            {
              payment_method: {card:card,
                billing_details: {
                  name:orderDetails.billingAddress.name
                }
              }
            }
          ).then(function(result) {
          
            if (result.error) {
              // Display error.message in your UI.
              setErrorMsg(result.error.message);
              setLoading(false);
              setOrderSuccess(false);
              setOrderSubmitted(false);
              setOrderFinished(false);
  
            } else {
  
              if (result.paymentIntent.status === 'requires_payment_method') {
                setLoading(false);
                setOrderSuccess(false);
                setOrderSubmitted(false);
                setOrderFinished(false);
              }
  
              if (result.paymentIntent.status === 'requires_capture') {
                startCheckout(token);
              }
  
              if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback execution
                // Set up a webhook or plugin to listen for the payment_intent.succeeded event
                // to save the card to a Customer        
                // The PaymentMethod ID can be found on result.paymentIntent.payment_method
                // The payment has succeeded
                // Display a success message
  
                // const card = await stripe.paymentMethods.attach(
                //   '{{PAYMENT_METHOD_ID}}',
                //   {
                //     customer: '{{CUSTOMER_ID}}',
                //   }
                // );              
                startCheckout(token);
              }
            }          
          });
        }
      }).catch(e=>{console.log(e)});
    } else {

      setOrderSuccess(false);
      setOrderSubmitted(false);
      setOrderFinished(false);     
      setLoading(false);

    }

    // let intent = await API.post("yayfunthingsapi", "/items/createPaymentIntent",{body:{amount:orderDetails.amount}});
    // console.log("intent:", intent);


    //OLD STRIPE CHARGES METHOD
    // CREATE A TOKEN AND USE STRIPE.CHARGES ON SERVER
    // const result = await stripe.createToken(card);
    // console.log("result.error:", result.error);

    // if (result.error) {
    //   // Inform the user if there was an error.
    //   setError(result.error.message);
    //   setOrderSubmitted(false);
    // } else {
    //   setError(null);
    //   // Send the token to your server.
    //   const token = result.token;
      // setOrderDetails({
      //   ...orderDetails,
      //   token: token.id
      // });
    // }

  };

  //   // Use your card Element with other Stripe.js APIs
  //   const {error, paymentMethod} = await stripe.createPaymentMethod({
  //     type: 'card',
  //     card: cardElement,
  //   });
  //   if (error) {
  //     console.log('[error]', error);
  //   } else {
  //     console.log('[PaymentMethod]', paymentMethod);
  //   }
  // };

  const updateOrderDetails = (updatedDetails)=>{
    setOrderDetails({...updatedDetails});
  }


  return (
    <>
      <div>
        
      <div style={{paddingTop:"1em", paddingBottom:"1em"}}>

      <Radio
      style={{display:"inline-block", verticalAlign:"top"}}
                checked={paymentMethod == 'Credit Card' ? true : false}
                onChange={handlePaymentMethodChange}
                value="Credit Card"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'Credit Card' }}
              />

        <span style={{cursor:"pointer", fontWeight:"bold", fontSize:"1.4em", verticalAlign:"top", marginTop:"10px", display:"inline-block", paddingRight:"1em"}} onClick={()=>{
          setPaymentMethod('Credit Card');
        }}>Credit Card</span>
        {
          !guestCheckout ? 
        <>
         <Radio  
              style={{display:"inline-block", verticalAlign:"top"}}            
              checked={paymentMethod == 'PayPal' ? true : false}
              onChange={handlePaymentMethodChange}
              value="PayPal"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'PayPal' }}
              label="PayPal"
            />

        <span style={{cursor:"pointer"}} onClick={()=>{
          setPaymentMethod('PayPal');
        }}>
          
          <img src="/images/44_Blue_PayPal_Pill_Button.png" alt="PayPal" />

        </span>

        </> :<></> }
        </div>
        {
          paymentMethod == 'Credit Card' ? <>
          <div className="checkout-form">
            <div className="checkout-card-group">
              <img className="checkout-card-img" src="images/discover.png" />
              <img className="checkout-card-img" src="images/mastercard.png" />
              <img className="checkout-card-img" src="images/visa.png" />
              <img className="checkout-card-img" src="images/amex.png" />
            </div>
             {/*
             <label htmlFor="checkout-address">Shipping Address</label>
        <input
          id="checkout-address"
          type="text"
          onChange={(e) => setOrderDetails({ ...orderDetails, address: e.target.value })}
        /> */}

            <div style={{ display: "inline-block", float: "left", }}>
              &nbsp;
              {/* <Radio
                checked={selectedPaymentMethod === 'credit_card'}
                onChange={handleChange}
                value="cred_card"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
              />
              <h3 style={{ display: "inline-block" }}>Credit Card</h3> */}
            </div>

            <div className="stripe-container">
              <div className="stripe-section">
                <CardElement id="stripe-element" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />          
              </div>
              <div className="card-errors" role="alert">
                &nbsp;{error}
              </div>
              <img className="stripe-svg" src="images/stripe-purple.svg" />
            </div>
      
          </div>
        
        <Button disabled={orderSubmitted} className="checkout-place-order" color="primary" variant="contained" onClick={async () => {
          if (!orderSubmitted) {
            setOrderSubmitted(true);  
            await handleSubmit();
          }
        }}>{loading ? <CircularProgress style={{width:"30px",height:"30px", color:"white"}} color="inherit" /> : 'Place Order'}
        </Button>
        {
          errorMsg && error != errorMsg ? <div className="card-errors" role="alert">{errorMsg}</div> : <></>
        }

        {paymentRequest ? <PaymentRequestButtonElement options={{paymentRequest}} /> : <></>}


          </>:<></>
        }
        {
          paymentMethod == 'PayPal' ? < div style={{paddingTop:"2em"}} >
                                            
            <PayPalButton orderDetails={orderDetails} updateOrderDetails={updateOrderDetails} startCheckout={startCheckout} guestCheckout={guestCheckout} />
            
            <div className="card-errors" role="alert">{errorMsg}</div>
            
            </div>:<></>
        }   
      </div>
    </>
  );
};

export default CheckoutForm;