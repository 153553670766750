import React, { useState, useContext, useEffect } from 'react'

import { API } from '@aws-amplify/api';

import { AmplifyAuthenticator, AmplifySignOut,AmplifySignIn , AmplifySignUp, AmplifyConfirmSignUp, AmplifyForgotPassword } from '@aws-amplify/ui-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from "../components/CheckoutForm";
import CustomTextField from '../components/yayfun/CustomTextField';

import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// REMOVED 02.13.21
// import Analytics from '@aws-amplify/analytics';
import Cart from "../pages/Cart";
import Checkbox from '@material-ui/core/Checkbox';
import UserContext from "../context/user";
import UiContext from "../context/ui";
import ProductContext from '../context/products';
import { ConvertStringToDollarsCents } from '../components/Helper';
import moment from 'moment';
import CartContext from "../context/cart";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProductImage from '../components/ProductImage';
import Seo from "../components/Seo";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',    
    margin:"0em"    
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  iconContainer: { // define styles for icon container
    transform: 'scale(1.5)',
  },
  formControl: {
    margin: "0em",
    marginTop:"0em",    
    marginBottom:"1em",    
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customBadge: {
    backgroundColor: "#ffb800",
    color: "black"
  },
  inputField: {
    height:"4.5em !important"
  }
}));


const dataStateAbrv = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American Samoa",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District Of Columbia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall Islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "MP": "Northern Mariana Islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VI": "Virgin Islands",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
}

function getSteps() {
  return ['Customer', 'Shipping', 'Billing', 'Payment'];
}


const defaultFormData = {
  name: "",
  guestEmail:"",
  email: "",
  phone: "",
  message: "",
  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  companyName: "",
  city: "",
  state: "",
  postalCode: "",
  phoneNumber: "",
  billingFirstName: "",
  billingLastName: "",
  billingAddress1: "",
  billingAddress2: "",
  billingCity: "",
  billingState: "",
  billingZip: "",
  guestCheckout: false
};

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}


const Checkout = () => {
  
  document.title = "Checkout";

  const stripePromise = loadStripe(window.location.hostname == "yayfunthings.com"  || window.location.hostname == "yaypokemon.com"  || window.location.hostname == "www.yaypokemon.com" || window.location.hostname.includes("yaypokemon.com") ?  process.env.REACT_APP_STRIPE_LIVE_KEY : process.env.REACT_APP_STRIPE_TEST_KEY);
  
  const { user, signedIn, shippingAddresses, fetchShippingAddresses } = useContext(UserContext)
  const { total, cart } = useContext(CartContext);

  const [formData, setFormData] = React.useState(defaultFormData);

  // Analytics.record({ name: 'Checkout visit' });

  const [inputError, setInputError] = React.useState({ firstName: false, name: false, guestEmail:false, email: false, phone: false, message: false, bannedDomain: false });
  const [checkoutStep, setCheckoutStep] = React.useState(0);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const [guestCheckout, setGuestCheckout] = React.useState(false);

  const [orderComplete, setOrderComplete] = React.useState(false);
  const [selectedShippingAddressIndex, setSelectedShippingAddressIndex] = React.useState(0);
  const [selectedShippingBillingSame, setSelectedShippingBillingSame] = React.useState(false);
  const [selectedShippingBillingSameOnce, setSelectedShippingBillingSameOnce] = React.useState(true);
  const [selectedShippingStateIndex, setSelectedShippingStateIndex] = React.useState(0);
  const [selectedBillingStateIndex, setSelectedBillingStateIndex] = React.useState(0);

  const [calculateShippingDirty, setCalculateShippingDirty] = React.useState(false);
  const [calculatingShipping, setCalculatingShipping] = React.useState(false);

  const [guestCheckoutCalculateShipping, setGuestCheckoutCalculateShipping] = React.useState(false);


  const [loadingShipping, setLoadingShipping] = React.useState(false);
  const [shippingCost, setShippingCost] = React.useState(0);
  const [shippingDay, setShippingDay] = React.useState(0);
  const [shippingCostError, setShippingCostError] = React.useState(false);
  const [taxCost, setTaxCost] = React.useState(0);
  const [completedOrderResult, setCompletedOrderResult] = React.useState(null);
  const {clearMessages, sendAlertMessage} = useContext(UiContext);
  const {products} = useContext(ProductContext);

  const [newAddressComplete, setNewAddressComplete] = useState(false);
  const steps = getSteps();
  const [loadingForm, setLoadingForm] = useState(false);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  function clearFormData() {
    setFormData(defaultFormData);
  }


  useEffect(() => {

    if (user && user.email) {
      if (formData && formData.email == "") {
        setFormData({ ...formData, "email": user.email });
        if (activeStep == 0) {
          handleNext();
        }
      }
      if (activeStep == 1) {
        
        // if (shippingAddresses && shippingAddresses.length > 0 && calculateShippingDirty) {
          setCalculateShippingDirty(false)
          calculateShipping();
        // }
      }
    } else if (!signedIn && !guestCheckout) {
      clearFormData();
      setActiveStep(0);
    } else if (guestCheckout) {
      if (activeStep == 1) {        
          setCalculateShippingDirty(false)
          calculateShipping();
      }
    }

  }, [user, calculateShippingDirty, signedIn]);


  const isDigitalOnly = ()=> {

    let digitalOnly = false;
    let containsCards = false;
    let containsCode = false;
    let containsLargeProducts = false;

    for (let i = 0; i < cart.length; i++) {
      let item = cart[i];
      if (item.type == "Single") {
        containsCards = true;
      }
      if (item.subtype == "Code") {
        containsCode = true;
      }
      if (item.type == "Sealed" && item.subtype != "Code") {
        containsLargeProducts = true;
      }
   }
  
   if (containsCards && !containsLargeProducts) {
   }else {
     if (containsLargeProducts) {
     } else {
      if (containsCode)  {
        digitalOnly = true;
      }
     }
   }
   return digitalOnly;
  }

  const containsCodeCards = ()=> {
    let containsCode = false;
    for (let i = 0; i < cart.length; i++) {
      let item = cart[i];
      if (item.subtype == "Code") {
        containsCode = true;
      }
   }
  
   return containsCode;
  }



  const isTaxableState = (stateName) =>{

    if (stateName == "tx" || stateName == "texas" || stateName == "t.x." || stateName == "t.x" ||  stateName == "tx." || stateName == "tex" || stateName == "texa") {
        return true;
    }
    return false;
  }

  const calculateProductWeights = async ()=>{
      
    let weightReturned = await API.post("yayfunthingsapi", "/items/getCartWeight",{body:{cartString:JSON.stringify(cart)}}).then(results => {
      if (results.weight) {        
        let weight = parseFloat(results.weight);
        return weight;
      }
      return 4;
    }).catch(e=>{
      return 4;
    });
    return weightReturned ? weightReturned : 4;
}

  
  async function calculateShipping() {

    setCalculatingShipping(true);

    let originZip = '78731';
    let destinationZip = formData.postalCode && formData.postalCode.length > 4 ? formData.postalCode.substring(0,5) : formData.postalCode;
    let shipDate = formatDate(new Date());    
    
    let weightCalculated = await calculateProductWeights(cart);

    let smallPackage = {
      service: 'FIRST CLASS',
      firstClassMailType: 'PACKAGE SERVICE RETAIL',
      zipOrigination: originZip,
      zipDestination: destinationZip,
      pounds: '0',
      ounces: '10',
      size: 'REGULAR',
      machinable: false,
      shipDate: { shipDate: shipDate }
    };

    let largePackage =  { service: 'PRIORITY',
    zipOrigination: originZip,
    zipDestination: destinationZip,
    pounds: weightCalculated,
    ounces: '0',
    container: 'NONRECTANGULAR',
    size: 'LARGE',
    width: '10',
    length: '10',
    height: '6',
    value: '100',
    specialServices: ['1'] 
  };

  let packages = [];
  let containsCards = false;
  let containsCode = false;
  let containsLargeProducts = false;

   for (let i = 0; i < cart.length; i++) {
      let item = cart[i];
      if (item.type == "Single") {
        containsCards = true;
      }
      if (item.subtype == "Code") {
        containsCode = true;
      }
      if (item.type == "Sealed" && item.subtype != "Code") {
        containsLargeProducts = true;
      }
   }
  
   if (containsCards && !containsLargeProducts) {
      packages.push(smallPackage)
   }else {
     if (containsLargeProducts) {
       packages.push(largePackage);
     } 
   }


   if (isDigitalOnly()){

    if (isTaxableState(formData.state.toLowerCase())) {
        setTaxCost(0.0825);
      } else {
        setTaxCost(0.0);
      }

      clearMessages();
      setShippingCostError(false);
      setShippingCost(0);

      setCalculatingShipping(false);

     return;
   }

       setLoadingShipping(true);

    // if (total > 100) {      
      // setShippingCost("0.00");
      // setLoadingShipping(false);
      await API.post("yayfunthingsapi", "/items/calculateShipDate",{body:{}}).then(results => {
        setShippingDay(results.shipDate);        
      }).catch(e=>{
        setShippingDay("");
      });

    
      if (isTaxableState(formData.state.toLowerCase())) {      
        setTaxCost(0.0825);
      } else {
        setTaxCost(0.0);
      }

    await API.post("yayfunthingsapi", "/items/calculateShipping", { body: { zip: formData.postalCode, packages: packages } }).then(async result => {
      await API.post("yayfunthingsapi", "/items/calculateShipDate",{body:{}}).then(results => {
        setShippingDay(results.shipDate);        
      }).catch(e=>{
        console.log("ERROR:", e);
        setShippingDay("");
      })
    
      if (Array.isArray(result)) {

        setShippingCostError(true);
        setShippingCost(0);
        sendAlertMessage("Error calculating shipping, not a valid address. Please check your address.");
      } else {

        if (result.package.error) {
          setShippingCostError(true);
          setShippingCost(0);
          sendAlertMessage("Error calculating shipping, not a valid address. Please check your address.");
        }

        if (result.package && result.package.postage && result.package.postage.rate) {
          clearMessages();
          setShippingCostError(false);
          setShippingCost(parseFloat(result.package.postage.rate))
          if (formData.state.toLowerCase() == "tx" || formData.state.toLowerCase() == "texas") {
            setTaxCost(0.0825);
          } else {
            setTaxCost(0.0);
          }
        }
      }

    }).catch(e => {
      setShippingDay("");
      setShippingCostError(true);
    });
    //await API.post("main", "/app", {"EventName":"InputData"}).then(result=>{console.log(result)}).catch(e=>{console.log(e)});
    setLoadingShipping(false);
    setCalculatingShipping(false);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => Math.min(3, prevActiveStep + 1));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(0,prevActiveStep - 1));
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const notifySuccess = (orderResult) => {

    setCompletedOrderResult(orderResult);
    setOrderComplete(true);

    // console.log("formData",formData);
    //   Analytics.updateEndpoint({
    //     ChannelType: 'EMAIL',
    //     Address: formData.email,
    //     location: {
    //         city: formData.billingCity,
    //         country: formData.country,
    //         postalCode: formData.postalCode,
    //         region: formData.state
    //     },
    //     optOut: 'NONE',
    //     attributes: {
    //         firstName: formData.firstName,
    //         hasShoppingCart: ['false'],
    //         completedOrder: ['false']
    //     },
    //     metrics: {
    //         itemsInCart: 0,
    //         orderNumber: "0"
    //     }
    // })

  }

  function isMobile() {
    if (window.innerWidth <= 1100) {
      return true;
    }
    return false;
  }


  async function verifyShippingAddress() {

    // billingFirstName: formData.firstName,
    // billingLastName: formData.lastName,
    // billingAddress1: formData.addressLine1,
    // billingAddress2: formData.addressLine2,
    // billingCity: formData.city,
    // billingState: formData.state,
    // billingZip: formData.postalCode

    let postMsg = {
      body:{
        address1:formData.addressLine1, 
        address2:formData.addressLine2, 
        city:formData.city, 
        state:formData.state, 
        zip5:formData.postalCode && formData.postalCode.length > 4 ? formData.postalCode.substring(0,5) : formData.postalCode
      }
    }

   let result = await API.post("yayfunthingsapi", "/items/verifyAddress", postMsg).then(results => {      
      if (results.address.error) {
        return false;
      }
      return true;
    }).catch(e=>{    
      return false;
    });

    return result;
  }


  const validateNewAddress = ()=> {
    let foundError = false;
    if (formData.email == "") {
      foundError = true;
    } 
    if (formData.firstName == "") {
      foundError = true;
    } 
    if (formData.lastName == "") {
      foundError = true;
    }
    if (formData.addressLine1 == "") {
      foundError = true;
    } 
    if (formData.city == "") {
      foundError = true;
    }

    var reZipTest = /^\d{5}(-\d{4})?$/;

    if (formData.postalCode == "" || !reZipTest.test(formData.postalCode)) {
      foundError = true;
    } 
   
    var reeamil = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
    if (!reeamil.test(formData.email)) {
      foundError = true;
    }

    if (selectedShippingStateIndex <= 0) {
      foundError = true;
    }
    return foundError;
  }

  const bannedDomains = ["protonmail"];

  function validateEmailData() {
    
    let foundError = false;
    let currentInputError = {...inputError};

    for (let i = 0; i < bannedDomains.length; i++) {
      let banned = bannedDomains[i];
      if (formData.email.toLowerCase().includes(banned)) {
        currentInputError = {...currentInputError, bannedDomain:true}    
        foundError = true;
      }
    }

    if (formData.email == "") {
      currentInputError = {...currentInputError, email:true}    
      foundError = true;
    } 

    var reeamil = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
    
    if (!reeamil.test(formData.email)) {
      currentInputError = {...currentInputError, email:true}    
      foundError = true;
    }

    if  (foundError) {

      setInputError({ ...currentInputError});      

      return {...currentInputError};
      
    } else  {

      setInputError({ ...currentInputError, email:false, bannedDomain:false});      
    }

    return true;
  }

  function validateFormData() {

    var Namere = /[A-Za-z]{1}[A-Za-z]/;
    // if (!Namere.test(formData.name)) {
    //     setInputError({...inputError, name: true});            
    //     return false;
    // }
    // var mobilere = /[0-9]{10}/;
    // if (!mobilere.test(formData.phone)) {
    //     setInputError({...inputError, phone: true});            
    //     return false;
    // }

    let foundError = false;

    let currentInputError = {...inputError};

    if (formData.email == "") {
      currentInputError = {...currentInputError, email:true}    
      foundError = true;
    } 

    if (formData.firstName == "") {
      currentInputError = {...currentInputError, firstName:true}
      foundError = true;
    } else {
      currentInputError = {...currentInputError, firstName:false}
    }

    if (formData.lastName == "") {
      currentInputError = {...currentInputError, lastName:true}
      foundError = true;
    } else {
      currentInputError = {...currentInputError, lastName:false}
    }

    if (formData.addressLine1 == "") {
      currentInputError = {...currentInputError, addressLine1:true}
      foundError = true;
    } else {
      currentInputError = {...currentInputError, addressLine1:false}
    }

    // if (formData.addressLine2 == "") {
    //   console.log("address1 ERROR");      
    //   currentInputError = {...currentInputError, addressLine2:true}
    //   foundError = true;
    // } else {
    //   currentInputError = {...currentInputError, addressLine2:false}
    // }

    if (formData.city == "") {
      currentInputError = {...currentInputError, city:true}
      foundError = true;
    } else {
      currentInputError = {...currentInputError, city:false}
    }


    var reZipTest = /^\d{5}(-\d{4})?$/;

    if (formData.postalCode == "" || !reZipTest.test(formData.postalCode)) {
      currentInputError = {...currentInputError, postalCode:true}
      foundError = true;
    } else {
      currentInputError = {...currentInputError, postalCode:false}
    }

   
    var reeamil = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
    if (!reeamil.test(formData.email)) {
      setInputError({ ...inputError, email: true });
      foundError = true;
    }

    if  (foundError) {

      setInputError({ ...currentInputError});      

      return false;
    }

    return true;
  }


  function validateBillingFormData() {

    var Namere = /[A-Za-z]{1}[A-Za-z]/;
    // if (!Namere.test(formData.name)) {
    //     setInputError({...inputError, name: true});            
    //     return false;
    // }
    // var mobilere = /[0-9]{10}/;
    // if (!mobilere.test(formData.phone)) {
    //     setInputError({...inputError, phone: true});            
    //     return false;
    // }

    let foundError = false;

    let currentInputError = {...inputError};

    if (formData.billingFirstName == "") {
      currentInputError = {...currentInputError, billingFirstName:true}
      foundError = true;
    } else {
      currentInputError = {...currentInputError, billingFirstName:false}
    }

    if (formData.billingLastName == "") {
      currentInputError = {...currentInputError, billingLastName:true}
      foundError = true;
    } else {
      currentInputError = {...currentInputError, billingLastName:false}
    }

    if (formData.billingAddressLine1 == "") {
      currentInputError = {...currentInputError, billingAddressLine1:true}
      foundError = true;
    } else {
      currentInputError = {...currentInputError, billingAddressLine1:false}
    }

    if (formData.billingCity == "") {
      currentInputError = {...currentInputError, billingCity:true}
      foundError = true;
    } else {
      currentInputError = {...currentInputError, billingCity:false}
    }


    var reZipTest = /^\d{5}(-\d{4})?$/;

    if (formData.billingZip == "" || !reZipTest.test(formData.billingZip)) {
      currentInputError = {...currentInputError, billingZip:true}
      foundError = true;
    } else {
      currentInputError = {...currentInputError, billingZip:false}
    }

    if  (foundError) {

      setInputError({ ...currentInputError});      

      return false;
    }

    return true;
  }


  function clearInputErrors () {
    setInputError({});      
  }


  function customInputTextField(name, fieldName, id, defaultValue, currentValue, showLabel) {

    return (
      <CustomTextField
        className="custom-input"
        style={{ float: "left", minWidth: "20em" }}
        key={id}
        useStylesClass={classes.inputField}
        properties={
          {
            name: name,
            labelName: showLabel ? name : '',
            error: inputError[fieldName],
            variant: 'outlined',
            key: id,
            currentValue:currentValue
          }
        }
        defaultValue={defaultValue}
        value={currentValue}
        inputProps={{
          'aria-label': showLabel ? name : '',
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            console.log("Key enter " + name);
            //TODO: End propagation
          }
        }}
        onChange={
          e => {
            if (e.target.value) {              
              setFormData({ ...formData, [fieldName]: e.target.value });
            }else {
              setFormData({ ...formData, [fieldName]: '' });
            }
          }
        } />
    );
  }


  async function createShippingAddress() {

    let intent = await API.post("yayfunthingsapi", "/items/createShippingAddress",{body:{
      owner:user.sub, 
      shippingAddress:{
        firstName: formData.firstName,
        lastName: formData.lastName,
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2,
        country: formData.country,
        postalCode: formData.postalCode,
        companyName: formData.companyName,
        phoneNumber: formData.phoneNumber,
        city: formData.city,
        state: formData.state,
      }
    }}).then( async result => {        
      return result
    }).catch(e=>{
      console.log(e);
      return null;
    });

    return intent;
  }


  const handleChangeShippingState = (event) => {
    let selectionIndex = event.target.value;
    let stateIndexSelected = Math.max(0, selectionIndex);
    setSelectedShippingStateIndex(stateIndexSelected);
    let stateKeys = Object.keys(dataStateAbrv);    
    let stateIndexName = ""
    for (let i = 0; i < stateKeys.length; i++) {
      if (i == stateIndexSelected-1) {
        stateIndexName =  stateKeys[i];//dataStateAbrv[stateKeys[i]];
      }      
    } 
    setFormData({
      ...formData,
      "state": stateIndexName
    })
  }

  const handleChangeBillingState = (event) => {

    let selectionIndex = event.target.value;
    let stateIndexSelected = Math.max(0, selectionIndex);

    
    setSelectedBillingStateIndex(stateIndexSelected);
    let stateKeys = Object.keys(dataStateAbrv);    
    let stateIndexName = ""
    for (let i = 0; i < stateKeys.length; i++) {
      if (i == stateIndexSelected-1) {
        stateIndexName =  dataStateAbrv[stateKeys[i]];
      }      
    } 
    setFormData({
      ...formData,
      "billingState": stateIndexName
    })

  }

  const updateBillingStateIndex = (stateName)=>{
    let stateKeys = Object.keys(dataStateAbrv);    
    let stateIndexName = ""
    let selectedIndex = 0;
    for (let i = 0; i < stateKeys.length; i++) {
        stateIndexName = stateKeys[i];
      if (stateIndexName == stateName) {
        selectedIndex = i + 1;
      }
    } 
    setSelectedBillingStateIndex(selectedIndex);
  }
  

  const handleChange = (event) => {

    let selectionIndex = event.target.value;
    let shippingAddressIndex = Math.max(0, selectionIndex - 1);

    setSelectedShippingAddressIndex(selectionIndex);

    if (selectionIndex != 0 && shippingAddresses) {

      let shippingAddress = shippingAddresses.length > 0 ? shippingAddresses[shippingAddressIndex].shippingAddress : null;

      if (shippingAddress) {

        if (selectionIndex > 0) {
          setFormData({
            ...formData,
            name: shippingAddress.name,
            phone: shippingAddress.phoneNumber,
            firstName: shippingAddress.firstName,
            lastName: shippingAddress.lastName,
            addressLine1: shippingAddress.addressLine1,
            addressLine2: shippingAddress.addressLine2,
            companyName: shippingAddress.companyName,
            city: shippingAddress.city,
            state: shippingAddress.state,
            postalCode: shippingAddress.postalCode,
            phoneNumber: shippingAddress.phoneNumber
          })
          setCalculateShippingDirty(true);
        }
      }
    } else {

      setShippingCostError(false);
      setSelectedShippingStateIndex(0);

      setFormData({
        ...formData,
        name: "",
        phone: "",
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        companyName: "",
        city: "",
        state: "",
        postalCode: "",
        phoneNumber: ""
      })
    }
  };

  function displayBillingAddress() {
    return (
      <div className="shipping-address">
        <p>
          {
            formData.billingFirstName + " " + formData.billingLastName
          }
        </p>
      
        {/* billingFirstName: formData.firstName,
                        billingLastName: formData.lastName,
                        billingAddress1: formData.addressLine1,
                        billingAddress2: formData.addressLine2,
                        billingCity: formData.city,
                        billingState: formData.state,
                        billingZip: formData.postalCode */}

        <p>
          {
            formData.billingAddress1
          }
        </p>
        <p>
          {
            formData.billingAddress2
          }
        </p>
        <p>
          {
            formData.billingCity
          }, &nbsp;
        {
            formData.billingState
          }&nbsp;
        {
            formData.billingZip
          }&nbsp;{formData.billingCountry ? <>{" / " + formData.billingCountry}</> : '' }
        </p>

      </div>
    )
  }

  function displayShippingAddress(address) {

    if (address == null) {
      return   <div className="shipping-address">
      <p></p></div>
    }
    return (
      <div className="shipping-address">
        <p>
          {
            address.firstName + " " + address.lastName
          }
        </p>
        <p>
          {
            address.companyName ? <>{address.companyName}</> : ''
          }        
        </p>
        <p>
          {
            address.addressLine1
          }
        </p>
        <p>
          {
            address.addressLine2
          }
        </p>
        <p>
          {
            address.city
          }, &nbsp;
        {
            address.state
          }&nbsp;
        {
            address.postalCode
          }&nbsp;{address.country ? <>{" / " + address.country}</> : '' }
        </p>
      </div>
    )
  }

  function displayShippingAddressMenuItems(increment) {

    return shippingAddresses.map((address, index) => {
      return (
        <MenuItem key={index} value={index + 1} >
          {
            address ?
              displayShippingAddress(address.shippingAddress)
              : <></>
          }
        </MenuItem>

      )
    })
  }


  function displayStateSelectionDropdown(forValue, onChangeEvent) {

    // selectedShippingStateIndex

   return (   
   <FormControl variant="outlined" className={classes.formControl}>
    <InputLabel id="demo-simple-select-outlined-label">State</InputLabel>
    <Select
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      value={forValue}
      onChange={onChangeEvent}
      label="State"
    >

      <MenuItem value={0}>
        <em>Select State</em>
      </MenuItem>
      { Object.keys(dataStateAbrv).map((key, index) => {
          return (
      <MenuItem key={index} value={index + 1}>
        {
          dataStateAbrv ?
          dataStateAbrv[key]
            : <></>
        }
          </MenuItem>
        )
      })      
      }  
    </Select>
    </FormControl>)

  }



  function displaySelectAddress() {

    return (<>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Choose Address</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectedShippingAddressIndex}
          onChange={handleChange}
          label="Shipping Address"
        >
          <MenuItem value={0}>
            <em>Add New Address</em>
          </MenuItem>
          {
            displayShippingAddressMenuItems(1)
          }
        </Select>
      </FormControl>

    </>);

  }

  function displaySelectShippingAddressForBilling() {

    return (<>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Choose Address</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectedShippingAddressIndex}
          onChange={handleChange}
          label="Shipping Address"
        >
          {
            displayShippingAddressMenuItems(0)
          }
        </Select>
      </FormControl>

    </>);

  }

  function displayBillingAddressInput() {

    return (<>
    <div>
      {
        customInputTextField("First Name", "billingFirstName", "id-input-billingFirstName", formData.billingFirstName, formData.billingFirstName, true)
      }
    </div>
    <div>
            {
        customInputTextField("Last Name", "billingLastName", "id-input-billingLastName", formData.billingLastName, formData.billingLastName, true)
      }
   </div>
    <div>
      {
        customInputTextField("Address", "billingAddress1", "id-input-billingAddress1", formData.billingAddress1, formData.billingAddress1, true)
      }
    </div>
      
    <div>
      {
        customInputTextField("Apartment/Suite/Building (Optional)", "billingAddress2", "id-input-billingAddress2", formData.billingAddress2, formData.billingAddress2, true)
      }
     </div>
      
      <div>

      <div style={{display:"inline-block", width:"48%", float:"left"}} >
      {
        customInputTextField("City", "billingCity", "id-input-billingCity", formData.billingCity, formData.billingCity, true)
      }
      </div>
      
      <div style={{display:"inline-block", width:"48%", height:"4.5em"}}>
      {
        displayStateSelectionDropdown(selectedBillingStateIndex, handleChangeBillingState)
      }
      </div>

      </div>

      {/* <p>
      {
        customInputTextField("State", "billingState", "id-input-billingState", formData.billingState, formData.billingState, true)
      }
      </p> */}
        


      <div>
      {
        customInputTextField("Postal Code", "billingZip", "id-input-billingZip", formData.billingZip, formData.billingZip, true)
      }
      </div>
    </>);

  }


  function displayAddressInput() {

    return (<>
                <div>
                {
                    customInputTextField("First Name", "firstName", "id-input-firstName", "", formData.firstName, true)
                  }
                  </div>
                  <div>
                  {
                    customInputTextField("Last Name", "lastName", "id-input-lastName", "", formData.lastName, true)
                  }

</div>

                <div>
                      {
                        customInputTextField("Address", "addressLine1", "id-input-addressLine1", "", formData.addressLine1, true)
                      }
                  </div>
                      
                      <div>
                      {
                        customInputTextField("Apartment/Suite/Building (Optional)", "addressLine2", "id-input-addressLine2", "", formData.addressLine2, true)
                      }
                      
                      </div>

                {/* <p>
                {
                  customInputTextField("Company Name (Optional)", "companyName", "id-input-companyName", "", formData.companyName, true)
                }
                </p> */}

<div>
                {
                  customInputTextField("City", "city", "id-input-city", "", formData.city, true)
                }
                  </div>
                {/* <p>
                {
                  customInputTextField("State", "state", "id-input-state", "", formData.state, true)
                }
                </p> */}

                {
                  displayStateSelectionDropdown(selectedShippingStateIndex, handleChangeShippingState)
                }
                <div>
                {
                  customInputTextField("Postal Code", "postalCode", "id-input-postalCode", "", formData.postalCode, true)
                }
                  </div>
                {/* <p>
                {
                  customInputTextField("Phone Number (Optional)", "phoneNumber", "id-input-phoneNumber", "", formData.phoneNumber, true)
                }
                </p> */}
    </>);
  }

  const displayCodeCardMessage = ()=>{
    return "You'll receive your codes through email.";
  }

  function getStepContent(step) {
    
    switch (step) {
      case 0:
        return <>
        {
          guestCheckout ? <>
            <div style={{padding:"2em", paddingBottom:"0em"}}>
          {customInputTextField("Email", "email", "id-input-email", formData.email, formData.email, true)}
          </div>
          </> : <>
          
          <div style={{minHeight:"400px"}}>
          <AmplifyAuthenticator>    

              <AmplifyConfirmSignUp 
                slot="confirm-sign-up"
                formFields={[
                    { 
                        type: "username",
                        label: "Email *",
                        placeholder: "Enter your email"
                    },
                    {
                      type:'code'
                    }
                ]} 
              />

              <AmplifyForgotPassword 
                slot="forgot-password"
                formFields={[
                    { 
                        type: "username",
                        label: "Email *",
                        placeholder: "Enter your email"
                    }
                ]} 
              />

              <AmplifySignIn
                slot="sign-in"
                formFields={[
                    { 
                        type: "username",
                        label: "Email *",
                        placeholder: "Enter your email"
                    },         
                    {
                        type: "password"
                    }
                ]} 
                /> 

            <AmplifySignUp
                slot="sign-up"
                formFields={[
                    { 
                        type: "username",
                        label: "Email",
                        placeholder: "Email"
                    },
                    { 
                        type: "email",
                        label: "Verify Email",
                        placeholder: "Verify your email"
                     },
                    {
                        type: "password"
                    }                    
                ]} 
                />
            <>
              {
                user ? <><span>{user.email}</span></> : <>
                {
                  guestCheckout ? <span>{formData.email}</span> : <></>
                }
                </>
              }
            </>

          </AmplifyAuthenticator>

          </div>
          </>
        }
        

        {
          guestCheckout ? <>
          

          <p className="subscribe-disclaimer">
               We use your email to communicate with you regarding the status of your order.
               </p>

          <Button
              style={{ margin: '0.8em' }} size="large" color="primary" variant="contained" onClick={async () => {                                 
                  setGuestCheckout(false);
                }} >
              Cancel
              </Button>
          <Button
              style={{ margin: '0.8em'}} size="large" color="primary" variant="contained" onClick={async () => {                                 
                let result = validateEmailData();

                // console.log("");
                if (result == true) {
                    handleNext();
                  }  else {
                  if (result.bannedDomain) {
                    sendAlertMessage("We're sorry you can not make guest orders with this domain.");
                  }
                }}} >                  
              Continue
              </Button>

           

          </> :<>
              
      
        <div>
          {/* <div className="create-account" style={{display:"inline-block"}}>
            <ul>
              <li style={{ fontSize: "1.3em", paddingLeft:"0em", paddingBottom:"1em" }}>Create an account to:</li>
              <li>Check out faster</li>
              <li>Save multiple shipping addresses</li>
              <li>Access your order history</li>
              <li>Track new orders</li>
            </ul>
          </div> */}

           
           <div>
             <h4>OR</h4>
           </div>
          <div style={{display:"inline-block"}}>    
                
            <Button
              style={{ margin: '0.8em', float: 'right' }} size="large" color="primary" variant="contained" onClick={async () => {                                 
              
                setGuestCheckout(true);
                  
                  await delay(100)
                  window.scrollTo(0, 0);
                  
                    // allInStockandPricesMatch();

                }} >
              Continue as guest
              </Button>

          </div>

          </div>
          </>
        }

     
    


          {/* <p>Checking out as a <b>Guest?</b> You'll be able to save your details to create an account with us later. </p>
                        {
                            customInputTextField("Email", "email", "id-input-email", "", formData.email, true)              
                        }
                        <Button style={{margin:'0.8em'}} size="large" color="primary" variant="contained" onClick={() => {
                        if (validateFormData()) {                            
                            handleNext();
                        }
                        }}>Continue As Guest</Button>
                    <p>Already have an account? <Link to='/signin'>Sign in</Link> now</p> */}

        </>;
      case 1:
        return <>

                {

                  isDigitalOnly() && !selectedShippingBillingSame ? <div> {displayCodeCardMessage()}
                  
              <Button
              style={{ margin: '0.8em', float: 'right' }} size="large" color="primary" variant="contained" onClick={async () => {                                 
                
                if (!selectedShippingBillingSameOnce) {
                  setSelectedShippingBillingSame(false);
                  setSelectedShippingBillingSameOnce(true);
                }
                  handleNext();

                }} >

              Continue
              </Button>

                  </div> :
                  <>
          <p>Shipping Address</p>
          
          <div>
            {
              isDigitalOnly() || containsCodeCards() ?  <p>{displayCodeCardMessage()}</p> :<></>
            }
            {
              guestCheckout ? <></> : displaySelectAddress()
            } 
            

            <>
              {
                selectedShippingAddressIndex == 0 ? <>
                                  
                  {
                    displayAddressInput()
                  }
                
                </> : <></>
              }
            </>
            <div>
              
            <FormGroup row>
            <FormControlLabel
        control={
      
          <Checkbox
          color="primary"
          checked={selectedShippingBillingSame}
          inputProps={{ 'aria-label': 'secondary checkbox' }}              
          onChange={e => {               
            setSelectedShippingBillingSame(!selectedShippingBillingSame) 
              if (!e.target.value) {
                setFormData({
                  ...formData,
                  billingFirstName: "",
                  billingLastName: "",
                  billingAddress1: "",
                  billingAddress2: "",
                  billingCity: "",
                  billingState: "",
                  billingZip: ""
                });
              }
          }}
        />
      
      
      }
        label="My billing address is the same as my shipping address."
      />
            </FormGroup>

            <span></span></div>
            <br />

            {
              selectedShippingAddressIndex == 0 ? <>
              
              <Button  disabled={ validateNewAddress()  || loadingShipping ? true : false || loadingForm ? true : false} 
              style={{ margin: '0.8em', float: 'right' }} size="large" color="primary" variant="contained" onClick={async () => {                 
                
                setLoadingShipping(true);

                if (!guestCheckout) {

                  let result = await createShippingAddress();

                  if (result) {
                    setCalculateShippingDirty(true);
                    clearInputErrors(); 
                    let addresses = await fetchShippingAddresses();
                    let foundIndex = 0;
                    for (let i = 0; i < addresses.length; i++) {
                      let address = addresses[i];
                      if (address.id == result.id) {                      
                        foundIndex = i + 1;
                      }
                    }
                    setSelectedShippingAddressIndex(foundIndex);
                  }
                } else {

                  // setCalculateShippingDirty(true);
                  // setCalculatingShipping(true);
                  setLoadingForm(true);

                  await calculateShipping();
                  
                  setGuestCheckoutCalculateShipping(true);

                  let addressVerified = await verifyShippingAddress();

                  while (loadingShipping || calculatingShipping) {
                    await delay(500);
                  }


                  if (addressVerified) {

                    clearMessages();

                    if (selectedShippingBillingSame) {
                          
                      setFormData({
                        ...formData,
                        billingFirstName: formData.firstName,
                        billingLastName: formData.lastName,
                        billingAddress1: formData.addressLine1,
                        billingAddress2: formData.addressLine2,
                        billingCity: formData.city,
                        billingState: formData.state,
                        billingZip: formData.postalCode
                      });
                      
                      updateBillingStateIndex(formData.state);
                      clearInputErrors();
                      handleNext();
                    }

                    handleNext();


                  } else {
                    sendAlertMessage("There's a problem verifying the address. If the issue persists, please contact us.");
                  }
                  setLoadingForm(false);
                }
                
              } } >
              Continue
              </Button>
              
              </> :<>
              
              {/* <Button style={{ margin: '0.8em', float:"right" }} size="large" color="primary" variant="contained" onClick={() => {
           
                  setSelectedShippingAddressIndex(0);

          }}>Edit Address</Button> */}

              <Button disabled={loadingShipping || shippingCostError ? true : false} style={{ margin: '0.8em', float: 'right' }} size="large" color="primary" variant="contained" onClick={async () => {
              if (shippingCostError) {
                setCalculateShippingDirty(true);
              } else {
   
                if (validateFormData()) {
                  
                  let addressVerified = await verifyShippingAddress();

                  if (addressVerified) {

                      clearMessages();

                      if (selectedShippingAddressIndex == 0) {

                        if (selectedShippingBillingSame) {
                          
                          setFormData({
                            ...formData,
                            billingFirstName: formData.firstName,
                            billingLastName: formData.lastName,
                            billingAddress1: formData.addressLine1,
                            billingAddress2: formData.addressLine2,
                            billingCity: formData.city,
                            billingState: formData.state,
                            billingZip: formData.postalCode
                          });
                          
                          updateBillingStateIndex(formData.state);
                          clearInputErrors();
                          handleNext();
                        }
                        handleNext();

                      } else {
                      
                        if (selectedShippingBillingSame) {
                          setFormData({
                            ...formData,
                            billingFirstName: formData.firstName,
                            billingLastName: formData.lastName,
                            billingAddress1: formData.addressLine1,
                            billingAddress2: formData.addressLine2,
                            billingCity: formData.city,
                            billingState: formData.state,
                            billingZip: formData.postalCode
                          });
                          updateBillingStateIndex(formData.state);
                          clearInputErrors(); 
                          handleNext();
                          handleNext();
                        } else {                      
                          handleNext();
                        }
                      }
                  } else {
                    sendAlertMessage("There's a problem verifying the address.");
                  }
                } else {
                  sendAlertMessage("There's a problem with the address.");                
                }
              }
            }}>Continue</Button>
              </>
            }
          
          </div>
                  
                  </>
                }
       
        </>;
      case 2:
        return <>
          {
            isDigitalOnly() ? <>
                   <div>
              
             
                   <FormGroup row>
            <FormControlLabel
        control={
      
          <Checkbox
          color="primary"
          checked={selectedShippingBillingSame}
          inputProps={{ 'aria-label': 'secondary checkbox' }}              
          onChange={e => {               
            setSelectedShippingBillingSame(!selectedShippingBillingSame) 
              if (!e.target.value) {
                setFormData({
                  ...formData,
                  billingFirstName: "",
                  billingLastName: "",
                  billingAddress1: "",
                  billingAddress2: "",
                  billingCity: "",
                  billingState: "",
                  billingZip: ""
                });

                setActiveStep(1);

              }
          }}
        />
      
      
      }
        label="My billing address is the same as my shipping address."
      />
            </FormGroup>


              {/* <Checkbox
                defaultChecked
                color="primary"
                checked={selectedShippingBillingSame}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={e => { 
                  
                  setSelectedShippingBillingSame(!selectedShippingBillingSame); 


                    if (!e.target.value) {
                      setFormData({
                        ...formData,
                        billingFirstName: "",
                        billingLastName: "",
                        billingAddress1: "",
                        billingAddress2: "",
                        billingCity: "",
                        billingState: "",
                        billingZip: ""
                      });
                      console.log("Reset form data");
                      setActiveStep(1);

                    } else {
                      // console.log("SEt true");
                      // setActiveStep(1);

                    }
  

                
                }}
              /> <span>My billing address is the same as my shipping address.</span> */}
              
              
              </div>
      
            </> :<></>
          }
          {
            displayBillingAddressInput()
          }
          <br />
          <Button style={{ margin: '0.8em', float:"right" }} size="large" color="primary" variant="contained" onClick={() => {
            if (validateBillingFormData()) {
              handleNext();
            }            
          }}>Continue</Button>
        </>;
      case 3:
        return <>
          <Elements stripe={stripePromise}>
            <CheckoutForm formData={formData} shippingCost={shippingCost} taxCost={taxCost} notifySuccess={notifySuccess} guestCheckout={guestCheckout}/>
          </Elements>
        </>;
      default:
        return 'Unknown step';
    }
  }

  const displayDiscount = (order)=>{
  
    if (order && order.orderDiscount) {
      return(<>
          <p className="checkout-complete-subtotal">Discount:<span className="checkout-complete-total-span">{order.orderDiscount ? ConvertStringToDollarsCents(order.orderDiscount) : "$0.00"}</span></p>
      </>)
    }
    return (<></>);
  }


  return (
    <div className="container-inner">

            <Seo
                  title={"Checkout"}
                  pathSlug={"checkout"}
                  description={"Securely checkout with your products. Log in with your account or checkout as a guest. "}
                />


      {
        orderComplete ? <>
          <div>
            <p className="checkout-bold">Yay!</p>
            <p className="checkout-complete">Thank you for your order!</p>
            <p>You will receieve an email when your order has shipped.</p>            
            <div style={{padding:"1em"}}><h3>Order Summary</h3></div>
            <div className="checkout-complete-block container-row-flex">
              <div className="checkout-complete-items container-flex-space">                           
                {
                  completedOrderResult  ? <>
                    <p className="checkout-complete-total">
                      <span className="checkout-complete-span">Order #</span><span>{completedOrderResult.orderNumber}</span>                      
                    </p>
                    <p><span className="checkout-complete-span">Date: </span><span>{moment(completedOrderResult.orderDate).format("MMMM DD, YYYY, hh:mm A")}</span></p>
                    
                    <div style={{display:"flex", flexDirection:isMobile() ? "column" : "row"}}>

                    <div className="checkout-complete-shipping">
                      <h3>Shipping Address</h3>
                      <div>
                        <div>
                          <ul>
                            <li>{completedOrderResult.shippingAddress.name}</li>
                            <li>{completedOrderResult.shippingAddress.addressLine1}</li>
                            <li>{completedOrderResult.shippingAddress.addressLine2}</li>
                            <li>{completedOrderResult.shippingAddress.city}, {completedOrderResult.shippingAddress.state}  {completedOrderResult.shippingAddress.postalCode}</li>
                          </ul>
                        </div>
                      </div>                  
                    </div>
                    <div className="checkout-complete-shipping">
                    <h3>Shipping Method</h3>
                    <ul>
                            <li>{completedOrderResult.shippingMethod ? completedOrderResult.shippingMethod: "Standard Shipping" }</li>
                      </ul>
                    </div>

                    </div>

                    <div style={{height:"2em", borderBottom:"1px solid #ccc", marginBottom:"1em"}}>&nbsp;</div>
                    <h3>Products Ordered</h3>
                    {
                      completedOrderResult.cart.map(orderItem => {
                        return (<div style={{padding:"1em"}}>
                          <div>
                            {/* <p>Order Number:<span>{orderItem.id}</span></p> */}
                          </div>
                          {/* <img style={{width:"6em", height:"auto"}} src={orderItem.image}/> */}
                          <div className="cart-image-parent">
                            <ProductImage product={orderItem} suffix="_120.png" customClassName={"cart-image-order-complete"}/>
                          </div>
                          <div className="cart-product">
                            <p> {orderItem.title}</p>
                            <p>Qty: {orderItem.amount}</p>
                            <p>Price: {ConvertStringToDollarsCents(orderItem.price)}</p>
                          </div>
                        </div>)
                      })
                    }
                  </> : <></>
                }

                <div style={{height:"1em", borderBottom:"1px solid #ccc", marginBottom:"3em"}}>&nbsp;</div>

                <div style={{display:"flex", flexDirection:isMobile() ? "column" : "row"}}>
                  <div className="checkout-complete-billing">
                  <h3>Payment Method</h3>
                  <ul>
                      <li>{completedOrderResult.paymentMethod? completedOrderResult.paymentMethod: "Credit Card" }</li>
                      </ul>
                    <h3>Billing Address</h3>
                      <div>
                        <ul>
                            <li>{completedOrderResult.billingAddress.name}</li>
                            <li>{completedOrderResult.billingAddress.addressLine1}</li>
                            <li>{completedOrderResult.billingAddress.addressLine2}</li>
                            <li>{completedOrderResult.billingAddress.city}, {completedOrderResult.billingAddress.state}  {completedOrderResult.billingAddress.postalCode}</li>
                        </ul>
                      </div>                              
                  </div>
                  <div className="checkout-complete-total-grp">
                    <p className="checkout-complete-subtotal">Subtotal: <span className="checkout-complete-total-span">{ConvertStringToDollarsCents(completedOrderResult.total)}</span></p>
                    {
                      displayDiscount(completedOrderResult)
                    }
                    <p className="checkout-complete-subtotal">Shipping:<span className="checkout-complete-total-span">{completedOrderResult.shippingCost ? ConvertStringToDollarsCents(completedOrderResult.shippingCost) : "$0.00"}</span></p>                    
                    <p className="checkout-complete-tax">Tax:<span className="checkout-complete-total-span">{completedOrderResult.taxCost ? ConvertStringToDollarsCents((completedOrderResult.total + (completedOrderResult.shippingCost ? completedOrderResult.shippingCost : 0)) * completedOrderResult.taxCost) : "$0.00"}</span></p>
                    <p className="checkout-complete-total">Total:<span className="checkout-complete-total-span">{ConvertStringToDollarsCents(completedOrderResult.orderTotal)}</span></p>
                  </div>
                </div>

              </div>

              {/* <p>Need Assistance?</p> */}
              
            </div>
          </div>
        </> :
          <>
            <div className={classes.root}>
              {
                isMobile() ? <><Cart email={formData.email} activeStep={activeStep} isMobileCheckout={true} isCheckoutCart={true} shippingCost={shippingCost} taxCost={taxCost} loadingShipping={loadingShipping} shippingDay={shippingDay}/></> : <><div></div></>
              }
              <div className="checkout-container">
                <div className={isMobile() ? "checkout-container-steps-mobile" : "checkout-container-steps"} >
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                     
                      <Step key={label}>

                        <StepLabel className="checkout-step-label" classes={{ // apply this style
                          iconContainer: classes.iconContainer
                        }} ><span>{label}</span>
                        {
                          index < activeStep ?
                            <div>
                              {
                                index == 0 ? <>
                                
                                <div style={{display:"inline-block", width:"100%"}}>
                                <p className="label-small" style={{display:"inline-block"}} >
                                  
                                  {formData.email}
                                  </p>
                                  {/* </p>
                                  
                                  <p style={{maxWidth:"100px", marginLeft:"auto", marginRight:"2.5em"}}>
                                     */}
                                  <div style={{maxWidth:"10em", display:"inline-block", float:"right"}} >
                                    {                                     
                                      guestCheckout ? <>
                                      <Button
                                          style={{ margin: '0.8em' }} size="large" color="primary" variant="contained" onClick={async () => {                                 
                                              // setGuestCheckout(false);
                                              handleBack();
                                              setActiveStep(0);          
                                            }} >
                                          Edit
                                         </Button>
                                      </> : <AmplifySignOut /> 
                                    }
                                    </div>
                                 
                                    </div>
                                 </> : <></>
                              }
                              {
                                index == 1 ? <> {
                                isDigitalOnly() ? <p className="label-small">{displayCodeCardMessage()}</p> :
                                <>
                                <div style={{textAlign:"center"}}>
                                  {displayShippingAddress(formData)}
                                </div>
                                </>
                                
                                }
                                  <Button style={{ float: 'right' }} variant='outlined' onClick={() => {
                                    handleBack();
                                    setActiveStep(1);
                                  }} >Edit</Button> </> : <></>
                              }
                              {
                                index == 2 ? <> {displayBillingAddress()}
                                  <Button style={{ float: 'right' }} variant='outlined' onClick={() => {
                                    handleBack();
                                    setActiveStep(2);
                                  }} >Edit</Button> </> : <></>
                              }
                          
                            </div> : <></>
                        }

</StepLabel>
                         <StepContent>
                          <div>{getStepContent(index)}</div>
                          <div className={classes.actionsContainer}></div>                          
                        </StepContent>
                      </Step>

                    ))}
                  </Stepper>
                  {activeStep === steps.length && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                      <Typography>Yay! - you&apos;re order is in!</Typography>
                      <div>
                        <img src="./images/yayfun.svg" style={{ width: "3em" }} />
                      </div>
                    </Paper>
                  )}
                </div>
                {
                  isMobile() ? <>
                  </> : <>
                      <div className="checkout-container-cart"><Cart email={formData.email} activeStep={activeStep} isCheckoutCart={true} shippingCost={shippingCost} shippingDay={shippingDay} taxCost={taxCost} loadingShipping={loadingShipping} />
                      </div></>
                }
              </div>
            </div>



          </>
      }


    </div>
  );
}

export default Checkout