// Copyright 2020 Yay Fun, Inc. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, {useContext, useState, useEffect, useRef} from 'react';
import { Link, useLocation, useHistory } from "react-router-dom";
import { isMobile } from '../components/Helper'
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CartContext from "../context/cart";
import ProductContext from "../context/products";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import moment from 'moment';
import {CheckDisabled} from './Helper';
import { useInView } from 'react-intersection-observer';
import UiContext from "../context/ui";
import {ViewItem} from "../components/yayfun/AnalyticsEvent";
import {SendAddToCartEvent} from "../components/yayfun/AnalyticsEvent";

const cardImageLocationPrefix = "/images/cards/";

const styles = theme => ({
  button: {
    "&:disabled": {
      backgroundColor: '#ccc' 
    }
  }});


const ProductCard = (props)=> {

    const product = props.product;
    const { addToCart, toggleDropdownOn } = useContext(CartContext);
    const { queueGetImage } = useContext(ProductContext)
    const [ loading, setLoading ] = useState(false);
    const { classes } = props;
    const location = useLocation();
    const [normalImage, setNormalImage] = useState(null);
    const [loadingImage, setLoadingImage] = useState(true);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const { sendAlertMessage } = useContext(UiContext);
    const params = new URLSearchParams(location.search);
    const s = params.get('k');    
    const isLoading = useRef(false);

    const normalImageUrl = useRef(null);
    const [productId, setProductId] = useState(0);

    const history = useHistory();

    const { ref, inView, entry } = useInView({
      threshold: 0,
    });


    useEffect(() => {

      if (inView  && (normalImageUrl.current == "" || normalImageUrl.current == null || productId != product.id)) {        
        // setNormalImage(null);
        normalImageUrl.current = null;
        getNormalImage(product);
      }
    },[product, inView]);


    const getNormalImage = async (product) => {
      setLoadingImage(true);
      setProductId(product.id);      
      isLoading.current = true;      
      if (product.imageAsset) {
        var re = /(?:\.([^.]+))?$/;
        let extensionString = re.exec(product.imageAsset);
        let imageKey = product.image.replace(extensionString[0],"") + "_120.png";          
        let result = await queueGetImage(imageKey);                    
        if (result) {
          // setNormalImage(result);
          normalImageUrl.current = result;
          forceUpdate();
        }           
      } else {
        if (product.imageUrlKey) {
          // setNormalImage(cardImageLocationPrefix + product.imageUrlKey);
          normalImageUrl.current = cardImageLocationPrefix + product.imageUrlKey;
          return;
        }  
        if (product.imageUrl) {
          // setNormalImage(product.imageUrl);
          normalImageUrl.current = product.imageUrl;
        } else {
          // setNormalImage(product.image);
          normalImageUrl.current = product.image;
        }  
      }
      isLoading.current = false;
    }
  

    function showSpecialTag(product) {
      
       if (moment().valueOf() < moment(new Date(product.releaseDate)).valueOf()) {
         if (product.allowPreorder) {
          if (product.quantity <= 0) {
            return <span className="product-image-badge">Pre Orders Sold Out</span>
          }else {
            return <span className="product-image-badge preorder">Pre Order</span>
          }
          
         }else {
          return <span className="product-image-badge">Coming Soon</span>
         }         
       }else {
        if (product.quantity <= 0) {
            return <span className="product-image-badge">Sold Out</span>
         } else if (product.quantity <= 1) {
            return <span className="product-image-badge low">Low Stock</span>
         }
        return <></>
       }       
    }


    const onImageLoad = ()=>{
      setLoadingImage(false);
    }


    const onErrorLoad = ()=>{      
      // setNormalImage("images/yayfun-coming-soon.svg");
      normalImageUrl.current = "images/yayfun-coming-soon.svg";
      setLoadingImage(false);
    }


    const getCardClassStyleName = (product)=>{
        if (product.type == "Single") {
          if (isMobile()) {
            return "product-card mobile single";
          }else {
            return "product-card desktop single";
          }
        }else {
          if (isMobile()) {
            return "product-card mobile";  
          }else {
            return "product-card desktop"
          }          
        }
    }


    const ConvertStringToDollarsCents = (total) => {
      return ("$" + ("" + parseFloat(total).toFixed(2)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
    }

    const showProductPrice = (product)=>{
      if (product.salePrice && parseFloat(product.salePrice) < parseFloat(product.price) && parseFloat(product.salePrice) > 0) {
        return <>
              <span>{salePrice}</span> 
              <span className="price-strike">{productPrice}</span>          
           </> 
      }else {

        if (moment().valueOf() < moment(new Date(product.releaseDate)).valueOf()) {
          if (product.allowPreorder) {
            return <> {productPrice} </>
          }else {
            return <> </>
          }
        }else {        
          return <> {productPrice} </>
        }
      }
    }
    
    const productPrice = ConvertStringToDollarsCents(product.price);
    const salePrice = ConvertStringToDollarsCents(product.salePrice);

    if (props.hide) {
      return <></>
    }


    const isShowingThumbnail = (product)=> {      
      return normalImageUrl.current ? false : product.imageThumbBase64 ? true : false;
    }

    const getProductImageWidth = (product) => {
      let widthString = isShowingThumbnail(product) ? "100%" : "100%";
      if (product.type != "Single") {
        return "";
      }
      return widthString;
    }


    return (<div key={product.id} style={{cursor:"pointer"}} className={getCardClassStyleName(product)} >       
                        <> 
                          <div ref={ref} className="product-card-object-group" style={{position:"relative", width:"auto", height:"13em"}}>
                          { 
                            inView ?  
                            <> 
                            { normalImageUrl.current != "" ? 
                              <>
                                <div style={{display:loadingImage ? 'normal' : 'none', verticalAlign:"top", height:"100%", padding:"2em", position:"absolute", width:"100%" }}>       
                                    <CircularProgress style={{scale:"0.8", paddingTop:"0em", width:"2em", height:"2em", zIndex:"1"}} color="primary" />                                     
                                </div>                                  
                                  <div style={{width:'auto'}} className="img-click" onClick={()=> {
                                      history.push(`/cards/${product.id}`);
                                  }}>                                                                                                                       
                                  <img style={{width:getProductImageWidth(product), filter:normalImageUrl.current ? "" : product.imageThumbBase64 ? "blur(4px)" : ""}} alt="" className="product-image" onLoad={onImageLoad} onError={onErrorLoad} src={normalImageUrl.current ? normalImageUrl.current : product.imageThumbBase64 ? "data:image/png;base64," + product.imageThumbBase64 : normalImageUrl.current }/>                                
                                </div>                              
                              </>
                              :                                                       
                              <>
                                <div style={{display:loadingImage ? 'normal' : 'none', verticalAlign:"top", height:"100%", padding:"2em", position:"absolute", width:"100%"  }}>                                  
                                  <CircularProgress style={{scale:"0.8", paddingTop:"0em", width:"2em", height:"2em", zIndex:"1"}} color="primary" /> 
                                </div>
                              </> 
                            }
                            </>
                            : <> </> 
                          }

                          {
                            product.type == "Single" ? <></> : showSpecialTag(product)
                          }
                          
                          </div>
                          {
                            isMobile() && product.type == "Single" ? <>
                            <>
                                 <Link to={{pathname:`/cards/${product.id}`}} className="btn product-link">  <p className="product-title card-single noselect">#{product.number + " - " + product.cardName}</p></Link>
                                </>
                             </>:                            
                            <>
                            {
                              product.type == "Single" ? 
                                <>
                                 <Link to={{pathname:`/cards/${product.id}`}} className="btn product-link">  <p className="product-title card-single noselect">#{product.number + " - " + product.cardName}</p></Link>
                                </> :
                                <>
                                  <Link to={{pathname:`/cards/${product.id}`}} className="btn product-link">                      
                                    <p className="product-title">{product.title}</p>
                                  </Link>
                                </>
                            }
                            </>
                          }

                          <p className="product-price align-left">                                                      
                            {
                            product.price <= 0 ? <></>
                              :
                            <>
                              <Link to={{pathname:`/cards/${product.id}`}} className="btn product-link">                                                       
                                {
                                  showProductPrice(product)
                                }
                              </Link>
                            </>
                            }
                          </p>

                        
                        </>

                 <div className={loading ? "product-hover-button-loading" : "product-hover-button"}>
               
                  <div style={{position:'relative'}}>

                  {/* <div style={{position:'absolute', top:'1em', textAlign:'right', marginLeft:'auto', width:'100%'}}>                      
               
               <Button
               className="modal-button-view"
               size="small" color="primary" variant="outline"
               onClick={() => {
               if (props.quickViewProduct) {
                     props.quickViewProduct(product);
                   }
                     }}>
               
               <CheckCircleIcon/>
               
               </Button>        
                 </div> */}

                    {
                      product.type == "Single" ? <>
                    
                      <div style={{position:'absolute', bottom:'4em', textAlign:'right', marginLeft:'auto', width:'100%'}}>                      
               
              
                        <Button
                        className="modal-button-view"
                        size="small" color="primary" variant="contained"
                        onClick={() => {
                        if (props.quickViewProduct) {
                                  props.quickViewProduct(product);
                                }
                              }}>
                        <SearchIcon/>
                        </Button>        
                          </div>


                      </> :<>
                      
                        <Button
                            className="modal-button-view"
                            size="small" color="primary" variant="contained"
                            onClick={() => {
                            if (props.quickViewProduct) {
                              props.quickViewProduct(product);
                            }
                              }}>
                        <SearchIcon/>
                        </Button>        
                                  
                        <Button
                          disabled={CheckDisabled(product)}
                          className={classes.button}
                          size="small" color="primary" variant="contained"
                          onClick={async () => {
                          setLoading(true);
                          let result = await addToCart({...product, id:product.id});                                          
                          setLoading(false);
                          if (result) {
                            SendAddToCartEvent(product);
                            toggleDropdownOn(true);
                          } else {
                              // sendAlertMessage(`Error adding ${product.title}`);
                          }
                          }}>
                          
                          { loading ? <CircularProgress color="inherit" style={{color:"white", scale:"0.8", padding:"0px", width:"1.7em", height:"1.7em"}} /> : <AddShoppingCartIcon/>}
                          
                          </Button>  
                      </>
                    }
                    </div>
                  </div>
                </div>
    )
}

ProductCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCard)