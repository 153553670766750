import React, {useContext} from 'react';
import { API } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import UiContext from "../../context/ui";


function QuickbooksButton (props) {

    const invoice = props.invoice;
    const { sendAlertMessage } = useContext(UiContext);



    const queryQuickbooksCustomer = async (displayName)=> {
        let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
        let quickBooksToken = window.localStorage.getItem("quickBooksToken");    

        let result = await API.get("yayfunthingsadmin","/app/queryQuickbooksCustomer?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&displayName=Yay Fun Customer").then(async response => {
            if (response.error) {
                sendAlertMessage(response.error);
                console.log("queryQuickbooksCustomer error:", response.ErrorResponse); 
            } else {
                console.log("queryQuickbooksCustomer response:", response); 
            }
            return response;
        }).catch(e =>{console.log(e); sendAlertMessage(e.error);
            return e; });
        return result;
    }

    const checkForInvoice = async (id)=> {

        let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
        let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
        let foundInvoice = false;

        console.log("queryForInvoice:", id);

        let result = await API.get("yayfunthingsadmin","/app/queryForInvoice?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&id="+id).then(async response => {
            if (response.ErrorResponse) {
                console.log("queryForInvoice error:", response.ErrorResponse); 
            } else {
                console.log("queryForInvoice response:", response); 
                if (response && response.Invoice) {
                    console.log("queryForInvoice Found Invoice:", response.Invoice)
                    foundInvoice = true;
                    return response.Invoice;
                }
            }

            return null;
        }).catch(e =>{console.log(e); return null});

        if (result && result.length > 1 ) {
            if (window.confirm("More than one invoice was found with the same order number. Delete duplicates in Quickbooks Online.")) {
                result = -1;
            }else {
                result = -1;
            }
        } else if (result && result.length <= 0) {
            if (window.confirm("No invoice was found with this order number. We can't perform an update operation at this time.")) {
                result = -1;
            }else {
                result = -1;
            }
        }

        return result;
    }

    
    const startCreateInvoice = async (invoiceInput) => {

        let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
        let quickBooksToken = window.localStorage.getItem("quickBooksToken");    

        let customerQuery = await queryQuickbooksCustomer("Yay Fun Customer");

        console.log("invoiceInput:", invoiceInput);

        if (invoiceInput.shippingCost && parseFloat(invoiceInput.shippingCost) > 0) {

        // let queryItem = await QueryQuickbooksItem("Shipping Charge");
    
        // console.log("queryItem result:", queryItem);

        // if (queryItem) {

        //   console.log("queryItem.Item[0]:", queryItem.Item[0]);
          
          console.log("invoiceInput.Line.length:", (invoiceInput.Line.length+1));

          invoiceInput.Line.push(
            {
              "DetailType": "SalesItemLineDetail", 
              "SalesItemLineDetail": {
                "ItemRef": {
                  "value": "SHIPPING_ITEM_ID"
                }
              }, 
              "Amount": invoiceInput.shippingCost
            }
          );  
        
        // }
      }
        let customerId = 0;

        if (customerQuery && customerQuery.Customer && customerQuery.Customer[0]) {
            customerId = customerQuery.Customer[0].Id;
        }

        invoiceInput["CustomerRef"] = {
            value: customerId 
        };

        delete(invoiceInput.shippingCost);

        console.log("startCreateInvoice:", invoiceInput);


        let invoiceString = JSON.stringify(invoiceInput);

        
        await API.get("yayfunthingsadmin","/app/createQBInvoice?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&invoice="+encodeURIComponent(invoiceString)+"&paymentMethod="+props.paymentMethod ).then(async response => {
            if (response.ErrorResponse) {
                console.log("createQBInvoice error:", response.ErrorResponse); 
            } else {
                console.log("createQBInvoice response:", response); 
                if (response && response.Invoice) {
                    console.log("createQBInvoice Found Invoice:", response.Invoice)
                }
            }
        }).catch(e =>{console.log(e)});

    }

    const createInvoice = async (invoiceInput)=> {

        console.log("createInvoice:", invoiceInput)
        let id = invoiceInput.DocNumber;

        let invoiceExists = await checkForInvoice(id);

        console.log("checkForInvoice:", invoiceExists);

        if (invoiceExists == -1) {
            console.log("Exit creating invoice");
            
        } else if (invoiceExists) {
            console.log("Has invoice")
            if (window.confirm("The invoice exists do you want to continue updating it?")) {
                
                console.log("Found invoice exists start update!:", invoiceExists);

                //await startUpdateInvoice(invoiceInput);
            }
        } else {

            console.log("Does not have invoice");
            await startCreateInvoice(invoiceInput);
        }
        
    }


    return (<>
    
    {/* <Button color="primary" variant="contained" onClick={async ()=>{
        await checkForInvoice(invoice.DocNumber)
        }}> Check For Invoice </Button>     */}

<Button color="primary" variant="contained" onClick={async ()=>{
            if (window.confirm("Are you sure you want to create the invoice?")) {
                await createInvoice(invoice);
            }
        }}> Create Invoice </Button> 
    </>);
}

export default QuickbooksButton;

export const QueryQuickbooksItem = async (name)=> {
    let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
    let quickBooksToken = window.localStorage.getItem("quickBooksToken");    

    let result = await API.get("yayfunthingsadmin","/app/queryQuickbooksItem?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&id="+name).then(async response => {
        if (response.error) {
            console.log("queryQuickbooksItem error:", response.ErrorResponse); 
        } else {
            console.log("queryQuickbooksItem response:", response); 
        }
        return response;
    }).catch(e =>{console.log(e);return e; });
    return result;
}
