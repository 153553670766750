import React, { useState, useEffect, useRef, useContext } from "react";
import { listOrders, listOrdersByDate } from "../api/queries";
import { listProducts, listUserItemByOwnerType, getMorelistUserItemByOwnerType } from "../api/private/queries";
import { createAnalytics, updateAnalytics, updateSiteItem, createSiteItem, deleteSiteItem } from "../graphql/mutations";
import { listingsByOwnerType, listingsByOwnerId, listSiteItems, itemsByType, getSubscriber, listSubscribers } from "../graphql/queries";


import { graphqlOperation } from "@aws-amplify/api-graphql";
import { GetGroupsOfItems, CheckInvoiceExists, ConvertCentsToDollarCents } from "../components/Helper";

import { updateOrder, deleteOrder, updateProduct } from "../api/mutations";
import { API } from '@aws-amplify/api';
import moment from 'moment';
import UserContext from "../context/user";
import UiContext from "../context/ui";

import pokemon from 'pokemontcgsdk'
// import { GoogleAdsApi } from "google-ads-api";
const { GoogleSpreadsheet } = require('google-spreadsheet');

// const client = new GoogleAdsApi({
//   client_id: "196853842439-i2ppmuu7t5gv5unk8v3t942p2o14j9c1.apps.googleusercontent.com",
//   client_secret: "tw5UicW0wyL5SCcGcYnYeBpb",
//   developer_token: "Js3jU8JUz7tasAn7OrXIWQ",
// });


const doc = new GoogleSpreadsheet('1QFEOCgb07WbSAmbzRJK5LQCjwiLc5HWxYmZFWOZclZg');
doc.useServiceAccountAuth(require('../yay-fun-things-f22bf2702d79.json'));

const AdminContext = React.createContext({});

const AdminProvider = ({ children }) => {  
  
  const { sendAlertMessage, clearMessages } = useContext(UiContext);

  const[inventorySheet, setInventorySheet] = useState({});
  const [nextToken, setNextToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [orders, setOrders] = useState([]);
  const [siteItems, setSiteItems] = useState([]);
  const [searchedOrders, setSearchedOrders] = useState([]);
  const [cardsSelected, setCardsSelected]  = useState([]);
  const [cardSetSelected, setCardSetSelected]  = useState("");
  const [cardSets, setCardSets] = useState([]);
  const [docInfo, setDocInfo] = useState(null);
  const [cardsToUpdateSelected, setCardsToUpdateSelected]  = useState([]);

  const [analytics, setAnalytics]  = useState([]);
  const { user} = useContext(UserContext);
  const productsRef = useRef([]);
  const products = productsRef.current; 

  useEffect(() => {
      startUp();
  }, []);

  const startUp = async ()=>{ 

      getDocInfo();
      
      setLoading(true);

      await fetchSets(false);
      
      await fetchOrders();
      
      setLoadingOrders(false);
      
      await fetchProducts();

      setLoading(false);
      
      listAnalyticsTypes();
  }


  const listAnalyticsTypes = async ()=>{
    let result = await listAnalyticsByType([], 'Price');
    let finalResult = await listAnalyticsByType(result, 'Market');
    setAnalytics(finalResult);
  }

  const fetchMoreAnalyticsForProduct = async (id) =>{ 
   
    console.log("fetchMoreAnalyticsForProduct:", id);
    
    let between = [moment("01/01/2021").startOf('day').toISOString(), moment().endOf('day').toISOString()];
    let resultData = await API.graphql(
      graphqlOperation(
        listingsByOwnerId,
        { 
          owner:user.sub,
          type:"Market",
          id:id,
          createdAt:{between:between},
          limit:5000})
        ).then(async result=>
      {
          console.log("fetchMoreAnalyticsForProduct RESULT DATA ", result.data);           
          if (result.data.listingsByOwnerId.items) {            

            let typeSeparted = {}
            for (let i = 0; i < result.data.listingsByOwnerId.items.length; i++) {
                let item = result.data.listingsByOwnerId.items[i];
               if (typeSeparted[item.type]) {
                typeSeparted[item.type].push(item);
               } else {
                typeSeparted[item.type] = new Array(item);
               }
            }

            let typeSeperatedKeys = Object.keys(typeSeparted);
            let analyticsUpdated = {...analytics};

            for (let i = 0; i < typeSeperatedKeys.length; i++ ){
              let key = typeSeperatedKeys[i];
              let typeItems = typeSeparted[key];
              if (analyticsUpdated[key] && analyticsUpdated[key].items) {
                analyticsUpdated[key].items.push(...typeItems);
              } else {
                analyticsUpdated[key].items = new Array(...typeItems);
              }
            }

            // let analyticsResult = {items:result.data.listingsByOwnerId.items, nextToken:result.data.listingsByOwnerId.nextToken};
                        
            setAnalytics({...analyticsUpdated});
                        
            return analyticsUpdated;
          }
      }).catch( error => {                                       
        if (error.errors && error.errors[0]) {
          sendAlertMessage(error.errors[0].message);        
        }
        
        console.log(error);
        
        return null;
      });        
      ;
  
      // console.log("Analytics:", analytics);
      
      return resultData;
      
      // if (resultData) {
      //   return ({...currentData, [type]:{...currentData[type], ...resultData}});
      // }
    
      return [];

  }


const updateCurrentProductWithResponse = async (productList, response) =>{


const processOffer = (asin, offer)=>{
  if (offer) {
    let currentProducts = productList;
    for (let i = 0; i < currentProducts.length; i++) {
      let product = currentProducts[i];
      if (product.asin == asin) {
        if (product.offers && Array.isArray(product.offers)) {
          product.offers.push(offer);
        }else {
          product.offers = [offer];
        }
      }      
    }
  }
}

const processResponseItem = (item)=>{
    if (item) {
      let asin = item["$"].ASIN;
      let status = item["$"].status;
      let offers = item["Product"]["Offers"];
      if (Array.isArray(offers)) {
        for (let i = 0; i < offers.length; i++) {
          processOffer(asin, offers[i])
        }        
      }else {
        processOffer(asin, offers)
      }
    }
  }
  if (Array.isArray(response)) {
    for (let i = 0; i < response.length; i++) {
      processResponseItem(response[i])
    }
  } else {
    processResponseItem(response);
  }

}


const fetchAmazonSellerData = async (productList)=>{
  
  let asinList = [];

  for (let i = 0; i < productList.length; i++) {
    let product = productList[i];
    if (product && product.asin) {
      asinList.push(product.asin);
    }    
  }

  let groupsOfAsins = GetGroupsOfItems(asinList, 20);
  for (let i = 0; i < groupsOfAsins.length; i++) {
    let asins = groupsOfAsins[i];
    // for (let x = 0; x < group.length; x++) {
      // let asins = group[x];
      const path2 = "/items/GetMyPriceForASIN";
      let myInit2 = {
          body: {
            asinList: asins,
            itemCondition:"New"
          }
        }

        const resultMyPriceForAsin = await API.post("yayfunthingsapi", path2, myInit2).then(async responseMyPrice => { 
          if (responseMyPrice.data && responseMyPrice.data.GetMyPriceForASINResponse && responseMyPrice.data.GetMyPriceForASINResponse.GetMyPriceForASINResult) {
                  let productResponse = responseMyPrice.data.GetMyPriceForASINResponse.GetMyPriceForASINResult;    
                  await updateCurrentProductWithResponse(productList, productResponse);
          }
        }).catch(e=>{console.log(e)});
    // }
  }

}



//Products
const fetchProducts = async () => {
  try {
    
    const { data } = await API.graphql({
      query: listProducts,
      variables:{limit:500, filter:{type:{eq:"Sealed"}}},
      authMode: "API_KEY"
    });
    let moreResults = [];
    if (data.listProducts.nextToken) {
      moreResults = await fetchMoreProducts(data.listProducts.nextToken);
    }      
   
    const fetched = [...data.listProducts.items, ...moreResults];
    for(let i = 0; i < fetched.length; i++){fetched[i].edited = false;}
    
    fetched.sort(function(a, b) {      
        if(moment(a.releaseDate).valueOf() > moment(b.releaseDate).valueOf()) { return -1; }
        if(moment(a.releaseDate).valueOf() < moment(b.releaseDate).valueOf()) { return 1; }
        return 0;
    })

    productsRef.current = [...fetched];
    
    fetchAmazonSellerData(productsRef.current);
    
  } catch (err) {      
    console.log("ERROR:", err)
  }
};


const fetchMoreProducts = async (nextToken) => {
  const { data } = await API.graphql({
    query: listProducts,
    variables:{limit:500, nextToken:nextToken, filter:{type:{eq:"Sealed"}}},
    authMode: "API_KEY"      
  });
  let moreResults = [];
  if (data.listProducts.nextToken) {
    moreResults = await fetchMoreProducts(data.listProducts.nextToken);
  }      
  const fetched = data.listProducts.items ? data.listProducts.items : [];
  return [...fetched, ...moreResults];
}


// const fetchProductsForSets = async (sets, callback) => {
//   if (sets && sets.length <= 0) return;
   
//   try { 
//     for (let i = 0; i < sets.length; i++) {
//         let setName = sets[i];
//         if (fetchSetFinished.current.indexOf(setName) == -1 && fetchSetQueue.current.indexOf({setName, callback}) == -1) {            
//           fetchSetQueue.current.push({setName, callback});
//         }else {
//           //ignore
//         }
//     }
    
//     // Current fetching sets
//     if (fetchingSet.current == true) {
    
//     } else {                          
//       // Start update fetch set queue check
//       continueFetchSetQueueCheck(callback);      
//     }            
//     // setNetworkError(false);
//   } catch (err) {      
//     console.log(err);   
//     // setNetworkError(true);   
//   }
// };



//Analytics
const listAnalyticsByType = async (currentData, type)=> {


  let between = [moment("01/01/2021").startOf('day').toISOString(), moment().endOf('day').toISOString()];
  let resultData = await API.graphql(
    graphqlOperation(
      listingsByOwnerType,
      { 
        owner:user.sub,
        type:type,
        createdAt:{between:between},
        limit:5000})
      ).then(async result=>
    {
        
        if (result.data.listingsByOwnerType.items) {
          let analyticsResult = {items:result.data.listingsByOwnerType.items, nextToken:result.data.listingsByOwnerType.nextToken};
          // setAnalytics({...analytics, [type]:{...analytics[type], ...analyticsResult}});
          
          return analyticsResult;
        }
    }).catch( error => {                                       
      // if (error.errors[0]) {
      //   props.toastError(error.errors[0].message);        
      // }
      console.log(error);

      return null;
    });      

    
    if (resultData) {
      return ({...currentData, [type]:{...currentData[type], ...resultData}});
    }
  
    return [];
}

const listAnalytics = async ()=> {

  let between = [moment("01/01/2021").startOf('day').toISOString(), moment().endOf('day').toISOString()];
  let result = await API.graphql(
    graphqlOperation(
      listingsByOwnerType,
      { 
        owner:user.sub,
        type:"Price",
        createdAt:{between:between},
        limit:5000})
      ).then(async result=>
    {
        console.log("startProductsQuery RESULT DATA ", result.data); 
        if (result.data.listingsByOwnerType.items) {
          let analytics = {items:result.data.listingsByOwnerType.items, nextToken:result.data.listingsByOwnerType.nextToken};
          setAnalytics(analytics);
          return analytics;
        }
    }).catch( error => {                                       
      // if (error.errors[0]) {
      //   props.toastError(error.errors[0].message);        
      // }
      console.log(error);

    });      

    console.log("listAnalytics:", result);
}


const getSecretKeyForEmail = async (email)=> {
  let finalResult = null;
  try {      
    finalResult =  await API.graphql({
      // authMode:"API_KEY",
      query: getSubscriber,
      variables:{id:email}
        } ).then(async result=>{          
          return result;
    }).catch(e=>{return e;});            
    return finalResult;
  } catch (err) {
      console.log(err);  
      return err;
  }
}

const fetchSubscribers = async ()=> {
  let finalResult = null;
  try {      
    finalResult =  await API.graphql({
      // authMode:"API_KEY",
      query: listSubscribers,
      variables:{limit:1000}
        } ).then(async result=>{          
          return result;
    }).catch(e=>{return e;});            
    return finalResult;
  } catch (err) {
      console.log(err);  
      return err;
  }
  return finalResult;
}



const updateAnalyticsInput = async (input) => {

  let finalResult = null;
  try {      
    finalResult =  await API.graphql({
      query: updateAnalytics,
      variables:{input:{...input, owner:user.sub, id:input.id, type:input.type}}
        } ).then(async result=>{          
          console.log("Updated Analytics Result:", result);
    }).catch(e=>{console.log(e)});            
    return finalResult;
  } catch (err) {
      console.log(err);  
  }  
}


const createAnalyticsInput = async (input)=> {
  // createAnalytics
  let finalResult = null;
  try {      
    finalResult =  await API.graphql({
      query: createAnalytics,
      variables:{input:{...input, owner:user.sub, id:input.id, type:input.type}}
        } ).then(async result=>{          
          console.log("Create Analytics Result:", result);
    }).catch(e=>{console.log(e)});            
    return finalResult;
  } catch (err) {
      console.log(err);  
  }  
  return finalResult;
}



 const getDocInfo = async () => {    
    let docInfo = await doc.loadInfo(); // loads document properties and worksheets
    setDocInfo(docInfo);
    const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id]
    setInventorySheet(sheet);    
}


const getSheetByTitle = (title) => {
  if (doc) {
    const sheet = doc.sheetsByTitle[title]; // or use doc.sheetsById[id]
    return sheet;
  }
  return null;
}

const resetSets = ()=>{
  setCardSets([]);
  console.log("RESET SETS");
  window.localStorage.setItem("cardSets", "");
  fetchSets(true);
}


const fetchSets = async(isReset)=>{
  let allSetsFound = [];
  let cardSets = window.localStorage.getItem("cardSets");
  
  if (cardSets && !isReset) {      
    let cardSetsParsed = JSON.parse(cardSets);
    if (cardSetsParsed) {
      cardSetsParsed = cardSetsParsed.sort((a,b)=> moment(a.releaseDate).valueOf() < moment(b.releaseDate).valueOf());
      setCardSets(cardSetsParsed);
    }
    
  } else {

    console.log("GET SETS");

    // let allSets = pokemon.set.all({ pageSize: 10 });
    
   await pokemon.set.all({ pageSize: 10 })
    .then((cards) => {
        console.log(cards) // "Base"
            allSetsFound.push(...cards);    
    })

    console.log("allSetsFound:", allSetsFound);

    let updatedSetNames = updateCardSetNames(allSetsFound);
    window.localStorage.setItem("cardSets", JSON.stringify(updatedSetNames));
    setCardSets(updatedSetNames);


    // allSets.then('data', (set) => {
    //   console.log("New Set:", set);
    //   allSetsFound.push(set);    
    // });
    
    // allSets.then('error', (e) => {
    //   console.log("ERROR:", e);      
    // });


    // allSets.then('end', () => {
      // let updatedSetNames = updateCardSetNames(allSetsFound);
      // window.localStorage.setItem("cardSets", JSON.stringify(updatedSetNames));
      // setCardSets(updatedSetNames);
    // });  
  }
}


const getCardSetNameFromCode = (setCode) => {
  for (let i = 0; i < cardSets.length; i++) {
    let cardset = cardSets[i];
    if (cardset.id == setCode) {
      return cardset.name;
    }
  }
  return "";
}

const updateCardSetNames = (cardSetsFound) => {

  for (let i = 0; i < cardSetsFound.length; i++) {
    let cardset = cardSetsFound[i];
    if (cardset.code == "swsh45sv") {
      cardset.name = "Shining Fates: Shiny Vault"
    }
  }

  return cardSetsFound;
};

const getReleaseDateForSet = (setCode)=>{
  for(let i = 0; i < cardSets.length; i++) {
    let cardSet = cardSets[i];
    if (cardSet.code == setCode) {
      return cardSet.releaseDate;
    }
  } 
  return 0;
}


const getPrintedCardCountForSet = (setCode)=>{
  for(let i = 0; i < cardSets.length; i++) {
    let cardSet = cardSets[i];
    if (cardSet.id == setCode) {
      return cardSet.printedTotal;
    }
  } 
  return 0;
}

const getTotalCardCountForSet = (setCode)=>{
  for(let i = 0; i < cardSets.length; i++) {
    let cardSet = cardSets[i];
    if (cardSet.id == setCode) {
      return cardSet.total;
    }
  } 
  return 0;
}


const checkForQbInvoice = async (orders) => {



  await CheckInvoiceExists(orders);

}

const fetchOrders = async ()=>{    


  // if (!loading) {


    try { 
      const { data } = await API.graphql({
        query: listOrdersByDate,
        variables : {type:"Order", sortDirection:"DESC", limit:500}
      });      
      if (data.listOrdersByDate.nextToken) {
        setNextToken(data.listOrdersByDate.nextToken);
      } else {
        setNextToken('');
      }


      const orders = data.listOrdersByDate.items;

      await checkForQbInvoice(orders);
      setOrders(orders);
      // console.log("SET LOADING FALSE 580");
    } catch (err) {
      // console.log(err);
      // console.log("SET LOADING FALSE 584");

    }
  // }
}


const fetchMoreOrders = async () => {
        
  if (!loading && nextToken != '') {
    setLoading(true);
    try {      
      const { data } = await API.graphql({
        query: listOrdersByDate,
        variables:{type:"Order", sortDirection:"DESC", nextToken}
      } );      

      if (data.listOrdersByDate.nextToken) {
        setNextToken(data.listOrdersByDate.nextToken);
      } else {
        setNextToken('');
      }

      const _orders = data.listOrdersByDate.items;                
      let oldOrders = [];
      oldOrders.push(...orders)
      oldOrders.push(..._orders)
      setOrders(oldOrders);
      console.log("SET LOADING FALSE 613");
      setLoading(false);
    } catch (err) {        
      setNextToken('');
      console.log(err);
      console.log("SET LOADING FALSE 618");
      setLoading(false);
    }
  }
};


const searchOrders = async (searchInput) => {
  
  if (!loading) {
    setLoading(true);
    setSearchedOrders([]);
    try {      
      const { data } = await API.graphql({
        query: listOrders,
        variables:{limit:1000, filter:{search:{contains:searchInput}}},
        limit:1000 
      });      

      if (data.listOrders.items && data.listOrders.items.length > 0) {
        const orders = data.listOrders.items;
        // console.log("Orders:", orders);
        setSearchedOrders(orders);  
      } else {
        setSearchedOrders([])
      }

      if (data.listOrders.nextToken) {        
        await searchOrdersWithNextToken(data.listOrders.nextToken)
      } else {
      //  setNextToken('');
      }
      setLoading(false);
      console.log("SET LOADING FALSE 651");
    } catch (err) {
      console.log(err);
      console.log("SET LOADING FALSE 654");
      setLoading(false);
    }
  }
}

const searchOrdersWithNextToken = async (nextToken) => {

    try {      
      const { data } = await API.graphql({
        query: listOrders,
        variables:{nextToken:nextToken},
        limit:100 
      });      

      if (data.listOrders.items && data.listOrders.items.length > 0) {
        const _orders = data.listOrders.items;                
        let oldOrders = [];
        oldOrders.push(...searchedOrders)
        oldOrders.push(..._orders)
        setSearchedOrders(oldOrders);  
      } else {
        setSearchedOrders([])
      }

      if (data.listOrders.nextToken) {        
        await searchOrdersWithNextToken(data.listOrders.nextToken)
      } else {
        // setNextToken('');
      }
    } catch (err) {
      console.log(err);
    }
}


const hasMoreOrdersToLoad = async () => {
  if (nextToken != '') {
    return false;
  }
  return true;
}


const deleteSiteItemInput = async (input)=>{
  let finalResult = null;
  try {      
    finalResult =  await API.graphql({
      query: deleteSiteItem,
      variables:{input:{id:input.id}}
        } ).then(async result=>{          
          console.log("Delete Site Input Result:", result);
          return result;
    }).catch(e=>{console.log(e)});            
    return finalResult;
  } catch (err) {
      console.log(err);  
  }  
  return finalResult;
}

const createSiteItemInput = async (input)=> {  
  let finalResult = null;
  try {      
    finalResult =  await API.graphql({
      query: createSiteItem,
      variables:{input:{...input, id:input.id, type:input.type}}
        } ).then(async result=>{          
          console.log("Create Site Input Result:", result);
          return result;
    }).catch(e=>{console.log(e)});            
    return finalResult;
  } catch (err) {
      console.log(err);  
  }  
  return finalResult;
}


const fetchSiteItemsByType = async (type)=> {
  try { 
    const { data } = await API.graphql({
      query: itemsByType,
      variables : {type:type, limit:200}
    });      
    if (data.itemsByType.nextToken) {
      // setNextToken(data.listOrdersByDate.nextToken);
    } else {
      // setNextToken('');
    }
    const siteItems = data.itemsByType.items;
    // setSiteItems(siteItems);  
    return siteItems;
  } catch (err) {
    
  }
  return null;
}


const updateSiteItemInput = async (input) => {

  console.log("updateSiteItemInput:", input);
    try { 
      
      let result = await API.graphql({
          query: updateSiteItem,
          variables:{input:input}
          }).then(result=>{        
            console.log("Update Site Item Result:", result);      
          return result;
        }).catch(e=>{
          console.log(e);
        });      
      
      return result;

    } catch (err) {
      console.log(err);
    }
}


const updateOrderWithInput = async (input) => {
  
  if (!loading) {
    setLoading(true);
    try { 

      let result = await API.graphql({
        query: updateOrder,
        variables:{input:input}
      }).then(result=>{
        
        console.log("Update Order Result:", result);      

        sendAlertMessage("Update Order Result Success");

      return result;      
    }).catch(e=>{
      console.log(e);
    });      
      setLoading(false);
      console.log("SET LOADING FALSE 797");

      return result;
    } catch (err) {
      // console.log(err);

      sendAlertMessage("Error:", JSON.stringify(err));
      console.log("SET LOADING FALSE 804");

      setLoading(false);
    }
  }
}

const deleteOrderWithId = async (id) => {

  if (!loading) {
    setLoading(true);
    try { 
      // await API.graphql({
      //   query: deleteOrder,
      //   variables:{input:{id:id}}
      // } ).then(result=>{console.log("Delete Order Result:", result)}).catch(e=>{console.log(e)});      
      console.log("SET LOADING FALSE 821");
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }
}

const makeVariationForProduct = async (variation, id)=>{
  console.log("makeVariationForProduct ", variation, id);

  let input = {
    id:id,

    variation: [{
      name:"Holo",
      quantity:0,
      price:0,    
    }]
  }
  let result = await API.graphql({
    query: updateProduct,
    variables:{input:input}
  }).then(result=>{
  
    console.log("Update Product Result:", result);      
  return result;      
}).catch(e=>{
  console.log(e);
});      

}


const updateProductFromInput = async (input)=>{  
  let result = await API.graphql({
    query: updateProduct,
    variables:{input:input}
  }).then(result=>{
    console.log("Update Product Result:", result);      
  return result;      
}).catch(e=>{
  console.log(e);
});      

}


const getMoreUserItemType  = async (id, type, nextTokenLinks) => {


  console.log("getMoreUserItemType:", id, type, nextTokenLinks)
  let items = [];

  try {
    
    const { data } = await API.graphql({
        query:getMorelistUserItemByOwnerType,
        variables:{type:type, limit:1000, nextTokenLinks:nextTokenLinks }              
      });

    if (data && data.listUserItemByOwnerType) {
      if (data && data.listUserItemByOwnerType.items) {  
        for (let i = 0; i < data.listUserItemByOwnerType.items.length; i++) {

          let item = data.listUserItemByOwnerType.items[i];
          if (item.id == id) {
            let moreItems = item.links.items;
            items.push(...moreItems);
            if (item.links.nextToken) {
              let evenMore = await getMoreUserItemType(id, type, item.links.nextToken);              
              items.push(...evenMore);
            }
          }            
        }    
      }
    }

  ///  console.log("API.graphqlOperation getMoreUserItemType RESULT:", data);

    return items;

  } catch (e) {  
    console.log("getMoreUserItemType ERROR:" , e);

    
    return [];
  }  
}

const getUserItemType = async (type) => {

  console.log("getUserItemType:", type);
  
  try {
    const { data } = await API.graphql({
      query: listUserItemByOwnerType,
      variables:{type:type, limit:1000}              
    });

    let items = data.listUserItemByOwnerType.items;        

    // console.log("getUserItemType:", items);

    console.log("data:::::", data);


    for (let i = 0; i < items.length; i++) {
      let link = items[i].links;
      if (link.nextToken) {

        console.log("getMoreUserItemType type:", type, link.nextToken)
        let resultMore = await getMoreUserItemType(items[i].id, type, link.nextToken );
        console.log("getMoreUserItemType resultMore:", resultMore);
        items[i].links.items.push(...resultMore);
      }
    }
    
    // if (data.listUserItemByOwnerType.nextToken) {
    //   await fetchShippingAddressesNextToken(data.listUserItemByOwnerType.nextToken, addresses);              
    // } 
    // if (addresses.length > 0) {
    //   setShippingAddresses(addresses);
    // }
      return items;

    } catch (err) {
      console.log(err);
    }
 }


return (
    <AdminContext.Provider
      value={{updateProductFromInput, loading, loadingOrders, fetchSubscribers, getUserItemType, getSecretKeyForEmail, createSiteItemInput, deleteSiteItemInput, fetchSiteItemsByType, updateSiteItemInput, inventorySheet, orders, cardsSelected, cardSets, cardSetSelected, cardsToUpdateSelected, searchedOrders, analytics, products, fetchProducts, fetchMoreAnalyticsForProduct, updateAnalyticsInput, createAnalyticsInput, getCardSetNameFromCode, setCardsToUpdateSelected, setCardSetSelected, resetSets, getSheetByTitle, setCardsSelected, fetchMoreOrders, hasMoreOrdersToLoad, searchOrders, updateOrderWithInput, deleteOrderWithId, getPrintedCardCountForSet, getTotalCardCountForSet, getReleaseDateForSet, makeVariationForProduct}}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
export {AdminProvider};