
export const SendAddToCartEvent = (eventData) => {

    if (window.gtag) {
        window.gtag('event', 'add_to_cart', {
            currency: 'USD',
            items: [{
              item_id: eventData.id,
              item_name: eventData.title,
              brand: eventData.brand,
              item_category: eventData.type,
              price: eventData.price,
              quantity: eventData.amount
            }],
            value: eventData.price
          });


        window.gtag('event', 'conversion', {
            'send_to': 'AW-756876116/BJ6BCOKA__ICENSG9OgC',
            'value': eventData.price,
            'currency': 'USD'
        });
      
    }
    

};

// <!-- Event snippet for Website sale conversion page
// In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->
// <script>
// function gtag_report_conversion(url) {
//   var callback = function () {
//     if (typeof(url) != 'undefined') {
//       window.location = url;
//     }
//   };
//   gtag('event', 'conversion', {
//       'send_to': 'AW-756876116/3mxMCNap7v8BENSG9OgC',
//       'transaction_id': '',
//       'event_callback': callback
//   });
//   return false;
// }
// </script>

export const SendSubscribeConversionEvent = (transactionId) => {
  
  // <!-- Event snippet for Website sale conversion page -->
  if(window.gtag) {    
      window.gtag('event', 'conversion', {'send_to': 'AW-756876116/QYGeCPCBqvMCENSG9OgC'});
  }        
  
}


export const SendConversionEvent = (transactionId) => {
  
    // <!-- Event snippet for Website sale conversion page -->
    if(window.gtag) {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-756876116/3mxMCNap7v8BENSG9OgC',
            'transaction_id': transactionId
        });

        
        window.gtag('event', 'subscribe', {
          'event_callback': function() {
            // form.submit();
          }
        });
    
    }        
}


{/* <script src="https://apis.google.com/js/platform.js?onload=renderOptIn" async defer></script>

<script>
  window.renderOptIn = function() {
    window.gapi.load('surveyoptin', function() {
      window.gapi.surveyoptin.render(
        {
          // REQUIRED FIELDS
          "merchant_id": 381863886,
          "order_id": "ORDER_ID",
          "email": "CUSTOMER_EMAIL",
          "delivery_country": "COUNTRY_CODE",
          "estimated_delivery_date": "YYYY-MM-DD",

          // OPTIONAL FIELDS
          "products": [{"gtin":"GTIN1"}, {"gtin":"GTIN2"}]
        });
    });
  }
</script> */}


export const SendRemoveFromCartEvent = (eventData) => {

    if (window.gtag) {

      window.gtag('event', 'remove_from_cart', {
        currency: 'USD',
        items: [{
            item_id: eventData.id,
            item_name: eventData.title,
            brand: eventData.brand,
            item_category: eventData.type,
            price: eventData.price,
            quantity: eventData.amount
          }],
        value: eventData.price
      });
    }
};


export const PurchaseEvent = (eventData) => {
    
    let items = [];

    if (eventData.cart) {
        for (let i = 0; i < eventData.cart.length; i++) {
            let product = eventData.cart[i];
            items.push({
                item_id:product.id,
                item_name:product.title,                
                price:product.price,
                currency:'USD',
                quantity:product.amount,
                item_category:product.type
            })
        }
    }

    if (items.length > 0) {
        
        if (window.gtag) {

        let taxCost = (((parseFloat(eventData.total) + eventData.shippingCost ? parseFloat(eventData.shippingCost) : 0 ) * (eventData.taxCost ? parseFloat(eventData.taxCost) : 0)));
        
        window.gtag('event', 'purchase', {
            currency: 'USD',
            items: items,
            transaction_id: eventData.orderNumber,
            shipping: eventData.shippingCost,
            value: eventData.orderTotal,
            tax: taxCost
          });

          window.gtag('event', 'conversion', {
            'send_to': 'AW-756876116/oJ4XCPL1_vICENSG9OgC',
            'value': eventData.orderTotal,
            'currency': 'USD',
            'transaction_id': eventData.orderNumber
        });


        }
    } 
}

export const BeginCheckoutEvent = (eventData) => {

    let items = [];

    if (eventData.cart) {
        for (let i = 0; i < eventData.cart.length; i++) {
            let product = eventData.cart[i];
            items.push({
                item_id:product.id,
                item_name:product.title,                
                price:product.price,
                currency:'USD',
                quantity:product.amount,
                category:product.type
            })
        }
    }

    if (items.length > 0) {
        if (window.gtag) {

            window.gtag('event', 'begin_checkout', {
            currency: 'USD',
            items: items,
            value: eventData.total
        });

        window.gtag('event', 'conversion', {
          'send_to': 'AW-756876116/m3VcCNKQqvMCENSG9OgC',
          'value':eventData.total,
          'currency': 'USD'
       });

        }
    }

}



export const ViewItem = (eventData) => {
    if (window.gtag) {

    window.gtag('event', 'view_item', {
        items:[{
            item_id: eventData.id,
            item_name: eventData.title,
            item_category: eventData.type,
            price:eventData.price,
            currency: 'USD',
            quantity: 1        
        }],
        value:eventData.price
    });
}
}

export const SignUp = () => {
  if (window.gtag) {
    window.gtag('event', 'sign_up', {
      'event_callback': function() {
        // form.submit();
        console.log("Signup complete");
      }
    });
    
    window.gtag('event', 'conversion', {'send_to': 'AW-756876116/UV-KCMqLqvMCENSG9OgC'});
}
}

export const ConfirmSignUp = () => {
  if (window.gtag) {
    window.gtag('event', 'sign_up_confirmed', {
      'event_callback': function() {
        // form.submit();
        console.log("Signup complete");
      }
    });
  
}
}

// export const ViewItemList =(eventData) => {

//     let items = [];

//     if (eventData.cart) {
//         for (let i = 0; i < eventData.cart.length; i++) {
//             let product = eventData.cart[i];
//             items.push({
//                     item_id: 'SKU_12345',
//                     item_name: 'jeggings',
//                     coupon: 'SUMMER_FUN',
//                     discount: 2.22,
//                     index: 5,
//                     item_list_name: 'Related Products',
//                     item_list_id: 'related_products',
//                     affiliation: 'Google Store',
//                     item_brand: 'Gucci',
//                     item_category: 'pants',
//                     item_variant: 'black',
//                     price: 9.99,
//                     currency: 'USD',
//                     quantity: 1
//             })
//         }
//     }

//     if (items.length > 0) {
//         if (window.gtag) {

//         window.gtag('event', 'view_item_list', {
//             items:items,
//             item_list_name: 'Related products',
//             item_list_id: 'related_products'
//           });
//         }
//     }
    
      
// }