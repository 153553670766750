import { TurnedIn } from '@material-ui/icons';
import moment from 'moment';
import { API } from 'aws-amplify';

 /**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }
  
  /**
  * Returns a random integer between min (inclusive) and max (inclusive).
  * The value is no lower than min (or the next integer greater than min
  * if min isn't an integer) and no greater than max (or the next integer
  * lower than max if max isn't an integer).
  * Using Math.round() will give you a non-uniform distribution!
  */
 export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  

  export function formatNumberLabel(label) {
    return label.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  export function ConvertStringToDollarsCents(stringAmount) {
    if (stringAmount === "--")
      return stringAmount;      
    return parseFloat(stringAmount).toLocaleString("en-US", { style: "currency", currency: "USD" })
  }


export function GetShortConditionName(condition) {
  if (condition === "New" || condition === "new") {
    return "N";    
  }
  if (condition === "Used" || condition === "used") {
    return "U";    
  }
  return condition;
}


export function GetRelativeTime(date) {
    const d = new Date(date);
    let fromNowString =  moment(d).fromNow();

    if (fromNowString.includes("years ago")) {
      fromNowString = "N/A";
    }
  return fromNowString;
}

export function ConvertCentsToDollarCents(cents) {
  if (cents) {
    var num = parseInt(cents);
    var dollars = num / 100;
    return dollars.toLocaleString("en-US", { style: "currency", currency: "USD" });
  }
}

export function CapitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isMobile() {      

  if (window.innerWidth <= 1100) {
    return true;
  }
  return false;
}


export function ConvertDimensionUnits(string) {
  if (string === "inches")
  {
    return "\"";
  }
  if (string === "pounds")
  {
    return "lbs";
  }
  return string;
}


export const CheckDisabled = (product) => {
  if (product && product.quantity <= 0 && !product.allowPreorder) 
      return true;        
  if (product && moment().valueOf() < moment(new Date(product.releaseDate)).valueOf()) {
      if (product.allowPreorder) {
          return false;
      }else {
          return true;
      }         
  }
  return false;            
}

export const  GetGroupsOfItems = (inputArray, batchLimit) => {
  let idGroups = new Array();
  idGroups.push(new Array());
  if (inputArray == undefined)
    return idGroups;
  let currentCount = 0;
  let currentArray = 0;
  for (let i = 0; i < inputArray.length; i++) {
    if (currentCount >= batchLimit) {
      idGroups.push(new Array());
      currentCount = 0;
      currentArray = currentArray + 1
    }
    let item = inputArray[i];
    idGroups[currentArray].push(item);
    currentCount = currentCount + 1;
  }

  return idGroups;
}

export const GetSeriesKeys = (isSearch, products)=> {    
  return isSearch ? Object.keys(products) : ["Scarlet & Violet","Sword & Shield", "Sun and Moon", "XY"];
}


export const GetSeriesIndexForKey = (seriesName, isSearch, products)=> {    
  let series = (GetSeriesKeys(isSearch,products));
  for (let i = 0; i < series.length; i++) {
    if (series[i] == seriesName) {
      return i;
    }
  }
  return 0;
}


export const GetIsExcludedFromSeries = (set) => {
  if (set != "Featured" && set != "25th Anniversary" && set != "League Battle" && set != "V Battle") {
    return true;
  }
  return false;
}


export const GetSetForSeries = (series) => {
  if (series == "Scarlet and Violet" || series == "Scarlet & Violet") {
    return ["Scarlet & Violet"];
  }

  if (series == "Sword and Shield" || series == "Sword & Shield") {
      return ["Crown Zenith","Silver Tempest", "Lost Origin","Pokémon GO", "Astral Radiance", "Brilliant Stars", "Fusion Strike", "Celebrations", "Evolving Skies", "25th Anniversary", "Chilling Reign", "V Battle", "League Battle", "Battle Styles", "Shining Fates", "Vivid Voltage","Champion's Path", "Darkness Ablaze", "Rebel Clash", "Sword & Shield"]
  }
  if (series == "Sun and Moon" || series == "Sun & Moon") {
      return ["Cosmic Eclipse","Burning Shadows", "Crimson Invasion", "Sun & Moon" ]
  }        
  if (series == "XY") {
      return ["Evolutions"]
  }
  return []
}


export const GetCardDisplayImagesForSet = (setName) => {

  if (setName == "Crown Zenith") {
    return ["swsh12pt5/160.png", "swsh12pt5/20.png", "swsh12pt5/99.png"]
  }

  if (setName == "Fusion Strike") {
    return ["swsh8/271.png", "swsh8/251.png", "swsh8/270.png"]
  }

  if (setName == "Celebrations") {
    return ["cel25/7.png", "cel25/25.png", "cel25/5.png"]
  }

  if (setName == "Evolving Skies") {
    return ["swsh7/215.png","swsh7/218.png", "swsh7/192.png"]
  }

  if (setName == "Chilling Reign") {
    return ["swsh6/97.png","swsh6/177.png", "swsh6/224.png"]
  }
  
  if (setName == "Battle Styles") {
    return ["swsh5/155.png","swsh5/170.png", "swsh5/179.png"]
  }

  if (setName == "Shining Fates") {
    return ["swsh45/72.png","swsh45/21.png","swsh45/70.png"];
  }

  if (setName == "Vivid Voltage") {
    return ["swsh4/188.png", "swsh4/170.png", "swsh4/198.png"];
  }

  if (setName == "Champion's Path") {
    return ["swsh35/79.png", "swsh35/76.png", "swsh35/8.png"];
  }

  if (setName == "Darkness Ablaze") {
    return ["swsh3/20.png", "swsh3/190.png", "swsh3/192.png"];
  }

  return [];
}


export const GetSetImageByName =(name) => {

  if (name == "Scarlet and Violet" || name == "Scarlet & Violet") {
    return "/images/products/pokemon/sets/sv01-logo.png";
  }

  if (name == "Sword and Shield" || name == "Sword & Shield") {
    return "/images/products/pokemon/sets/Sword-Shield.logo.286.png";
  }
  if (name == "25th Anniversary") {
    return "/images/products/pokemon/sets/25th-anniversary.png";
  }

  if (name == "Pokémon GO") {
    return "/images/products/pokemon/sets/pgo_logo_169_en.png";
  }
  
  if (name == "Crown Zenith") {
    return "/images/products/pokemon/sets/swsh125_logo_169_en.png";
  }

  if (name == "Silver Tempest") {
    return "/images/products/pokemon/sets/swsh12_logo_169_en.png";
  }

  if (name == "Lost Origin") {
    return "/images/products/pokemon/sets/swsh11_logo_169_en.png";
  }

  if (name == "Astral Radiance") {
    return "/images/products/pokemon/sets/swsh10_logo_169_en.png";
  }
  if (name == "Brilliant Stars") {
    return "/images/products/pokemon/sets/SWSH9_Logo_EN.png";
  }
  if (name == "Fusion Strike") {
    return "/images/products/pokemon/sets/swsh08_logo_169_en.png";
  }
  if (name == "Celebrations") {
    return "/images/products/pokemon/sets/celebrations-logo.png";
  }

  if (name == "Evolving Skies") {
    return "/images/products/pokemon/sets/EvolvingSkies.Logo.png";
  }
  if (name == "Chilling Reign") {
    return "/images/products/pokemon/sets/swsh6-chilling-reign-logo.png";
  }

  if (name == "Battle Styles") {
    return "/images/products/pokemon/sets/Battle-Styles-logo-700.png";
  }

  if (name == "Rebel Clash") {
    return "/images/products/pokemon/sets/Rebel-Clash.logo.292.png";
  }

  if (name == "Shining Fates") {
    return "/images/products/pokemon/sets/swsh045_logo_169_en.png";
  }

  if (name == "Sun & Moon") {
    return "/images/products/pokemon/sets/Sun-Moon.logo.205.png";
  }
  if (name == "Darkness Ablaze") {
    return "/images/products/pokemon/sets/Darkness-Ablaze.logo.296.png"
  }
  if (name == "Champion's Path") {
    return "/images/products/pokemon/sets/Champions-Path.logo.298.png"
  }
  if (name == "Evolutions") {
    return "/images/products/pokemon/sets/Evolutions.logo.197.png";
  }    
  if (name == "Vivid Voltage") {
    return "/images/products/pokemon/sets/Vivid-Voltage.logo.299.png";      
  }
  if (name == "Burning Shadows") {
    return "/images/products/pokemon/sets/Burning-Shadows.logo.225.png";      
  }
  if (name == "Crimson Invasion") {
    return "/images/products/pokemon/sets/Crimson-Invasion.logo.229.png";            
  }
  if (name == "Cosmic Eclipse") {
    return "/images/products/pokemon/sets/Cosmic-Eclipse.logo.280.png";            
  }
  if (name == "League Battle") {
    return "/images/products/pokemon/sets/league-battle-deck-logo.jpg";            
  }
  if (name == "V Battle") {
    return "/images/products/pokemon/sets/v-battle-deck-logo.jpg";            
  }

}

export const GetSeriesImageByName = (name) => {

  if (name == "Scarlet and Violet" || name == "Scarlet & Violet") {
    return "/images/products/pokemon/sets/sv01-logo.png";
  }

  if (name == "Sword and Shield" || name == "Sword & Shield") {
    return "/images/products/pokemon/sets/Sword-Shield.logo.286.png";
  }

  if (name == "Sword and Shield" || name == "Sword & Shield") {
    return "/images/products/pokemon/sets/Sword-Shield.logo.286.png";
  }
  if (name == "Sun and Moon" || name == "Sun & Moon") {
    return "/images/products/pokemon/sets/Sun-Moon.logo.205.png";
  }
  if (name == "XY") {
    return "/images/products/pokemon/sets/XY.logo.142.png";
  }
}

export const GetTotalForOrder = (order) => {
  let subtotal = parseFloat(order.subtotal);
  let totalPlusShipping = subtotal + parseFloat(order.shippingCost ? order.shippingCost : 0);
  let finalTotal = (totalPlusShipping + ( totalPlusShipping * parseFloat(order.taxCost ? order.taxCost : 0) )).toFixed(2);
  return finalTotal;
}

export const GetTaxForOrder = (order) => {
  let subtotal = parseFloat(order.subtotal);
  // let totalPlusShipping = subtotal + parseFloat(order.shippingCost ? order.shippingCost : 0);
  let taxTotal = (subtotal * parseFloat(order.taxCost ? order.taxCost : 0)).toFixed(2);
  // Do not include shipping tax , quickbooks gets this for us
  return taxTotal;
}



export const CheckInvoiceExists = async (orders, sendAlertMessage)=> {
  //Check invoices for existing quickbooks invoices
  let invoiceIds = [];
  for (let i = 0; i < orders.length; i++) {
    invoiceIds.push(orders[i].number);
  }
  let groups = GetGroupsOfItems(invoiceIds, 30);

  for (let i = 0; i < groups.length; i++) {
  
    let ids = groups[i];
    let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
    let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
    let result = await API.get("yayfunthingsadmin","/app/batchQueryForInvoice?token=" + quickBooksToken + "&realmId=" + quickBooksRealmId + "&ids=" + JSON.stringify(ids)).then(async response => {
        if (response.error) {              
            if (sendAlertMessage) {
              // sendAlertMessage(response.error)
            }
        } else {

          for (let y = 0; y < response.length; y++) {
              let queryResponse = response[y];
              let id = queryResponse["bId"];
              let invoiceExists = queryResponse && queryResponse.QueryResponse && queryResponse.QueryResponse.Invoice ? true : false;
              if (invoiceExists) {
                
                for (let x = 0; x < orders.length; x++) {
                  let order = orders[x];
                    if (order && order.number == id) {
                      order['hasQuickbooksInvoice'] = true;
                    }
                }
                // for (let x = 0; x < invoiceIds.length; x++) {
                //   let invoice = currentInvoices[invoiceIds[x]];
                //   console.log("Invoice - ", invoice);
                //     if (invoice && invoice[0] && invoice[0].orderId == id) {
                //       console.log("Found match - ", invoice[0].orderId)
                //       currentInvoices[id].push({hasQuickbooksInvoice:true});
                //     }
                // }

              }
          }
        }
        return response;
    }).catch(e =>{console.log(e); return null;});
    }
  }

  function isUPSTracking(trackingNumber) {
    const re = /\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/;
    const re2 = /^(1Z\s?[0-9A-Z]{3}\s?[0-9A-Z]{3}\s?[0-9A-Z]{2}\s?[0-9A-Z]{4}\s?[0-9A-Z]{3}\s?[0-9A-Z]$|[\dT]\d{3}\s?\d{4}s?\d{3})$/i;

    if (re.test(String(trackingNumber).toLowerCase())) {
        return true;
    } else if (re2.test(String(trackingNumber).toLowerCase())) {
        return true;
    }
    return false;
}


  export const GetTrackingUrl = (trackingNumber)=>{

    let trackUrl = "";
    if (isUPSTracking(trackingNumber)) {
        // carrierName = "UPS";
        trackUrl = 'https://wwwapps.ups.com/WebTracking/processInputRequest?AgreeToTermsAndConditions=yes&loc=en_US&tracknum='+trackingNumber+'&requester=ST/trackdetails';
    } else {
      // carrierName = "USPS";
      trackUrl = 'https://tools.usps.com/go/TrackConfirmAction.action?tLabels='+trackingNumber    
  }

  return trackUrl;
}

