
import React, {useContext} from 'react';
import { API } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import AdminContext from "../../context/admin";

function TableColumnEdit ({propertyName, product}) {
    const {updateProductFromInput} = useContext(AdminContext);

    return (<>
    <div style={{paddingTop:"1em"}}>

        <Button color="primary" variant="outlined" onClick={ async ()=>{
                    console.log("CLICKED:", product);
                   await updateProductFromInput({id:product.id, [propertyName]:!product[propertyName]})                                        
            }}>
                {product[propertyName] == true ? "YES" : "NO"}
            </Button>
            </div>
    </>)
}

export default TableColumnEdit;