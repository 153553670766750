
import React, {useState} from 'react'
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css';

function AdminQuill () {
    const [value, setValue] = useState("");

    return (<>
     <ReactQuill theme="snow" value={value}
                  onChange={setValue} /></>)
}

export default AdminQuill