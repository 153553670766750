import React, { useContext, useEffect, useState } from "react";
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core';
import ProductDraw from '../components/ProductDraw';
import ProductContext from "../context/products";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {useStyles} from '../styles/rootContainer';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ProductQuickView from '../components/ProductQuickView'
import UiContext from '../context/ui';
import Seo from '../components/Seo';

const Styles = styled.div`
`


function Search(props) {

    document.title = "Search : " + props.search;

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const { products, loading, getProductsBySearch, showInStockOnly } = useContext(ProductContext);
    const [ searchResults, setSearchResults] = useState([]);
    const [ loadingSearch, setLoadingSearch] = useState(true);
    const { clearMessages } = useContext(UiContext);
    const { classes } = props;
    const [openedModal, setOpenedModal] = useState(false);
    const [productInModal, setProductInModal] = useState(null);


    const startSearch = async()=> {
        setLoadingSearch(true);

        let cleanedSearch = props.search.toLowerCase().replace("pokémon", "");
        cleanedSearch = cleanedSearch.replace("pokemon", "");
        let r = await getProductsBySearch(cleanedSearch);        
        if (r) {
            setSearchResults(r);
        }        
        setLoadingSearch(false);
    }


    useEffect(()=> {
        startSearch();
    },[])


    const quickViewProduct = (product) =>{
        setProductInModal(product);
        setOpenedModal(true);
        clearMessages();
    }


    const endQuickViewProduct = (product) =>{
        setProductInModal(null);
        setOpenedModal(false);
        clearMessages();
    }


    const listSearchResults = (results)=>{    
        return <div className={classes.root}>
            <div className={classes.container}>
                <div className="container-inner">
                    <Grid container justify="center" direction="row">
                        {results && Object.keys(results).length > 0 ? 
                        <ProductDraw products={results} isSearch={true} hideSetCards={true} quickViewProduct={quickViewProduct} /> : <>                            
                            <div className="oops-container">
                            <p className="large-font font-bold">SORRY!</p>
                            <p className="medium-font"> No results found.</p>
                            {loadingSearch ? <img src="./images/oops.svg"/> :<></>}
                            </div>
                        </>}
                    </Grid>
                </div>
            </div>
        </div>
    }

return (
    <Styles>
        {
            <>                
            <Seo
                  title={"Yay Pokemon - Search"}
                  pathSlug={"/s"}
                  description={"Search from thousands of cards in the Pokemon TCG."}
                />

            {
             loading || loadingSearch ?   
            <div style={{textAlign:"center", 'minHeight':600, paddingTop:"4em"}}> <CircularProgress className={classes.progress} /> </div>
            : 
            <> { listSearchResults(searchResults) } </>
            }

            <Dialog onClose={()=>{
                            setOpenedModal(false);
                        }} aria-labelledby="simple-dialog-title" open={openedModal}>
                            <DialogContent>
                            <ProductQuickView product={productInModal} endQuickViewProduct={endQuickViewProduct}/>
                            </DialogContent>
             </Dialog> 
            </>
        }
    </Styles>
)

}

export default withStyles(useStyles)(Search)
