import React, { useContext, useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CustomTextField from '../components/yayfun/CustomTextField';
import { Link, useLocation, useHistory } from "react-router-dom";
import AccountCircle from '@material-ui/icons/AccountCircle';

import { GetSetImageByName, GetCardDisplayImagesForSet } from './Helper';
import { Carousel } from 'react-responsive-carousel';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import CartContext from "../context/cart";
import UiContext from "../context/ui";
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import NavigatorSelector from "./NavigatorSelector";
import CardDisplay from "./CardDisplay";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    margin: '0em'
  },
  flexSpace: {
    flexGrow: 1
  },
  customBadge: {
    backgroundColor: "#ffb800",
    color: "black"
  },
  input: {
    color: 'white'
  },
  heroImg: {
    maxWidth: 1200
  }

}));

function NavigatorAppBar(props) {

  const location = useLocation();
  const [searchInput, setSearchInput] = useState("");
  const [enterOnce, setEnterOnce] = useState(false);
  const [offset, setOffset] = useState(0);
  const navRef = React.useRef();
  const containerRef = React.useRef();
  const isNavOpen = React.useRef(false)
  const lastNavHeightTransition = React.useRef(moment().valueOf())
  const lastTransition = React.useRef(moment().valueOf())
  const [isSwiping, setSwipeAction] = useState(false);
  const { showDropdown, totalItems, toggleSideCart, toggleDropdown, toggleDropdownOn} = useContext(CartContext);
  const { alertMessage, closeAlertMessage, totalMessages } = useContext(UiContext);
  const [currentNavHeightOffset, setCurrentNavHeightOffset] = useState(0);
  const [inSearch, setInSearch] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {

    const params = new URLSearchParams(location.search);

    const s = params.get('k');
    if (s && s.length >= 0) {
      setSearchInput(s);
      setInSearch(true);
    } else {
      setInSearch(false);
      // setSearchInput('');
    }

    window.onscroll = () => {
      updateNav();
    }
  }, [location.search, location.pathname, showDropdown, alertMessage]);


  const shouldShowCarousel = ()=>{
    if (location) {
     if (location.pathname == "/")
      return true;
    }
    return false;
  }

  const shouldShowSearchBar = ()=>{
    if (location) {
      if (location.pathname.includes("/checkout") || location.pathname.includes("/account"))
       return false;
     }
     return true; 
  }

  const shouldShowSelector = ()=>{
    if (location) {
     if (location.pathname.includes("/cards") || location.pathname.includes("/s") || location.pathname.includes("/checkout") 
     || location.pathname.includes("/cart") || location.pathname.includes("/account") || location.pathname.includes("/admin")
     ||  location.pathname.includes("/returns") || location.pathname.includes("/contact") || location.pathname.includes("/privacy") || location.pathname.includes("/pokemon") 
     || location.pathname.includes("/vr") || location.pathname.includes("/order") || location.pathname.includes("/unsubscribe") || location.pathname.includes("/track"))
      return false;
    }
    return true;
  }


  function swipeEnded() {
    if (isSwiping) {
      setTimeout(() => {
        setSwipeAction(false)
      }, 100);
    }
  }

  function swipeMoved() {
    setSwipeAction(true);
  }

  function swipeStarted() {
    // setSwipeAction(true);
    // setTimeout(() => {setSwipeAction(true)}, 100); 
  }


  const updateClassesOpen = ()=>{
    if (!navRef.current.classList.contains("is-nav-open")) {
      navRef.current.classList.add("is-nav-open");
    }
    if (navRef.current.classList.contains("is-nav-closed")) {
      navRef.current.classList.remove("is-nav-closed");
    }

    if (!containerRef.current.classList.contains("container-nav-open")) {
      containerRef.current.classList.add("container-nav-open");
    }

    if (containerRef.current.classList.contains("container-nav-closed")) {
      containerRef.current.classList.remove("container-nav-closed");
    }
  }

  const updateClassesClosed = ()=>{
    if (navRef.current.classList.contains("is-nav-open")) {
      navRef.current.classList.remove("is-nav-open");
    }

    if (!navRef.current.classList.contains("is-nav-closed")) {
      navRef.current.classList.add("is-nav-closed");
    }

    if (containerRef.current.classList.contains("container-nav-open")) {
      containerRef.current.classList.remove("container-nav-open");
    }

    if (!containerRef.current.classList.contains("container-nav-closed")) {
      containerRef.current.classList.add("container-nav-closed");
    }
  }


  const updateNav = () => {
    let yOffset = window.pageYOffset;
    setCurrentNavHeightOffset(yOffset);
  }


  const clickedOnCarouselItem = (item) => {
    if (!isSwiping) {
      history.push(item.url);
    }
  }


  const performSearch = () => {
    if (searchInput != '') {

      toggleDropdownOn(false);
      history.push('/s?k=' + searchInput);
    }
  }

  const carouselItems = () => {
    return [
      // {set:'Shining Fates', url:'/s?k=Shining Fates Bundle', image:"images/products/pokemon/bundles/shining-fates-bundle-3.png", overrideImage:true},    
      { set: 'Scarlet & Violet', url: '/s?k=Scarlet & Violet', image: "", overrideImage: false },
      { set: 'Crown Zenith', url: '/s?k=Crown Zenith', image: "", overrideImage: false },
      { set: 'Silver Tempest', url: '/s?k=Silver Tempest', image: "", overrideImage: false },
      { set: 'Lost Origin', url: '/s?k=Lost Origin', image: "", overrideImage: false },
      { set: 'Astral Radiance', url: '/s?k=Astral Radiance', image: "", overrideImage: false },
      { set: 'Brilliant Stars', url: '/s?k=Brilliant Stars', image: "", overrideImage: false },
      { set: 'Fusion Strike', url: '/s?k=Fusion Strike', image: "", overrideImage: false },
      { set: 'Celebrations', url: '/s?k=Celebrations', image: "", overrideImage: false },
      { set: 'Evolving Skies', url: '/s?k=Evolving Skies', image: "", overrideImage: false },
      { set: 'Chilling Reign', url: '/s?k=Chilling Reign', image: "", overrideImage: false },
      { set: 'Battle Styles', url: '/s?k=Battle Styles', image: "", overrideImage: false },
      { set: 'Shining Fates', url: '/s?k=Shining Fates', image: "", overrideImage: false },
      { set: 'Vivid Voltage', url: '/s?k=Vivid Voltage', image: "", overrideImage: false },
      { set: 'Champion\'s Path', url: '/s?k=Champion\'s Path', image: "", overrideImage: false },
      { set: 'Darkness Ablaze', url: '/s?k=Darkness Ablaze', image: "", overrideImage: false },
    ]
  }

  const getItemFromIndex = (index) => {
    let resultItem = carouselItems()[index];
    return resultItem;
  }
  
// max-height:33em;
// max-height:7em;

const hideAccountIcon = ()=>{
  if (window.location.href.indexOf("cart") > -1) {
    return true;
  }
  if (window.location.href.indexOf("admin") > -1) {
    return true;
  }
  if (window.location.href.indexOf("account") > -1) {
    return true;
  }
}


const hideShoppingCartIcon = ()=> {
  if (window.location.href.indexOf("cart") > -1) {
    return true;
  }
  if (window.location.href.indexOf("admin") > -1) {
    return true;
  }
  if (window.location.href.indexOf("account") > -1) {
    return true;
  }
}

const getMaxNavBarHeight = ()=>{
  if (inSearch || !shouldShowCarousel() || showDropdown || (alertMessage && alertMessage != "")) {
    return 100;
  }
  let startingHeight = 470;
  let minHeight = 100;
  let currentOffset = currentNavHeightOffset ? currentNavHeightOffset : 0;
  let offset =  Math.max(minHeight, startingHeight - currentOffset);
  let result = offset + "px";
  return result;
}

const getMaxNavContainerMargin = ()=>{
  let startingHeight = 470;
  if (inSearch || !shouldShowCarousel() || showDropdown  || (alertMessage && alertMessage != "")) {
    return 100;
  }
  // let minHeight = 100;
  // let currentOffset = currentNavHeightOffset ? currentNavHeightOffset : 0;
  // let offset =  Math.max(minHeight, startingHeight - currentOffset);
  let result = startingHeight + "px";
  return result;
}

const CardDisplayImagesForSet = (setName)=>{ 
  let images = GetCardDisplayImagesForSet(setName);
  if (images) {
    return images.map( (item,index) =>{
      return (<CardDisplay key={"_k"+item+index} classNameKey={"banner-card-display"+(1+index)} offsetIndex={index} imgKey={item} suffix="_200.png"/>)
    })
  }

}


const displaySearchBar = ()=>{
  if (shouldShowSearchBar()) {
    return(<>
     <CustomTextField
            className="custom-input"
            style={{ float: "left", minWidth: "20em" }}
            key={"search-input"}
            color={"white"}
            properties={
              {
                name: "Search",
                labelName: "Search",
                error: false,
                variant: 'outlined',
                key: "search-input",
                onKeyPressFunction: performSearch,
                currentValue: searchInput,
                inputLabelColor:{ color: '#fff' }
              }
            }
            defaultValue={""}
            value={searchInput}
            inputProps={{ className: classes.input }}
            // onKeyPress={(e) => {
            //   if (e.key === 'Enter') {
            //     console.log("Key enter ");
            //     //TODO: End propagation
            //     performSearch();
            //   }
            // }}
            onChange={
              e => {
                if (e.target.value) {
                  setSearchInput(e.target.value);
                } else {
                  setSearchInput('');
                }
              }
            } />
    </>) 
  }

  return (<></>);
}
  return <>  
    <div ref={containerRef} style={{marginBottom:getMaxNavContainerMargin()}}>
      <AppBar ref={navRef} position="fixed" style={{maxHeight:getMaxNavBarHeight()}} className={"sticky-nav"}>
        <Toolbar style={{ zIndex: "5", padding:"0em" }}>
          <Link to='/' style={{ margin: "0em" }}>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" style={{padding:"0em", paddingLeft:"0em"}}>
              <img style={{ width: "3em", height: "3em" }} src="/images/yaypokemon.png" alt="yay fun" />
            </IconButton>
          </Link>

          {/* <MenuIcon /> */}
          {/* <Button color="inherit">
              <Link style={{ color: "white", fontWeight: "200", fontSize: "2em" }} to='/cards'>CARDS</Link>
            </Button> */}
         {
           displaySearchBar()
         }
          <div className={classes.flexSpace}>&nbsp;</div>
          {
            (window.location.href.indexOf("checkout") > -1) ? <>
              <Link to='/cart' style={{ margin: "5px", marginRight: "0em", color: "white" }}>
                {/* <Button color="inherit" style={{ width: "10em", float: "right" }}>Edit Cart</Button>
                 */}
              </Link>
            </> : <>
              <Button color="inherit" style={{padding:"0em"}}>
                {/* <Button color="inherit" style={{ width: "10em", float: "right" }}>Account</Button> */}
                
                {
                  hideAccountIcon() ? <></> : <>
                  <AccountCircle fontSize="large" onClick={() => {
                  history.push('/account');
                  // <Link to='/account' style={{ margin:"5px", marginTop:"0.8em", color: "white" }}>
                  // </Link> 
                }} /></>
                }
                
              </Button>
              {/* <AccountButton /> */}
              {/* {signedIn ? <AmplifySignOut /> : <></>} */}
              {
                (hideShoppingCartIcon()) ? <></> : <>
                  <Button color="inherit" style={{padding:"0em"}}>
                    <Badge classes={{ badge: classes.customBadge }} badgeContent={totalItems()} max={99}>
                      <ShoppingCartIcon style={{ margin: "0em", paddingRight: "0em", color: "white" }} fontSize="large" onClick={() => {
                        toggleDropdownOn(false);
                        toggleSideCart()
                      }} />
                    </Badge>
                  </Button>
                </>
              }
            </>
          }
        </Toolbar>
        <div id="carouselParent">
          {
            shouldShowCarousel() ? <>
                 <Carousel interval={10000} showThumbs={false} showStatus={false}
                    emulateTouch={true}
                    preventMovementUntilSwipeScrollTolerance={false}
                    onSwipeMove={swipeMoved}
                    onSwipeEnd={swipeEnded}
                    onSwipeStart={swipeStarted}
                    infiniteLoop
                    transitionTime={500}
                    useKeyboardArrows={true}
                    swipeScrollTolerance={10}
                    onClickItem={(index) => {
                      clickedOnCarouselItem(getItemFromIndex(index))
                    }}
                    autoPlay={false}
                    onChange={(index, item) => {
                      // this.updateBackgroundColor(index);
                    }} >
                    {
                      carouselItems().map((item, index) => {
                        return (<div key={"item-" + index} style={{ minHeight: "23em" }}>
                          <div>
                            {/* maxHeight:"23em", maxWidth:"20em", width:"auto", */}            
                            <img className={classes.heroImg} style={{ maxHeight: "250px", width: "auto", padding: "2em" }} src={item.overrideImage ? item.image : GetSetImageByName(item.set)} />  
                             { 
                               CardDisplayImagesForSet(item.set)
                             }
                          </div>
                        </div>)
                      })
                    }
                  </Carousel>
            </> :<></>
          }    
        </div> 
      </AppBar>
    </div>

{
  shouldShowSelector() ?     <NavigatorSelector /> : <></>
}


  </>
}

export default NavigatorAppBar;