import React, {useContext, useState, useRef} from 'react'
import ProductCard from '../components/ProductCard';
import ProductContext from "../context/products";
// import Slider from 'infinite-react-carousel';
import ScrollBox from "./ScrollBox";
import {GetSetImageByName} from "./Helper";

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Header, Popup, Grid } from 'semantic-ui-react'
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from "@material-ui/core/styles";
import FilterSinglesInput from "./yayfun/FiilterSinglesInput";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles({
  root:{
    paddingTop: "1em",
    paddingBottom: "1em",
    paddingRight:"1em",
    height:"26px"      
  },
  rootCheckbox:{
    paddingTop: "0.5px",
    paddingBottom: "0.5px",
    paddingRight:"0.1em",
    paddingLeft:"0.4em"
  },

  MuiInputBase:{
    padding: 0
  },
  
  MuiButtonLabel:{
    fontFamily:"Roboto",
    fontSize:"1em",
    fontWeight:"400",
    color:"#3e4244"
  },

  MuiButtonLabelGlow:{
    fontFamily:"Roboto",
    fontSize:"1em",
    fontWeight:"400",
    color:"#3e4244"
  },
  MuiIconButton: {
    padding:"0em",
    paddingRight:"0.1em",
    margin:"0em"
  },
  MuiButtonLabelDisabled:{
    fontFamily:"Roboto",
    fontSize:"1em",
    fontWeight:"400",
    color:"#ccc"

  }

});

const ProductSingles = (props)=> {

    const {productSingles, singlesFilters, builtFilters, fetchMoreSinglesForSets, moreToFetchForSet, sortSinglesForSets } = useContext(ProductContext);
    const [filters, setFilters] = useState({});
    const [filtersOpened, setFiltersOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const [setkeySortOrder, setSetkeySortOrder ] = useState({});
    const classes = useStyles();    
    //const filterInput = useRef({});
    const [filterInput, setFilterInput] = useState({});

    const settings =  {
        rows:1,
        pauseOnHover: true,
        shift: 0,
        dots:false,
        swipe:true,
        slidesPerRow:3,
        adaptiveHeight:false
    };
    
    const getCardSingles = ()=>{  
        return productSingles;
    }

  
    const isAtEnd = async ()=>{
      if (moreToFetchForSet(props.setKey) && !loading) {
        setLoading(true); 
        await fetchMoreSinglesForSets([props.setKey]);
        setLoading(false); 
      }
    }




const drawScrollBox = (cs) =>{

  let result = applySinglesFilter(cs);
  result = applyFilterInput(result);


  if (result && result.length == 0) {
    return <p style={{padding:"2em"}}>There are no cards with the filters you have selected.</p>
  } else if (result && result.length > 0) {

    let singlesShown = [];

    for (let i = 0; i < result.length; i++) {
      let single = result[i];        
      if ((props.showInStockOnly && single.quantity > 0 )|| !props.showInStockOnly) {
        singlesShown.push(single);
      }
    }

    if (singlesShown.length > 0) {


      return <>      
      <ScrollBox title="scroll-box" notifyIsAtEnd={isAtEnd}>
      {drawSinglesSlider(singlesShown)}
    </ScrollBox></>
    }else {
      return <p style={{padding:"2em"}}>There are no cards with the filters you have selected.</p>
    }
  }
  
}                 


const loadingMoreProduct = (setKey)=>{

  return (<>
  
  {
     <div className="fulfillment-dropdown" style={{display:"inline-block", paddingLeft:"2em", paddingTop:"2em", verticalAlign:"top"}}>
     <FormGroup row>
       <FormControl variant="outlined">

       <InputLabel id="demo-simple-select-outlined-label">Sort</InputLabel>

         <Select
           classes={{
             root: classes.root
           }}
           labelId="demo-simple-select-outlined-label"
           value={setkeySortOrder[props.setKey] ? setkeySortOrder[props.setKey]  : 0}  
           onChange={async (event) => {
             let selectionIndex = event.target.value;           
            let updatedSort = {...setkeySortOrder};
            updatedSort[props.setKey] = selectionIndex;
            setSetkeySortOrder(updatedSort);

            sortSinglesForSets([setKey], selectionIndex);

            if (moreToFetchForSet(setKey) && !loading) {
              setLoading(true); 
              await fetchMoreSinglesForSets([setKey]);
              setLoading(false); 
            }


           }}
           label="Sort">        
            <MenuItem
             key={0}
             value={0}>
                 1,2,3,...
             </MenuItem>
             <MenuItem
             key={1}
             value={1}>
                 ...,3,2,1
             </MenuItem>
             <MenuItem
             key={2}
             value={2}>
                 A-Z
             </MenuItem>
             <MenuItem
             key={3}
             value={3}>
                 Z-A
             </MenuItem>
             <MenuItem
             key={4}
             value={4}>
                Price Low to High
             </MenuItem>
             <MenuItem
             key={5}
             value={5}>
                 Price High to Low
             </MenuItem>

         </Select>                
       </FormControl>

       <FilterSinglesInput set={setKey} onFocus={async (e)=>{
              if (moreToFetchForSet(setKey) && !loading) {
                setLoading(true); 
                await fetchMoreSinglesForSets([setKey]);
                setLoading(false); 
              }
              }} onInputChange={(newInput)=>{                
                  setFilterInput({...filterInput, [setKey]:newInput})
            }}/>
     </FormGroup>   

            
   
   </div>
  }




  {
  //  moreToFetchForSet(setKey) ? 
  // <div style={{float:'right', marginTop:'20px'}}>
  // <Button variant='outline' onClick={async ()=>{
  //     setLoading(true); 
  //     await fetchMoreSinglesForSets([setKey]);
  //     setLoading(false); 
  // }}>
  //   Load More
  // </Button>
  // </div> :<></>
  }

  
  </>)
}


    const drawProductSingles = (setKey)=> {


        let cardSingles = getCardSingles();

        let cs = cardSingles[setKey]  && cardSingles[setKey].cards? cardSingles[setKey].cards : [];    
        if (cs && cs.length > 0 && cs[0]) {            
        return <>  
          <Paper>
          {/* <div style={{textAlign:"left"}} onClick={()=>{

            setFiltersOpened(!filtersOpened);

          }}> */}

          <div style={{textAlign:"left"}}>           
            <img style={{paddingLeft:"1em", display:"inline-block"}} className="product-set-image" src={GetSetImageByName(setKey)} alt={setKey} />
           

                {
                  loadingMoreProduct(setKey)           
                }

            <div className="product-singles-set-header" style={{float:"right"}}>            
              {/* <div>              
                <p className="fun-title">Singles</p>
              </div> */}
            </div>
          </div>
            {          
            // drawFilterSingles()
           }
          {/* </div> */}


        {/* <div style={{display:"inline-block"}}>
          <p style={{display:"inline-block", paddingTop:"1em", verticalAlign:"top"}} className="fun-title"> Singles</p>   
        </div> */}
       
        

          {
            drawScrollBox(cs)
          }
           
          </Paper>
            {
              // makeSlider(cs)
            }

          </>
        }     
    }

    const applyFilterInput = (singles) =>{
      if (filterInput) {    
        let filteredSingles = [];
        for (let i = 0; i < singles.length; i++) {
          let single = singles[i];
          let setKeyFilterInput = filterInput[single.set]
            if (setKeyFilterInput) {
              if ((""+single.keyword).includes(setKeyFilterInput.toLowerCase())) {
                filteredSingles.push(single);
              }
            } else {
              filteredSingles.push(single);
            }
        }
        return filteredSingles;
      }
      return singles;
    } 

    
    const applySinglesFilter = (singles)=> {

      let filteredSingles = [];
      if (builtFilters.Rarity && builtFilters.EnergyType) {
        let filterKeys = Object.keys(singlesFilters);
        let filtersOn = [];
        for (let i = 0; i < filterKeys.length; i++){
          let filterKey = filterKeys[i];
          if (singlesFilters[filterKey]) {
            filtersOn.push(filterKey);
          }
        }
        for (let i = 0; i < singles.length; i++) {
          let single = singles[i];
          let includesFilter = false;                         
          
          for (let x = 0; x < filtersOn.length; x++){
            let filterKey = filtersOn[x];

            if(single.rarity.toLowerCase() == filterKey.toLowerCase()) {
              includesFilter = true;
            }
            if(single.supertype.toLowerCase() == filterKey.toLowerCase()) {
              includesFilter = true;
            }
            if(single.types && single.types.length > 0) {
              for (let z = 0; z < single.types.length; z++) {
                let singleType = single.types[z];
                if(singleType.toLowerCase() == filterKey.toLowerCase()) {
                  includesFilter = true;
                }
              }
            }
          }
          if (includesFilter || filtersOn.length == 0) {
              filteredSingles.push(single);
          }
        }
      } else {
        return singles;
      }
      return filteredSingles;
    }


    const drawSinglesSlider = (singles)=> {
      let singlesShown = [];
      for (let i = 0; i < singles.length; i++) {
        let single = singles[i];        
        if ((props.showInStockOnly && single.quantity > 0 )|| !props.showInStockOnly) {
          singlesShown.push(single);
        }
      }      

      return  singlesShown.map((single, index) => {
          return (<ProductCard key={index} product={single} delayLoad={true} quickViewProduct={props.quickViewProduct} />)
        });
    }


    
    const drawLoading = ()=>{
      return (
      <div style={{position:"absolute", top:"50%", left:"50%"}}>
          <CircularProgress/>
      </div>);
    }


    const drawProductSinglesList = (singles)=>{
      if (singles && singles.length > 0 && singles[0]) {      
      return <>          
        <p className="fun-title"> Singles</p>    
        {drawSinglesSlider(singles)}
        {/* <Slider {...settings}>
          {
            singles.map((single, index) => {
              if ((props.showInStockOnly && single.quantity > 0 )|| !props.showInStockOnly) {
                return (<ProductCard key={index} product={single} quickViewProduct={props.quickViewProduct} />)
              }
            })
          }
          </Slider> */}

         
        </>
      }     

    }

    return (<div style={{position:"relative"}}>
        {
             props.search ? drawProductSingles(props.setKey): props.products ? drawProductSinglesList(props.products) : <></>
        }

{
            loading ? drawLoading() : <></>
          }
    </div>)
}

export default ProductSingles;