

const debugOn = false;

function ConsoleLog(msg) {
    
    if (debugOn) {
        console.log(msg)
    }
}

export default ConsoleLog;