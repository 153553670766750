

import React, { useContext, useState, useEffect } from "react";
import CustomTextField from './yayfun/CustomTextField';


function SearchInput(props) {

    const [searchInput, setSearchInput] = useState("");

    return <>
    <div style={{paddingLeft:"1em"}}>

        <CustomTextField
            className="custom-input"
            key={"search-input"}
            color={"black"}
            properties={
            {
                name: "Search",                
                labelName: "Search",
                error: false,
                variant: 'outlined',
                key: "search-input",
                onKeyPressFunction:()=>{
                    props.performSearch(searchInput);
                },
                currentValue:searchInput
            }}
            onChange={
                e => {
                    if (e.target.value) {
                    setSearchInput(e.target.value);
                    } else {
                    setSearchInput('');
                    }
                }
            }
        />
</div>
    
    </>
}

export default SearchInput;