import React, { useState } from 'react';
import moment from 'moment';
import {DropzoneArea} from 'material-ui-dropzone'

const csvParser = require('csv-parser')
const csv = require('csvtojson')

const ID = 0;
const TOTAL_ITEMS = 1;
const DATE = 2;
const COST = 3;
const PAYMENT_METHOD = 4;
const DELIVERY_ADDRESS = 5;
const DATA = 6;

function CsvParser(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
        

function readFileContent(file) {    
    const reader = new FileReader();    
    return new Promise((resolve, reject) => {
        reader.onload = event => resolve(event.target.result);
        reader.onerror = error => reject(error);
        reader.readAsText(file);
    });
}

function handleChange(files){
        console.log(files);
        let selectedFile = files[0];
        if (selectedFile) {
            let results = [];
            readFileContent(selectedFile).then((textFile) => {
              csv({
                noheader:true,
                output: "csv"
            })
            .fromString(textFile)
            .then((csvRow)=>{ 
                console.log(csvRow); 
                
                // => [["1","2","3"], ["4","5","6"], ["7","8","9"]]
                // for (let i = 0; i < csvRow.length; i++) {
                //     let row = csvRow[i];
                //     row[DATA] = JSON.parse(row[DATA]);    
                //     row[DELIVERY_ADDRESS] = JSON.parse(row[DELIVERY_ADDRESS]);                        
                //     results.push(row);
                // }
                // console.log(results);
                //setOrders(results);
                //forceUpdate();

                if (props && props.dataHandler) {
                    props.dataHandler(csvRow);
                }
            })
        //     });
      
            
            }).catch(error=>{console.log(error)});
      
      
          }
      
     }

    

     return(
        <div style={{width:"80%", marginLeft:"auto", marginRight:"auto"}}>
        <DropzoneArea
           acceptedFiles={['text/csv']}
           onChange={handleChange}
       />
       </div>
     )

}

export default CsvParser
