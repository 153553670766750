import React , {useContext, useEffect} from "react";
import UserContext from "../context/user";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';


function FetchMoreOrdersButton(props){

    const { fetchMoreOrders, hasMoreOrdersToLoad, isLoadingMore } = useContext(UserContext);
    
    return(<>
    {
        hasMoreOrdersToLoad() ?
            <Button color="primary" variant="contained" style={{ marginTop: "2em" }} onClick={() => {
                fetchMoreOrders();
            }}>                
                {
                    isLoadingMore ? <CircularProgress style={{scale:"0.7", padding:"0em", margin:"0em", height:"20px", width:"20px"}} /> : 'Load More'
                }
            </Button> 
            : <></>
    }
    
    </>)
}

export default FetchMoreOrdersButton;