import React, { useState, useContext, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid';
import Storage from "@aws-amplify/storage";
import { API } from "@aws-amplify/api";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';

import { createProduct, deleteProduct, updateProduct, updateOrder, createAnalytics } from '../graphql/mutations'
import config from '../aws-exports'
import Button from '@material-ui/core/Button';
import ProductContext from "../context/products";
import UiContext from "../context/ui";
import AdminContext from "../context/admin";
import TableObject from "../components/yayfun/TableObject";
import CsvParser from "../components/yayfun/CsvParser";
import CircularProgress from '@material-ui/core/CircularProgress';
import { AdminProvider } from '../context/admin';
import moment from 'moment';
import OrdersDraw from '../components/OrderDraw';
import CustomTextField from '../components/yayfun/CustomTextField';
import OrderDetails  from '../pages/OrderDetails';

import { CapitalizeFirstLetter, ConvertStringToDollarsCents, GetGroupsOfItems } from '../components/Helper'
import AddSingleDisplay from '../components/AddSingleDisplay';
import AddAsinDisplay from '../components/AddAsinDisplay';
import UpdatePopupDisplay from '../components/UpdatePopupDisplay';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Fab } from '@material-ui/core';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TrendingDown from '@material-ui/icons/TrendingDown';
import MenuIcon from '@material-ui/icons/Menu';

import TrendingUp from '@material-ui/icons/TrendingUp';
import TrendingFlat from '@material-ui/icons/TrendingFlat';
import SearchInput from '../components/SearchInput';

import AdminProductFilters from '../components/AdminProductFilters';
import AdminOrderFilters from '../components/AdminOrderFilters';

import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';

import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Divider from '@material-ui/core/Divider';
import AnalyticsPage from './AnalyticsPage';
import AdminSetsPage from './AdminSetsPage';
import CouponsPage from './CouponsPage';

import axios from 'axios';
import aws_config from "../aws-exports";
import Amplify from '@aws-amplify/core';
import Jimp from 'jimp';

import S3Image from "../components/S3Image";
import PreviewEmail from '../components/PreviewEmail';
import Quickbooks from '../components/yayfun/Quickbooks';
import AdminEmailPage from './AdminEmailPage';

Amplify.configure(aws_config);

const FileType = require('file-type');
const drawerWidth = 240;
const https = require('https');
const Stream = require('stream').Transform;                                  
const fs = require('fs');                                                    

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex:1
    },
    drawerPaper: {
      width: drawerWidth,
      zIndex:10
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }));

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

let dataColumnNameIndexes = {};

const delay = ms => new Promise(res => setTimeout(res, ms));

const AdminExt = () => {

    document.title = "Admin";

    const classes = useStyles();

    const [productDetails, setProductDetails] = useState({ title: "", description: "", image: "", author: "", price: "" });
    const {products, fetchProducts, inventorySheet, orders, searchedOrders, hasMoreOrdersToLoad, fetchMoreOrders, searchOrders, loading, loadingOrders,
         updateOrderWithInput, deleteOrderWithId, cardsSelected, cardSetSelected, setCardsSelected, getPrintedCardCountForSet, getTotalCardCountForSet, 
          getReleaseDateForSet, getSheetByTitle, cardSets, getCardSetNameFromCode , createAnalyticsInput} = useContext(AdminContext);
    const {productSingles, singles, getSinglesForSet, updateProductData, updateSinglesData, fetchProductsForSets, loadingProducts, moreToFetchForSet, fetchMoreSinglesForSets, getSetCodeByName} = useContext(ProductContext);
    const {sendAlertMessage } = useContext(UiContext);
    const [syncing, setSyncing] = useState(false);
    const [, updateState] = React.useState();
    const [currentTab, setCurrentTab] = useState("Orders");
    const [orderDetailShown, setOrderDetailShown] = useState({});
    const [emailCustomerDisplayOpen, setEmailCustomerDisplayOpen] = useState(false);
    const [displayAddSingle, setDisplayAddSingle] = useState(false);
    const [displaySelected, setDisplaySelected] = useState(false);
    const [busy, setBusy] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [hasCheckedProducts, setHasCheckedProducts] = useState(false);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [selectedSetIndex, setSelectedSetIndex] = useState(0);
    const [selectedSetName, setSelectedSetName] = useState("");

    const [displayCompareUpdate, setDisplayCompareUpdate] = useState(false);
    const [updatedProductPrices ,setUpdatedProductPrices] = useState([])

    const [displayAddAsin, setDisplayAddAsin] = useState(false);
    const [displayPopupDetails, setDisplayPopupDetails] = useState(false);
    const [displayEditFieldPopup, setDisplayEditFieldPopup] = useState(false);

    
    
                             
    const [updatedResults, setUpdatedResults] = useState([]);
    const [displayResults, setDisplayResults] = useState(false);
    const [displaySearchedOrders, setDisplaySearchedOrders] = useState(false);
    const [productFilters, setProductFilters] = useState({});
    const [searchProducts, setSearchProducts] = useState(false);
    const [orderFilters, setOrderFilters] = useState({});
    const [checkPaymentResult, setCheckPaymentResult] = useState({});
    const [capturePaymentResult, setCapturePaymentResult] = useState({});
    const [showHistory, setShowHistory] = useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState('Orders');
    const [addingProduct, setAddingProduct] = React.useState(false);
    const [addAsinInput , setAddAsinInput] = React.useState("");
    const [cancelReasonSelectionIndex, setCancelReasonSelectionIndex] = useState(0);

    const [formData, setFormData] = useState({emailMessage:"", shippingPaid:0, customEmail:""});
    const [displayEmailPreview, setDisplayEmailPreview ] = useState(false);
    const [displayPreviewDetails, setDisplayPreviewDetails ] = useState(false);

    

    let cardImageUrlIndex = 0;
    var zip = new JSZip();

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!productDetails.title || !productDetails.price) return
            await API.graphql(graphqlOperation(createProduct, { input: productDetails }))
            setProductDetails({ title: "", description: "", image: "", author: "", price: "" })
        } catch (err) {
            console.log('error creating todo:', err)
        }
    }

    function updateData(id, data) {
        updateProductData(id, data);
        for (let i = 0; i < products.length; i++) {
            let p = products[i];
            if (p.id == id) {
                let productkeys = Object.keys(data);
                for (let i = 0;i < productkeys.length; i++) {
                    p[productkeys[i]] = data[productkeys[i]];
                }
            }
        }
          }

      async function saveData(id, data) {
        // updateProduct(id, data);
        console.log("saveData:", data);
        await performUpdateActionOnItem(data);
        // forceUpdate();
      }


      function checkIsLoading(){
        return loadingProducts();
      }

      function checkForValidData(row) {
        let validData = false;
        if (row[0] == "id" && row[1] == "asin"
        && row[2] == "title") {
            validData = true;
        }
        return validData;
      }


      function columnIndex(columnName) {          
        let result = dataColumnNameIndexes[columnName]; 

        if (result >= 0) {
            return result 
        }

        return -1;
      }

      const inputObjectFromResults = (result) => {
        let item = {
            id:result[  ("id")],
            asin:result[columnIndex("asin")],
            title:result[columnIndex("title")],
            description:result[columnIndex("description")],
            image:result[columnIndex("image")],
            cost: result[columnIndex("cost")] ? parseFloat(result[columnIndex("cost")]) : 0,
            author:result[columnIndex("author")],
            brand:result[columnIndex("brand")],
            featured:result[columnIndex("featured")],
            price:result[columnIndex("price")],
            units:result[columnIndex("units")],
            unitsRemaining:result[columnIndex("unitsRemaining")],
            quantity:result[columnIndex("quantity")],
            type:result[columnIndex("type")],
            subtype:result[columnIndex("subtype")],
            status:result[columnIndex("status")],            
            salePrice:result[columnIndex("sale_price_compare")],
            salePriceEndDate:result[columnIndex("sale_price_end_date")],
            moreDetails:result[columnIndex("more_details")],
            keyword:result[columnIndex("keyword")],
            series:result[columnIndex("series")],
            set:result[columnIndex("set")],
            packs:result[columnIndex("packs")] ? parseInt(result[columnIndex("packs")]) : 0,
            limit:result[columnIndex("limit")],
            releaseDate:result[columnIndex("releaseDate")],
            allowPreorder:result[columnIndex("allow_preorder")],            
        }
        return item;
    }

    const inputObjectFromRow = (result) => {
        let item = {
            id:result[("id_")], // Updated from id to id_ , id used by google
            asin:result[("asin")],
            title:result[("title")],
            cost:result[("cost")] ? parseFloat(result[("cost")]) : 0,
            description:result[("description")],
            image:result[("image")],
            imageUrlKey:result[("imageUrlKey")],
            imageUrlHiResKey:result[("imageUrlHiResKey")],
            author:result[("author")],
            brand:result[("brand")],
            unitsRemaining:result[("unitsRemaining")],
            featured:result[("featured")],
            price:result[("price_number")], // Updated from price to price_ , price used by google
            units:result[("units")],
            type:result[("type")],
            subtype:result[("subtype")],
            status:result[("status")],            
            salePrice:result[("sale_price_compare")],
            salePriceEndDate:result[("sale_price_end_date")],
            moreDetails:result[("more_details")],
            keyword:result[("keyword")],
            series:result[("series")],
            set:result[("set")],
            weight:result[("weight")],
            packs:result[("packs")] ? parseInt(result[("packs")]) : 0, 
            limit:result[("limit")],
            releaseDate:result[("releaseDate")],
            allowPreorder:result[("allow_preorder")]
         }
         
        if (result[("update_quantity")] && result[("update_quantity")] == "TRUE") {
            item["quantity"] = result[("quantity")];
        } else {

        }
        

        return item;
    }

    async function performUpdateActionOnItem(item) {
        delete(item["edited"]);
        await API.graphql(graphqlOperation(updateProduct, { input:item })).then(result=>{console.log(result)}).catch(e=>{console.log(e)});
    }

      async function performActionOnItem(item) {

        let action = item[columnIndex("action")];
        let id     = item[columnIndex("id")];
 
        if (action == "A") {
            await API.graphql(graphqlOperation(createProduct, { input:inputObjectFromResults(item) }))
        }else if (action == "U") {
            let inputObject = inputObjectFromResults(item);        
            await API.graphql(graphqlOperation(updateProduct, { input:inputObject })).then(result=>{console.log(result)}).catch(e=>{console.log(e)})            
        }
        else if (action == "D") {
            await API.graphql(graphqlOperation(deleteProduct, { input:{id:id}}))
        } else {
            console.log("No action found")
        }
        console.log("End action");

      }
      
      async function dataHandler(data) {
            console.log("Data handler:", data);
            let validData = false;
            let results = [];
            dataColumnNameIndexes = {};
            for (let i = 0; i < data.length; i++) {
                let row = data[i];
                if (i == 0) {                                          
                    for (let x = 0; x < row.length; x++) {                        
                        dataColumnNameIndexes[row[x]] = x;
                    }
                    validData = checkForValidData(row);
                } else {
                    if (validData) {
                        results.push(row);
                    }
                }
            }

            for(let i = 0; i < results.length; i++) {
                try {
                    if (!results[0] || !results[1]) return;                    
                    await performActionOnItem(results[i]);
                } catch (err) {
                    console.log('error performaing action on item:', err)
                }                    
            }
      }


    function displayAddSingleButton() {
        return (<div style={{float:"right"}}> 
        <Button                                     
       className="modal-button"
       size="large" color="primary" variant="contained"
       onClick={()=>{
           setDisplayAddSingle(true)
       }}>
           Update Singles
       </Button>
   </div>);
    }

    function displayOpenMenuButton() {
        return (<div className="menu-button" style={{margin:"1em"}}> 
        <Button                                     
       className="modal-button"
       size="small" color="custom" variant="contained"
       onClick={()=>{
           setDrawerOpen(true);
       }}>
           <MenuIcon/>
       </Button>
   </div>);
    }

    const saveAll = async ()=> {
        
        // inputObjectFromResults
        for (let i = 0; i < updatedProductPrices.length; i++) {
            let product = updatedProductPrices[i]["update"];
            await performUpdateActionOnItem(product);
            await delay(400);
        }
        // saveData
    }


    async function downloadImagesFromUrlUploadSizes(defaultKey, extension, url, imageSizes) {     
        
        let result = await axios.get(url, {
          responseType: 'arraybuffer'
        })
        .then(response => {
          const buffer = Buffer.from(response.data, 'base64');
          
          return (async () => {
            let type = (await FileType.fromBuffer(buffer)).mime
            
            for (let i = 0; i < imageSizes.length; i++) {   
                let imageSize = imageSizes[i];
                let key = Object.keys(imageSize);
                let size = imageSize[key];
                let resizedBuffer = await createThumbnailBuffer(size, buffer);
                let resizedFileKey = defaultKey + key + extension;
                if (resizedBuffer) {
                    let resizedFileType = await FileType.fromBuffer(resizedBuffer);                    
                    if (resizedFileType) {                        
                        let resizedUploadResult = await uploadAttachmentToS3(resizedFileKey, resizedFileType.mime, resizedBuffer)                        
                    }else {
                        console.log("No resized file type");
                    }                    
                }else {
                    console.log("No resize buffer found");
                }
                
            }

            let uploadResult = await uploadAttachmentToS3(defaultKey + extension, type, buffer)
            //Create Thumbnails
            
            return uploadResult;
          })();
        })
        .catch(err => {
          return {type: 'error', err: err}
        });  
        return result;
      }




      
    const createThumbnailBuffer = async (size, originalBuffer) => {
         let result = await Jimp.read(originalBuffer).then(async image => {
            let imageResized;                
            image.resize(size, Jimp.AUTO).quality(100).getBuffer(Jimp.MIME_PNG, function (err, src) {                    
                imageResized = src;
            });               
            if (imageResized) {
                return imageResized;
            }else {
                console.log("Image resize not found")
            }
  }).catch(err => {
    console.error(err);
  });
  return result;

      }


      async function uploadAttachmentToS3(fileKey, type, buffer) {        
        try {
            let storageResult =  await Storage.put(fileKey, buffer, {
                level: 'public',
                contentType: type,
                bucket: aws_config.aws_user_files_s3_bucket, 
                region:aws_config.aws_user_files_s3_bucket_region,
                identityPoolId:aws_config.aws_user_pools_id
            }).then((response) => {
                console.log("RESPONSE:", response) ;
                return response;
            }).catch((e)=>{
                console.log("ERROR:", e);
            }); 
            return storageResult;
        }
        catch (e) {
            console.log(e);
        }

        // return s3.upload(params).promise().then((response) => {
        //   return response.Location
        // }, (err) => {
        //   return {type: 'error', err: err}
        // })
      }
      
    async function getImageBufferFromUrl (url) {
        let result = await axios.get(url, {
            responseType: 'arraybuffer'
          })
          .then(response => {            
            const buffer = Buffer.from(response.data, 'base64');
            return buffer;
          }).catch(e =>{console.log(e); return null;});
          return result;
    }


    async function downloadSetImages() {

        // set.logoUr

        for (let i = 0; i < cardSets.length; i++) {
            let cardSet = cardSets[i];
            
            let logoKey = "sets/"+cardSet.id +".png";
            let logoBuffer = await getImageBufferFromUrl(cardSet.images.logo);
            let logoType = (await FileType.fromBuffer(logoBuffer)).mime

            await uploadAttachmentToS3(logoKey, logoType, logoBuffer);
            
            let symbolKey = "sets/" + cardSet.id + "_symbol" + ".png";
            let symbolBuffer = await getImageBufferFromUrl(cardSet.images.symbol);
            let symbolType = (await FileType.fromBuffer(symbolBuffer)).mime

            await uploadAttachmentToS3(symbolKey, symbolType, symbolBuffer);

        }
    //     cardSets.map((set, index) => {
    //         return (
    //         <MenuItem value={index + 1}>
                
    //             { set ? displaySet(set) : <>N/A</> }
    //         </MenuItem>
    //         )
    //     })
    // }


    }

    const createThumbnail = async (url, size)=>{
        let result = await axios.get(url, {
            responseType: 'arraybuffer'
          })
          .then(async response => {
            const buffer = Buffer.from(response.data, 'base64');
            let resizedBuffer = await createThumbnailBuffer(size, buffer);
            return resizedBuffer;
          }).catch(e=>{console.log(e)});

          return result;
    }


    const createThumbnailsForSelection = async(products)=>{

        for (let i = 0; i < products.length; i++) {            
            let product = products[i];
            if (product.imageThumbBase64 == null) {
                var re = /(?:\.([^.]+))?$/;
                let extensionString = re.exec(product.imageAsset);
                let imageKey = product.imageAsset.replace(extensionString[0],""); 
                imageKey = imageKey + (".png");
                let resultUrl = await Storage.get(imageKey, { level: 'public', bucket: aws_config.aws_user_files_s3_bucket, 
                  region:aws_config.aws_user_files_s3_bucket_region});                       

                  console.log("resultUrl:", resultUrl);
                  if (resultUrl) {
                    let result = await createThumbnail(resultUrl, 32);
                    let base64String = result.toString('base64');
                    await performUpdateActionOnItem({id:product.id, imageThumbBase64:base64String})        
                }    
            }     
        }
    }

    async function updateImagesToStorage(cards) {
    
        for (let i = 0; i < cards.length; i++) {
            let card = cards[i];
            let defaultKey = card.imageUrlKey;
            var re = /(?:\.([^.]+))?$/;
            let extensionString = re.exec(defaultKey);

            if (card.imageAsset == null) {                
                let location = await downloadImagesFromUrlUploadSizes(defaultKey.replace(extensionString[0],""), extensionString[0], "https://www.yaypokemon.com/images/cards/" + card.imageUrlHiResKey, 
                [{"_120" : 120},
                {"_200" : 200},
                {"" : 400},
                {"_hires" : 700}])
                if (location && location.key) {
                    await performUpdateActionOnItem({...card, imageAsset:location.key})
                }
            } else {
                console.log("Image asset found", card.imageAsset)
            }
            
            // const file = e.target.files[0];

            // const extension = file.name.split(".")[1];
            // const name = file.name.split(".")[0];

            // const key = `images/${uuidv4()}${name}.${extension}`;
            // const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`

            // try {
            //     // Upload the file to s3 with private access level. 
            //     await Storage.put(key, file, {
            //         level: 'public',
            //         contentType: file.type
            //     });
            //     // Retrieve the uploaded file to display
            //     // const image = await Storage.get(key, { level: 'public' })
            //     // setImage(image);
            //     // setProductDetails({ ...productDetails, image: url });
            // } catch (err) {
            //     console.log(err);
            // }




        }
    }


    async function getMarketPricesForSet(setName) {

        setSyncing(true);
        setBusy(true);

        await API.post("yayfunthingsapi", "/items/getLatestMarketPrices", { 
            body:{setName:setName}
        }).then(result=>{
            // setMarketPrices(result);
            processMarketPrices(result);
        }).catch(e=>{
            console.log(e);
        });


        setSyncing(false);
        setBusy(false);
        
    }


    
    async function setAmazonProductPrice(productMSKU, productPrice) {

        console.log("setAmazonProductPrice:",productMSKU, "productPrice:",productPrice );

        setSyncing(true);
        setBusy(true);

        await API.post("yayfunthingsapi", "/items/SubmitFeed", { 
            body:{productMSKU, productPrice}
        }).then(result=>{

            console.log("Result:",result);
            // setMarketPrices(result);
            // processMarketPrices(result);
        }).catch(e=>{
            console.log(e);
        });


        setSyncing(false);
        setBusy(false);

    }

    function processMarketPrices(result) {        
        for (let i = 0; i < result.length; i++) {
            let item = result[i];
            item.marketPrice = item.marketPrice.replace(/\n                                     /g,'');
            item.marketPrice = item.marketPrice.replace(/\n                            /g,'');

            item.medianPrice = item.medianPrice.replace(/\n                                     /g,'');//item.medianPrice.replaceAll("\n                                     ","");
            item.medianPrice = item.medianPrice.replace(/\n                            /g,'');//item.medianPrice.replaceAll("\n                                     ","");
            
            item.number = item.number.replace(/\n                                /g,''); //item.number.replaceAll("\n                                     ","");
            item.number = item.number.replace(/\n                            /g,''); //item.number.replaceAll("\n                                     ","");

            item.rarity = item.rarity.replace(/\n                                /g,'');
            item.rarity = item.rarity.replace(/\n                            /g,'');

            item.title = item.title.replace(/\n                                /g,'');
            item.title = item.title.replace(/\n                            /g,'');            
        }

        updateProductsWithMarketPrices(result);

    }


    const zeroPad = (num, places) => String(num).padStart(places, '0')


    function updateProductsWithMarketPrices(prices) {

        let currentProductSingles = {...productSingles};

        console.log("updateProductsWithMarketPrices:::", prices);

        let updatedProductPricesToCheck = []

        console.log("selectedSetName:", selectedSetName);

        console.log(currentProductSingles[selectedSetName]);

        if (prices) {

            for (let i = 0; i < currentProductSingles[selectedSetName].cards.length; i++) {
            
                let card = currentProductSingles[selectedSetName].cards[i];       
                let cardNumberString = zeroPad(card.number, 3) + "/" + zeroPad(card.setTotal, 3);
             
                for (let x = 0; x < prices.length; x++) {
                    let price = prices[x];     
                    
                    if (price.number == cardNumberString) {

                        let marketPrice = price.marketPrice.replace("$", "");
                        let medianPrice = price.medianPrice.replace("—", "").replace("$","");                                                
                        let currentCard = {...card};

                        card.marketPrice = marketPrice;
                        card.medianPrice = medianPrice;

                        if (parseFloat(card.marketPrice) != parseFloat(price.marketPrice)) {
                            updatedProductPricesToCheck.push({current:currentCard, update:card});
                        }                                                
                    }
                }
                // updateSinglesData(card.id, card)        
            }
            if (updatedProductPricesToCheck.length > 0) {
                console.log("Set updated prices:", updatedProductPricesToCheck);
                setUpdatedProductPrices(updatedProductPricesToCheck);
                setDisplayCompareUpdate(true);
            } 
                
            forceUpdate();
        }
        
    }



    function displayAddAsinProduct() {
        return (<div style={{float:"right"}}> 
        {
        currentTab == "Products" ? 
        <>
            <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={()=>{
                        setDisplayAddAsin(true);
                    }}>
                        Add ASIN
                    </Button>
        </>:<></>
        }
        </div>)
    }

    const displayUpdateResults = ()=> {

        console.log("updatedResults:", updatedResults);

        return (
            <>
            {
                updatedResults.map(updateResult => {
                    return (<>
                    <div>
                        <div style={{padding:"1em"}}>
                        
                            <img src={"./"+updateResult.item.image} style={{width:"60px"}}/>
                            <p>{updateResult.item.title}</p>                            
                            <p className={updateResult.result == "Success"? "result-success" :"result-failure"}>{updateResult.result}</p>
                        </div>

                      </div>
                   </>)
                })
            }

              {
                  updatedResults && updatedResults.length == 0 ? <><div style={{width:"400px", height:"400px"}}><p>No updates</p></div></> : <></>
              }  
            </>
        );
    }


    const updateProductMarketPrice = async (product, asin)=>{
        // product.CompetitivePricing.CompetitivePrices.CompetitivePrice

        console.log("updateProductMarketPrice:", product);

        if (product.LowestOfferListings) {
            if (product.LowestOfferListings.LowestOfferListing ){

                let updatedProducts = [];
                let updateCreationTime = moment().toISOString();

                let lowestListings = product.LowestOfferListings.LowestOfferListing;
                let lowestOffer = 0;
                for (let i = 0; i < lowestListings.length; i++) {
                    if ( i == 0) {
                        let offer = lowestListings[i];
                        lowestOffer = offer.Price.LandedPrice.Amount;
                    }
                }

                if (lowestOffer) {

                    for (let i = 0; i < products.length; i++) {
                        let p = products[i];
                        if (p.asin == asin) {
                            console.log("Found match:", asin, "lowestOffer:", lowestOffer);                            
                            p.marketPrice = lowestOffer;
                            p.medianPrice = (lowestOffer - (lowestOffer * 0.15)).toFixed(2);
                           await updateProductData(p.id, {marketPrice:lowestOffer, medianPrice:p.medianPrice})
                           await createAnalyticsInput({id:p.id, type:'Price', instockQty:p.unitsRemaining, createdAt:updateCreationTime, amazonPrice:lowestOffer});
                        }
                    }

                    
                }

            }
        }

    }

    const processPricingResults = async (result)=> {

        console.log("PROCESS PRICING RESULTS:", result);

        // if (result && result.data && result.data.GetCompetitivePricingForASINResponse) {
        //     let data = result.data.GetCompetitivePricingForASINResponse;
        //     if (data && data.GetCompetitivePricingForASINResult) {
        //         for (let i = 0; i < data.GetCompetitivePricingForASINResult.length; i++) {
        //             let productData = data.GetCompetitivePricingForASINResult[i];
        //             let productAsin = productData['$'].ASIN;
        //             let product = productData['Product'];
        //             if (product) {
        //                 // await updateProductData(product)
        //             }
        //         }
        //     }
        // }

        if (result && result.data && result.data.GetLowestOfferListingsForASINResponse) {
            let data = result.data.GetLowestOfferListingsForASINResponse;
            if (data && data.GetLowestOfferListingsForASINResult) {
                for (let i = 0; i < data.GetLowestOfferListingsForASINResult.length; i++) {
                    let productData = data.GetLowestOfferListingsForASINResult[i];
                    let productAsin = productData['$'].ASIN;
                    let product = productData['Product'];
                    if (product) {
                        await updateProductMarketPrice(product, productAsin);
                    }
                }

                forceUpdate();
            }
        }
    }



    const pushInventoryToGoogleSheet = async ()=>{
        let sheet = await getSheetByTitle("Inventory");
        if (sheet) {

            try {
                const rows = await sheet.getRows(); // can pass in { limit, offset }
                // Load the cell range first
                let loadCellsString = 'C2:E' + (rows.length + 1);
                await sheet.loadCells(loadCellsString);

                for (let i = 0; i < rows.length; i++) {
                    let row = rows[i];
                    for (let x = 0; x < products.length; x++) {
                        let product = products[x];
                        if (product.id == row["id_"]) {
                            // await row.saveUpdatedCells();
                            let getCellString = 'C'+(i+2);
                            // console.log("getCellString:", getCellString);   
                            const yourcell = sheet.getCellByA1(getCellString);
                            yourcell.value = parseInt(product.quantity ? product.quantity : 0);
                            let getCellPrice = 'D'+(i+2);
                            // console.log("getCellString:", getCellString);   
                            const yourcellPrice = sheet.getCellByA1(getCellPrice);
                            yourcellPrice.value = parseFloat(product.price ? product.price : 0);

                            let getCellUnitsRemaining = 'E'+(i+2);
                            const yourcellUnitsRemaining = sheet.getCellByA1(getCellUnitsRemaining);
                            yourcellUnitsRemaining.value = parseFloat(product.unitsRemaining ? product.unitsRemaining : 0);

                        }
                    }                    
                }

                sheet.saveUpdatedCells();


                // await sheet.loadCells('A1:D5');
                // Then you save the specific cell into a variable.
                // const yourcell = await sheet.getCellByA1('A1');
                // Then you can change the value.
                // yourcell.value = 'The string you want to write'
                
                // And finally you have to save it ether with:
                // await yourcell.save()
                // Or
                // sheet.saveUpdatedCells();

//                 await sheet.loadCells('A1:E10'); // loads a range of cells
// console.log(sheet.cellStats); // total cells, loaded, how many non-empty
// const a1 = sheet.getCell(0, 0); // access cells using a zero-based index
// const c6 = sheet.getCellByA1('C6'); // or A1 style notation
// // access everything about the cell
// console.log(a1.value);
// console.log(a1.formula);
// console.log(a1.formattedValue);
// // update the cell contents and formatting
// a1.value = 123.456;
// c6.formula = '=A1';
// a1.textFormat = { bold: true };
// c6.note = 'This is a note!';
// await sheet.saveUpdatedCells(); // save all updates in one call


// // read/write row values
// console.log(rows[0].name); // 'Larry Page'
// rows[1].email = 'sergey@abc.xyz'; // update a value
// await rows[1].save(); // save updates
// await rows[1].delete(); // delete a row

                // await sheet.updateRows()
                // const moreRows = await sheet.addRows([product]).then(results=>{console.log(results)}).catch(e=>{
                    // console.log(e)        
                    // SET ERROR MESSAGE HERE 
                // });        
            }
            catch (e) {
                console.log(e);
            }
            

        } else {    
            console.log("DID NOT FIND SHEET ", cardSetSelected);
        }        
    }


    const saveAllProducts = async ()=>{        
        for (let i = 0; i < products.length; i++) {
            let product = products[i];
            await performUpdateActionOnItem(product);
            await delay(350);
        }
    }

    const calculateProfits = async ()=>{

    }


    const getMarketPrices = async ()=>{

        let asins = []
        for (let i = 0; i < products.length; i++) {
            let asin = products[i].asin;
            if (asin && asin.length > 3) {
                let alreadyAdded = false;
                for (let x = 0; x < asins.length; x++) {
                    if (asins[x] == asin) {
                        alreadyAdded = true;
                    }
                }
                if (!alreadyAdded) {
                    asins.push(asin)
                }
                
            }            
        }

        let asinGroups = GetGroupsOfItems(asins, 20);

        for (let i = 0; i < asinGroups.length; i++) {
            let asinList = asinGroups[i];
            await API.post("yayfunthingsapi", "/items/GetLowestOfferListingsForASIN", { 
                body:{asinList:asinList}
            }).then(async result=>{
                await processPricingResults(result);
            }).catch(e =>{console.log(e);            
                console.log("Error");
                sendAlertMessage("Error getting market prices.")
            });
            await delay(1000)
        }


    }

    function displayActionButtons() {    
                
    return (<div className="admin-buttons" style={{float:"right", paddingTop:"1em", paddingBottom:"2em", paddingLeft:"2em", paddingRight:"2em"}}> 
        {
        currentTab == "Products" ? 
        <>

                    <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{     
                            setDisplayAddAsin(true);
                        }}>
                        Add Product
                    </Button>

                    <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{     
                        setSyncing(true);                       
                        await getMarketPrices();
                        setSyncing(false);
                        }}>
                        Get Market Prices
                    </Button>

                    <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{
                        let result = await updateSheetRows(inventorySheet);
                        setDisplayResults(true);
                    }}>
                        Sync Google Sheet
                    </Button>

                  
                    <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{
                        setSyncing(true);                       
                        await calculateProfits();
                        setSyncing(false);
                    }}>
                        Calculate Profits
                    </Button>

                    <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{
                        setSyncing(true);                       
                        await saveAllProducts();
                        setSyncing(false);
                    }}>
                        Save All Products
                    </Button>



                      <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{
                        pushInventoryToGoogleSheet();
                    }}>
                        Push To Google
                    </Button>

            

                    {/* <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{
                        await setAmazonProductPrice("POKEMON-TCG-TRAINERS-TOOLKIT", "39.00");
                    }}>
                        Set Amazon Price
                    </Button> */}

                    


        </>:<></>
        }

    {
        currentTab == "Cards" ? 
        <div style={{paddingTop:"3em"}}>
           <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{
                        console.log("Selected :", selectedSetName)
                        await getMarketPricesForSet(selectedSetName);
                    }}>
                        Market Prices
            </Button>


            <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{
                        await saveAll();
                    }}>
                        Save All
            </Button>

             <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{
                        let sheet = await getSheetRowsForSet(getSetCodeByName(selectedSetName));
                        console.log("getSheetRowsForSet:", getSetCodeByName(selectedSetName), sheet)
                        if (sheet) {
                            // updateSheetRows(sheet);
                        }
                    }}>
                        Sync {selectedSetName} Google Sheet
                    </Button>

                    {displayAddSingleButton()}

                    {/* <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{
                        await updateImagesToStorage(productSingles[selectedSetName].cards);
                    }}>
                        Update Images
                    </Button> */}


                    <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{
                        await createThumbnailsForSelection(productSingles[selectedSetName].cards);
                    }}>
                        Create Thumbs
                    </Button>

                
                    
                    {/* <Button                                     
                    className="modal-button"
                    size="large" color="primary" variant="contained"
                    onClick={async ()=>{
                        await downloadSetImages();
                    }}>
                        Download Set Images
                    </Button> */}

       </div>:<></>
    }
            </div>);
    }


    async function getSheetRowsForSet(title){
        if (!busy) {
            let sheet = await getSheetByTitle(title);
            return sheet;
        }
        return null;
    }


    async function updateSheetRows(sheet) {

        setSyncing(true);
        setBusy(true);

        // let credentials = await Auth.currentCredentials();        
        // AWS.config.update({region: 'us-west-2', credentials:credentials});
        // const documentClient = new AWS.DynamoDB.DocumentClient();
        let rows = await sheet.getRows();
        let deleteRows = [];
        let addRows = [];
        let updateRows = [];
        let results = [];

        console.log("Rows", rows);

        try {

            // setProgressCurrent(0);
            // setProgressTotal(rows.length);

        for (let i = 0; i < rows.length; i++) {

            let row = rows[i];
            let action = row["action"];        
            
            if (action == "D") {
                let inputObject = inputObjectFromRow(row);
                await API.graphql(graphqlOperation(deleteProduct, { input:{id:inputObject.id}}));
                deleteRows.push(inputObject);
                results.push({
                    name:"Deleted Item Successfully",
                    result:"Success",
                    item:inputObject
                })
            }
            
            if (action == "A") {
                console.log("ADD:", row);
                let inputObject = inputObjectFromRow(row);
                console.log("inputObject:", inputObject);
                try {

                let result = await API.graphql(graphqlOperation(createProduct, { input:inputObject}));
                if (result) {
                    console.log("RESULT:",result);
                    addRows.push(inputObject);
                    results.push({
                        name:"Added Item Successfully",
                        result:"Success",
                        item:inputObject
                    })
                }else {
                    console.log("RESULT:",result);
                }
                }catch (e){
                    console.log("ERROR::::", e);
                }   
            }
            if (action == "U") {
                let inputObject = inputObjectFromRow(row);
                
                if (inputObject) {

                    inputObject["updatedAt"] = moment().toISOString();
                  
                    //Delete quantity update
                    if (row["update_quantity"] != "TRUE") {
                        delete(inputObject["quantity"]);
                    }
                    
                    console.log("inputObject:", inputObject);
                    await API.graphql(graphqlOperation(updateProduct, { input:inputObject })).then(result=>{console.log(result);
                    
                        results.push({
                            name:"Updated Item Successfully",
                            result:"Success",
                            item:inputObject
                        })

                    }).catch( async e=>{
                        
                        console.log(e);

                        if (e.errors && e.errors.length > 0) {
                            if (e.errors[0].errorType == "DynamoDB:ConditionalCheckFailedException") {

                                let result = await API.graphql(graphqlOperation(createProduct, { input:inputObject}));
                                if (result) {
                                    console.log("RESULT:",result);
                                    addRows.push(inputObject);
                                    results.push({
                                        name:"Added Item Successfully",
                                        result:"Success",
                                        item:inputObject
                                    })
                                }else {
                                    console.log("RESULT:",result);
                                }
                
                            }
                        }
                    
                    })            

                    updateRows.push(inputObject);    
                }
            }

            // setProgressCurrent(i);

            if (action != "I") {
                await delay(1000);
            }            
        }/// end of loop

            
        } catch (e) {
            console.log("ERROR:", e);
        }
     
        setUpdatedResults(results);
        console.log("addRows", addRows);
        // setProgressCurrent(rows.length);
        setBusy(false);
        setSyncing(false);
    }  

    
    const viewDetailsCallback = (order) => {
        if (order) {
            
            console.log("viewDetailsCallback order:", order);

            
            setOrderDetailShown({...order});

            if (order.paymentMethod == "PayPal") {

            }else {

                // console.log("order", order)
                // delete(order.paymentIntentId);

                if (order.paymentIntentId) {
                    API.post("yayfunthingsapi", "/items/checkPaymentIntent", { 
                        body:{paymentIntentId:order.paymentIntentId}
                    }).then(async result=>{
        
                       console.log("capturePayment Result:", result);
                       console.log("order:", order);
                       order["paymentStatus"] = result.status;
                       setOrderDetailShown({...order,paymentStatus:result.status });
        
                    }).catch(e =>{console.log(e);
                    
                        setOrderDetailShown({...order,paymentStatus:"Unknown" });
                    });
                } else {
                    setOrderDetailShown({...order,paymentStatus:"Unknown" });
                }

            }
            

        } else {
            setOrderDetailShown(null)
        }        
    }


    const sendOrderRefundedEmail = async (order, refund) => {
        let productsString  =  ""; 
        console.log("sendOrderCancelledEmail", order);

        if (order) {

        await API.post("yayfunthingsapi", "/items/sendEmail", { 
            body: 
            {  
               email:order.customer, 
               data:`<div style="border:1px solid #ccc; max-width:680px;"> 
               <div style="background-color:#2196f3; background-repeat:no-repeat; background-image:url(https://www.yaypokemon.com/images/yayfun-head.svg)">         
                <p style="text-align:center; margin:0em; color:#fff; height:3em; font-size:1.5em; padding-top:1em; font-weight:bold;"> Refunded Order #${order.number}</p>                   
               </div>
               
               <div style="margin:2em">
                    <div><p style="font-weight:bold; margin-top:0.5em;margin-bottom:0.1em">Order No. <span style="font-weight:normal">${order.number}</span></p>
                    <p style="margin-top:0em;margin-bottom:0.5em"><span style="font-weight:normal;">Order Date:${moment(order.date).format("MMMM DD, YYYY")}</span></p>
                    
                    <p style="margin-bottom:0.1em">Date:${moment(order.created).format("MMMM DD, YYYY")}</p>
                    <p style="margin-bottom:0.1em">Amount:${ConvertStringToDollarsCents(refund.amount/100)} ${refund.currency}</p>
                    <p style="margin-bottom:0.1em">Transaction ID:${refund.balance_transaction}</p>
                    <p style="margin-bottom:0.1em">Charge ID:${refund.charge}</p>

                    </div>
                    <div style="padding-top:1em;margin-bottom:0em">`+
               '<p>This email was sent by <a href="https://www.yaypokemon.com/">yaypokemon.com</a></p></div>'+
               '<div></div>', 
               subject:`Order #${order.number} has been cancelled` } }).then(result => {
                        console.log(result)
        }).catch(e=>{console.log(e)});
        }
    }


    const sendOrderCancelledEmail = async (order, reason)=> {
        
        let productsString  =  ""; 
        console.log("sendOrderCancelledEmail", order);

        if (order) {
        //     for (let i = 0; i < order.products.items.length; i++) {                                        
        //         let orderItem = order.products.items[i];
        //         console.log("orderItem,",orderItem);
        //         productsString += '<div>';
        //         productsString += '<img src=' + orderItem.product.image + ' alt="cart item" />';                          
        //         productsString += '</div>';
        //         productsString += '<div className="cart-product">';
        //         productsString += '<p> '+ orderItem.product.title +'</p>';
        //         productsString += '<p> '+ orderItem.amount +'</p>';
        //         productsString += '<p> '+ ConvertStringToDollarsCents(orderItem.price) +'</p>';
        //         productsString +=  '</div>';
        // }                                    
        
        await API.post("yayfunthingsapi", "/items/sendEmail", { 
            body: 
            {  
               email:order.customer, 
               data:`<div style="border:1px solid #ccc; max-width:680px;"> 
               <div style="background-color:#2196f3; background-repeat:no-repeat; background-image:url(https://www.yaypokemon.com/images/yayfun-head.svg)">         
                <p style="text-align:center; margin:0em; color:#fff; height:3em; font-size:1.5em; padding-top:1em; font-weight:bold;"></p>                   
               </div>
               
               <div style="margin:2em">
                    <div><p style="font-weight:bold; margin-top:0.5em;margin-bottom:0.1em">Order No. <span style="font-weight:normal">${order.number}</span></p>
                    <p style="margin-top:0em;margin-bottom:0.5em"><span style="font-weight:normal;">Order Date:${moment(order.date).format("MMMM DD, YYYY")}</span></p>
                    
                    <p style="margin-bottom:0.1em">We\'re sorry we had to cancel your order.</p>
                    <p style="margin-bottom:0.1em">${reason}</p>

                    </div>
                    <div style="padding-top:1em;margin-bottom:0em">`+
               '<p>This email was sent by <a href="https://www.yaypokemon.com/">yaypokemon.com</a></p></div>'+
               '<div></div>', 
               subject:`Order #${order.number} has been cancelled` } }).then(async result => {
                        console.log(result);
                        await insertOrderHistoryChange(order, "Sent Order Cancelled");

        }).catch(async e=>{console.log(e);
            await insertOrderHistoryChange(order, "Order Cancelled Failed");
        });
        }
      
    }

    const sendEmailCode = async (order, codes) => {

        if (order) {

            console.log("sendEmailCode email:", order.customer);

            let codeString  =  ""; 
            for (let i = 0; i < codes.length; i++) {                                        
                    let codeItem = codes[i];
                    codeString += '<p> <span style="font-weight:bold;">'+ codeItem.name + '</span>:' + codeItem.value + '</p>';
            }                                    
            

            // let isnum = /^\d+$/.test(trackingNumber);
            // let trackUrl = '';

            // if (isnum) {
            //     trackUrl = 'https://tools.usps.com/go/TrackConfirmAction.action?tLabels='+trackingNumber
            // }
            // https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=9405511108400996476876



            await API.post("yayfunthingsapi", "/items/sendEmail", { 
                body: 
                {  
                   email:order.customer, 
                   data:`<div style="border:1px solid #ccc; max-width:680px;"> 
                   <div style="background-color:#2196f3; background-repeat:no-repeat; background-image:url(https://www.yaypokemon.com/images/yayfun-head.svg)">         
                    <p style="text-align:center; margin:0em; color:#fff; height:3em; font-size:1.5em; padding-top:1em; font-weight:bold;">Pokemon TCG codes </p>                   
                   </div>

                   <div style="margin:2em">                   
                        <p style="margin-top:0.1em">Order #:${order.number}</p>  
                        <p style="margin-bottom:0.1em">Date:${moment(order.created).format("MMMM DD, YYYY")}</p>                      
                        <div style="padding-top:1em;margin-bottom:0em">                        
                            ${codeString}
                        </div>                        
                   `+
                   '<p style="border-top:2px solid #ccc;margin-top:4em; padding-top:1em">Need help with your order?</p><p><a href="https://www.yaypokemon.com/contact/">Contact us</a> and we will get back to you as soon as we can.</p>'+
                   '<p>Track your order <a href="https://www.yaypokemon.com/track/">here</a>.</p>'+
                   '<p>This email was sent by <a href="https://www.yaypokemon.com/">yaypokemon.com</a></p></div>'+
                   '<div></div>', 
                   subject:"Your codes are here!" } }).then(result => {
                            console.log(result);
            }).catch(e=>{console.log(e)});
        }
    }

    // 
    // const 

    const sendCustomerEmailCustom = async() => {

    
        // console.log("Send customer email:", order);

        // Arvizo22@gmail.com

        await API.post("yayfunthingsapi", "/items/sendEmail", { 
            body: 
            {  
               email:formData.customEmail, 
               data:`<div style="border:1px solid #ccc; max-width:680px;"> 
               <div style="background-color:#2196f3; background-repeat:no-repeat; background-image:url(https://www.yaypokemon.com/images/yayfun-head.svg)">         
                <p style="text-align:center; margin:0em; color:#fff; height:3em; font-size:1.5em; padding-top:1em; font-weight:bold;"> Order Inquiry </p>                   
               </div>
               
               <div style="margin:2em">
                    <div style="padding-top:1em;margin-bottom:0em">                    
                    ${formData.emailMessage} 
                    </div>
                    
                      <div>
                      </div>                    
               ` +
            //    '<div style="border-top:1px solid #ccc; font-size:0.8em;margin-top:1em"><p>Thank you for shopping with us!</p>'+
               '<p>Need help with your order?</p><p><a href="https://www.yaypokemon.com/contact/">Contact us</a> and we will get back to you as soon as we can.</p>'+
               '<p>Track your order <a href="https://www.yaypokemon.com/track/">here</a>.</p>'+
               '<p>This email was sent by <a href="https://www.yaypokemon.com/">yaypokemon.com</a></p></div>'+
               '<div></div>', 
               subject:"Order Inquiry" } }).then(async result => {
                        console.log(result);
                        // await insertOrderHistoryChange(order, "Email Sent:" + formData.emailMessage);
        }).catch(e=>{console.log(e)});
    }


    const sendCustomerEmail = async(order) => {

    
        console.log("Send customer email:", order);


        await API.post("yayfunthingsapi", "/items/sendEmail", { 
            body: 
            {  
               email:order.customer, 
               data:`<div style="border:1px solid #ccc; max-width:680px;"> 
               <div style="background-color:#2196f3; background-repeat:no-repeat; background-image:url(https://www.yaypokemon.com/images/yayfun-head.svg)">         
                <p style="text-align:center; margin:0em; color:#fff; height:3em; font-size:1.5em; padding-top:1em; font-weight:bold;"> Order Inquiry </p>                   
               </div>
               
               <div style="margin:2em">
                    <div>
                    <p style="font-weight:bold; margin-top:0.5em;margin-bottom:0.1em">Order No. <span style="font-weight:normal">${order.number}</span></p>
                    <p style="font-weight:bold; margin-top:0em;margin-bottom:0.5em">Order Date: <span style="font-weight:normal;">${moment(order.date).format("MMMM DD, YYYY")}</span></p>
                    </div>
                    <div style="padding-top:1em;margin-bottom:0em">                    
                    ${formData.emailMessage} 
                    </div>
                    <p style="font-weight:bold; padding-top:1em">Order Shipping Address</p>
                      <div>
                        <div>
                          <ul style="list-style-type:none;">
                              <li>${order.shippingAddress.name}</li>
                              <li>${order.shippingAddress.addressLine1}</li>
                              <li>${order.shippingAddress.addressLine2}</li>
                              <li>${order.shippingAddress.city}, ${order.shippingAddress.state}  ${order.shippingAddress.postalCode} ${order.shippingAddress.country}</li>
                              </>                                
                          </ul>
                        </div>
                      </div>                    
               ` +
               '<div style="border-top:1px solid #ccc; font-size:0.8em;margin-top:1em"><p>Thank you for shopping with us!</p>'+
               '<p>Need help with your order?</p><p><a href="https://www.yaypokemon.com/contact/">Contact us</a> and we will get back to you as soon as we can.</p>'+
               '<p>Track your order <a href="https://www.yaypokemon.com/track/">here</a>.</p>'+
               '<p>This email was sent by <a href="https://www.yaypokemon.com/">yaypokemon.com</a></p></div>'+
               '<div></div>', 
               subject:"Order Inquiry" } }).then(async result => {
                        console.log(result);
                        await insertOrderHistoryChange(order, "Email Sent:" + formData.emailMessage);
        }).catch(e=>{console.log(e)});
    }



    const displayCheckTracking = ()=> {
        // checkTracking/    
    }

    const displayUpdateShippingPaid = ()=> {
        return (<>{displayInputField("shippingPaid", "black", "shippingPaid", orderDetailShown, null, null, setFormData)}</>)
    }


    const displaySendEmail = (order)=>{      
        return <AdminEmailPage email={order.customer}/>      
        return (<>

                    {/* {              
                            displayInputField("emailMessage", "black", "emailMessage", formData, null, null, setFormData)
                    }                           */}

                    {              
                            displayInputField("customEmail", "black", "customEmail", formData, null, null, setFormData)
                    }                          
                    {              
                            displayInputField("subject", "black", "subject", formData, null, null, setFormData)
                    }                          
                    {              
                            displayInputField("title", "black", "title", formData, null, null, setFormData)
                    }    

                    <Button          
                            className="modal-button"
                            size="small" color="primary" variant="contained"
                            onClick={async ()=>{

                        }}>Cancel</Button>

                    <Button          
                        className="modal-button"
                        size="small" color="primary" variant="contained"
                        onClick={async ()=>{          

                            if (window.confirm("Are you sure you want email the customer?")) {

                                // await sendCustomerEmail(order)

                             
                                // await sendCustomerEmailCustom();
                                
                                setDisplayEmailPreview(true);
                                setDisplayPreviewDetails({
                                    type:"Custom",
                                    email:formData.customEmail,
                                    subject:formData.subject,
                                    body:formData.body,
                                    title:formData.title
                                    }
                                )

    
                            }

                        }}>Email
                    </Button>
        </>)
    }
    // <meta data-src='//maps.googleapis.com/maps/api/js?key=AIzaSyCDXTIKP8_9Swd2vTyw4paM7gFao54xBXs&amp;libraries=places' id='googleMapAPI'/>

    const sendOrderReplacementShippedEmail = async (order, trackingNumber)=> {
        
        if (order) {

            console.log("order.email:", order.customer);

            let productsString  =  ""; 

            for (let i = 0; i < order.products.items.length; i++) {                                        
                    let orderItem = order.products.items[i];
                    productsString += '<div className="cart-product">';
                    productsString += '<p> '+ orderItem.amount + " x " + orderItem.product.title + " - " + ConvertStringToDollarsCents(orderItem.price) +'</p>';                    
                    productsString +=  '</div>';
            }                                    
            
            // productsString += '<div className="cart-product">';
            // productsString += '<p>1 x ' + "Pikachu V SWSH063 Promo Card (Complimentary)" + " - " + '$0.00</p>';                    
            // productsString +=  '</div>';


            let isnum = /^\d+$/.test(trackingNumber);
            let trackUrl = '';

            if (isnum) {
                trackUrl = 'https://tools.usps.com/go/TrackConfirmAction.action?tLabels='+trackingNumber
            }
            // https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=9405511108400996476876


            await API.post("yayfunthingsapi", "/items/sendEmail", { 
                body: 
                {  
                   email:order.customer, 
                   data:`<div style="border:1px solid #ccc; max-width:680px;"> 
                   <div style="background-color:#2196f3; background-repeat:no-repeat; background-image:url(https://www.yaypokemon.com/images/yayfun-head.svg)">         
                    <p style="text-align:center; margin:0em; color:#fff; height:3em; font-size:1.5em; padding-top:1em; font-weight:bold;"> Your replacement has shipped </p>                   
                   </div>
                   
                   <div style="margin:2em">
                        <div>
                        <p style="font-weight:bold; margin-top:0.5em;margin-bottom:0.1em">Order No. <span style="font-weight:normal">${order.number}</span></p>
                        <p style="font-weight:bold; margin-top:0em;margin-bottom:0.5em">Order Date <span style="font-weight:normal;">${moment(order.date).format("MMMM DD, YYYY")}</span></p></div>
                        <div style="padding-top:1em;margin-bottom:0em">
                        
                        <p style="margin-bottom:0.1em">Thank you for contacting us regarding your order. We have sent a replacement to you at no cost.</p>
                        <p style="margin-bottom:0.1em">Here's your tracking.</p>
                        
                        <p style="margin-top:0.1em">USPS Tracking:<a href='${trackUrl}' target='_blank'>${trackingNumber}</a></p></div>

                        <p style="font-weight:bold; padding-top:1em">Shipping Address</p>
                          <div>
                            <div>
                              <ul style="list-style-type:none;">
                                  <li>${order.shippingAddress.name}</li>
                                  <li>${order.shippingAddress.addressLine1}</li>
                                  <li>${order.shippingAddress.addressLine2}</li>
                                  <li>${order.shippingAddress.city}, ${order.shippingAddress.state}  ${order.shippingAddress.postalCode} ${order.shippingAddress.country}</li>
                                  </>                                
                              </ul>
                            </div>
                          </div>                   
                   ` + 
                   '<div style="border-top:1px solid #ccc; font-size:0.8em;margin-top:1em"><p>Thank you for shopping with us!</p>'+
                   '<p>Need help with your order?</p><p><a href="https://www.yaypokemon.com/contact/">Contact us</a> and we will get back to you as soon as we can.</p>'+
                   '<p>Track your order <a href="https://www.yaypokemon.com/track/">here</a>.</p>'+
                   '<p>This email was sent by <a href="https://www.yaypokemon.com/">yaypokemon.com</a></p></div>'+
                   '<div></div>', 
                   subject:"Your replacement has shipped" } }).then(result => {
                            console.log(result)
            }).catch(e=>{console.log(e)});
        }   
        
    }


    const getSendOrderShippedHtml = (order, trackingNumber)=>{

        if (order) {

            console.log("order.email:", order.customer);

            let productsString  =  ""; 

            for (let i = 0; i < order.products.items.length; i++) {                                        
                    let orderItem = order.products.items[i];
                    productsString += '<div className="cart-product">';
                    productsString += '<p> '+ orderItem.amount + " x " + orderItem.product.title + " - " + ConvertStringToDollarsCents(orderItem.price) +'</p>';                    
                    productsString +=  '</div>';
            }                                    
            

            let isnum = /^\d+$/.test(trackingNumber);
            let trackUrl = '';

            if (isnum) {
                trackUrl = 'https://tools.usps.com/go/TrackConfirmAction.action?tLabels='+trackingNumber
            }

            return `<table width="100%" border="0" cellspacing="0" cellpadding="0"> <tbody> 
            <tr>
            <td>
            </td>
            
            <td align="center">
            <div style="text-align:left; border:1px solid #ccc; max-width:680px;"> 
                   <div style="background-color:#2196f3; background-repeat:no-repeat; background-image:url(https://www.yaypokemon.com/images/yayfun-head.svg)">         
                    <p style="text-align:center; margin:0em; color:#fff; height:3em; font-size:1.5em; padding-top:1em; font-weight:bold;"> Your order has shipped! </p>                   
                   </div>
                   
                   <div style="margin:2em">
                        <div><p style="font-weight:bold; margin-top:0.5em;margin-bottom:0.1em">Order No. <span style="font-weight:normal">${order.number}</span></p>
                        <p style="margin-top:0em;margin-bottom:0.5em"><span style="font-weight:normal;">${moment(order.date).format("MMMM DD, YYYY")}</span></p></div>
                        <div style="padding-top:1em;margin-bottom:0em">
                        
                        <p style="margin-bottom:0.1em">Here's your tracking.</p>
                        
                        <p style="margin-top:0.1em">USPS Tracking:<a href='${trackUrl}' target='_blank'>${trackingNumber}</a></p></div>

                        <p style="font-weight:bold; padding-top:1em">Shipping Address</p>
                          <div>
                            <div>
                              <ul style="list-style-type:none;">
                                  <li>${order.shippingAddress.name}</li>
                                  <li>${order.shippingAddress.addressLine1}</li>
                                  <li>${order.shippingAddress.addressLine2}</li>
                                  <li>${order.shippingAddress.city}, ${order.shippingAddress.state}  ${order.shippingAddress.postalCode} ${order.shippingAddress.country}</li>
                                  </>                                
                              </ul>
                            </div>
                          </div>

                        <p style="font-weight:bold; padding-top:1em">Products Shipped</p>
                   `+'<div style="border-top:1px solid #ccc; font-size:0.8em;margin-top:1em"><p>Prices do not include taxes and shipping costs.</p>' + productsString + 
                   '<div style="border-top:1px solid #ccc; font-size:0.8em; margin-top:3em"><p>Thank you for shopping with us!</p>'+
                   '<p>Need help with your order?</p><p><a href="https://www.yaypokemon.com/contact/">Contact us</a> and we will get back to you as soon as we can.</p>'+
                   '<p>Track your order <a href="https://www.yaypokemon.com/track/">here</a>.</p>'+
                   '<p>This email was sent by <a href="https://www.yaypokemon.com/">yaypokemon.com</a></p></div>'+
                   '<div></div></td><td></td><tr></tbody></table>'            
        }   
    }



    const sendOrderShippedEmail = async (order, trackingNumber)=> {
        
        if (order) {

            console.log("order.email:", order.customer);

            let productsString  =  ""; 

            for (let i = 0; i < order.products.items.length; i++) {                                        
                    let orderItem = order.products.items[i];
                    productsString += '<div className="cart-product">';
                    productsString += '<p> '+ orderItem.amount + " x " + orderItem.product.title + " - " + ConvertStringToDollarsCents(orderItem.price) +'</p>';                    
                    productsString +=  '</div>';
            }                                    
            
            // productsString += '<div className="cart-product">';
            // productsString += '<p>1 x ' + "Pikachu V SWSH063 Promo Card (Complimentary)" + " - " + '$0.00</p>';                    
            // productsString +=  '</div>';


            let isnum = /^\d+$/.test(trackingNumber);
            let trackUrl = '';

            if (isnum) {
                trackUrl = 'https://tools.usps.com/go/TrackConfirmAction.action?tLabels='+trackingNumber
            }
            // https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=9405511108400996476876


            await API.post("yayfunthingsapi", "/items/sendEmail", { 
                body: 
                {  
                   email:order.customer, 
                   data:`<div style="border:1px solid #ccc; max-width:680px;"> 
                   <div style="background-color:#2196f3; background-repeat:no-repeat; background-image:url(https://www.yaypokemon.com/images/yayfun-head.svg)">         
                    <p style="text-align:center; margin:0em; color:#fff; height:3em; font-size:1.5em; padding-top:1em; font-weight:bold;"> Your order has shipped! </p>                   
                   </div>
                   
                   <div style="margin:2em">
                        <div><p style="font-weight:bold; margin-top:0.5em;margin-bottom:0.1em">Order No. <span style="font-weight:normal">${order.number}</span></p>
                        <p style="margin-top:0em;margin-bottom:0.5em"><span style="font-weight:normal;">${moment(order.date).format("MMMM DD, YYYY")}</span></p></div>
                        <div style="padding-top:1em;margin-bottom:0em">
                        
                        <p style="margin-bottom:0.1em">Here's your tracking.</p>
                        
                        <p style="margin-top:0.1em">USPS Tracking:<a href='${trackUrl}' target='_blank'>${trackingNumber}</a></p></div>

                        <p style="font-weight:bold; padding-top:1em">Shipping Address</p>
                          <div>
                            <div>
                              <ul style="list-style-type:none;">
                                  <li>${order.shippingAddress.name}</li>
                                  <li>${order.shippingAddress.addressLine1}</li>
                                  <li>${order.shippingAddress.addressLine2}</li>
                                  <li>${order.shippingAddress.city}, ${order.shippingAddress.state}  ${order.shippingAddress.postalCode} ${order.shippingAddress.country}</li>
                                  </>                                
                              </ul>
                            </div>
                          </div>

                        <p style="font-weight:bold; padding-top:1em">Products Shipped</p>
                   `+'<div style="border-top:1px solid #ccc; font-size:0.8em;margin-top:1em"><p>Prices do not include taxes and shipping costs.</p>' + productsString + 
                   '<div style="border-top:1px solid #ccc; font-size:0.8em;margin-top:1em"><p>Thank you for shopping with us!</p>'+
                   '<p>Need help with your order?</p><p><a href="https://www.yaypokemon.com/contact/">Contact us</a> and we will get back to you as soon as we can.</p>'+
                   '<p>Track your order <a href="https://www.yaypokemon.com/track/">here</a>.</p>'+
                   '<p>This email was sent by <a href="https://www.yaypokemon.com/">yaypokemon.com</a></p></div>'+
                   '<div></div>', 
                   subject:"Your Order Shipped!" } }).then(result => {
                            console.log(result)
            }).catch(e=>{console.log(e)});
        }   
        
    }

    const showChangeStatus =() => {
        return (<>
            {
                displayInputField("tracking", "black", "Tracking", orderDetailShown)
            }
            
        {/* <Button color="primary" variant="contained" style={{marginTop:"2em"}} onClick={ async ()=>{
                                console.log("Update change to shipped");                                                           
                                await updateOrderWithInput({id:orderDetailShown.id, status:"Shipped"}).then(e=>{                                    
                                    console.log(e);
                                    // sendOrderShippedEmail(e);
                                }).catch(e=>{console.log(e)})
                            }}>
                Change To Shipped
        </Button> */}
        </>
        )
    }

    const checkSearchInput = (product) => {
        if (searchProducts == '') {
            return true;
        } else {
            let search = product.title.toLowerCase() + " " + product.series.toLowerCase() + " " + product.description.toLowerCase()  + " " + product.set.toLowerCase() + " " + product.subtype.toLowerCase() + " " + product.type.toLowerCase() + " " +product.id.toLowerCase() + " ";
            if (search.includes(searchProducts.toLowerCase())) {
                return true;
            }
            else 
            {
                return false;
            }
        }
    }
    
    const getFilteredOrders = (orders) =>{
        let filteredOrders = [];            
        for (let i = 0; i < orders.length; i++) {
            let order = orders[i];            
            
            if (orderFilters["showPending"]) {
                if (order.status == "Pending") {
                    if (orderFilters["showShippingPaidMissing"] && order.shippingPaid <= 0) {
                        filteredOrders.push(order);
                    } else if (!orderFilters["showShippingPaidMissing"]) {
                        filteredOrders.push(order);
                    }
                    
                } 
            } else {
                if (orderFilters["showShippingPaidMissing"] && order.shippingPaid <= 0) {
                    filteredOrders.push(order);
                } else if (!orderFilters["showShippingPaidMissing"]){
                    filteredOrders.push(order);
                }
            }         
        }
        return filteredOrders;
    }

    const getFilteredProducts = (products) =>{
        let filteredProducts = [];            
        console.log("Get filtered products:", products);
        for (let i = 0; i < products.length; i++) {
            let product = products[i];            
         
            if (productFilters["filterAmazonActive"]) {
                if (product.offers && product.offers.length > 0) {
                    if (checkSearchInput(product)) {
                        filteredProducts.push(product);
                    }                    
                } 
            }else {

                if (productFilters["filterInStockOnly"]) {
                    if (product.quantity > 0) {
                        if (checkSearchInput(product)) {
                            filteredProducts.push(product);
                        }                    
                    } 
                }  else {
                    if (checkSearchInput(product)) {
                        filteredProducts.push(product);
                    }
                }
            }         
        }

        console.log("filteredProducts:", filteredProducts);

        return filteredProducts;
    }

    const displayDrawer = ()=>{

       return <Drawer
        className={classes.drawer}
        // variant="permanent"
        open={drawerOpen}
        onClose={()=>{setDrawerOpen(false)}}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {['Orders', 'Products', 'Cards', 'Sets','Coupons', 'E-mail', 'Analytics'].map((text, index) => (
              <ListItem selected={selectedIndex == text}
              button key={text} onClick={async (event)=>{
                
                handleListItemClick(event, text);

                setDrawerOpen(false);

                if (text == "Orders") {
                    setCurrentTab("Orders");                                                
                }
                if (text == "Products") {
                    setCurrentTab("Products");                            
                }
                if (text == "Cards") {
                    setCurrentTab("Cards");                            
                }
                if (text == "Analytics") {
                    setCurrentTab("Analytics");                            
                }
                if (text == "E-mail") {
                    setCurrentTab("E-mail");                            
                }
                if (text == "Coupons") {
                    setCurrentTab("Coupons");                            
                }
                if (text == "Messages") {
                    setCurrentTab("Messages");                            
                }
                if (text == "Sets") {
                    setCurrentTab("Sets");                            
                }
                if (text == "Settings") {
                    setCurrentTab("Settings");                            
                }

              }}>
                <ListItemText primary={text} />

              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {['Settings', 'Logs'].map((text, index) => (
              <ListItem button key={text} onClick={async (event)=>{
                
                handleListItemClick(event, text); 
                
                if (text == "Settings") {
                    setCurrentTab("Settings");                            
                }

              }}>
                <ListItemText primary={text} />

              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>

    }

    const displayAnalytics = (cards)=> {
        
        let totalWithImageAsset = 0;
        for (let i = 0; i < cards.length; i++) {
            let card = cards[i];
            if (card.imageAsset != null) {
                totalWithImageAsset++;
            }else {
                console.log(card.number)
            }
        }

        return (<p>
            {
                `${totalWithImageAsset}/${cards.length} `
            }

        </p>)
    }


    const displayCurrentTab = ()=> {

        if (currentTab == "Orders") {
            return <>                                                     
                    <div style={{paddingTop:"1em", marginRight:"2em", textAlign:"left"}}>            
                        <OrdersDraw orders={getFilteredOrders(displaySearchedOrders ? searchedOrders : orders)} isLoading={loadingOrders} viewDetailsCallback={viewDetailsCallback} />
                        {
                            hasMoreOrdersToLoad ? 
                            <Button color="primary" variant="contained" style={{marginTop:"2em"}} onClick={()=>{
                                fetchMoreOrders();
                            }}>
                                Load More
                        </Button> : <></>
                    }      
                </div>    
            </>
        }        

        
        return <>
                    <div className={syncing ? "sync-screen" : "sync-hidden"}>                                            
                        <div style={{height:"100%", width:"100%"}}>
                                <div style={{paddingTop:"10em"}}>
                                    <CircularProgress className="sync-screen-circular-progress" />    
                                </div>
                        </div>
                </div>
                {
                    currentTab == "Products" ?                      
                    <>
                    <div>
                    <TableObject key="table-products" data={getFilteredProducts(products)} type={"Products"} isLoading={loading} updateData={updateData} saveData={saveData} updateAmazonPrice={setAmazonProductPrice}/></div>  </>:                    
                    <></>
                }
                {
                    currentTab == "Cards" ?  <>
                    <div style={{paddingTop:"1em"}}>

                    <div style={{}}>

                    {displaySetSelector()}

                    { 
                        productSingles && productSingles[selectedSetName] ?
                        <>
                            {displayAnalytics(productSingles[selectedSetName].cards)}
                            <TableObject key="table-singles" data={productSingles[selectedSetName].cards} type={"Singles"} isLoading={checkIsLoading()} updateData={updateData} saveData={saveData} />
                         </>:<></>
                    }

                    </div>

                { moreToFetchForSet(selectedSetName) ? 
                <div style={{marginTop:'20px'}}>
                <Button variant='outline' onClick={async ()=>{
                    await fetchMoreSinglesForSets([selectedSetName]);
                }}>
                    Load More
                </Button>
                </div> :<></>
                }
                    </div>                    
                    </>: <></>
                }

                {
                    currentTab == "Analytics" ? <>

                        <AnalyticsPage />
                       </>:<></> 
                }

{
                    currentTab == "E-mail" ? <>

                        <AdminEmailPage />
                       </>:<></> 
                }


{
                    currentTab == "Coupons" ? <>

                        <CouponsPage />
                       </>:<></> 
                }

                {
                    currentTab == "Settings" ? <> 

                            <Quickbooks userProps={null}/>

                    </> : <></>
                }

                {
                    currentTab == "Sets" ? <>

                        <AdminSetsPage />
                       </>:<></> 
                }
    </>
    }

    const performSearch = (searchInput)=> {

        if (currentTab == "Orders") {
            if (searchInput != '') {    
                setDisplaySearchedOrders(true);      
                searchOrders(searchInput);
            } else {
                setDisplaySearchedOrders(false);
            }
        }else if (currentTab == "Products")            {
            
            setSearchProducts(searchInput);            
        }
      }


      const displayInputField = (key, color, name, currentObject, keyPressFunc, onChangeCallback, setValue)=>{    
          
        console.log("currentObject--->", key, currentObject)

        return (
           <CustomTextField
           className="custom-input"
           key={key}
           color={color}
           properties={
             {
               name: name,                
               labelName: name,
               error: false,
               variant: 'outlined',
               key: "search-input",
               onKeyPressFunction:()=>{console.log("Pressed Enter Key"); if (keyPressFunc) { keyPressFunc(); }},
               currentValue:currentObject[key]
             }
           }
           defaultValue={""}
           value={currentObject[key]}
           onChange={ onChangeCallback ? e=>{onChangeCallback(e)} : 
             e => {
                 console.log("On change:", e.target.value);
               if (e.target.value) {
                   console.log("currentObject[key]",key,currentObject, currentObject[key]);
                   if (setValue) {
                    setValue({...currentObject, [key] : e.target.value});
                   } else {
                    currentObject[key] = e.target.value;
                   }
                   
                
               } else {
                if (setValue) {
                    setValue({...currentObject, [key] : ''});
                   } else {
                    currentObject[key] = '';
                }
               }
             }
           } />)        
       }

    // const updateTrackingNumber = (currentObject) => {
    //     currentObject.trackingNumber
    // }

    const updateOrderPackageShipped = async (order, packageItem) => {
        
        let updatedPackages = [];
        for (let i = 0; i < order.packages.length; i++) {        
            let packageitem = order.packages[i];
            if (packageitem.id == packageItem.id) {
                packageitem.status = "Shipped"
            }
            updatedPackages.push(packageitem);
        }
        await updateOrderWithInput({id:order.id, packages:updatedPackages})
    }

    //DISPLAY STATUS paymentStatus
    //order.paymentStatus
/*
UPS:
/\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/
/\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/

FEDEX:
/(\b96\d{20}\b)|(\b\d{15}\b)|(\b\d{12}\b)/
/\b((98\d\d\d\d\d?\d\d\d\d|98\d\d) ?\d\d\d\d ?\d\d\d\d( ?\d\d\d)?)\b/
/^[0-9]{15}$/

USPS:
/(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)/
/^E\D{1}\d{9}\D{2}$|^9\d{15,21}$/
/^91[0-9]+$/
/^[A-Za-z]{2}[0-9]+US$/
*/

function UPS(trackingNumber) {
    const re = /\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/;
    const re2 = /^(1Z\s?[0-9A-Z]{3}\s?[0-9A-Z]{3}\s?[0-9A-Z]{2}\s?[0-9A-Z]{4}\s?[0-9A-Z]{3}\s?[0-9A-Z]$|[\dT]\d{3}\s?\d{4}s?\d{3})$/i;

    if (re.test(String(trackingNumber).toLowerCase())) {
        return true;
    } else if (re2.test(String(trackingNumber).toLowerCase())) {
        return true;
    }
    return false;
}


function USPS(trackingNumber) {
    const re = /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)/;
    const re2 = /^E\D{1}\d{9}\D{2}$|^9\d{15,21}$/;
    const re3 = /^91[0-9]+$/;
    const re4 = /^[A-Za-z]{2}[0-9]+US$/;

    if (re.test(String(trackingNumber).toLowerCase())) {
        return true;
    } else if (re2.test(String(trackingNumber).toLowerCase())) {
        return true;
    } else if (re3.test(String(trackingNumber).toLowerCase())) {
        return true;
    } else if (re4.test(String(trackingNumber).toLowerCase())) {
        return true;
    }
    return false;    
}


    const displayTrackingCarrier = (trackingNumber)=>{        
        if (trackingNumber) {
            if (USPS(trackingNumber)) {
                return <img src="./images/usps_logo.png" style={{width:"3em"}}/>
            } else  if (UPS(trackingNumber)){
                return <img src="./images/ups_logo.png" style={{width:"3em"}}/>
            } 
        }        
    }


    const showCodes = (order) => { 

        return <>{
                        
            order.codes.map((codeItem, index) => {                                      

            return <>            
            {              
                displayInputField("name", "black", "Name", codeItem)
            }   
            
            {              
                displayInputField("value", "black", "Value", codeItem)
            }                 
            
            <Button          
                className="modal-button"
                size="small" color="primary" variant="contained"
                onClick={async ()=>{
                    await updateOrderWithInput({id:order.id, codes:order.codes})
            }}>Save</Button>

<Button          
                        disabled={codeItem.emailed != true ? false : true}
                        className="modal-button"
                        size="small" color="primary" variant="contained"
                        onClick={async ()=>{          
                          
                            if (window.confirm('Are you sure you want to delete this item?')) 
            {
                let updatesCodes = [];

                console.log("orderDetailShown.packages:", orderDetailShown.codes);
                console.log("order.packages:", order.codes);
                console.log("order.packages:", codeItem.id);

                for (let i = 0; i < orderDetailShown.codes.length; i++) {
                    let code_ = orderDetailShown.codes[i];
                    console.log("CODE:", code_, "codeItem:", codeItem);
                    if (code_.id != codeItem.id) {
                        updatesCodes.push(code_);
                    }
                }     
                                            
                await updateOrderWithInput({id:order.id, codes:updatesCodes}).then( result=> {    

                    setOrderDetailShown({...orderDetailShown, codes:updatesCodes});

                }).catch(e=>{console.log(e)})

                forceUpdate();

            }
                        }}>Delete
                    </Button>

            {
               codeItem.emailed ?  <p>Has been emailed</p>:<></>
            }
            
            </>
        })
        }

            <div style={{paddingTop:"3em"}}>
                    <Button          
                            className="modal-button"
                            size="small" color="primary" variant="contained"
                            onClick={async ()=>{
                                // await updateOrderWithInput({id:order.id, codes:order.codes})
                                console.log("UPDATE ORDER HERE")
                                await sendEmailCode(order, order.codes);                       
                                await updateOrderWithInput({id:order.id, status:"Shipped", codes:order.codes})                                
                        }}>Email Codes</Button>
            </div>
        </>

    }


    const showPackages = (order)=> {

        return order.packages.map((packageItem, index) => {                                      
            return(<div key={"package-"+index}>  
                    {
                        displayTrackingCarrier(packageItem.trackingNumber)
                    }              
                    {              
                        displayInputField("trackingNumber", "black", "trackingNumber", packageItem)
                    }                
      {/*   const displayInputField = (key, color, name, currentObject, keyPressFunc, onChangeCallback)=>{     */}
          
            

                  

                    <Button          
                            className="modal-button"
                            size="small" color="primary" variant="contained"
                            onClick={async ()=>{
                                console.log("UPDATE ORDER WITH INPUT:", order);
                                // order.packages[0].trackingNumber = "9405509205568442722874";
                                await updateOrderWithInput({id:order.id, packages:order.packages})
                        }}>Save</Button>


<Button          
                    className="modal-button"
                    size="small" color="primary" variant="contained"
                    onClick={async ()=>{
                        let result = await API.post("yayfunthingsapi", "/items/checkTracking", {body:{trackingNumber:packageItem.trackingNumber}}).then(async results => {
                 
                            console.log("verifyAddress results:", results);
                            console.log("Results:", results);
        
                            await insertOrderHistoryChange(order, "Tracking Result: " + JSON.stringify(results));
        
                        return true;
                      }).catch(e=>{    
                        return false;
                      });

                      console.log("RESULT::",  result);

                        }}>Track Package</Button>



                    <Button          
                        disabled={packageItem.trackingNumber && packageItem.status != "Shipped" ? false : true}
                        className="modal-button"
                        size="small" color="primary" variant="contained"
                        onClick={async ()=>{      
                            console.log("Display email")
                            setDisplayEmailPreview(true);
                            setDisplayPreviewDetails({
                                subject:`Your order #${order.number} has shipped`,
                                order:{...order},
                                    trackingNumber:packageItem.trackingNumber,
                                    packageItemId:packageItem.id                                    
                                }
                            )

                            // getSendOrderShippedHtml(order, packageItem.trackingNumber)
                            // if (window.confirm("Are you sure you want to mark shipped and email?")) {

                            // await updateOrderWithInput({id:order.id, status:"Shipped"}).then(async result=>{                                    
                            //     if (result.data && result.data.updateOrder) {
                            //         await sendOrderShippedEmail(order, packageItem.trackingNumber);   
                            //         await updateOrderPackageShipped(order, packageItem);
                            //     }
                            // }).catch(e=>{console.log(e)})

                            // }

                        }}>Send Email
                    </Button>


                    <Button          
                        disabled={packageItem.trackingNumber && packageItem.status != "Shipped" ? false : true}
                        className="modal-button"
                        size="small" color="primary" variant="contained"
                        onClick={async ()=>{          

                            if (window.confirm("Are you sure you want to send a replacement was shipped email?")) {

                                await sendOrderReplacementShippedEmail(order, packageItem.trackingNumber);
                                await updateOrderPackageShipped(order, packageItem);
                            }

                        }}>Replacement Shipped
                    </Button>

                    <Button          
                        disabled={packageItem.status != "Shipped" ? false : true}
                        className="modal-button"
                        size="small" color="primary" variant="contained"
                        onClick={async ()=>{          
                          


                            if (window.confirm("Are you sure you want to delete this package?")) {

                                let updatedPackages = [];

                            console.log("orderDetailShown.packages:", orderDetailShown.packages);
                            console.log("order.packages:", order.packages);
                            console.log("order.packages:", packageItem.id);

                            for (let i = 0; i < orderDetailShown.packages.length; i++) {
                                let package_ = orderDetailShown.packages[i];
                                console.log("PACKAGE:", package_, "packageItem:", packageItem);
                                if (package_.id != packageItem.id) {
                                    updatedPackages.push(package_);
                                }
                            }     
                                                        
                            await updateOrderWithInput({id:order.id, packages:updatedPackages}).then( result=> {    
                                setOrderDetailShown({...orderDetailShown, packages:updatedPackages});

                            }).catch(e=>{console.log(e)})

                            forceUpdate();
                            }
                            




                        }}>Delete
                    </Button>


            </div>)
        }) 

}


const displaySearchInput = ()=>{    
    return (<SearchInput performSearch={performSearch} />);
   }

   const createCodeCard = async(order)=>{
    let currentCodes = order.codes;
    if (currentCodes == null || currentCodes == undefined || !Array.isArray(currentCodes)) {
        currentCodes = []
    }

    currentCodes.push({
        id:uuidv4(),          
    });
    
    await updateOrderWithInput({id:order.id,codes:currentCodes});
    setOrderDetailShown({...order, codes:currentCodes})
   }


const createPackage = async(order)=>{

        let currentPackages = order.packages;
        if (currentPackages == null || currentPackages == undefined || !Array.isArray(currentPackages)) {
            currentPackages = []
        }

        currentPackages.push({
            id:uuidv4(),          
        });
        
        await updateOrderWithInput({id:order.id,packages:currentPackages});
        setOrderDetailShown({...order, packages:currentPackages})
}




const handleChange = async (event) => {


    setBusy(true);
    setSyncing(true);

    let selectionIndex = event.target.value;    
    setSelectedSetIndex(selectionIndex);
    let selectionName = cardSets[Math.max(0, selectionIndex - 1)].name;
    console.log("selectionName:", selectionName);    
    setSelectedSetName(selectionName);       
    console.log("selectionName", selectionName);
    await fetchProductsForSets([selectionName]);
    console.log("Finished");
    
    setBusy(false);
    setSyncing(false);
    
    //forceUpdate();
  };


const displaySetMenuItems = ()=> {

    return cardSets.map((set, index) => {
            return (
            <MenuItem value={index + 1}>
                
                { set ? displaySet(set) : <>N/A</> }
            </MenuItem>
            )
        })
    }

    const displaySet = (set)=> {
        
        // <img className="card-set-logo" src={set.logoUrl}/>
        // return (<><p className="card-set-name">{set.name}</p></>)
        return (<>
        
        {/* <img className="card-set-logo" src={set.logoUrl}/> */}
        
        <S3Image classNameKey={"card-set-logo"} imgKey={"sets/" + set.id + ".png"} />
        &nbsp; <p className="card-set-name">{set.name}</p></>)
        
    }
      


const displaySetSelector = ()=> {
    return (<div className="card-set-dropdown">
      <FormControl variant="outlined">
        <InputLabel id="demo-simple-select-outlined-label">Choose a Set</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectedSetIndex}
          onChange={handleChange}
          label="Shipping Address"
        >
          <MenuItem value={0} name="default">
            <em>Select a set</em>
          </MenuItem>
          {
            displaySetMenuItems()
          }
        </Select>
     
      </FormControl>

    </div>);
}


const displayRefundOrder = (order)=>{
    return (<Button          
        // disabled={packageItem.trackingNumber ? false : true}
        className="modal-button"
        size="small" color="secondary" variant="contained"
        onClick={async ()=>{          

            if (window.confirm('Are you sure you wish to refund the full amount for this order?')) 
            {

             
                if (order.paymentIntentId) {

                    console.log("order:", order.paymentIntentId);

                    if (order.paymentMethod == "PayPal") {

                        await API.post("yayfunthingsapi", "/items/getPayPalOrder", { 
                            body:{orderId:order.paymentIntentId}
                        }).then(async result => {
                           
                            console.log("displayRefundOrder getPayPalOrder result:", result);
        
                            let purchaseUnits = result.result.purchase_units;
                            if (purchaseUnits) {

                                for (let i = 0; i < purchaseUnits.length; i++) {
                                    let purchaseUnit = purchaseUnits[i];
                                    if (purchaseUnit.payments && purchaseUnit.payments.captures) {
                                        for (let x = 0; x < purchaseUnit.payments.captures.length; x++) {
                                            let capture = purchaseUnit.payments.captures[x];

                                            console.log("capture:", capture);
                                            
                                            await API.post("yayfunthingsapi", "/items/refundPayPalOrder", { 
                                                body:{captureId:capture.id}
                                            }).then(async result => {

                                                console.log("refund result:", result);

                                                    if (result.result && result.result.status == "COMPLETED") {

                                                        await insertOrderHistoryChange(order, "Refund Success " + result.result.id);

                                                        await updateOrderWithInput({id:order.id, status:"Refunded"}).then(async resultUpdate =>{                                    
                                                            console.log("Refunded:", resultUpdate);
                                                            // if (resultUpdate.data && resultUpdate.data.updateOrder) {
                                                            //     sendOrderRefundedEmail(order, resultRefund);                                                           
                                                            // }    
                                                        }).catch(e=>{
                                                        
                                                            
                                                            console.log(e);
                                                        
                                                        })

                                                        
                                                    } else {

                                                        await insertOrderHistoryChange(order, "Refund Failure " + result.message);


                                                    }
                                             }).catch (e =>{console.log(e)});
                    
                                        }
                                    }
                                }
                            }
                        }).catch(e=>{console.log(e)});


                    } else {
                        
    
                    }
                    
                
                } else {


                    console.log("Order:", order);
                    if (order.order.paymentIntentId) {
    
                        await API.post("yayfunthingsapi", "/items/checkPaymentIntent", { 
                            body:{paymentIntentId:order.order.paymentIntentId}
                        }).then(async result => {
                            console.log("displayRefundOrder result:", result);
            
                            if (result. charges) {
    
                                if (result.charges.data) {
                                    for (let i = 0; i < result.charges.data.length; i++) {
                                        let charge = result.charges.data[i];
                                        if (charge) {
                                            console.log("Charge:", charge);
                                            let id = charge.id;
                                            await API.post("yayfunthingsapi", "/items/createRefund", { 
                                                body:{chargeId:id}
                                            }).then(async resultRefund=>{
                                                
                                                console.log("Create refund:", resultRefund);
    
                                                if (resultRefund.statusCode == 400) {
                                                    sendAlertMessage(resultRefund.code);

                                                    await insertOrderHistoryChange(order, "Refund Error with Failure " + resultRefund.status);

                                                }
    
                                                if (resultRefund.status == "succeeded") {

                                                    await updateOrderWithInput({id:order.id, status:"Refunded"}).then(async resultUpdate =>{                                    
                                                        console.log("Refunded:", resultUpdate);
                                                        if (resultUpdate.data && resultUpdate.data.updateOrder) {
                                                            sendOrderRefundedEmail(order, resultRefund);                                                           
                                                        }

                                                        await insertOrderHistoryChange(order, "Refund Success of " + ConvertStringToDollarsCents(resultRefund.amount/100));

                                                    }).catch(e=>{
                                                    
                                                        
                                                        console.log(e);
                                                    
                                                    })
                                                }                                            
                                            }).catch(e=>{
                                                console.log(e);
                                            
                                            })
                                        }
                                    }
                                }
                            }
    
                            
                            // await API.post("yayfunthingsapi", "/items/createRefund", { 
                            //     body:{paymentIntentId:order.paymentIntentId}
                            // }).then(async result=>{
                
            
                            // }).catch(e=>{console.log(e)})
            
                            
                        }).catch(e=>{console.log(e)});
        
    
                    }
                }
            }
         
        }}>Full Refund</Button>)

}


const confirmCancelOrder = ()=>{


}

const cancelReasonList = ()=> {
    return ['We couldn\'t verify the payment method.','One or more of your items are out of stock.', 'We cancelled the order as requested.'];
}


const displayCancelOrder = (order)=>{
    return (<Button          
        // disabled={packageItem.trackingNumber ? false : true}
        className="modal-button"
        size="small" color="secondary" variant="contained"
        onClick={async ()=>{                   
            let cancelReason = cancelReasonList()[cancelReasonSelectionIndex];
            if (window.confirm(`Are you sure you wish to cancel this order and email the customer? Cancel Reason: ${cancelReason}`)) 
            {
                await updateOrderWithInput({id:order.id, status:"Cancelled"}).then(result=>{                                    
                    console.log("Cancelled:", result);
                    if (result.data && result.data.updateOrder) {
                        // sendOrderCancelledEmail(order, 'We had a problem processing your payment with PayPal as a new customer. This is a problem we are working with PayPal on correcting. <br/> <br/> <b>Please place a new order so we can process and ship your order out as soon as possible.</b> We are very sorry for the inconvenience this may have caused.');   
                        sendOrderCancelledEmail(order, cancelReason);   
                    }
                }).catch(e=>{console.log(e)});
            } 
        }}>Cancel and Email
    </Button>)
}


const displayCancelOrderNoEmail = (order)=>{
    return (<Button          
        // disabled={packageItem.trackingNumber ? false : true}
        className="modal-button"
        size="small" color="secondary" variant="contained"
        onClick={async ()=>{          
            if (window.confirm('Are you sure you wish to cancel this order?')) 
            {
                await updateOrderWithInput({id:order.id, status:"Cancelled"}).then(async result=>{                            
                    await insertOrderHistoryChange(order, "Order Cancelled with Success");
                    // console.log("Cancelled:", result);
                }).catch(async e=>{console.log(e);                
                    await insertOrderHistoryChange(order, "Order Cancelled with Failure");
                });
            }            
        }}>Cancel Order
    </Button>)
}


const displayShowHistory = ()=>{    

    let orderHistoryAmount = 0;

    if (orderDetailShown) {
        if (orderDetailShown.history && orderDetailShown.history.length) {
            orderHistoryAmount = orderDetailShown.history.length;
        }
    }
    
    return (<><Button          
               className="modal-button"
               size="small" color="secondary" variant="contained"
               onClick={async ()=>{
                   setShowHistory(!showHistory);
               }}> {showHistory ? "Hide " : "Show"} History {"(" + orderHistoryAmount + ")"}</Button>                      
           </>)        
   }

const displayCreatePackage = (order)=>{    
     return (<><Button          
                className="modal-button"
                size="small" color="secondary" variant="contained"
                onClick={async ()=>{
                    await createPackage(order);
                    
                }}> Create Package</Button>                      
            </>)        
    }

    const displayCreateCodeCard = (order)=>{    
        return (<><Button          
                   className="modal-button"
                   size="small" color="secondary" variant="contained"
                   onClick={async ()=>{
                       await createCodeCard(order);
                   }}> Create Code Card</Button>                      
               </>)        
       }

    const getBase64 = (file) => new Promise(function (resolve, reject) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject('Error: ', error);
    })
    
    function getBase64Image(img) {
        // Create an empty canvas element
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
    
        // Copy the image contents to the canvas
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
    
        // Get the data-URL formatted image
        // Firefox supports PNG and JPEG. You could check img.src to
        // guess the original format, but be aware the using "image/jpg"
        // will re-encode the image.
        var dataURL = canvas.toDataURL("image/png");
    
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }


    const getCardUrl = async (setCode, url) => {
        
        await delay(500);
        var filename = url.split('/').pop().split('?')[0].split('#')[0];        
        let key = setCode+"/"+filename;
        return key;
        // return await new Promise((resolve, reject)=>{
        //     https.get(url, function(res) {        
        //         var data = [];
        //         res.on('data', function(chunk) {
        //             data.push(chunk);                                                         
        //         });
        //         res.on('end', async function() {
        //             try {
        //                 var base64 = new Buffer.concat(data).toString('base64');
        //                 let key = setCode+"/"+filename;
        //                 // zip.file(key, base64, {base64:true, type:"image/png"});
        //                 resolve(key);
        //             } catch (err) {
        //                 console.log("ERROR", err);
        //                 reject("");
        //             }
        //         });
        //         });
        // })
        

    }

    const buildCardKeywords = (card, cardCount, seriesName, setName) => {
        let keyword = "";

        let attackString = "";
        if (card.attacks) {
            for(let i = 0; i < card.attacks.length; i++) {
                let attack = card.attacks[i];                
                attackString += " " + attack.name + " " + attack.text;
            }    
        }

        keyword = (card.name + " " + card.number + "/" + cardCount + " " + card.rarity + " " + seriesName + " - " + setName + " " + card.text + " " + card.set.id + " " + card.artist + " " + attackString).toLowerCase()
        keyword = keyword.replaceAll("undefined", "");
        keyword = keyword.replaceAll("null", "");

        return keyword;
    }


    
    const createCardInput = async (card)=>{

        let cardPrintedCount = getPrintedCardCountForSet(card.set.id);
        let cardTotalCount = getTotalCardCountForSet(card.set.id);
        let releaseDate = getReleaseDateForSet(card.set.id);

        let getCardUrlNormal = await getCardUrl(card.set.id, card.images.small);
        let getCardHiResUrl  = await getCardUrl(card.set.id, card.images.large);
                        
        let setName = getCardSetNameFromCode(card.set.id);
        let seriesName = card.set.series;
        // console.log("CREATE CARD INPUT:", card);
        //TODO:///
        //Try adding a single card making sure it adds to the database and also creates sizes and uploads the card 
        //Add new variables
        //  cardmarket, legalities, tcgplayer:prices
        let cardInput = {
            id:card.id,
            title:card.name + " " + card.number + "/" + cardPrintedCount + " " + card.rarity + " " + card.set.series + " - " + setName + " " + (card.set.id).toUpperCase(),
            // asin:result[columnIndex("asin")],
            // title:result[columnIndex("title")],
            // description:result[columnIndex("description")],
            // image:result[columnIndex("image")],
            // author:result[columnIndex("author")],
            // brand:result[columnIndex("brand")],
            // featured:result[columnIndex("featured")],
            price:0,
            units:0,
            quantity:0,            
            ...card,
            set:setName,
            series:seriesName,
            image:getCardUrlNormal,
            imageUrlKey:getCardUrlNormal,
            imageUrlHiResKey:getCardHiResUrl,            
            setTotal:cardTotalCount,
            setPrintedTotal:cardPrintedCount,
            releaseDate:releaseDate,
            keyword:buildCardKeywords(card, cardPrintedCount, seriesName, setName)
            // type:result[columnIndex("type")],
            // subtype:result[columnIndex("subtype")],
            // status:result[columnIndex("status")],            
            // salePrice:result[columnIndex("sale_price")],
            // salePriceEndDate:result[columnIndex("sale_price_end_date")],
            // moreDetails:result[columnIndex("more_details")],
            // keyword:result[columnIndex("keyword")],
            // series:result[columnIndex("series")],
            // set:result[columnIndex("set")],
            // packs:result[columnIndex("packs")],
            // limit:result[columnIndex("limit")],
            // releaseDate:result[columnIndex("release_date")],
            // allowPreorder:result[columnIndex("allow_preorder")],
        };

        cardInput["cardName"] = cardInput.name;
        cardInput["cardSubtype"] = cardInput.subtypes ? cardInput.subtypes[0] : "";
        cardInput["cardSubtypes"] = cardInput.subtypes;
        // cardInput["cardType"] = cardInput.type;
        cardInput["subtype"] = "Raw";
        cardInput["type"] = "Single";
        cardInput["brand"] = "Pokemon";
        cardInput["author"] = "Pokemon";
        
        if (cardInput.images) {
            delete(cardInput.images);
        }
            
        if (cardInput.attacks) {
            cardInput["attacks"] = convertAttackInput(cardInput.attacks)
        }
        
        if (cardInput.subtypes) {
            delete(cardInput.subtypes);
        }
        
        delete(cardInput.name);

        console.log("CARD INPUT:", cardInput);
        return(cardInput)
    }

    const convertAttackInput = (attacks) =>{
        let attacksUpdates = [];
        for(let i = 0; i < attacks.length; i++) {
            let attack = attacks[i];
            attacksUpdates.push({
                convertedEnergyCost:attack.convertedEnergyCost,
                cost:attack.cost,
                damage:attack.damage,
                name:attack.name,
                text:attack.text
            })
        }
        return attacksUpdates;      
    }

    const updateRowToGoogleSheet = async(result)=>{
            
        let rows = await getSheetRowsForSet(cardSetSelected);

        if (rows) {
            for (let i = 0; i < rows.length; i++) {
                let row = rows[i];
                if (row.id == result.data.updateProduct.id) {
                    console.log("ROW:", row);
                }            
            }
        }
        
    }


    const addRowToGoogleSheet = async (product)=>{

        console.log("addRowToGoogleSheet:", product);
        
        product["action"] = "I";
        product["unit"] = 1;

        console.log("Sheet Selected:", cardSetSelected);

        let sheet = await getSheetByTitle(cardSetSelected);
        if (sheet) {
            
            const moreRows = await sheet.addRows([product]).then(results=>{console.log(results)}).catch(e=>{
                console.log(e)        
                // SET ERROR MESSAGE HERE 

            });    
        } else {    
            console.log("DID NOT FIND SHEET ", cardSetSelected);
        }

        
        //     {
        //          id:result.id,
        //          release_date:'',
        //          title:	,
        //          series:	,
        //          set:	,
        //          type:	,
        //          subtype:	,
        //          packs:	,
        //          description:	,
        //          image:	,
        //          author:	,
        //          brand:	,
        //          featured:	,
        //          price:	,
        //          units:	,
        //          quantity:	,
        //          action:	,
        //          keyword:	,
        //          max_per_purchase:	,
        //          available:	,
        //          allow_preorder:	,
        //          sale_price:	,
        //          sale_price_end_date:	,
        //          asin:	,
        //          status:	,
        //          more_details:	,
        //          limit:	,
        //          cost:	,
        //          ship: ,
        //          from:	,
        //          ship: ,
        //          to:	,
        //          total: ,
        //          roi:	,
        //          percent: ,
        //          roi:	,
        //          total: ,
        //          profit:	,
        //          total: ,
        //          investment:	,
        //          distributor:'' ]);
    }


    const downloadSelectedImages = async ()=>{
        if (cardsSelected && cardsSelected.length > 0) {
            setBusy(true);
            let setDataJson = {};                
            for (let i = 0; i < cardsSelected.length; i++) {
                let card = cardsSelected[i];
                let cardInput = await createCardInput(card);              
                console.log("addSelectedCardsToDatabase cardInput:", cardInput);
                if (setDataJson[card.setCode]){
                    setDataJson[card.setCode][card.id] = "1"
                }else {
                    setDataJson[card.setCode] = {[card.id]:"1"}
                }

            //     await API.graphql(graphqlOperation(createProduct, { input:cardInput})).then(result=>{                                           
            //         addRowToGoogleSheet(result);
            // }).catch(e=>{console.log(e)})
           }

           console.log("setDataJson:", setDataJson);

           try {
                
                let setKeys = Object.keys(setDataJson);
                for (let i = 0; i < setKeys.length; i++) {
                    let setCode = setKeys[i];
                    let setCodeData = Object.keys(setDataJson[setCode]);
                    let setCodeDataJson = {cards:[...setCodeData]}
                    zip.file(setCode + "/set.json", JSON.stringify(setCodeDataJson));
                }
                
            } catch (err) {
                console.log("ERROR", err);
                // reject("");
            }

           await zip.generateAsync({type:"base64"})
           .then(function(content) {
                var zipName = 'download.zip';
                var a = document.createElement('a'); 
                a.href = "data:application/zip;base64," + content;
                a.download = zipName;
                a.click();               
           });            
        }

        setBusy(false);

    }


    const addSelectedCardsToDatabaseWithoutImages = async ()=> {

        if (cardsSelected && cardsSelected.length > 0) {
            setBusy(true);
            let setDataJson = {};                
            for (let i = 0; i < cardsSelected.length; i++) {
                let card = cardsSelected[i];
                let cardInput = await createCardInput(card);              
                await API.graphql(graphqlOperation(createProduct, { input:cardInput})).then(result=>{                                           
                    addRowToGoogleSheet(...result.data.createProduct);
                if (setDataJson[card.setCode]){
                    setDataJson[card.setCode][card.id] = "1"
                }else {
                    setDataJson[card.setCode] = {[card.id]:"1"}
                }
            }).catch(e=>{console.log(e)})
           }

           console.log("setDataJson:", setDataJson);
           // add json data file
           try {
            
            let setKeys = Object.keys(setDataJson);

            // for (let i = 0; i < setKeys.length; i++) {
            //     let setCode = setKeys[i];
            //     let setCodeData = Object.keys(setDataJson[setCode]);
            //     let setCodeDataJson = {cards:[...setCodeData]}
            //     zip.file(setCode + "/set.json", JSON.stringify(setCodeDataJson));
            // }
                        
            } catch (err) {
                console.log("ERROR", err);
            }

        //    await zip.generateAsync({type:"base64"})
        //    .then(function(content) {
        //         var zipName = 'download.zip';
        //         var a = document.createElement('a'); 
        //         a.href = "data:application/zip;base64," + content;
        //         a.download = zipName;
        //         a.click();               
        //    });        
        }

        setBusy(false);
    }

    const addSelectedCardsToGoogleSheet = async ()=>{
        if (cardsSelected && cardsSelected.length > 0) {
            setBusy(true);
            for (let i = 0; i < cardsSelected.length; i++) {
                let card = cardsSelected[i];
                let cardInput = await createCardInput(card);              
                console.log("addSelectedCardsToDatabase cardInput:", cardInput);
                addRowToGoogleSheet(cardInput);
           }          
            setBusy(false);
        }
    }


    const createImageAssetForCardInput = async (card, cardInput)=>{

        let imageLarge = card.images ? card.images.large : "";
        let defaultKey = cardInput.imageUrlKey;
        var re = /(?:\.([^.]+))?$/;
        let extensionString = re.exec(defaultKey);
        
        if (card.imageAsset == null && imageLarge) {                
            let location = await downloadImagesFromUrlUploadSizes(defaultKey.replace(extensionString[0],""), extensionString[0], imageLarge, 
            [{"_120" : 120},
            {"_200" : 200},
            {"" : 400},
            {"_hires" : 700}])
            if (location && location.key) {
                cardInput.imageAsset = location.key;
                // await performUpdateActionOnItem({...card, imageAsset:location.key})
            }
        } else {
            console.log("Image asset found", card.imageAsset)
        }                                    

        return cardInput;
    }


    const addSelectedCardsToDatabase = async ()=>{

            if (cardsSelected && cardsSelected.length > 0) {
                setBusy(true);
                let setDataJson = {};                
                for (let i = 0; i < cardsSelected.length; i++) {
                    
                    let card = cardsSelected[i];
                    let cardInput = await createCardInput(card); 

                    await createImageAssetForCardInput(card, cardInput)

                    await API.graphql(graphqlOperation(createProduct, { input:cardInput})).then( async result=> {                                           
                        let productResult = result.data.createProduct;
                        addRowToGoogleSheet(productResult);
                        if (setDataJson[card.setCode]){
                            setDataJson[card.setCode][card.id] = "1"
                        }else {
                            setDataJson[card.setCode] = {[card.id]:"1"}
                        }
                    }).catch(e=>{console.log(e)})            
            }

               // add json data file
               try {
                
                // let setKeys = Object.keys(setDataJson);
                // for (let i = 0; i < setKeys.length; i++) {
                //     let setCode = setKeys[i];
                //     let setCodeData = Object.keys(setDataJson[setCode]);
                //     let setCodeDataJson = {cards:[...setCodeData]}
                //     zip.file(setCode + "/set.json", JSON.stringify(setCodeDataJson));
                // }
                
                } catch (err) {
                    console.log("ERROR", err);
                    // reject("");
                }

            //    await zip.generateAsync({type:"base64"})
            //    .then(function(content) {
            //         var zipName = 'download.zip';
            //         var a = document.createElement('a'); 
            //         a.href = "data:application/zip;base64," + content;
            //         a.download = zipName;
            //         a.click();               
            //    });
                
                // id:result[columnIndex("id")],
                // asin:result[columnIndex("asin")],
                // title:result[columnIndex("title")],
                // description:result[columnIndex("description")],
                // image:result[columnIndex("image")],
                // author:result[columnIndex("author")],
                // brand:result[columnIndex("brand")],
                // featured:result[columnIndex("featured")],
                // price:result[columnIndex("price")],
                // units:result[columnIndex("units")],
                // quantity:result[columnIndex("quantity")],
                // type:result[columnIndex("type")],
                // subtype:result[columnIndex("subtype")],
                // status:result[columnIndex("status")],            
                // salePrice:result[columnIndex("sale_price")],
                // salePriceEndDate:result[columnIndex("sale_price_end_date")],
                // moreDetails:result[columnIndex("more_details")],
                // keyword:result[columnIndex("keyword")],
                // series:result[columnIndex("series")],
                // set:result[columnIndex("set")],
                // packs:result[columnIndex("packs")],
                // limit:result[columnIndex("limit")],
                // releaseDate:result[columnIndex("release_date")],
                // allowPreorder:result[columnIndex("allow_preorder")],     

            }

            setBusy(false);
    }


    const updateSelectedCardsToDatabase = async ()=>{

        if (cardsSelected && cardsSelected.length > 0) {
            setBusy(true);
            let setDataJson = {};                
            for (let i = 0; i < cardsSelected.length; i++) {
                let card = cardsSelected[i];
                let cardInput = await createCardInput(card);       
                
                await API.graphql(graphqlOperation(updateProduct, { input:cardInput})).then(async result=>{                                           
                    console.log("Update Result", result);                    

                    if (result && result.data && result.data.updateProduct && result.data.updateProduct.imageAsset == null) {
                        await createImageAssetForCardInput(card, cardInput)
                    }
                    

                    updateRowToGoogleSheet(result);
                if (setDataJson[card.setCode]){
                    setDataJson[card.setCode][card.id] = "1"
                }else {
                    setDataJson[card.setCode] = {[card.id]:"1"}
                }
                
             }).catch(async e => {
                console.log(e);
            
                if (e) {
                    if (e && e.errors && e.errors[0] && e.errors[0].message) {
                        if (e.errors[0].message.includes("Request ID: null")){
                            console.log("ERROR FINDING ID, SO CREATE:", card, cardInput);
                            await createImageAssetForCardInput(card, cardInput)
                            await API.graphql(graphqlOperation(createProduct, { input:cardInput})).then(result=>{                                           
                                console.log("Create Result", result);                    
                                updateRowToGoogleSheet(result);
                            if (setDataJson[card.setCode]) {
                                setDataJson[card.setCode][card.id] = "1"
                            }else {
                                setDataJson[card.setCode] = {[card.id]:"1"}
                            }
                        }).catch(er=>{ console.log(er)});
                    }
                  }
                }
            })
           }

        //    console.log("setDataJson:", setDataJson);
           // add json data file

           try {
            
            // let setKeys = Object.keys(setDataJson);
            // for (let i = 0; i < setKeys.length; i++) {
            //     let setCode = setKeys[i];
            //     let setCodeData = Object.keys(setDataJson[setCode]);
            //     let setCodeDataJson = {cards:[...setCodeData]}
            //     zip.file(setCode + "/set.json", JSON.stringify(setCodeDataJson));
            // }
            
            // var urlString = zip.generate({type:"base64"});
            // var base64 = new Buffer.concat(data).toString('base64');
            // let key = setCode+"/"+filename;              
            // zip.file(key, base64, {base64:true, type:"image/png"});
            
            } catch (err) {
                console.log("ERROR", err);
                // reject("");
            }

        //    await zip.generateAsync({type:"base64"})
        //    .then(function(content) {
        //         var zipName = 'download.zip';
        //         var a = document.createElement('a'); 
        //         a.href = "data:application/zip;base64," + content;
        //         a.download = zipName;
        //         a.click();               
        //    });
            
            // id:result[columnIndex("id")],
            // asin:result[columnIndex("asin")],
            // title:result[columnIndex("title")],
            // description:result[columnIndex("description")],
            // image:result[columnIndex("image")],
            // author:result[columnIndex("author")],
            // brand:result[columnIndex("brand")],
            // featured:result[columnIndex("featured")],
            // price:result[columnIndex("price")],
            // units:result[columnIndex("units")],
            // quantity:result[columnIndex("quantity")],
            // type:result[columnIndex("type")],
            // subtype:result[columnIndex("subtype")],
            // status:result[columnIndex("status")],            
            // salePrice:result[columnIndex("sale_price")],
            // salePriceEndDate:result[columnIndex("sale_price_end_date")],
            // moreDetails:result[columnIndex("more_details")],
            // keyword:result[columnIndex("keyword")],
            // series:result[columnIndex("series")],
            // set:result[columnIndex("set")],
            // packs:result[columnIndex("packs")],
            // limit:result[columnIndex("limit")],
            // releaseDate:result[columnIndex("release_date")],
            // allowPreorder:result[columnIndex("allow_preorder")],     

        }

        setBusy(false);
}

const calculateDifference = (current, update) =>{    
    if (current == undefined || current == 0 || current == "0" || !current) {
                
        return <span style={{color:"green"}}>{(update * 1).toFixed(2) + "%" }  <TrendingUp/> </span>;
    }else {      
        let result = (update - current) / current * 100;
        if (update == current) {
            return <span>{result.toFixed(2) + "%"} <TrendingFlat/> </span>;
        }else if (update > current) {
            return <span style={{color:"green"}}>{result.toFixed(2) + "%"} <TrendingUp/> </span>;
        } else {
            return <span style={{color:"red"}}>{result.toFixed(2) + "%"} <TrendingDown/> </span>;
        }        
    }    
}

const updateCardPrices = ()=>{
    // updatedProductPrices
    for (let i = 0; i < updatedProductPrices.length; i++) {
        let updatedPrice = updatedProductPrices[i];        
        updatedPrice["update"].price = updatedPrice["update"].marketPrice;    
        console.log("Updated price:", updatedPrice["update"].price);
    }

    forceUpdate();
}


    const displayCompareList = ()=> {

        // let objKeys = Object.keys(displayCompareUpdate);
        
        console.log("updatedProductPrices:", updatedProductPrices);

        return(
            <>
                {
                    updatedProductPrices.map(obj=>{

                        return <>
                        
                        <div className="compareColumnRow">

                                 <div className="compareColumn">
                                
                                    <img src={"./images/cards/"+ obj["current"].image} style={{ width:"40px"}}/>
                                
                            </div>


                            <div className="compareColumn">
                                {
                                obj["current"].marketPrice ? obj["current"].marketPrice : <>0.00</>
                                }
                            </div>
                            
                            <div className="compareColumn">
                                {
                                obj["update"].marketPrice ? obj["update"].marketPrice : <>0.00</>
                                }
                            </div>
                        
                            <div className="compareColumn">
                                {
                                    calculateDifference(parseFloat(obj["current"].marketPrice), parseFloat(obj["update"].marketPrice))
                                }
                            </div>

                        </div>
                        </>
                    })
                }

             
            </>
        )
        
    }


    const displayAddAsinPopup = ()=>{
        return (<>
         {displayInputField("asin", "asin", "asin", addAsinInput, ()=>{
             console.log("ENTER PRESSED FOR ", addAsinInput)
         }, (e)=>{

            if (e.target.value) {

                console.log("displayAddAsinPopup On change", e.target.value);

                setAddAsinInput(e.target.value);

            }
             
         })
         }
        </>);
    }

    const displayTotalOrders = ()=>{

        let pending = 0;
        let cancelled = 0;
        let awaitingFulfillment = 0;
        let shipped = 0;

        if (orders) {
            for (let i = 0; i < orders.length; i++) {
                let order = orders[i];
                if (order.status == "Pending") {
                    pending++;
                }
                if (order.status == "Cancelled") {
                    cancelled++;
                }
                if (order.status == "Awaiting Fulfillment") {
                    awaitingFulfillment++;
                }
                if (order.status == "Shipped") {
                    shipped++;
                }
            }
            return<>
                <div className="label-large">Pending <span className="label-number-tag">{pending}</span></div>
                <div className="label-large">Awaiting Fulfillment <span className="label-number-tag">{awaitingFulfillment}</span></div>
                <div className="label-large">Shipped <span className="label-number-tag">{shipped}</span></div>
                <div className="label-large">Canceled <span className="label-number-tag">{cancelled}</span></div>
            </>    
        }
    }
    
    const displayTotalProducts = ()=>{

        let total = 0;
        let totalActive = 0;

        if (products) {
            for (let i = 0; i < products.length; i++) {
                let product = products[i];
                if (product.quantity > 0) {
                    totalActive++;
                }
                total++;
            }
            return<>
                <div className="label-large">Total {total}</div>
                <div className="label-large">Active {totalActive}</div>
            </>    
        }
    }

const displayProductFilters = ()=>{
  return <AdminProductFilters updateFilters={updateFilters}/>
}

const displayOrderFilters = ()=>{
    return <AdminOrderFilters updateFilters={updateOrderFilters} currentFilters={orderFilters}/>
  }

  
const updateFilters =(filters)=>{
    // console.log("update filters:", filters);
    setProductFilters(filters);
    forceUpdate();
}

const updateOrderFilters =(filters)=>{
    // console.log("update filters:", filters);
    setOrderFilters(filters);
    forceUpdate();
}


const displayMessage = ()=> {
    return (<>
        {              
            displayInputField("To:", "black", "Email", ()=>{

            })
        }     
        {              
            displayInputField("Message:", "black", "Message", ()=>{

            })
        }     
        <Button          
        className="modal-button"
        size="small" color="secondary" variant="contained"
        onClick={async ()=>{
            
            console.log("send message order");

            if (window.confirm('Are you sure you wish to send the message?'))  {

                await API.post("yayfunthingsapi", "/items/sendEmail", { 
                    body: 
                    {  
                       email:"eblackmouth@gmail.com", 
                       data:`<div style="border:1px solid #ccc; max-width:680px;"> 
                       <div style="background-color:#2196f3; background-repeat:no-repeat; background-image:url(https://www.yaypokemon.com/images/yayfun-head.svg)">         
                        <p style="text-align:center; margin:0em; color:#fff; height:3em; font-size:1.5em; padding-top:1em; font-weight:bold;"> Contact regarding your Order #2420-013101-0802 </p>                   
                       </div>
                       
                       <div style="margin:2em;margin-bottom:0em">
                            <div>

                            <p style="margin-bottom:0.1em">
                            
                            <br/> Thank you for placing your order. We have received your message regarding the shipping address. The item has not shipped so we will cancel the order and give you a full refund if you have been charged. <br/> <br/> We hope you visit us again soon and attempt to place your order again.
                            </p>
                            
                            </div>
                            <div style="padding-top:1em;margin-top:8em;margin-bottom:1em">`+
                       '<p>This email was sent by <a href="https://www.yaypokemon.com/">yaypokemon.com</a></p></div>'+
                       '<div></div>', 
                       subject:`Your Recent Order` } }).then(result => {
                                console.log(result)
                }).catch(e=>{console.log(e)});
                }
        
              
            
        }}>Send Message</Button>
    </>);

}


const displayCapturePaymentButton = (order)=>{

    if (order.paymentMethod == "PayPal") {               
        return (<></>);
    }

    return (<Button          
        className="modal-button"
        size="small" color="secondary" variant="contained"
        onClick={async ()=>{
            console.log("displayCapturePaymentButton order", order);

            if (window.confirm('Are you sure you wish to capture the payment?'))  {


                if (order.paymentMethod == "PayPal") {                    
                    await API.post("yayfunthingsapi", "/items/capturePayPalOrder", { 
                        body:{orderId:order.paymentIntentId}
                    }).then(async result => {
                      
                        console.log("capturePayPalOrder result:", result);


                        if (result.result && result.result.status == "COMPLETED") {

                        await insertOrderHistoryChange(order, "Captured Payment with Result " + result.result.status);
                                

                         console.log("order:", order);
                             
                         await updateOrderWithInput({id:order.id, status:"Awaiting Fulfillment"}).then(result=>{                                    
                             if (result.data && result.data.updateOrder) {
                                 // sendOrderShippedEmail(order, packageItem.trackingNumber);   
                             }
                         }).catch(e=>{console.log(e)})
         
         
                        }else {
         
                        //  if (result.raw && result.raw.message ) {         
                             await insertOrderHistoryChange(order, "Error Capturing Payment with Result " + result.message);
                             sendAlertMessage(result.message);
                        //  }
                        }


                    }).catch(e=>{console.log(e)});
                }else {


                    await API.post("yayfunthingsapi", "/items/capturePayment", { 
                        body:{paymentIntentId:order.paymentIntentId}
                    }).then(async result=>{
                        
                       console.log("capturePayment Result:", result);
        
                       setCapturePaymentResult(result);
        
                       await insertOrderHistoryChange(order, "Captured Payment with Result " + result.status);
        
                       if (result.status == "succeeded") {
        
                        console.log("order:", order);
                            
                        await updateOrderWithInput({id:order.id, status:"Awaiting Fulfillment"}).then(result=>{                                    
                            if (result.data && result.data.updateOrder) {
                                // sendOrderShippedEmail(order, packageItem.trackingNumber);   
                            }
                        }).catch(e=>{console.log(e)})
        
        
                       }else {
        
                        if (result.raw && result.raw.message ) {
        
                            await insertOrderHistoryChange(order, "Error Capturing Payment with Result " + result.raw.message);
        
                            sendAlertMessage(result.raw.message);
                        }
                       }
                    }).catch(e=>{
                        console.log(e);
                    });
                                }
            
          
            }



           
        }}>Capture Payment</Button>)
    }


    const displayCheckPaymentIntentButton = (order)=>{

        
        return (<Button          
            className="modal-button"
            size="small" color="secondary" variant="contained"
            onClick={async ()=>{

                console.log("displayCheckPaymentIntentButton:", order);

                if (order.paymentMethod == "PayPal") {                    
                    await API.post("yayfunthingsapi", "/items/getPayPalOrder", { 
                        body:{orderId:order.paymentIntentId}
                    }).then(async result => {
                        console.log("getPayPalOrder result:", result);


                        if (result.statusCode == 200) {
                            // result.status
                            
                            let urlString = "";
                            let amount = 0;

                            for(let i = 0; i < result.result.links.length; i++) {
                                let stringUrl = result.result.links[i].href;
                                urlString +=  (" " + stringUrl);
                            }

                            for(let i = 0; i < result.result.purchase_units.length; i++) {
                                let unit = result.result.purchase_units[i];
                                amount += parseFloat(unit.amount.value);
                            }

                            await insertOrderHistoryChange(order, "Checked Payment with status " + result.result.status + " intent " + result.result.intent +  " amount " + amount + " urls " + urlString);
  
                        }
                    }).catch(e=>{
                        console.log(e);
                    });                    
                 } else {


                    await API.post("yayfunthingsapi", "/items/checkPaymentIntent", { 
                        body:{paymentIntentId:order.paymentIntentId}
                    }).then(async result => {
                       console.log("checkPayment Result:", result);
                       console.log("order:", order);
                       
                       setCheckPaymentResult(result);
                      
                       await insertOrderHistoryChange(order, "Checked Payment with status " + result.status + " amount " +  ConvertStringToDollarsCents(result.amount/100));
    
                       if (result.status == "succeeded") {
                        
                        console.log("order:", order);
    
    
                        if (order.status == "Pending") {
                            await updateOrderWithInput({id:order.id, status:"Awaiting Fulfillment"}).then(async result=>{                                    
                                // if (result.data && result.data.updateOrder) {
                                //     // sendOrderShippedEmail(order, packageItem.trackingNumber);   
                                // }
    
                                await insertOrderHistoryChange(order, "Succeeded Changing Status to Awaiting Fulfillment");
    
                                console.log("Result:", result);
                            }).catch(e=>{console.log(e)});    
                        }
                        // await updateOrderWithInput({id:order.id, status:"Awaiting Fulfillment"}).then(result=>{                                    
                        //     if (result.data && result.data.updateOrder) {
                        //         // sendOrderShippedEmail(order, packageItem.trackingNumber);   
                        //     }
                        // }).catch(e=>{console.log(e)})
                       }else {
                        if (result.raw && result.raw.message ) {
                            sendAlertMessage(result.raw.message);
                        }
                      }
    
    
        
                    }).catch(e=>{
                        console.log(e);
                    });
    
                }
                
                



            }}>Check Payment</Button>)
        }



        

        const displayCaptureAuthorization = (order)=>{

            if (order.paymentMethod != "PayPal") {                    

                return (<></>);
            }

            return (<Button          
                className="modal-button"
                size="small" color="secondary" variant="contained"
                onClick={async ()=>{
                        if (order.paymentMethod == "PayPal") {                    
                            await API.post("yayfunthingsapi", "/items/capturePayPalAuthorization", { 
                                body:{authorizationId:order.authorizationId}
                            }).then(async result => {
                                console.log("authorizePayPalOrder result:", result);
                                if (result.result) {
                                    await insertOrderHistoryChange(order, "Captured Authorization Result: " + JSON.stringify(result.result));
                                    await updateOrderWithInput({id:order.id, status:"Awaiting Fulfillment"}).then(result => {                                    
                                    }).catch(e=>{console.log(e)})
                                    
                                } else if (result.message) {
                                    await insertOrderHistoryChange(order, "Captured Authorization Error: " + result.message);
                                    sendAlertMessage(result.message);
                                }
                            }).catch(e=>{console.log(e)});
                        }
                }}>Capture Authorization</Button>)

        }


        const displayAuthorizePaymentButton = (order)=>{
            return (<Button          
                className="modal-button"
                size="small" color="secondary" variant="contained"
                onClick={async ()=>{
                    console.log("displayAuthorizePaymentButton order", order);
        
                        if (order.paymentMethod == "PayPal") {                    
                            await API.post("yayfunthingsapi", "/items/authorizePayPalOrder", { 
                                body:{orderId:order.paymentIntentId}
                            }).then(async result => {
                              
                                console.log("authorizePayPalOrder result:", result);
        
                                // await insertOrderHistoryChange(order, "Error Capturing Payment with Result " + result.message);
        
                                // if (result.result && result.result.status == "COMPLETED") {
        
                                // await insertOrderHistoryChange(order, "Captured Payment with Result " + result.result.status);
                                        
        
                                //  console.log("order:", order);
                                     
                                //  await updateOrderWithInput({id:order.id, status:"Awaiting Fulfillment"}).then(result=>{                                    
                                //      if (result.data && result.data.updateOrder) {
                                //          // sendOrderShippedEmail(order, packageItem.trackingNumber);   
                                //      }
                                //  }).catch(e=>{console.log(e)})
                 
                 
                                // }else {
                 
                                // //  if (result.raw && result.raw.message ) {         
                                //      await insertOrderHistoryChange(order, "Error Capturing Payment with Result " + result.message);
                                //      sendAlertMessage(result.message);
                                // //  }
                                // }
        
        
                            }).catch(e=>{console.log(e)});
                        }
                    
                   
                }}>Authorize Payment</Button>)
            }
        
const displayValidatePaymentButton = (order)=>{
    return (<Button          
        className="modal-button"
        size="small" color="secondary" variant="contained"
        onClick={async ()=>{
            console.log("displayValidatePaymentButton order", order);


                if (order.paymentMethod == "PayPal") {                    
                    await API.post("yayfunthingsapi", "/items/verifyPayPalOrder", { 
                        body:{orderId:order.paymentIntentId}
                    }).then(async result => {
                      
                        console.log("capturePayPalOrder result:", result);

                        // await insertOrderHistoryChange(order, "Error Capturing Payment with Result " + result.message);

                        // if (result.result && result.result.status == "COMPLETED") {

                        // await insertOrderHistoryChange(order, "Captured Payment with Result " + result.result.status);
                                

                        //  console.log("order:", order);
                             
                        //  await updateOrderWithInput({id:order.id, status:"Awaiting Fulfillment"}).then(result=>{                                    
                        //      if (result.data && result.data.updateOrder) {
                        //          // sendOrderShippedEmail(order, packageItem.trackingNumber);   
                        //      }
                        //  }).catch(e=>{console.log(e)})
         
         
                        // }else {
         
                        // //  if (result.raw && result.raw.message ) {         
                        //      await insertOrderHistoryChange(order, "Error Capturing Payment with Result " + result.message);
                        //      sendAlertMessage(result.message);
                        // //  }
                        // }


                    }).catch(e=>{console.log(e)});
                }
            
           
        }}>Validate Payment</Button>)
    }


        
const displayVerifyAddressButton = (order)=>{
    return (<Button          
        className="modal-button"
        size="small" color="secondary" variant="contained"
        onClick={async ()=>{

            let postMsg = {
                body:{
                  address1:order.shippingAddress.addressLine1, 
                  address2:order.shippingAddress.addressLine2, 
                  city:order.shippingAddress.city, 
                  state:order.shippingAddress.state, 
                  zip5:order.shippingAddress.postalCode
                }
              }
          
             let result = API.post("yayfunthingsapi", "/items/verifyAddress", postMsg).then(async results => {
                 
                    console.log("verifyAddress results:", results);

                    if (results.address.error) {
                        await insertOrderHistoryChange(order, "Verify Address Error" + JSON.stringify(results.address));

                      return false;
                    }
                    console.log("Results:", results);

                    await insertOrderHistoryChange(order, "Verify Address " + JSON.stringify(results.address));

                return true;
              }).catch(e=>{    
                return false;
              });
          
              return result;
          
        }}>Verify Address</Button>)
    }

        
const displayBackButton = ()=>{
    return (<Button          
                className="modal-button"
                size="large" color="primary" variant="outlined"
                onClick={()=>{
                    setOrderDetailShown(null);
                    setCheckPaymentResult(null);
                    setCapturePaymentResult(null);
                }}>Back</Button>)
}


const insertOrderHistoryChange = async (order, description)=>{

    if (order.history) {

        let updatedHistory = [...order.history];
    
        updatedHistory.push({
            createdAt:moment(),
            description:description
        })

        await updateOrderWithInput({id:order.id, history:updatedHistory});
        setOrderDetailShown({...orderDetailShown, history:updatedHistory});

    } else {
        let updatedHistory = [];
        updatedHistory.push({
            createdAt:moment().toISOString(),
            description:description
        })

        await updateOrderWithInput({id:order.id, history:updatedHistory});
        setOrderDetailShown({...orderDetailShown, history:updatedHistory});

    }
    
}

const displayAddProductPage = ()=>{    
    return displayAddAsinPopup()
    // return   displayInputField("addAsinInput", "black", "Asin", null)
    
}


return (
        <section className="container-inner-admin">

            <AmplifyAuthenticator>
                    {
                        orderDetailShown && Object.keys(orderDetailShown).length > 0 && orderDetailShown.constructor === Object ?                         
                        <>
                        {/* {showChangeStatus()} */}
                        
                        <div style={{marginLeft:'1em', textAlign:'left', paddingBottom:"2em", maxWidth:"5em"}}>
                        {displayBackButton()}
                        </div>

                        <div style={{display:"block", marginLeft:'auto', marginRight:'auto', width:"60%", border:"1px solid #ccc", borderRadius:"3px", padding:"2em", textAlign:"left"}}>
                            <div style={{display:"inline-block", width:"100%", borderRadius:"3px",paddingLeft:"0em", textAlign:"left"}}>
                               
                                <div className="admin-order-buttons" style={{width:"100%"}}>
                                {displayCheckPaymentIntentButton(orderDetailShown)}
                                {/* {displayAuthorizePaymentButton(orderDetailShown)} */}
                                {displayCaptureAuthorization(orderDetailShown)}
                                {displayVerifyAddressButton(orderDetailShown)}
                                {displayCapturePaymentButton(orderDetailShown)}
                                {displayCancelOrder(orderDetailShown)}
                                {displayCancelOrderNoEmail(orderDetailShown)}
                                {displayRefundOrder(orderDetailShown)}
                                {displayShowHistory()}
                                {/* {displayCheckTracking(orderDetailsShown)} */}
                                </div>

                                <div style={{width:"100%"}}>
                                {
                                    checkPaymentResult && checkPaymentResult.amount ? <>
                                    Amount {ConvertStringToDollarsCents(checkPaymentResult.amount/100)} { checkPaymentResult.status}
                                    </>:<></>
                                }
                                </div>

                                <div style={{width:"100%"}}>
                                {
                                    capturePaymentResult && capturePaymentResult.amount ? <>
                                    Amount {ConvertStringToDollarsCents(capturePaymentResult.amount/100)} { capturePaymentResult.status}
                                    </>:<></>
                                }

                                </div>
                                <div style={{width:"100%"}}>
                                {
                                    showHistory &&  orderDetailShown && orderDetailShown.history && orderDetailShown.history.length > 0 ? <>                                        
                                    {
                                        orderDetailShown.history.sort((a,b) => b.createdAt > a.createdAt).map(historyItem => {
                                            return <p>
                                            {
                                                moment(historyItem.createdAt).format("MM/DD/YY")
                                            }
                                                    &nbsp;
                                            {
                                               historyItem.description
                                            }
                                            </p>
                                        })
                                    }

                                    </>:<></>
                                }
                                </div>

                                

                            </div>

                        </div>





                        <div style={{display:"block", marginLeft:'auto', marginRight:'auto', border:"1px solid #ccc", width:"60%", borderRadius:"3px", padding:"2em", marginTop:"2em", textAlign:"left"}}>                          
                            <div style={{paddingTop:"1em"}}>
                            <Button          
                            className="modal-button"
                            size="large" color="primary" variant="contained"
                            onClick={async ()=> {     
                                setEmailCustomerDisplayOpen(!emailCustomerDisplayOpen)
                            }}>
                               
                               {
                                   emailCustomerDisplayOpen ? <>Cancel </>: <>Email Customer</>
                               }
                           </Button>


                            {
                            emailCustomerDisplayOpen ?
                                 displaySendEmail(orderDetailShown) :<></>
                          }
                       
                            </div>
                        </div>


                        <div style={{display:"block", marginLeft:'auto', marginRight:'auto', border:"1px solid #ccc", width:"60%", borderRadius:"3px", padding:"2em", marginTop:"2em", textAlign:"left"}}>                          
                            <div style={{paddingTop:"1em"}}>
                            {displayUpdateShippingPaid(orderDetailShown)}
                            <Button          
                            className="modal-button"
                            size="large" color="primary" variant="contained"
                            onClick={async ()=>{       
                                await updateOrderWithInput({id:orderDetailShown.id, shippingPaid:formData.shippingPaid}) 
                            }}>
                               Update
                           </Button>
                            </div>
                        </div>

                        <div style={{display:"block", marginLeft:'auto', marginRight:'auto', border:"1px solid #ccc", width:"60%", borderRadius:"3px", padding:"2em", marginTop:"2em", textAlign:"left"}}>
                          
                          <div style={{width:"60%", marginLeft:"auto", marginRight:"auto", paddingBottom:"2em"}}>
                            {displayCreatePackage(orderDetailShown)}
                            </div>
                        
                             <div style={{paddingTop:"1em"}}>
                            {displayCreateCodeCard(orderDetailShown)}
                            </div> 
                        
                        <div style={{width:"60%", marginLeft:"auto", marginRight:"auto"}}>
                        { orderDetailShown.packages ? <> {showPackages(orderDetailShown)}</>: <>No Packages Created</> }        
                        </div>

                        <div style={{width:"60%", marginLeft:"auto", marginRight:"auto"}}>
                        { orderDetailShown.codes ? <> {showCodes(orderDetailShown)}</>: <>No Codes Created</> }        
                        </div>
                        </div>

                        <OrderDetails order={orderDetailShown}/> 
                                            
                        </> :                         
                        <>
                        <div style={{display:"block"}}>

                        {/* <div className="admin-buttons">
                            
                            <Button          
                            disabled={currentTab == "Orders" ? true : false}                           
                            className="modal-button"
                            size="large" color="primary" variant="contained"
                            onClick={()=>{                                
                                setCurrentTab("Orders");                            
                            }}>
                               Orders
                           </Button>

                           <Button                  
                            className="modal-button"
                            disabled={currentTab == "Products" ? true : false}                           
                            size="large" color="primary" variant="contained"
                            onClick={ async ()=>{
                                if (!hasCheckedProducts) {
                                    setHasCheckedProducts(true);
                                    setSyncing(true);
                                   await fetchProducts();
                                   setSyncing(false);
                                }
                                setCurrentTab("Products")
                            }}>
                               Products
                           </Button>


                           <Button                  
                            className="modal-button"
                            disabled={currentTab == "Cards" ? true : false}                           
                            size="large" color="primary" variant="contained"
                            onClick={()=>{
                                if (!hasCheckedProducts) {
                                    // setHasCheckedProducts(true);
                                    // fetchProducts();
                                }
                                setCurrentTab("Cards")
                            }}>
                               Cards
                           </Button>

                           <Button                  
                            className="modal-button"
                            disabled={currentTab == "Analytics" ? true : false}                           
                            size="large" color="primary" variant="contained"
                            onClick={()=>{
                                // if (!hasCheckedProducts) {
                                    // setHasCheckedProducts(true);
                                    // fetchProducts();
                                // }
                                setCurrentTab("Analytics")
                            }}>
                               Analytics
                           </Button>

                           <Button                  
                            className="modal-button"
                            disabled={currentTab == "Message" ? true : false}                           
                            size="large" color="primary" variant="contained"
                            onClick={()=>{
                                // if (!hasCheckedProducts) {
                                    // setHasCheckedProducts(true);
                                    // fetchProducts();
                                // }
                                setCurrentTab("Message")
                            }}>
                               Message
                           </Button>
                           <div>
                            {
                              currentTab == "Orders" ?  displayTotalOrders() :<></>
                            }                          
                          </div>

                          <div>
                            {
                              currentTab == "Products" ?  displayTotalProducts() :<></>
                            }                          
                          </div>
                          
                           <div>
                            {
                             currentTab == "Orders" || currentTab == "Products" ?  displaySearchInput() :<></>
                            }                          
                           </div>

                           <div>
                            {
                              currentTab == "Products" ?  displayProductFilters() :<></>
                            }                          
                            {
                              currentTab == "Orders" ?  displayOrderFilters() :<></>
                            } 
                            {
                                currentTab == "Message" ? displayMessage() :<></>
                            }
                          </div>

                        </div>
                    </div> */}
                 
                    {/* <div>                     */}

                    {
                        displayOpenMenuButton()
                    }
                    {
                        displayDrawer()
                    }

                    <div style={{marginLeft:"1em", marginRight:"1em"}}>

                            {
                                displayActionButtons()
                            }
                           <div style={{zIndex:"100", textAlign:"left", backgroundColor:"white"}}>
                            
                            { 
                              currentTab == "Orders" ?  displayTotalOrders() :<></>
                            }                          

                            {
                              currentTab == "Products" ?  displayTotalProducts() :<></>
                            }                          
                            
                            {
                             currentTab == "Orders" || currentTab == "Products" ?  displaySearchInput() :<></>
                            }

                            {
                              currentTab == "Products" ?  displayProductFilters() :<></>
                            }                          

                            {
                              currentTab == "Orders" ?  displayOrderFilters() :<></>
                            } 

                           </div>
                           <div>
                            
                            {
                              currentTab == "Message" ? displayMessage() :<></>
                            }
                        </div>
                            {
                                displayCurrentTab()
                            }
                        </div>
                    </div>
                        
                        </>
                    }
                    
                  
                    <div>
                    
                    {/* <CsvParser dataHandler={dataHandler} /> */}

                    {/* <header className="form-header">
                        <h3>Add New Product</h3>
                    </header>
                    <form className="form-wrapper" onSubmit={handleSubmit}>
                        <div className="form-image">
                            {image ? <img className="image-preview" src={image} alt="" /> : <input
                                type="file"
                                accept="image/jpg"
                                onChange={(e) => handleImageUpload(e)} />}
                        </div>
                        <div className="form-fields">
                            <div className="title-form">
                                <p><label htmlFor="title">Title</label></p>
                                <p><input
                                    name="email"
                                    type="title"
                                    placeholder="Type the title"
                                    onChange={(e) => setProductDetails({ ...productDetails, title: e.target.value })}
                                    required
                                /></p>
                            </div>
                            <div className="description-form">
                                <p><label htmlFor="description">Description</label></p>
                                <p><textarea
                                    name="description"
                                    type="text"
                                    rows="8"
                                    placeholder="Type the description of the product"
                                    onChange={(e) => setProductDetails({ ...productDetails, description: e.target.value })}
                                    required
                                /></p>
                            </div>
                            <div className="author-form">
                                <p><label htmlFor="author">Author</label></p>
                                <p><input
                                    name="author"
                                    type="text"
                                    placeholder="Type the author's name"
                                    onChange={(e) => setProductDetails({ ...productDetails, author: e.target.value })}
                                    required
                                /></p>
                            </div>
                            <div className="price-form">
                                <p><label htmlFor="price">Price ($)</label>
                                    <input
                                        name="price"
                                        type="text"
                                        placeholder="What is the Price of the book (USD)"
                                        onChange={(e) => setProductDetails({ ...productDetails, price: e.target.value })}
                                        required
                                    /></p>
                            </div>
                            <div className="featured-form">
                                <p><label>Featured?</label>
                                    <input type="checkbox"
                                        className="featured-checkbox"
                                        checked={productDetails.featured}
                                        onChange={() => setProductDetails({ ...productDetails, featured: !productDetails.featured })}
                                    />
                                </p>
                            </div>
                            <div className="submit-form">
                                <button className="btn" type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                     */}
                </div>
                <div>
                    {
                        displayAddSingle ? <>
                        
                        <Dialog
                            open={displayAddSingle}
                            // TransitionComponent={Transition}
                            style={{ padding: "0px !important" }}
                            keepMounted
                            onClose={() => {                                
                                setDisplayAddSingle(false);                                
                            }}                        
                        >
                            <DialogContent>                            
                                <AddSingleDisplay displaySelected={displaySelected} setDisplaySelected={setDisplaySelected}/> 
                            </DialogContent>
                         
                            <DialogActions>
                         
                            <span onClick={()=>{
                                setDisplaySelected(true)
                            }}>
                            {
                            displaySelected ? <>
                             <span className="card-select-text">{
                                "" + cardsSelected.length + " Selected"
                            }</span>                           
                            </> :<>
                            <span className="card-select-text">{
                                "View " + cardsSelected.length + " Selected"
                            }</span>
                            </>
                            }&nbsp;
                            </span>                            
                            {
                                displaySelected ? <>
                                
                            
                                 <Button size="small" variant="contained" onClick={() => {
                                    setDisplaySelected(false);
                                }}>Back</Button>
                                
                                <Button size="small" variant="contained" disabled={busy} onClick={() => {
                                    updateSelectedCardsToDatabase()
                                }}>Update Selected</Button>

                                {/* <Button size="small" variant="contained" disabled={busy} onClick={() => {
                                    downloadSelectedImages()
                                }}>Download Images</Button> */}
                                

                                <Button size="small" variant="contained" disabled={busy} onClick={() => {
                                    addSelectedCardsToDatabase()
                                }}>Add</Button>
                                
                                <Button size="small" variant="contained" disabled={busy} onClick={() => {
                                    addSelectedCardsToDatabaseWithoutImages()
                                }}>Add Without Images</Button>

                   

                                <Button size="small" variant="contained" disabled={busy} onClick={() => {
                                    addSelectedCardsToGoogleSheet()
                                }}>Update Google Only</Button>

                                </>
                                
                                :<></>

                                
                            }           
                                             
                            </DialogActions>
                        </Dialog>                                           
                        </> :<></>
                    }
                </div>

                <div>
                    {
                        displayResults ? <>
                         
                         <Dialog
                            open={displayResults}
                            // TransitionComponent={Transition}
                            style={{ padding: "0px !important" }}
                            keepMounted
                            onClose={() => {                                
                                setDisplayCompareUpdate(false);                                
                            }}                        
                        >
                            <DialogContent>                            
                               {
                                   displayUpdateResults()
                               }
                            </DialogContent>
                         
                            <DialogActions>
                                             
                            <Button size="small" variant="contained" onClick={() => {                                
                                setDisplayResults(false);
                            }}>Close</Button>                                
                            
                            </DialogActions>
                        </Dialog>     
                        </> :<></>
                    }
                    {
                        addingProduct ? <>
                          <Dialog
                            open={addingProduct}
                            // TransitionComponent={Transition}
                            style={{ padding: "0px !important" }}
                            keepMounted
                            onClose={() => {                                
                                setAddingProduct(false);                                
                            }}                        
                        >
                            <DialogContent>                            
                                {
                                    displayAddProductPage()
                                }
                               
                            </DialogContent>
                         
                            <DialogActions>
                                             
                            <Button size="small" variant="contained" onClick={() => {
                                
                                console.log("Add product");

                            }}>Add Product</Button>                                

                            <Button size="small" variant="contained" onClick={() => {
                                
                            }}>Close</Button>                                
                            
                            </DialogActions>
                        </Dialog>    
                        </> :<></>
                    }
                    {
                        displayCompareUpdate ? <>
                        
                        <Dialog
                            open={displayCompareUpdate}
                            // TransitionComponent={Transition}
                            style={{ padding: "0px !important" }}
                            keepMounted
                            onClose={() => {                                
                                setDisplayCompareUpdate(false);                                
                            }}                        
                        >
                            <DialogContent>                            
                               
                               {
                                   displayCompareList()
                               }
                            </DialogContent>
                         
                            <DialogActions>
                                             
                            <Button size="small" variant="contained" onClick={() => {
                                updateCardPrices()
                            }}>Update</Button>                                

                            <Button size="small" variant="contained" onClick={() => {
                                
                            }}>Close</Button>                                
                            
                            </DialogActions>
                        </Dialog>     

                        </> :
                        
                        <>
                        {
                             displayEditFieldPopup ? <>
                             <UpdatePopupDisplay setShouldDisplay={setDisplayEditFieldPopup} details={displayPopupDetails} />
                          </> :<></>
                        }
                         {
                             displayAddAsin ? <>
                                <AddAsinDisplay setDisplayAddAsin={setDisplayAddAsin} />
                             </> :<></>
                         }
                         {
                             displayEmailPreview ? <>
                                <PreviewEmail details={displayPreviewDetails} setDisplayPreview={setDisplayEmailPreview} insertOrderHistoryChange={insertOrderHistoryChange}/>
                             </> : <></>
                         }

                        </>
                    }
                </div>
            </AmplifyAuthenticator>
        </section>
    )
}

const Admin = ()=> {
    return(<AdminProvider>
            <AdminExt/>
        </AdminProvider>)
}

export default Admin