import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useScrollBox from '../hooks/useScrollBox';

function ScrollBox({ children, notifyIsAtEnd } ) {
  
  const scrollWrapperRef = useRef();
  const { isDragging, isAtEnd, speed } = useScrollBox(scrollWrapperRef);
  
  useEffect(()=>{
      if (isAtEnd) {
        if (notifyIsAtEnd) {
          notifyIsAtEnd();
        }
      }
  },[isAtEnd]);

  
  return (
    <div className="scroll-box">
      <div className="scroll-box__wrapper" ref={scrollWrapperRef}>
        <div className="scroll-box__container" role="list" style={{ pointerEvents: isDragging ? 'none' : undefined }}>
          {children && Array.isArray(children) && children.map((child, i) => (
            <div role="listitem" key={`scroll-box-item-${i}`} className="scroll-box__item">
              {child}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

ScrollBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollBox;