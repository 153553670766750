
import React, { useContext } from 'react'
import CustomTextField from './CustomTextField';
import ProductContext from "../../context/products";
import AdminContext from "../../context/admin";

import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ConvertStringToDollarsCents } from '../Helper';
import moment from 'moment';
import TableInputCell from './TableInputCell';
import Storage from "@aws-amplify/storage";
import aws_config from "../../aws-exports";
import ProductImage from "../ProductImage";

import TableColumnEdit from './TableColumnEdit';

const CustomTextFieldInput = ({name, fieldName, id, defaultValue, currentValue, showLabel, updateProductFunc, updateAmazonPrice})=> {    
   
    return (<CustomTextField   
    className="search-input"
    style={{float:"left"}}                         
    key={"custom-input-"+id+"-"+name}
    properties={
    {
        name:name,
        labelName:showLabel ? name : '',
        error:'',
        variant:'outlined',
        key:id,
        currentValue:currentValue
    }
    }
    // defaultValue={defaultValue}    
    inputProps={{
        'aria-label': showLabel ? name : '',
    }}
    onKeyPress={(e) => {
    if (e.key === 'Enter') {
        console.log("Key enter " + name);
        //TODO: End propagation
        }
    }}
    onChange={
        e =>
        { 
            console.log("On Change", e.target.value)
            // console.log();
            if (e.target.value) {
                currentValue = e.target.value; 
                updateProductFunc(id, {[fieldName]:currentValue});                
            }else {
                currentValue = ''; 
                updateProductFunc(id, {[fieldName]:''});
            }
        }
    }
/>)
}

const customInputTextField = (name, fieldName, id, defaultValue, currentValue, showLabel, updateProductFunc) => {    
    return (
       <CustomTextFieldInput key={name} name={name} fieldName={fieldName} id={id} defaultValue={defaultValue} 
       currentValue={currentValue} showLabel={showLabel} updateProductFunc={updateProductFunc} />
    );
}

export function GetColumnDataForProductType(type, updateProductFunc, saveProductFunc) {

    function customButton(values, edited) {
        return (
            <Button className="checkout-place-order" color={edited ? "primary" : "secondary"} variant="contained" onClick={ async() => {
                // fix it
                // save should save the edited cell values
                console.log("updateProduct with values:",values);
                // console.log("finished updateProduct");
                // if (edited) {     
                //     values = {...values, edited:false};           
                //     updateProductFunc(values.id, values)    
                //     saveProductFunc(values.id, values);
                // }

                saveProductFunc(values.id, values);

        }}> Save
          {/* {loading ? <CircularProgress style={{scale:"1"}} color="white" /> : 'Order Now'} */}
        </Button>
        )
    }

    // async function getImage(values) {
    //     if (type == "Singles") {            
    //         if (values.imageAsset) {             
    //             let result = await Storage.get(values.imageAsset, { level: 'public', bucket: aws_config.aws_user_files_s3_bucket, 
    //             region:aws_config.aws_user_files_s3_bucket_region});

    //             return result;
    //         } else {
    //             return "./images/cards/" + values.image;   
    //         }            
    //     }
    //     return values.image;
    // }



    const calculateProfit = (product)=>{

        if (product.price > 0) {
            let profit = (product.price - product.cost).toFixed(2);
            let percentProfit = ((profit/product.price) * 100).toFixed(2);
         return <><p>{profit}</p><p>{percentProfit}%</p></>
        }
        return <></>
    }
    
    if (type == "Products" || type == "Singles") {
        return [
            {
                Header: ' ',
                Cell:row=><></>,     
                draggable: true,       
                id:"mainHeader",
                columns: [
                    {
                        Header: 'Edited',
                        accessor: 'edited',
                        Cell:row=><>
                           
    
                        <Button size="small" className="checkout-place-order" color={row.row.values.edited ? "primary" : "secondary"} variant="contained" onClick={ async() => {
                                        saveProductFunc(row.row.values.id, row.row.values);
                                }}> Save
                                {/* {loading ? <CircularProgress style={{scale:"1"}} color="white" /> : 'Order Now'} */}
                                </Button>
                            {
                            //     type == "Singles"  ? <>
                            //    <Button size="small" className="checkout-place-order" color={"secondary"} variant="contained" onClick={ async() => {
                                
                            //     console.log("Clicked make variation:", row.row.values)
                            //     // makeVariationForProduct(null, row.row.values.id)
                            //             }}> Make Variation
                            //             {/* {loading ? <CircularProgress style={{scale:"1"}} color="white" /> : 'Order Now'} */}
                            //             </Button>
    
                            //     </>:<>
                                    
                                    
                            //     <Button size="small" className="checkout-place-order" color={"secondary"} variant="contained" onClick={ async() => {                            
                                        
                            //             console.log("Clicked make variation:", row.row.values)
                                
                            //             if (window.confirm('Are you sure you want to save?')) 
                            //             {
                            //                 console.log("row.row.values:", row.row.values);
                            //              //   updateAmazonPrice("", row.row.values.price);
                            //             }
                            //         }}> Save Price
                            //     </Button>
                            
                            //     </>
                            }
                        </>
                    },
                    {
                        Header: 'Main Image',
                        accessor: 'image',
                        Cell:row=><>             
                        {/* <img className="table-image" src={getImage(row.row.values)} /> */}
                        <ProductImage product={{...row.row.original}} suffix="_120.png" customClassName="cart-image-order-complete"/>
                        </>
                    },
                    {
                        Header: 'Preorder',
                        accessor: 'allowPreorder',
                        Cell:row=><>             
                        {/* <img className="table-image" src={getImage(row.row.values)} /> */}                        
                        <TableColumnEdit propertyName="allowPreorder" product={row.row.original} currentValue={row.row.allowPreorder} />                        
                        {/* <ProductImage product={{...row.row.original}} suffix="_120.png" customClassName="cart-image-order-complete"/> */}
                        </>
                    },
                {
                    Header: 'ID',
                    accessor: 'id',
                },        
                {
                    Header: 'Title',
                    accessor: 'title',
                    Cell:row=><>{                            
                        <a target="_blank" href={`./cards/${row.row.original.id}`} >{row.row.values.title}</a>
                    }</>
                },  
                {
                    Header: 'ASIN',
                    accessor: 'asin',
                    Cell:row=><>{                        
                        // customInputTextField('asin', 'asin', row.row.values.id, row.row.values.asin, row.row.values.asin, false, updateProductFunc)
                        <TableInputCell key="input-asin" variant="outlined" name="asin" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.asin} />
                        }</>
                },     
                
                {
                    Header: 'Quantity',
                    accessor: 'quantity',                    
                    Cell:row=><>
                    
                    <TableInputCell key="input-quantity" variant="outlined" name="quantity" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.quantity} />                    


                    </>
                },
                {
                    Header: 'Price',
                    accessor: 'price',
                    Cell:row=><>{
                         //customInputTextField('Price', 'price', row.row.values.id, row.row.values.price, row.row.values.price, false, updateProductFunc)
                        //  <TableInputCell variant="outlined" name="price"  onChange={updateProductFunc}  currentValue={row.row.values.price} />
                         <TableInputCell key="input-price" variant="outlined" name="price" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.price} />                    
                         }</>                    
                },    
                {
                    Header: 'Market Price',
                    accessor: 'marketPrice',
                    Cell:row=><>{
                        
                        // <TableInputCell variant="outlined" name="marketPrice"  onChange={updateProductFunc}  currentValue={row.row.values.marketPrice} />

                        <TableInputCell key="input-marketPrice" variant="outlined" name="marketPrice" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.marketPrice} />                    

                        // customInputTextField('Market Price', 'marketPrice', row.row.values.id, row.row.values.marketPrice, row.row.values.marketPrice, false, updateProductFunc)
                        
                        }</>                    
                },        

                {
                    Header: 'Min Price',
                    accessor: 'minPrice',
                    Cell:row=><>{ 
                            <TableInputCell key="input-minPrice" variant="outlined" name="minPrice" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.minPrice} />                    
                        }</>                    
                }, 
                {
                    Header: 'Max Price',
                    accessor: 'maxPrice',
                    Cell:row=><>{ 
                        <TableInputCell key="input-maxPrice" variant="outlined" name="maxPrice" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.maxPrice} />                    
                    }</>                    
                }, 
                {
                    Header: 'Cost',
                    accessor: 'cost',
                    Cell:row=><>{<TableInputCell key="input-cost" variant="outlined" name="cost" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.cost} />}</>
                }, 
                {
                    Header: 'Profit',
                    accessor: 'profit',
                    Cell:row=><>{ 

                        calculateProfit(row.row.values)
                        // <TableInputCell key="input-profit" variant="outlined" name="profit" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.profit} />                    
                        }</>                    
                },         
                {
                    Header: 'Units',
                    accessor: 'units',
                    Cell:row=><>{ 
                        
                        // <TableInputCell variant="outlined" name="units"  onChange={updateProductFunc}  currentValue={row.row.values.units} />
                        <TableInputCell key="input-units" variant="outlined" name="units" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.units} />                    

                        // customInputTextField('Units', 'units', row.row.values.id, row.row.values.units, row.row.values.units, false, updateProductFunc)
                        
                        }</>
                },  
                {
                    Header: 'Units Remaining',
                    accessor: 'unitsRemaining',
                    Cell:row=><>{ 
                        
                        // <TableInputCell variant="outlined" name="units"  onChange={updateProductFunc}  currentValue={row.row.values.units} />
                        <TableInputCell key="input-unitsRemaining" variant="outlined" name="unitsRemaining" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.unitsRemaining} />                    

                        // customInputTextField('Units', 'units', row.row.values.id, row.row.values.units, row.row.values.units, false, updateProductFunc)
                        
                        }</>
                },  
             
                {
                    Header: 'Limit',
                    accessor: 'limit',
                    Cell:row=><>
                    
                    {/* <TableInputCell variant="outlined" name="limit" id={row.row.values.id}  onChange={updateProductFunc} currentValue={row.row.values.limit} />                     */}
                    <TableInputCell key="input-limit" variant="outlined" name="limit" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.limit} />                    

                    </>


                },  

                {
                    Header: 'Weight',
                    accessor: 'weight',                    
                    Cell:row=><>
                    
                    <TableInputCell key="input-weight" variant="outlined" name="weight" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.weight} />                    


                    </>
                },

                {
                    Header: 'Msku',
                    accessor: 'msku',
                    Cell:row=><div key="input-msku">
                    {/* <TableInputCell variant="outlined" name="msku" id={row.row.values.id} onChange={updateProductFunc} currentValue={row.row.values.msku} />                     */}
                    <TableInputCell key="input-msku" variant="outlined" name="msku" id={row.row.values.id}  values={row.row.values} onChange={updateProductFunc} currentValue={row.row.values.msku} />                    

                    {
                        // customInputTextField('Msku', 'msku', row.row.values.id, row.row.values.msku, row.row.values.msku, false, updateProductFunc)
                    }                    
                    </div>
                }                  
                ]
            }
        ];
    }
    

    if (type == "Orders") {
        return [
            {
                Header: ' ',
                Cell:row=><></>,     
                draggable: false,       
                id:"mainHeader",
                columns: [
                    {
                    Header: 'Date',
                    accessor: 'date',
                    Cell:row=><>{ moment(row.row.values.date).format("MMMM DD, YYYY")}</>
                    },
                    {
                    Header: 'Id',
                    accessor: 'id',
                    Cell:row=><>{ "Order #" + row.row.values.id}</>                    
                    },  
                    {
                    Header: 'Total',
                    accessor: 'total',
                    Cell:row=><>{ ConvertStringToDollarsCents(row.row.values.total)}</>
                    },
                    {
                    Header: 'User',
                    accessor: 'user'
                    }                                              
                ]
            }
        ];
    }


    return [
        {
            Header: ' ',
            Cell:row=><></>,     
            draggable: false,       
            id:"mainHeader",
            columns: [
            {
                Header: 'ID',
                accessor: 'id'
            },
            {
                Header: 'Main Image',
                accessor: 'image',
                Cell:row=><>             
                {/* <img className="table-image" src={getImage(row.row.values)} /> */}
                <ProductImage product={{...row.row.original}} />
                </>
            },
            {
                Header: 'Title',
                accessor: 'title',
                Cell:row=><>{ customInputTextField('Title', 'title', row.row.values.id, row.row.values.title, row.row.values.title, false, updateProductFunc)}</>
            },                 
            {
                Header: 'Price',
                accessor: 'price',
                Cell:row=><>{ customInputTextField('Price', 'price', row.row.values.id, row.row.values.price, row.row.values.price, false, updateProductFunc)}</>                    
            },   
            
            {
                Header: 'Market Price',
                accessor: 'marketPrice',
                Cell:row=><>{ customInputTextField('Market Price', 'marketPrice', row.row.values.id, row.row.values.marketPrice, row.row.values.marketPrice, false, updateProductFunc)}</>                    
            },
            {
                Header: 'Min Price',
                accessor: 'minPrice',
                Cell:row=><>{ customInputTextField('Min Price', 'minPrice', row.row.values.id, row.row.values.minPrice, row.row.values.minPrice, false, updateProductFunc)}</>                    
            }, 
            {
                Header: 'Max Price',
                accessor: 'maxPrice',
                Cell:row=><>{ customInputTextField('Max Price', 'maxPrice', row.row.values.id, row.row.values.maxPrice, row.row.values.maxPrice, false, updateProductFunc)}</>                    
            }, 

            
            {
                Header: 'Median Price',
                accessor: 'medianPrice',
                Cell:row=><>{ customInputTextField('Median Price', 'medianPrice', row.row.values.id, row.row.values.medianPrice, row.row.values.medianPrice, false, updateProductFunc)}</>                    
            }, 
            {
                Header: 'Description',
                accessor: 'description',
                Cell:row=><>{ customInputTextField('Description', 'description', row.row.values.id, row.row.values.description, row.row.values.description, false, updateProductFunc)}</>
            },                 
            // {
            //     Header: 'Author',
            //     accessor: 'author',
            //     Cell:row=><>{ customInputTextField('Author', 'author', row.row.values.id, row.row.values.author, row.row.values.author, false, updateProductFunc)}</>
            // },
            // {
            //     Header: 'Brand',
            //     accessor: 'brand',
            //     Cell:row=><>{ customInputTextField('Brand', 'brand', row.row.values.id, row.row.values.brand, row.row.values.brand, false, updateProductFunc)}</>
            // },
            {
                Header: 'Units',
                accessor: 'units',
                Cell:row=><>{ customInputTextField('Units', 'units', row.row.values.id, row.row.values.units, row.row.values.units, false, updateProductFunc)}</>
            },  
            {
                Header: 'Quantity',
                accessor: 'quantity',
                Cell:row=><>{ customInputTextField('Quantity', 'quantity', row.row.values.id, row.row.values.quantity, row.row.values.quantity, false, updateProductFunc)}</>
            },  
            // {
            //     Header: 'Type',
            //     accessor: 'type',
            //     Cell:row=><>{ customInputTextField('Type', 'type', row.row.values.id, row.row.values.type, row.row.values.type, false, updateProductFunc)}</>
            // },
            // {
            //     Header: 'Subtype',
            //     accessor: 'subtype',
            //     Cell:row=><>{ customInputTextField('Subtype', 'subtype', row.row.values.id, row.row.values.subtype, row.row.values.subtype, false, updateProductFunc)}</>
            // },
            // {
            //     Header: 'Status',
            //     accessor: 'status',
            //     Cell:row=><>{ customInputTextField('Status', 'status', row.row.values.id, row.row.values.status, row.row.values.status, false, updateProductFunc)}</>
            // },
            // {
            //     Header: 'Featured',
            //     accessor: 'featured',
            //     Cell:row=><>{ customInputTextField('Featured', 'featured', row.row.values.id, row.row.values.featured, row.row.values.featured, false, updateProductFunc)}</>
            // },
            {
                Header: 'Edited',
                accessor: 'edited',
                Cell:row=><>
                    {                        
                        customButton(row.row.values, row.row.values.edited)
                    }
                </>
            }    
            ]
        }
    ];
}