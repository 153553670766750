import React, { Component } from 'react';
import { Main } from './components';
import Navigator from './components/Navigator';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Amplify from '@aws-amplify/core';
import aws_config from "./aws-exports";
import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import { BrowserRouter as Router } from 'react-router-dom';
// import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';
// import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';

// import aphroditeInterface from 'react-with-styles-interface-aphrodite';

// REMOVED 02.13.21
// import {Analytics} from 'aws-amplify';

Amplify.configure(aws_config);

// REMOVED 02.13.21
// Analytics.configure(aws_config);
// Analytics.record('Store load');
// Analytics.autoTrack('Store page view', {
//     enable: true,
//     type: 'SPA'
// });

// window.LOG_LEVEL = 'DEBUG';
const primaryColor = "#2185d0";

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: blueGrey,
  },
  status: {
    danger: 'red',
  },
   typography: {
    useNextVariants: true,
    button: {
      textTransform: 'none'
    }  
  }, 
  // spacing:{
  //   margin:"1rem"
  // },
  overrides: {
    MuiToolbar: {
      gutters: {
        [defaultTheme.breakpoints.up('sm')]: {
          paddingLeft: '1em',
          paddingRight: '1em',
        },
      },
    },
    MuiCardContent: {
      root: {
        paddingBottom:"0em",
        "&:last-child": {
          paddingBottom: 0
        }
      }
    }
  }
});

// ThemedStyleSheet.registerInterface(aphroditeInterface);

// ThemedStyleSheet.registerTheme({
//   reactDates: {
//     ...DefaultTheme.reactDates,
//     color: {
//       ...DefaultTheme.reactDates.color,
//       textFocused:"#2c88fb",
//       placeholderText: '#fff',
//       core: {
//         ...DefaultTheme.core, 
//         primary:"#2185d0",
//         secondary:"398edb",
//         primaryShade_1: '#2185d0',
//         primaryShade_2: '#b4d3f1',
//         primaryShade_3: '#b4d3f1',
//         primaryShade_4: '#b4d3f1',
//         primary_dark: '#b4d3f1'        
//       },
//       highlighted: {
//         backgroundColor: '#82c0e0',
//         backgroundColor_active: '#d5d8d8',
//         backgroundColor_hover: '#ebeeee',
//         color: '#2382b3',
//         color_active: '#2382b3',
//         color_hover: '#2382b3'        
//       },
//       selected: {
//         backgroundColor:  '#075ba8', // the select block background color
//         backgroundColor_active: '#075ba8',
//         backgroundColor_hover: primaryColor,
//         color: '#fff', // the color of the font 

//         borderColor: "#fff",
//         borderColor_active: primaryColor,
//         borderColor_hover: primaryColor,
//       },
//       selectedSpan: {
//         backgroundColor:"#479ae6",
//         backgroundColor_active: "#3490e4",
//         backgroundColor_hover: "#3490e4",
//         borderColor: "#fff",
//         borderColor_active: "#075ba8",
//         borderColor_hover: "#075ba8",
//         color: "#fff",
//         color_active: "#fff",
//         color_hover: "#fff"
//       }
//     },
//   },
// });

class App extends Component {

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <React.Fragment>
          <Router>                     
            <Navigator />
            <Main />
          </Router>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

export default App;