

import React, { useContext, useState, useEffect } from "react";
import aws_config from "../aws-exports";
import Storage from "@aws-amplify/storage";
import { useInView } from 'react-intersection-observer';

function CardDisplay(props) {

    const { ref, inView, entry } = useInView({
        threshold: 0,
      });
  
    const [url, setUrl] = useState("");
    
    const getImageForKey = async (key) => {                

        if (key) {
            if (key && url == "") {
                var re = /(?:\.([^.]+))?$/;
                let extensionString = re.exec(key);
                let imageKey = key.replace(extensionString[0],""); 
                imageKey = imageKey + (props.suffix ? props.suffix : ".png");
                let result = await Storage.get(imageKey, { level: 'public', bucket: aws_config.aws_user_files_s3_bucket, 
                  region:aws_config.aws_user_files_s3_bucket_region});                       
                  if (result) {
                      setUrl(result);
                  } 
              }                    
        }      
      }
  
      
    useEffect(()=>{
        if (url == "" && inView) {
            getImageForKey(props.imgKey)
        }        
    }, [inView]);


    return <>
    
        <div style={{marginLeft:"auto", marginRight:"auto", maxWidth:"400px"}} ref={ref}>        
           {
               url != "" ? <img className={props.classNameKey} src={url} alt={""} /> : <></>
           } 
        </div>        
    </>
}

export default CardDisplay;