import React from 'react';
import { useHistory } from "react-router-dom";

function Breadcrumb(props) {

    const history = useHistory();

    const onClick = (url) =>{
        history.push(url);
    }

    const buildBreadCrumb = ()=>{
        return <>
        {
           <p className="breadcrumb-link" style={{textAlign:"left", paddingLeft:"1em", paddingTop:"1em"}} ><span onClick={()=>{onClick("/s?k="+encodeURIComponent(props.product.series))}}>{props.product.series}</span> › <span onClick={()=>{onClick("/s?k="+encodeURIComponent(props.product.set))}}>{props.product.set}</span> </p>
        }
        </>
    }

    return <>{buildBreadCrumb()}</>
}

export default Breadcrumb;