import React, { Component } from 'react';

export default class Educational extends Component {
  render() {
    return (
      <>
      <h1 style={{paddingTop:"30px"}}> Educational Items Coming Soon!</h1>
      <div>

        <img alt="abc-img" src="images/abc.png"/>
      </div>
      </>
    )
  }
}