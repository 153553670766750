import React, {useContext, useState, useEffect} from 'react'
import { InView } from 'react-intersection-observer';
import moment from 'moment';


function ProductSeriesHeader(props){

    const [entryTime, setEntryTime] = useState(moment().valueOf());
    const [triggered, setTriggered] = useState(false);

    const getDelayForSeries = (series) => {
        
        if (series == "Sword and Shield" || series == "Sword & Shield") {
            return 0;
        }
        if (series == "Sun and Moon" || series == "Sun & Moon") {
            return 2000;
        }        
        if (series == "XY") {
            return 3000;
        }
        return 0;
    }


    return (<>
      <InView as="div" style={{ width: "100%"}} triggerOnce={false} onChange={ async (inView, entry) => {                    
            if (!triggered) {            
                if (inView && moment().valueOf() - entryTime > getDelayForSeries(props.series)) {
                setTriggered(true);
                if (!props.isSearch) {
                    // await fetchProductsForSets(GetSetForSeries(props.series));
                 }   
              } 
            } 
          } }>              
            <div style={{ width: "100%", padding:"1em",paddingTop:"2em", textAlign: "left", paddingLeft: "2em" }}>
                <img className="product-series-image" src={props.imgSrc} alt={props.series} />
            </div>
      </InView>
    </>)
}

export default ProductSeriesHeader;