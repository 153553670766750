import React from 'react'
// import PropTypes from 'prop-types'
// import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  rootWhite: {
    '& .MuiOutlinedInput-root': { // main root working
      margin: theme.spacing(1),
      // width: '50%',
      padding: '0',
      // minWidth:'24em',
      // width:'100%',
      fontSize:"1.5em",
      color:"white !important",
    },
    '& .MuiOutlinedInput-root.Mui-focused': { // main root working
      backgroundColor:"#0033 !important"
    }
  },
  root: {
    width:"100%",
    display:"inline-block",
    marginTop:"0em",
    '& .MuiInputBase-root':{
      margin:"0em"      
    },
    '& .MuiOutlinedInput-inputMultiline' : {
      margin: "0.6em",
    },
      //MuiFormControl-root, 
    
    '& .MuiOutlinedInput-root': { // main root working
      margin:"0em",      
      width:"100% !important",
      // width: '50%',
      padding: '0',
      // minWidth:'24em',
      // width:'100%',
      fontSize:"1em",
      color:"black !important"
    }
  } ,
  
  rootWidthAuto: {
    width:"auto !important",
    display:"inline-block",
    marginTop:"0em",
    '& .MuiInputBase-root':{
      margin:"0em"      
    },
    '& .MuiOutlinedInput-inputMultiline' : {
      margin: "0.6em",
    },
      //MuiFormControl-root, 
    
    '& .MuiOutlinedInput-root': { // main root working
      margin:"0em",      
      width:"auto !important",
      // width: '50%',
      padding: '0',
      // minWidth:'24em',
      // width:'100%',
      fontSize:"1em",
      color:"black !important"
    }
  }    
}));

// const styles = theme => ({
  // textField: {
  //   marginTop: '0px',
  //   marginLeft: '0px',
  //   marginBottom: '0px', 
  //   width:"auto"
  // },
//   label: {
//     '&$focused': {
//       color: '#4A90E2'
//     },
//   },
//   focused: {},
  // outlinedInput: {
  //   '&$focused $notchedOutline': {
  //     border: '1px solid #4A90E2'
  //   },
  // },
  // input: {
  //   marginTop:"3px"
  // }
//   // root:{    'label + &': {
//   //   marginTop: "0px"
//   // },
// // }
// })

const getClassName = (color, classes) =>{
    if (color == "white") {
      return classes.rootWhite;
    } 
    if( color == "auto") {
      return classes.rootWidthAuto;
    }
    return classes.root;
}


const CustomTextField = ({color, properties, defaultValue, useStylesClass, onChange, onFocus}) => 
{
  // const inputRef = React.useRef();

  // React.useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     inputRef.current.focus();
  //   }, 100);
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  const classes = useStyles();

  return( 
  // <form style={{display:"inline-block", verticalAlign:"top"}} className={classes.root} noValidate autoComplete="off" key={"form-"+properties.key}> 
    <TextField
      // inputRef={inputRef}
      id="outlined-multiline-static"
      key={properties ? properties.key+"-"+properties.name : "Input"}
      label={properties ? properties.labelName : "properties.labelName"}
      name={properties ? properties.name : "properties.name"}
      className={getClassName(color, classes)}      
      value={properties ? properties.currentValue : "properties.currentValue"}
      rowsMax={10}
      rows={properties && properties.rows ? properties.rows : 1}
      multiline={properties && properties.multiline ? properties.multiline : false}
      // type="search"
      // autoComplete="search"
      margin="normal"
      padding="0"
      // variant="standard" //outlined, standard
      variant={properties ? properties.variant : "standard"}
      onFocus= {
        e=> {if (onFocus) {onFocus(e);}}
      }
      onChange= {            
          e =>{ onChange(e); }
      }
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          if (properties && properties.onKeyPressFunction) {
              properties.onKeyPressFunction();
          }
        }
      }}
      InputLabelProps={{
        style: properties.inputLabelColor ? properties.inputLabelColor : null,
        classes: {
          root: classes.label,
          focused: classes.focused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.outlinedInput,
          focused: classes.focused,
          notchedOutline: classes.notchedOutline,
        },
      autoComplete: 'off',
      error:properties && properties.error ? properties.error : false
      }
    }
    />
  // </form>
  )
}

export default (CustomTextField)