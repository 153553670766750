import React, { useEffect, useState, useRef } from "react";
import { API } from "@aws-amplify/api";
// import { graphql } from "@aws-amplify/api-graphql";
import { getProduct, productsBySet, productsBySetType } from "../api/queries";
import { listProducts, listSiteItems } from "../api/public/queries";
import { processOrder } from "../api/mutations";
import Storage from "@aws-amplify/storage";
import aws_config from "../aws-exports";

import moment from 'moment';

const ProductContext = React.createContext([]);
const delay = ms => new Promise(res => setTimeout(res, ms));

const ProductProvider = ({ children }) => {
  // const [products, setProducts] = useState([]);
  // const [productSingles, setProductSingles] = useState([]);
  const productSinglesRef = useRef({})
  const productSingles = productSinglesRef.current;

  const [featured, setFeatured] = useState([]);
  const [loading, setLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [nextToken, setNextToken] = useState('');
  const [nextTokenSearch, setNextTokenSearch] = useState('');  
  const [seriesNameSelected, setSeriesNameSelected] = useState("Scarlet & Violet");
  const [nextTokenForSet, setNextTokenForSet] = useState('');
  const [setItems, setSetItems] = useState([]);
  
  const setSortType = useRef({});
  const singles = useRef({});
  const productsRef = useRef([]);
  const products = productsRef.current; 
  const fetchSetQueue = useRef([]);
  const fetchSetFinished = useRef([]);
  const fetchingSet = useRef(false);
  const fetchingPaused = useRef(false);
  const fetchImageQueue = useRef([]);
  
  const [showInStockOnly, setShowInStockOnly] = useState(false);
  const [singlesFilters, setSinglesFilters] = useState({
    "Pokemon" : false,
    "Supporter" : false, 
    "Item" : false, 
    "Tool" : false, 
    "Energy" : false, 
    "Stadium" : false,
    "Fighting" : false, 
    "Grass" : false, 
    "Water" : false, 
    "Metal" : false, 
    "Psychic" : false, 
    "Darkness" : false,
    "Fire" : false, 
    "Lightning" : false, 
    "Colorless" : false,
    "Uncommon" : false,
    "Common" : false, 
    "Ultra Rare" : false, 
    "Rare" : false, 
    "Secret Rare" : false, 
    "Holo Rare" : false,
    "Amazing Rare" : false
  });


  const [builtFilters, setBuiltFilters] = useState({});
  const [showSeriesName, setShowSeriesName] = useState("Sword and Shield");


  useEffect(() => {
    fetchSiteItems(); 
}, []);


const fetchSiteItems = async ()=>{
  let result = await fetchSiteItemsByType("Set");
  setSetItems(result);
}


const getSetCodeByName = (name) => {
  if  (setItems) {
    for (let i = 0; i < setItems.length; i++) {
      let set = setItems[i];
      if (set.id == name) {
        if (set.data) {
          let setData = JSON.parse(set.data);         
          return setData.code;
        }        
      }
    }
  }else {
  }
  return "";
}

const getSetHasSubset = (name) => {
  if  (setItems) {
    for (let i = 0; i < setItems.length; i++) {
      let set = setItems[i];
      if (set.id == name) {
        if (set.data) {
          let setData = JSON.parse(set.data);         
          return setData.subset;
        }        
      }
    }
  }
  return null;
}

const getSetIsHiddenByName = (name) => {
  if  (setItems) {
    for (let i = 0; i < setItems.length; i++) {
      let set = setItems[i];
      if (set.id == name) {
        if (set.data) {
          let setData = JSON.parse(set.data);         
          return setData.hideFromHomePage;
        }        
      }
    }
  }else {
  }
  return "";
}


const getSetImageByName = (name) => {
  if  (setItems) {
    for (let i = 0; i < setItems.length; i++) {
      let set = setItems[i];
      if (set.id == name) {
        if (set.data) {
          let setData = JSON.parse(set.data)
          return setData.logoUrl;
        }        
      }
    }
  }else {
  }
  return null;
}


  const checkout = async (orderDetails, guestCheckout) => {  

    const payload = {
      ...orderDetails,
      guestCheckout:guestCheckout      
    };

    
    try {
      
      let result = await API.graphql({ query:processOrder, variables:{ input: payload }, authMode: guestCheckout ? "API_KEY" : "AMAZON_COGNITO_USER_POOLS" });      
      

      if (result && result.data && result.data.processOrder && result.data.processOrder.OrderStatus == "SUCCESS") {
        orderDetails["orderDate"] = result.data.processOrder.OrderDate;
        orderDetails["orderNumber"] = result.data.processOrder.OrderNumber;
        orderDetails["orderTotal"] = result.data.processOrder.OrderTotal;        
        orderDetails["orderDiscount"] = result.data.processOrder.OrderDiscount;        
      } else {
        return {errors:"Error"};
      }   
      return orderDetails;
    } catch (err) {         

      console.log("ERROR:", err)
      return {errors:err};
    }
  };


  const loadMoreProducts = async () => {
    try {
      setLoading(true);
      // Switch authMode to API_KEY for public access
      const { data } = await API.graphql({
        query: listProducts,
        variables:{limit:100},
        authMode: "API_KEY",
        nextToken:nextToken
      });      
    } catch (err) {      
      console.log(err);   
    //   setNetworkError(true);   
    }
  };

  
  const fetchProducts = async () => {

    try {
    
      setLoading(true);
      // Switch authMode to API_KEY for public access
      const { data } = await API.graphql({
        query: listProducts,
        variables:{limit:500, filter:{type:{eq:"Sealed"}}},
        authMode: "API_KEY"
      });

      let moreResults = [];

      if (data.listProducts.nextToken) {
        moreResults = await fetchMoreProducts(data.listProducts.nextToken);
      }      
      const fetched = [...data.listProducts.items, ...moreResults];
      for(let i = 0; i < fetched.length; i++){fetched[i].edited = false;}
      fetched.sort(function(a, b) {      
          if(moment(new Date(a.releaseDate)).valueOf() > moment(new Date(b.releaseDate)).valueOf()) { return -1; }
          if(moment(new Date(a.releaseDate)).valueOf() < moment(new Date(b.releaseDate)).valueOf()) { return 1; }
          return 0;
      })

      const featured = fetched.filter((product) => {
        return !!product.featured;
      });

      // setProducts(products);
      productsRef.current = fetched;
      setFeatured(featured);
      setLoading(false);
      setNetworkError(false);
    } catch (err) {      
      setNetworkError(true);   
    }
  };


  const fetchMoreProducts = async (nextToken) => {
    
    const { data } = await API.graphql({
      query: listProducts,
      variables:{limit:500, nextToken:nextToken, filter:{type:{eq:"Sealed"}}},
      authMode: "API_KEY"      
    });
    let moreResults = [];
    if (data.listProducts.nextToken) {
      moreResults = await fetchMoreProducts(data.listProducts.nextToken);
    }      
    const fetched = data.listProducts.items ? data.listProducts.items : [];
    return [...fetched, ...moreResults];
  }

  const fetchQueuedSet = async (setName) => {

    const { data } = await API.graphql({
      query: productsBySetType,
      variables:{limit:100, set:setName, type:{eq:"Sealed"}},
      authMode: "API_KEY"
    });
    
    let updatedNextToken = {...nextTokenForSet};
    if (data.productsBySetType.nextToken) {
      updatedNextToken[setName] = data.productsBySetType.nextToken;      
    } else {
      updatedNextToken[setName] = "None"
    }
    
    setNextTokenForSet(updatedNextToken);

    const productResults = data.productsBySetType.items;    
    
    productResults.sort(function(a, b) {      
        if(moment(new Date(a.releaseDate)).valueOf() < moment(new Date(b.releaseDate)).valueOf()) { return -1; }
        if(moment(new Date(a.releaseDate)).valueOf() > moment(new Date(b.releaseDate)).valueOf()) { return 1; }
        return 0;
    })
    
    let nonDupeProducts = [];

    for (let i = 0; i < productResults.length; i++) {
      let product = productResults[i];
      let isAlreadyAdded = false;
      for (let x = 0; x < products.length; x++) {
        let otherProduct = products[x];   
        if (otherProduct.id == product.id) {
          isAlreadyAdded = true;
        }
      }
      if (!isAlreadyAdded) {
        nonDupeProducts.push(product);
      }
    }
   
    productsRef.current = [...productsRef.current, ...nonDupeProducts];
    return productResults;
  }

  const continueFetchSetQueueCheck = async ()=> {

    if (fetchSetQueue && fetchSetQueue.current.length > 0 && !fetchingPaused.current) {      
    
      fetchingSet.current  = true;

      let setName = fetchSetQueue.current[0].setName;
      let callback =  fetchSetQueue.current[0].callback;   
      let callbackSingles =  fetchSetQueue.current[0].callbackSingles;      
      let resultSet = await fetchQueuedSet(setName);

      if (callback ) {
        callback(resultSet);
      }
      
      fetchSinglesForSets([setName], callbackSingles);
          
      fetchSetFinished.current.push(setName);
      fetchSetQueue.current.shift();   

      await continueFetchSetQueueCheck();

      fetchingSet.current = false;
    
    }
  }


  const fetchProductsForSets = async (sets, callback, callbackSingles) => {
      
    if (sets && sets.length <= 0) return;
     
    try { 
      for (let i = 0; i < sets.length; i++) {
          let setName = sets[i];
          if (fetchSetFinished.current.indexOf(setName) == -1 && fetchSetQueue.current.indexOf({setName, callback}) == -1) {            
            fetchSetQueue.current.push({setName, callback, callbackSingles});
          }else {
            //ignore
          }
      }
      
      // Current fetching sets
      if (fetchingSet.current == true) {
      
      } else {                          
        // Start update fetch set queue check
        continueFetchSetQueueCheck();      
      }            
      setNetworkError(false);
    } catch (err) {      
      console.log(err);   
      setNetworkError(true);   
    }
  };


  const fetchSinglesForSets = async (sets, callback) => {    

    for (let i = 0; i < sets.length; i++) {
      try {        

        let setObtained = await fetchSinglesForSet(sets[i]);

        let subset = getSetHasSubset(sets[i]);
        
        if (subset) {
          let subsetObtained = await fetchSinglesForSet(subset);
          singles.current[sets[i]] = {cards:[...setObtained.cards, ...subsetObtained.cards], nextToken:setObtained.nextToken, nextTokensubset:subsetObtained.nextToken };        
        } else {
          singles.current[sets[i]] = {...setObtained};        
        }
        

        if (callback) {
          callback(sets[i]);
        }
    
      } catch (err) {      
        console.log(err);
      }
    }
    try {
      if (singles && singles.current) {
        // setProductSingles({...singles.current});
        productSinglesRef.current = {...singles.current};
        updateFilters(singles.current);
      }      
    }catch (err) {
      console.log("err:", err);
    }
    
  };

  
  const fetchSinglesForSet = async (set) => { 

    try {    
      const { data } = await API.graphql({
        query: productsBySetType,
        variables:{limit:25, set:set, type:{eq:"Single"}},
        authMode: "API_KEY"
      });
  
      let productsReturned = data.productsBySetType.items;

      productsReturned.sort(function(a, b){      
        if(parseInt(a.number) < parseInt(b.number)) { return -1; }
        if(parseInt(a.number) > parseInt(b.number)) { return 1; }
        return 0;
    });

    return {cards:productsReturned, nextToken:data.productsBySetType.nextToken}

    } catch (err) {      
      console.log(err);   
      setNetworkError(true);   
    }
  };


  const fetchMoreSinglesForSets = async (sets) => {    

    for (let i = 0; i < sets.length; i++) {
      try {        

        let setObtained = await continueFetchSinglesForSet(sets[i]);

        if (singles.current[sets[i]]) {
          singles.current[sets[i]].cards = singles.current[sets[i]].cards.concat(setObtained.cards);
          singles.current[sets[i]].nextToken = setObtained.nextToken;
        }else {
          singles.current[sets[i]] = {...setObtained};        
        }
        sortLastForSet(sets[i]);
      } catch (err) {      
        console.log(err);
      }
    }
    try {
      if (singles && singles.current) {
        // setProductSingles({...singles.current});
        productSinglesRef.current = {...singles.current};
        updateFilters(singles.current);
      }      
    }catch (err) {
      console.log("err:", err);
    }
    
  };


  const sortLastForSet = (set) =>{
    if (setSortType.current[set]) {
      sortSinglesForSets(set, setSortType.current[set])
    }  else {
      sortSinglesForSets(set, 0) 
    }
  }

  const sortSinglesForSets = (set, sortType)=>{

    if (singles.current[set]) {

      
      setSortType.current[set] = sortType;

      if (sortType == 5) {
        singles.current[set].cards = singles.current[set].cards.sort(function(a, b){      
          if(parseFloat(a.price) > parseFloat(b.price)) { return -1; }
          if(parseFloat(a.price) < parseFloat(b.price)) { return 1; }
          return 0;
      })      
      }
      else if (sortType == 4) {
        singles.current[set].cards = singles.current[set].cards.sort(function(a, b){      
          if(parseFloat(a.price) < parseFloat(b.price)) { return -1; }
          if(parseFloat(a.price) > parseFloat(b.price)) { return 1; }
          return 0;
      })      
     }
     else if (sortType == 3) {
      singles.current[set].cards = singles.current[set].cards.sort(function(a, b){      
        if(a.title > b.title) { return -1; }
        if(a.title < b.title) { return 1; }
        return 0;
    }) }
    else if (sortType == 2) {
      singles.current[set].cards = singles.current[set].cards.sort(function(a, b){      
        if(a.title < b.title) { return -1; }
        if(a.title > b.title) { return 1; }
        return 0;
    }) }
     else if (sortType == 1) {
        singles.current[set].cards = singles.current[set].cards.sort(function(a, b){      
          if(parseInt(a.number) > parseInt(b.number)) { return -1; }
          if(parseInt(a.number) < parseInt(b.number)) { return 1; }
          return 0;
      }) } else {
          singles.current[set].cards = singles.current[set].cards.sort(function(a, b){      
            if(parseInt(a.number) < parseInt(b.number)) { return -1; }
            if(parseInt(a.number) > parseInt(b.number)) { return 1; }
            return 0;
        })              
      }  
      
    // setProductSingles({...singles.current});
    productSinglesRef.current = {...singles.current};

    }
  }


  const moreToFetchForSet = (set)=>{

    if (singles.current[set] && singles.current[set].nextToken) {
      return true;
    }
    
    return false;
  }


  const continueFetchSinglesForSet = async (set) => { 
    try {    

      if (singles.current[set]) {
        let nextToken = singles.current[set].nextToken
        const { data } = await API.graphql({
          query: productsBySetType,
          variables:{limit:250, set:set, nextToken:nextToken, type:{eq:"Single"}},
          authMode: "API_KEY"
        });
    
        let productsReturned = data.productsBySetType.items;
  
        productsReturned.sort(function(a, b){      
          if(parseInt(a.number) < parseInt(b.number)) { return -1; }
          if(parseInt(a.number) > parseInt(b.number)) { return 1; }
          return 0;
        });

        return {cards:productsReturned, nextToken:data.productsBySetType.nextToken}       
      }
    } catch (err) {      
      console.log(err);   
      setNetworkError(true);   
    }
  };


  function validateDataFields(data) {
    return true;
  }


  const allowedAttributes = () => {
      return ["title", "series", "set", "subtype", "description", "brand", "keyword"];
  }

  const productContainsSearchTerm = (product, searchTerm) => {    
    let terms = searchTerm.split(" ");    
    let productKeys = allowedAttributes();
    let containsTerm = false;    
    for (let i = 0; i < terms.length; i++) {
      let term = terms[i].toLowerCase();    
      for (let x = 0; x < productKeys.length; x++) {
        let productAttributeString = product[productKeys[x]] ? product[productKeys[x]].toLowerCase() : undefined
        if (productAttributeString && productAttributeString.includes(term)) {
          containsTerm = true;
        }
      } 
    }
    return containsTerm;
  }


  const getProductsBySet = (setName)=>{

    
    let productBySeries = getProductsBySeries();    



let resultSeriesKeys = Object.keys(productBySeries);
    for (let z = 0; z < resultSeriesKeys.length; z++) {
        let series  = productBySeries[resultSeriesKeys[z]];
        let setKeys = Object.keys(series);                               
        for (let aa = 0; aa < setKeys.length; aa++) {
          if (setKeys[aa] == setName) {
            let set = productBySeries[resultSeriesKeys[z]][setKeys[aa]];            
            return set;
          }            
        }
    }
    return null;
  }


  const getProductsBySeries = (searchTerm) => {    
    let productsBySeries = {};

    console.log("getProductsBySeries:", products);

    for (let i = 0; i < products.length; i++) {
      let product = products[i];      
        //First check search term 
        if (searchTerm == undefined || (searchTerm && searchTerm.length >= 3 && productContainsSearchTerm(product, searchTerm))) {        
          if (productsBySeries[product.series] == undefined) {
            //add
            productsBySeries[product.series] = {};
            productsBySeries[product.series][product.set] = new Array(product);
          } else {
            //get current and add to it
            if (productsBySeries[product.series] && productsBySeries[product.series][product.set] && Array.isArray(productsBySeries[product.series][product.set]) ) {
              productsBySeries[product.series][product.set].push(product);
            } else {
              productsBySeries[product.series][product.set] = new Array(product);
            }
          }        
            productsBySeries[product.series][product.set].sort((a,b) => a.subtype > b.subtype);
            productsBySeries[product.series][product.set].sort((a,b) => a.quantity < b.quantity);
      }      
    }
    return productsBySeries;
  }
  


  const getSearchResultsBySeries = (results) => {
    
    let productsBySeries = {};
    
    for (let i = 0; i < results.length; i++) {
      let product = results[i];      
        //First check search term 
        if (productsBySeries[product.series] == undefined) {          
          //add
          productsBySeries[product.series] = {};
          if (productsBySeries[product.series][product.set] == undefined) {
            productsBySeries[product.series][product.set] = {};
          }
          if (productsBySeries[product.series][product.set][product.type] == undefined) {
            productsBySeries[product.series][product.set][product.type] = new Array(product)
          }else {
            productsBySeries[product.series][product.set][product.type].push(product);
          }
        } else {
          //get current and add to it
          if (productsBySeries[product.series] && productsBySeries[product.series][product.set] && Array.isArray(productsBySeries[product.series][product.set]) ) {          
            if (productsBySeries[product.series][product.set][product.type] == undefined) {
              productsBySeries[product.series][product.set][product.type] = new Array(product)
            }else {
              productsBySeries[product.series][product.set][product.type].push(product);
            }
          } else {
            if (productsBySeries[product.series][product.set] == undefined) {
              productsBySeries[product.series][product.set] = {};
              productsBySeries[product.series][product.set][product.type] = new Array(product)
            }else {
            if (productsBySeries[product.series][product.set][product.type] == undefined) {
                productsBySeries[product.series][product.set][product.type] = new Array(product)
            }else {
                  productsBySeries[product.series][product.set][product.type].push(product);
                }
              }
          }
        }  

        if (productsBySeries[product.series][product.set]) {
          productsBySeries[product.series][product.set][product.type].sort((a,b) => product.type == "Single" ? parseInt(a.number) > parseInt(b.number) : a.subtype > b.subtype);
        }        
    }

    return productsBySeries;
  }
  
  const getProductsBySearch = async (searchTerm) => {    
   
    const { data } = await API.graphql({
      query: listProducts,
      variables:{limit:2000, filter:{keyword:{contains:searchTerm.toLowerCase()}}},
      authMode: "API_KEY"
    });
    let items = [];
    if (data.listProducts.items && data.listProducts.items.length > 0) {
      items.push(...data.listProducts.items);
    }
    if (data.listProducts.nextToken) {
      let results = await continueSearchingProducts(data.listProducts.nextToken, searchTerm)
      items.push(...results);
    }
    return getSearchResultsBySeries(items);
  }


  const continueSearchingProducts = async (nextToken, searchTerm)=>{   
    let items = [];
    const { data } = await API.graphql({
      query: listProducts,
      variables:{limit:2000, nextToken:nextToken, filter:{keyword:{contains:searchTerm.toLowerCase()}}},
      authMode: "API_KEY"
    });
    if (data.listProducts.items) {
      items.push(...data.listProducts.items);
    }
    if (data.listProducts.nextToken) {
      let results = await continueSearchingProducts(data.listProducts.nextToken, searchTerm)
      if (results && Array.isArray(results) && results.length > 0) {
        items.push(...results);
      }      
    }
    return items;
  }


  const getProductDataFromId = (id)=> {
    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      if (product.id == id) {
        return product;
      } 
    }
    return null;
  }

  const getProductValueFromId = (id, valueName) => {
    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      if (product.id == id) {
        return product[valueName];
      } 
    }
    return null;
  }
  

  const updateProductData = async (id, data) => {

    try {

      let editedProduct = false;
      let editedSingle = false;
      let updatedProducts = [...products];
            
      if (data.type == "Single") {

      let objectSinglesSetKeys = Object.keys(productSingles);
      let updatedProductSingles = {...productSingles};

      for (let i = 0; i < objectSinglesSetKeys.length; i++) {
        let setCards = updatedProductSingles[objectSinglesSetKeys[i]].cards;
        for (let x = 0; x < setCards.length; x++) {
          let product = setCards[x];
          if (product.id == id) {
            if (validateDataFields(data)) {
              data = {...data, edited:true};              
              product = {...product, ...data}              
              setCards[x] = {...product};            
              editedSingle = true;              
            } else {
              console.log("Error validating fields")
            }  
          }
        }
      }

        if (editedSingle) {
          // setProductSingles(updatedProductSingles);        
          productSinglesRef.current = updatedProductSingles;
        }   

      } else {
        
        for (let i = 0; i < updatedProducts.length; i++) {
          let product = updatedProducts[i];
          if (product.id == id) {
            if (validateDataFields(data)) {
              data = {...data, edited:true};
              product = {...product, ...data}
              updatedProducts[i] = product;
              // updatedProducts[i] = {...product};            
              editedProduct = true;
              break;
            } else {
              console.log("Error validating fields")
            }
          } else {
            // updatedProducts.push(product);
          }
        }  


        
        if (editedProduct) {
          productsRef.current = updatedProducts;
        }
  
        
      }
  
      setNetworkError(false);

    }catch (err) {
      console.log(err);
      setNetworkError(true);
    }
  };


  const updateFilters = (updatedSingles) => {

    let buildFiltersRarity = {};
    let buildFiltersEnergyType = {};
    let buildFiltersSuperType = {};
    let setKeys = Object.keys(updatedSingles);

    for (let i = 0; i < setKeys.length; i++) {
      
      let setKey = setKeys[i];
      let set = updatedSingles[setKey];
        
      if (set && set.cards && set.cards.length > 0) {
          
          for (let x = 0; x < set.cards.length; x++) {
            let card = set.cards[x];
            if (card) {
              let rarity = card.rarity;              
              if (buildFiltersRarity[rarity]) {
                buildFiltersRarity[rarity] = parseInt(buildFiltersRarity[rarity]) + 1;
              } else {
                buildFiltersRarity[rarity] = 1;
              }

              let supertype = card.supertype;
              if (buildFiltersSuperType[supertype]) {
                buildFiltersSuperType[supertype] = parseInt(buildFiltersSuperType[supertype]) + 1;
              } else {
                buildFiltersSuperType[supertype] = 1;
              }

              if (card.types) {
                for (let z = 0; z < card.types.length; z++) {
                  let energyType = card.types[z];
                  if (buildFiltersEnergyType[energyType]) {
                    buildFiltersEnergyType[energyType] = parseInt(buildFiltersEnergyType[energyType]) + 1;
                  } else {
                    buildFiltersEnergyType[energyType] = 1;
                  }
    
                }
              }
            }
          }
        }
    }
        
    setBuiltFilters({Rarity:{...buildFiltersRarity}, EnergyType:{...buildFiltersEnergyType}, SuperType:{...buildFiltersSuperType}});

  }

  const updateSinglesData = async (id, data) => {
    try {
      
      let editedSingle = false;
      let objectSinglesSetKeys = Object.keys(productSingles);
      let updatedProductSingles = {...productSingles};

      for (let i = 0; i < objectSinglesSetKeys.length; i++) {
        let setCards = updatedProductSingles[objectSinglesSetKeys[i]].cards;
        for (let x = 0; x < setCards.length; x++) {
          let product = setCards[x];
          if (product.id == id) {
            if (validateDataFields(data)) {
              data = {...data, edited:true};              
              product = {...product, ...data}              
              setCards[x] = {...product};            
              editedSingle = true;
            } else {
              console.log("Error validating fields")
            }  
          }
        }
      }

      if (editedSingle) {
        // setProductSingles(updatedProductSingles);        
        productSinglesRef.current = updatedProductSingles;
      }   
      setNetworkError(false);
    }catch (err) {
      console.log(err);
      setNetworkError(true);
    }
  };


  const getProductForId = async (id) => {
    const { data } = await API.graphql({
      query: getProduct,
      variables:{id:id},
      authMode: "API_KEY"
    });
    const getProductResult = data.getProduct;
    return getProductResult
  }


  const getSinglesForSet = async (set)=>{
        if (singles.current[set]) {          
          return singles.current[set].cards
        }
        return [];
  }

  const hasFetchedSet = (setName)=> {
    if (fetchSetFinished && fetchSetFinished.current){
      if (fetchSetFinished.current.indexOf(setName) == -1 ){

      }else {
        return true;
      }
    }

    return false;
  }
  const loadingProducts = ()=> {
    return loading;
  }

  const updateSeriesNameSelected = (seriesName)=>{
    setSeriesNameSelected(seriesName);
  }

  const updateSinglesFilters = (updatedFilters)=>{
    setSinglesFilters(updatedFilters);
  }

  const MAX_IMAGES_TO_LOAD = 1;
  
  Array.prototype.remove = function (v) {
    if (this.indexOf(v) != -1) {
        this.splice(this.indexOf(v), 1);
        return true;
    }
    return false;
}

  const queueGetImage = async (imageKey) =>{
        
    if (fetchImageQueue.current.length < MAX_IMAGES_TO_LOAD) {                  
        
      fetchImageQueue.current.push(imageKey);
      let result = await Storage.get(imageKey, { level: 'public', bucket: aws_config.aws_user_files_s3_bucket, 
      region:aws_config.aws_user_files_s3_bucket_region});               
      await delay(500);
      fetchImageQueue.current.remove(imageKey);
      return result;

    } else {

      while (fetchImageQueue.current.length >= MAX_IMAGES_TO_LOAD) {
        await delay(100);
      }

      fetchImageQueue.current.push(imageKey);
      let result = await Storage.get(imageKey, { level: 'public', bucket: aws_config.aws_user_files_s3_bucket, 
      region:aws_config.aws_user_files_s3_bucket_region});               
      await delay(100);
      fetchImageQueue.current.remove(imageKey);
      return result;
    }    
  }


  const fetchSiteItemsByType = async (type)=> {
    try { 
      const { data } = await API.graphql({
        query: listSiteItems,
        variables : {type:type,limit:200},
        
        authMode: "API_KEY",
      });      
      const siteItems = data.listSiteItems.items;
      return siteItems;
    } catch (err) {
      
      console.log("ERROR:", err);
    }
    return null;
  }
  
  const updateProductPrice = (id, price)=>{

    if (productsRef.current) {
      for (let i = 0; i < productsRef.current.length; i++) {
        let product = productsRef.current[i];
        if (product.id == id) {
          productsRef.current[i].price = price;
        }
      }
    }


  } 

  return (
    <ProductContext.Provider value={{getProductValueFromId, updateProductPrice, setItems, getSetCodeByName, getSetImageByName, getSetIsHiddenByName, fetchSiteItemsByType, queueGetImage, products, productSingles, singles, featured, moreToFetchForSet, sortSinglesForSets,  loadingProducts, networkError,  showInStockOnly, setShowInStockOnly, updateSinglesData, checkout, updateProductData, getProductsBySearch, getProductsBySeries, getProductsBySet, getProductDataFromId, loadMoreProducts, getSinglesForSet, getProductForId, fetchProductsForSets, fetchProducts, hasFetchedSet, seriesNameSelected, updateSeriesNameSelected, singlesFilters, updateSinglesFilters, builtFilters, fetchMoreSinglesForSets, productSinglesRef}}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContext;
export {ProductProvider};