import React, {useState, useContext, useRef} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import moment from "moment";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import {ConvertStringToDollarsCents} from "../components/Helper";
import { API } from "@aws-amplify/api";
import AdminContext from "../context/admin";
import UiContext from "../context/ui";
import UserContext from "../context/user";
import PreviewEmail from "../components/PreviewEmail";

const useStyles = makeStyles({
    root:{
      paddingTop: "1em",
      paddingBottom: "1em",
      paddingRight:"1em",
      '& .MuiPaper-root': {
        maxWidth:"none",
    }  
    },
});


function AdminEmailCampaign (props) {
    
    const classes = useStyles();    
    const {updateOrderWithInput} = useContext(AdminContext);
    const {sendAlertMessage } = useContext(UiContext);
    const {updateCreateUserItem } = useContext(UserContext);
    const [currentSubscriber, setCurrentSubscriber] = useState(props.selectedSubscribers  && props.selectedSubscribers.length > 0 ? props.selectedSubscribers[0] : null);
    const [currentSubscriberIndex,  setCurrentSubscriberIndex] = useState(0);
    const [previewHtml, updatePreviewHtml] = useState("");
    const emailHtml = useRef("");

    const [isProcessing, setIsProcessing] = useState(false);
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const getSubscriberEmailStateClass = (subscriber)=> {
        console.log("subscriber:", subscriber);
        if (props.checkHasSentSelectedCampaign) {
            if (props.checkHasSentSelectedCampaign(currentSubscriber.email.toLowerCase())) {
                return "campaign-subscriber-email-current";        
            }
        }
        if (currentSubscriberIndex != -1 && currentSubscriber && currentSubscriber.email.toLowerCase() == subscriber.email.toLowerCase()) {
            return "campaign-subscriber-email-current";    
        }
        if (subscriber.success) {
            return "campaign-subscriber-email-success";
        }
        return "campaign-subscriber-email";
    }


    const displayContent = ()=> {
        let subscriberEmails = props.selectedSubscribers.map(subscriber=>{            
            return <span className={getSubscriberEmailStateClass(subscriber)}>{subscriber.email}</span>
        })
        return (<>
            <div style={{marginBottom:"4em"}}>
                {subscriberEmails}
            </div>
        </>)
    }

    const sendEmail =  async (details)=>{

        console.log("!!!!updatePreviewHtml:", previewHtml);
        console.log("!!!!current updatePreviewHtml:", emailHtml.current);

        var re = new RegExp("<a([^>]* )href=\"([^\"]+)\"", "g");

        let bodyUpdate = emailHtml.current.replace(re, `<a$1href=\"$2?utm_source=${props.details.campaignSource}&utm_medium=email&utm_campaign=${props.details.name}\"`);
      
        bodyUpdate = bodyUpdate.replaceAll('&?','&');

        await API.post("yayfunthingsapi", "/items/sendEmail", { 
            body: 
            {  
               email:details.email, 
               data:bodyUpdate, 
               subject:details.subject } }).then(async result => {

                await updateCreateUserItem({type:"EmailSent", createdAt:moment().toISOString(), id:result.MessageId, name:details.name, status:'Pending', data:JSON.stringify(details)}, true);    

            console.log(result);
            // sendAlertMessage("Success sending email.")

        }).catch(e=>{
        
            // sendAlertMessage("Error sending email");
            console.log(e);
        });

    }

    const startProcessingEmails = async()=> {
        console.log("startProcessingEmails:");
        
        for (let i = 0; i < props.selectedSubscribers.length; i++) {            

            setCurrentSubscriberIndex(i);
            setCurrentSubscriber(props.selectedSubscribers[i])

            await delay(1000);            

            await sendEmail({
                type:"Custom",
                email:props.selectedSubscribers[i].email,
                subject:props.details.subject,
                body:props.details.body,
                title:props.details.title,
                name:props.details.name,
                secret:props.selectedSubscribers[i].secret,
                subscribed:props.selectedSubscribers[i].subscribed
                })
                                
            await delay(1000);            

            props.selectedSubscribers[i].success = true;
        }
        
        setCurrentSubscriberIndex(-1);
        
        console.log("Finish ProcessingEmails:");

    }


    return(<>
                 <Dialog
                            style={{maxWidth:"none"}}
                            open={true}
                            // TransitionComponent={Transition}
                            style={{ padding: "0px !important" }}
                            keepMounted
                            classes={{
                                root: classes.root
                              }}
                            onClose={() => {                                
                                props.setDisplayEmailCampaign(false);                                
                            }}>

                                
                                
                            <DialogContent>                                                           
                               {
                                   displayContent()                                   
                               }
                               
                               {
                                   currentSubscriber ?
                                    <PreviewEmail emailHtml={emailHtml} updatePreviewHtml={updatePreviewHtml} showInline={true} isCampaign={true} details={{
                                        type:"Custom",
                                        email:currentSubscriber.email,
                                        subject:props.details.subject,
                                        body:props.details.body,
                                        title:props.details.title,
                                        name:props.details.name,
                                        secret:currentSubscriber.secret,
                                        subscribed:currentSubscriber.subscribed
                                        }} setDisplayPreview={true} 
                                    /> :<></>
                               } 
                            </DialogContent>
                         
                            <DialogActions>
                                             
                            <Button size="small" variant="contained" onClick={() => {
                                props.setDisplayEmailCampaign(false);                                
                            }}>Close</Button>                                

                            <Button disabled={isProcessing ? true : false } size="small" variant="contained" onClick={async () => {
                                
                                if (window.confirm("Are you sure you want to start sending emails?")) {                                    
                                    setIsProcessing(true);                           
                                    await startProcessingEmails();
                                    setIsProcessing(false);        
                                }
                            }}>Proccess</Button>                                                       

                            </DialogActions>
                        </Dialog>     
    </>);
}

export default AdminEmailCampaign;