import React, { useContext, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import ProductContext from "../context/products";
import CartContext from "../context/cart";
import Button from "@material-ui/core/Button";
import { ConvertStringToDollarsCents, isMobile } from '../components/Helper'
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonAddToCart from '../components/ButtonAddToCart';
import NotifyButton from '../components/NotifyButton';

import moment from 'moment';
// import ReactImageMagnify from 'react-image-magnify';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import Popover   from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import {ViewItem} from "../components/yayfun/AnalyticsEvent";
import Breadcrumb from "../components/Breadcrumb";
import aws_config from "../aws-exports";
import Storage from "@aws-amplify/storage";
import ProductReviews from "../components/yayfun/ProductReviews";
import ProductDraw from "../components/ProductDraw";
import ProductQuickView from '../components/ProductQuickView'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import UiContext from '../context/ui';
import Seo from "../components/Seo";


const cardImageLocationPrefix = "/images/cards/";

const ProductDetails = (props) => {
  
  const { id } = useParams();
  const history = useHistory();
  const { products, singles, getProductForId, getProductsBySet, fetchProductsForSets } = useContext(ProductContext);
  const { clearMessages } = useContext(UiContext);

  const [product, setProduct] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [normalImage, setNormalImage] = useState(null);
  const [hiResImage, setHiResImage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [productNotFound, setProductNotFound] = useState(false);

  const [openedModal, setOpenedModal] = useState(false);
  const [productInModal, setProductInModal] = useState(null);
  const [loadingSet, setLoadingSet] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  const quickViewProduct = (product) =>{
    setProductInModal(product);
    setOpenedModal(true);
    clearMessages();
  }

  const endQuickViewProduct = (product) =>{
      setProductInModal(null);
      setOpenedModal(false);
      clearMessages();
  }

  const getNormalImage = async (product) => {    
    if (product.imageAsset) {
      var re = /(?:\.([^.]+))?$/;
      let extensionString = re.exec(product.imageAsset);
      let imageKey = product.image.replace(extensionString[0],"") + ".png";
      let result = await Storage.get(imageKey, { level: 'public', bucket: aws_config.aws_user_files_s3_bucket, 
        region:aws_config.aws_user_files_s3_bucket_region});     
        if (result) {
          setNormalImage(result);
        }           
    } else {
      if (product.imageUrlKey) {
        setNormalImage(cardImageLocationPrefix + product.imageUrlKey);    
        return;
      }
      if (product.imageUrl) 
      {
        setNormalImage(product.imageUrl);    
      }else 
      {
        setNormalImage(product.image);
      }
    }
}


 const getHiResImage = async (product) => {

  if (product.imageAsset) {
    var re = /(?:\.([^.]+))?$/;
    let extensionString = re.exec(product.imageAsset);
    let imageKey = product.image.replace(extensionString[0],"") + "_hires.png";
    let result = await Storage.get(imageKey, { level: 'public', bucket: aws_config.aws_user_files_s3_bucket, 
      region:aws_config.aws_user_files_s3_bucket_region});     
      if (result) {
        setHiResImage(result);
        setImages([result]);
      }           
  } else {
    if (product.imageUrlHiResKey) {
      setHiResImage(cardImageLocationPrefix + product.imageUrlHiResKey);
      setImages([cardImageLocationPrefix + product.imageUrlHiResKey]);
      return;
    }
    
    if (product.imageUrlHiRes) 
    {
      setHiResImage(product.imageUrlHiRes);
      setImages([product.imageUrlHiRes]);
    }
    else 
    {
      setHiResImage(product.image);
      setImages([product.image]);
    }
  
  }

 }



 const finishedLoadingSet = (result) =>{       
  setLoadingSet(false);
}

 const getProductForIdAsync =  async ()=>{

     let productData = await getProductForId(id);

     if (productData && productData.set) {
        setLoadingSet(true);
        fetchProductsForSets([productData.set], finishedLoadingSet);
     }
     
     if (productData && productData.cardName) {        
       productData["isSingle"] = true;
     }

     if (productData) {

      setProduct(productData);

      document.title = productData.title;

      await getNormalImage(productData);
            
      ViewItem(productData)

      setProductNotFound(false);   

     } else {
        
        console.log("product not found")
        setProductNotFound(true);   

     }

    //  gtag('event', <action>, {
    //   'event_category': <category>,
    //   'event_label': <label>,
    //   'value': <value>
    // });

    //  window.gtag('event', 'productDetail', {
    //   'method': 'Click'
    // });

    //   window.gtag('send', 'pageview'); 

  //    window.ga("ec:addImpression", {
  //     "id": productData.id,                   // Product details are provided in an impressionFieldObject.
  //     "name": productData.title,
  //     "category": productData.type,
  //     "list": "Product Detail"
  // });
    
  //   window.ga("ec:setAction", "detail");

    // window.gtag('send', 'pageview'); 


    //  ReactGA.ga('send', 'pageview', productData.id);

    // ReactGA.pageview('/'+productData.id);

    //  window.gtag('ec:addImpression', {            // Provide product details in an impressionFieldObject.
    //   'id': productData.id,                   // Product ID (string).
    //   'name': productData.title, // Product name (string).
    //   'category': productData.type,   // Product category (string).
    //   // 'brand': 'Google',                // Product brand (string).
    //   // 'variant': 'Black',               // Product variant (string).
    //   // 'list': 'Search Results',         // Product list (string).
    //   // 'position': 1,                    // Product position (number).
    //   // 'dimension1': 'Member'            // Custom dimension (string).
    // });
    

    // window.gtag('ec:addProduct', {               // Provide product details in a productFieldObject.
    //   'id': productData.id,                   // Product ID (string).
    //     'name': productData.title, // Product name (string).
    //   // 'category': 'Apparel',            // Product category (string).
    //   // 'brand': 'Google',                // Product brand (string).
    //   // 'variant': 'Black',               // Product variant (string).
    //   // 'position': 1,                    // Product position (number).
    //   // 'dimension1': 'Member'            // Custom dimension (string).
    // });
    

    // window.gtag('ec:send');

 };

  useEffect(() => {
    
    getProductForIdAsync();

}, [id]);



if ((product && Object.keys(product).length == 0) || product == undefined) {
  return <div style={{paddingTop:"10em", textAlign:"center"}}> 
  {
    productNotFound ? <><h3>Product Not Found</h3></>:<>    <CircularProgress style={{scale:"1", width:"30px",height:"30px"}}/> </>
  }
    
  </div>;
}

  const { image, url, title, description, brand, price, units, imageUrl, quantity, salePrice, releaseDate, allowPreorder, imageUrlHiRes, isSingle, imageUrlKey, imageUrlHiResKey, types} = product;

  if (!title) {
    return <div style={{}}><CircularProgress className="product-circle-loader" /></div>;
  }

//   function showSpecialTag(product) {      
//     if (moment().valueOf() < moment(releaseDate).valueOf()) {
//       if (allowPreorder) {
//        return <span className="product-image-badge">Pre Order</span>
//       }else {
//       return <><span className="product-image-badge">Coming Soon: {moment(releaseDate).format("MM/DD/YYYY")}</span></>
//       }         
//     }else {
//      return <></>
//     }
//  }


const isNotReleased = ()=> {
  return moment().valueOf() < moment(product.releaseDate).valueOf();
}


function showSpecialTag(product) {

  if (isNotReleased()) {
    if (product.allowPreorder) {

      if (product.quantity <= 0)
      {
      return <span className="product-badge">Pre Orders Sold Out</span>
      }else {
        return <p className="product-image-badge preorder">Pre Order Releases {moment(product.releaseDate).format("MM/DD/YY")}</p>      
      }

    }else {
     return <span className="product-image-badge">Coming Soon</span>
    }         
  }else {
   if (product.quantity <= 0)
    {
       return <span className="product-image-badge">Sold Out</span>
    } else if (product.quantity <= 1) {
       return <span className="product-image-badge low">Low Stock</span>
    }
   return <></>
  }       
}


 
//  ability:Ability
//  artist:String
//  attacks:[Attack]
//  evolvesFrom:String
//  hp:String  
//  number:String
//  text:String
//  convertedRetreatCost:Int
//  imageUrl:String
//  imageUrlHiRes:String
//  nationalPokedexNumber:Int
//  rarity:String
//  resistances:[Resitance]
//  retreatCost:[String]
//  series:String
//  set:String
//  setCode:String
//  supertype:String
//  types:[String]
//  weaknesses:[Weakness]


const getEneryCostImage = (energyCost) => {
  if (energyCost == "Psychic") {
    return <i className="energy icon-psychic"></i> 
  }
  if (energyCost == "Fighting") {
    return <i className="energy icon-fighting"></i> 
  }
  if (energyCost == "Metal") {
    return <i className="energy icon-metal"></i> 
  }
  if (energyCost == "Water") {
    return <i className="energy icon-water"></i> 
  }
  if (energyCost == "Colorless") {
    return <i className="energy icon-colorless"></i> 
  }
  if (energyCost == "Grass") {
    return <i className="energy icon-grass"></i> 
  }
  if (energyCost == "Lightning") {
    return <i className="energy icon-lightning"></i> 
  }
  if (energyCost == "Darkness") {
    return <i className="energy icon-darkness"></i> 
  }
  if (energyCost == "Fairy") {
    return <i className="energy icon-fairy"></i> 
  }
  if (energyCost == "Dragon") {
    return <i className="energy icon-dragon"></i> 
  }

  return <i className="energy icon-fire"></i>
}

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};

const imageDimensions = file => 
    new Promise((resolve, reject) => {
        const img = new Image()

        // the following handler will fire after the successful loading of the image
        img.onload = () => {
            const { naturalWidth: width, naturalHeight: height } = img
            resolve({ width, height })
        }

        // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
        img.onerror = () => {
            reject('There was some problem with the image.')
        }
    
        img.src = URL.createObjectURL(file)
})

// here's how to use the helper
const getInfo = async ({ target: { files } }) => {
    const [file] = files
 
    try {
        const dimensions = await imageDimensions(file)
        console.info(dimensions)
    } catch(error) {
        console.error(error)
    }
}


const open = Boolean(anchorEl);
const popOverId = open ? 'simple-popover' : undefined;

 const displaySinglesData = (product)=>{
   
   return (<>
   
   {/* <div className="tcgplayer-group">
   <p className="tcgplayer-link">
     Available on <a href="https://tcgplayer.com">TCGplayer.com</a>
   </p>
  <IconButton color="primary" aria-label="info"  aria-describedby={popOverId} variant="outline" color="primary" onClick={handleClick}>
  <InfoIcon />
    </IconButton>

   <Popover 
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}

      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Typography>
      
      <div className="popover-msg">
        <img className="tcgplayer-logo" src="/images/tcgplayer-logo-color_320x120.png" />
        <p className="tcgplayer-msg"> Clicking on this link takes you to TCGplayer.com. As affiliates with TCGplayer we earn a commission when you buy through this link.
      </p>
      </div>
      </Typography>
    </Popover>
    </div> */}  

   <div className="card-detail-group top">
      <p className="card-detail-flag bold"><span>{product.cardName}</span></p>
      <p className="card-detail-ability-name">
          <span className="card-detail-item">{product.cardSubtype} {product.supertype} &nbsp;
            {product.hp?
            <>
            <span>HP</span><span>{product.hp}</span>
            </> :<></> }
            {product.types ? <>
            {
              product.types.map(type=>{
                return(<>{getEneryCostImage(type)}</>)
              })
            }
           </>:<></>}
          </span>
        </p>
        {
       product.evolvesFrom ? <p><span className="card-detail-item">Evolves From:</span> <span className="card-detail-value"><Link to={'/s?k='+product.evolvesFrom}>{product.evolvesFrom}</Link></span></p> : <></>
 }
      <div className="card-detail-small-group">
    
      <p><span className="card-detail-value-large">{product.set}&nbsp;{product.number}/{product.setTotal}&nbsp;{product.rarity}</span></p>
      <p><span className="card-detail-item"></span><span className="card-detail-value">{product.series} Series</span></p>
       {product.artist ? <p><span className="card-detail-item">Artist:</span> <span className="card-detail-value">{product.artist}</span></p> :<></>}  
      </div>
      {
        product.ability ?
          <>
           <p className="card-detail-flag"><span>Ability</span></p>
           <div className="card-detail-small-group">
           <p className="card-detail-ability-name">
              {product.ability.name}
            </p>
            <p className="card-detail-text">
              {product.ability.text}
            </p>
            </div>
            {/* <p>
              {product.ability.type}
            </p> */}
          </> : <></>        
       }
      {
        product.attacks && product.attacks.map(attack=>{
          return (<div className="card-detail-small-group">
            <span className="card-detail-attack-cost">
              {attack.cost.map(cost=>{
                return(<span>{getEneryCostImage(cost)}</span>)
              })}
            </span>
            <span className="card-detail-attack-name">
              {attack.name}
            </span>
            <span className="card-detail-attack-damage">
              {attack.damage}
            </span>           
            <p>
              {attack.text}
            </p>         
          </div>)
        })
      }

      {
        product.weaknesses ? <div className="split-1-3">
          <p className="card-detail-flag"><span>Weakness</span></p>
            {
              product.weaknesses && product.weaknesses.map(weakness=>{
                return (<div className="card-detail-small-group">
                  <p>
                    {getEneryCostImage(weakness.type)}
                    <span className="card-detail-strong-value">{weakness.value}</span>
                  </p>
                </div>)
              })
            }
        </div> :<></>
      }
      
      {
      product.resistances&& product.resistances.length > 0 ?<div className="split-1-3"><p className="card-detail-flag"><span>Resistance</span></p>
        <div className="card-detail-small-group">
        {
          product.resistances && product.resistances.map((resistance, index)=>{return(
            <p>{getEneryCostImage(resistance.type)}<span className="card-detail-strong-value">{resistance.value}</span></p>)
          })
        }</div></div>:<></>
      }
      

      {product.retreatCost&& product.retreatCost.length > 0 ?<div className="split-1-3"><p className="card-detail-flag"><span>Retreat Cost</span></p> 
      <div className="card-detail-small-group">
        {
        product.retreatCost && product.retreatCost.map((retreatCost, index) =>{
          return (<>
            {getEneryCostImage(retreatCost)}
          </>)
        })
      }
      </div>
      </div>:<></> }      
    
    <p style={{paddingTop:'1em'}}>{product.text}</p>
   </div></>);
 }

 const onErrorLoad = ()=>{  
  setNormalImage("/images/yayfun-coming-soon.svg");
}

const getReturnsPolicyForProduct = ()=> {
  if (product.subtype == "Code") {
    return "No returns for online codes."
  }
  return "30 day returns, see our store policy."
}


const userHovered = ()=> {
    getHiResImage(product);      
}


const getNormalImageThumb = ()=> {

 if (normalImage) {
  return normalImage;
 }
 
 if (product.imageThumbBase64) {
  return "data:image/png;base64," + product.imageThumbBase64;
 }

 if (!normalImage && !product.imageThumbBase64) {
  return "/images/pokemon-logo.png";
 }

}

const displayOtherProducts = ()=>{

  let set = getProductsBySet(product.set);
  if (set && set.length > 0) {
    return <ProductDraw products={set} detailsPage={true} quickViewProduct={quickViewProduct}/>
  }

  return <></>
}

const getProductKeywords = (product)=>{
  
  if (product.type == "Single") {

  }

  return product.keywords ? product.keywords.split(" ") : ""
}
const getProductDescription = (product)=>{

  if (product.type == "Single") {

    // <p><span className="card-detail-value-large">{product.set}&nbsp;{product.number}/{product.setTotal}&nbsp;{product.rarity}</span></p>
    // <p><span className="card-detail-item"></span><span className="card-detail-value">{product.series} Series</span></p>
    //  {product.artist ? <p><span className="card-detail-item">Artist:</span> <span className="card-detail-value">{product.artist}</span></p> :<></>}  
    // </div>

    // {product.types ? <>
    //   {
    //     product.types.map(type=>{
      //     return(<>{getEneryCostImage(type)}</>)
      //   })
      // }

      let energyTypeString = "";

      if (product.types) {
        if (product.types[0]) {
          energyTypeString = ", Energy Type: " + product.types[0];
        } 
      }

      let extraString = "";

      if (product.ability) {
        extraString += " Ability:" + product.ability.name + " - " + product.ability.text
      }


    return product.series + " - " + product.set + " Number "+ product.number + "/"+product.setTotal + " Rarity:" + product.rarity + energyTypeString + " , Artist:" + product.artist + extraString;
  }
  if (product.description) {
    return product.description;
  }


}

const displayPrice = ()=>{

  if (isNotReleased() && !product.allowPreorder) {
    return <></>;
  }

  return(<>
    {salePrice ? <> <span>{ConvertStringToDollarsCents(salePrice)}</span> <span className="price-strike">{ConvertStringToDollarsCents(price)}</span> </> :<> {ConvertStringToDollarsCents(price)} </>}
  </>)
}
  return (
    <div className="container-inner">

      <Breadcrumb product={product}/>

      <Seo
          title={product.title}
          pathSlug={"cards/"+product.id}
          description={getProductDescription(product)}
          keywords={getProductKeywords(product)}
        />

        {
          isOpen ?
          <>
          <Lightbox 
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false) }
            onMovePrevRequest={() =>              
                setPhotoIndex((photoIndex + images.length - 1) % images.length)              
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)              
            }
          /></> :<></>
        }
      
      <div className="product-details">        
        <div style={{ 'marginLeft': 'auto', 'marginRight': 'auto', 'width': 'auto', maxWidth: '1117px' }}>
          <div style={{}} className={isMobile() ? "detail-image-mobile" : "detail-image"} 
          onMouseEnter={userHovered} onClick={()=>{
            if (isMobile()) {
              getHiResImage(product);      
            }
            setIsOpen(true);
          }}>
        
            <img style={{ maxWidth: "30em", maxHeight:"25em" }} src={hiResImage ? hiResImage : url} alt={title} />
                      
            
            {/* <ReactImageMagnify {...{             
              enlargedImageContainerClassName: "enlargedImage",
              smallImage: {
                  alt:product.name,
                  isFluidWidth:true,
                  src: getNormalImageThumb(),      
                  onError:onErrorLoad,                  
                  // maxWidth:245,
                  // height:342
                //  sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
              },
              largeImage: {
                  src: hiResImage,
                  width:isSingle ? 734 : 1024,
                  height:1024,
                  zIndex: 1001,
                  // width: '200%'                      
                  // height: 512
              }
            //   enlargedImageContainerDimensions: {
            //     width: '200%',
            //     height: '200%',
            //     zIndex: 10
            // }
              // lensStyle: {
              //   background: 'hsla(0, 0%, 100%, .3)',
              //   border: '1px solid #ccc'
              // }
            }} /> */}



          </div>

          <div className={isMobile() ? "detail-description-mobile" : "detail-description"}>
            
            <div style={{paddingLeft:"1em", paddingRight:"1em"}}>
            <h1>{title}</h1>            
           
            <div style={{position:"relative", width:"auto", height:"auto", paddingTop:"2em"}}>
            {
              product.cardName != null ? <></> : showSpecialTag(product)
            }
            </div>

            <p className="product-price details align-left">              
              { price <= 0 ? <></>:
                <>
                {
                  displayPrice()
                }
                </>
              }
            </p>                        
            <div className="product-details-description">           
            <ButtonAddToCart product={product} />

            <div style={{paddingTop:"2em", textAlign:"left"}}>
              <p><span className="product-details-description-column left">Shipping:</span><span className="product-details-description-column">Calculated at Checkout</span></p>
              <p><span className="product-details-description-column left">Returns:</span><span className="product-details-description-column">{getReturnsPolicyForProduct()}</span></p>
            </div>
            {/* <NotifyButton /> */}
            {
              isSingle ? <>
                {
                  displaySinglesData(product)
                }
              </> :<>          

                <div style={{paddingTop:"4em", textAlign:"left", minHeight:"330px"}}>
                {description ? <><h2>Description</h2> <div style={{paddingTop:"1em"}} dangerouslySetInnerHTML={{ __html:description }} /></> : <></>}                 
                {
                  
                  isSingle || product.subtype == "Code" ? <></> :
                <p className="product-condition">The product is brand new/factory sealed.  Minor imperfections in shrink wrap and product packaging may occur and does not qualify for a replacement. Many times sealed cases will have boxes with tears in the wrapping.</p>
                }
              </div>
              
              </>
            }       

{/* Product is brand new/factory sealed.  Minor imperfections in shrink wrap and product packaging may occur and does not qualify for a replacement.

Any issues related to mispacks, misprints or other manufacturing issues must be resolved by contacting the Pokemon Company directly. */}
                                        
            {/* <ProductReviews /> */}
                 
             
            </div>

            
            </div>

          </div>

        </div>
      </div>

      { displayOtherProducts()}    

       
      <Dialog onClose={()=>{
                setOpenedModal(false);
              }} aria-labelledby="simple-dialog-title" open={openedModal}>
                <DialogContent>
                  <ProductQuickView product={productInModal} endQuickViewProduct={endQuickViewProduct}/>
                </DialogContent>
              </Dialog> 
    </div>
  );
};

export default ProductDetails;
