import React, { useContext, useEffect, useState, useRef } from 'react';
import CartContext from "../context/cart";
import UiContext from "../context/ui";
import Cart from "../pages/Cart";
import Drawer from '@material-ui/core/Drawer';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { Link, useLocation, useHistory } from "react-router-dom";
import Alert from './Alert';
import { ConvertStringToDollarsCents } from '../components/Helper'
import {BeginCheckoutEvent} from "../components/yayfun/AnalyticsEvent";
import NavigatorAppBar from "./NavigatorAppBar";
import NavigatorSelector from "./NavigatorSelector";
import aws_config from "../aws-exports";
import ProductImage from "./ProductImage";

const useStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: "#ffb800",
    color: "black"
  }
    // paper: {
  //   width: 400
  // }
}));

const Navigator = () => {

  const { totalItems, cart, total, lastAdded, showSideCart, toggleSideCart, showDropdown, toggleDropdown,toggleDropdownOn,  showAlert, setShowAlert } = useContext(CartContext);
  const { alertMessage, closeAlertMessage, totalMessages } = useContext(UiContext);
  const history = useHistory();
  const classes = useStyles();

  const onCloseAlert = ()=> {    
    closeAlertMessage();
  }


  const getColorFromMessage = ()=>{
    if (alertMessage && alertMessage.toLowerCase().includes("success")) {
        return "rgb(38, 187, 61)";
    }
    return "#e3296f";
  }

  
  return (<>
      
      <NavigatorAppBar/>
      {
        alertMessage && alertMessage != "" ? <Alert backgroundColor={getColorFromMessage()} color="white" message={alertMessage} onCloseAlert={onCloseAlert}/>         
        : <>
        <div style={{ zIndex: "2" }} className={showDropdown ? "notification show" : "notification hide"}>
            <div className="notification-main">
              <div className="notification-group">
                <div className="notification-main-left" style={{ float: "left" }}>
                  <p><CheckIcon /> Added to your cart!</p>
                  <div>
                    {
                      lastAdded && lastAdded.product ? <div>
                        <div className="notification-left">
                          <Badge classes={{ badge: classes.customBadge }} badgeContent={lastAdded.quantity} max={99}>
                             <ProductImage product={lastAdded.product} suffix="_120.png" />
                          </Badge>
                        </div>
                        <div className="notification-right">
                          <p className="product-title">
                            {
                              lastAdded.product.title
                            }
                          </p>
                          <p className="product-price">
                            {
                              ConvertStringToDollarsCents(lastAdded.product.price)
                            }
                          </p>
                        </div>

                      </div> : <></>
                    }
                  </div>
                </div>

                <div className="notification-main-right" style={{ float: "right" }}>
                  <div>
                    <CloseIcon style={{ color: "black", fill: "black", scale: "1.7", float: "right", marginRight: "0.6em" }} onClick={() => {
                      toggleDropdown(false);
                    }} />

                  </div>
                  <div style={{ paddingTop: "3em" }}>
                    <p className="notification-p" >Subtotal: {ConvertStringToDollarsCents(total)}</p>

                    <Button
                      className="dropdown-button"
                      color="primary"
                      variant="outlined"
                      onClick={() => {                        
                        toggleDropdownOn(false);
                        history.push("/cart")
                      }}>View Cart</Button>

                    <Button
                      className="dropdown-button"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        BeginCheckoutEvent({cart:cart, total:total});
                        toggleDropdownOn(false);
                        history.push("/checkout");
                      }}>Checkout</Button>
                  </div>
                </div>
                <div>
                </div>
              </div>
            </div>
            <div style={{ height:"300px", backgroundColor: "transparent" }} onClick={() => {
              // toggleDropdown(false);
            }}>
            </div>

        </div>
        </>
      }
      <Drawer   classes={{ paper: classes.paper }}  anchor={'right'} open={showSideCart} onClose={toggleSideCart}>
        <Cart sideCart={true} />
      </Drawer>

    </>
  )
}

export default Navigator;
