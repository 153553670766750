/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processOrder = /* GraphQL */ `
  mutation ProcessOrder($input: ProcessOrderInput!) {
    processOrder(input: $input) {
      OrderStatus
      OrderNumber
      OrderDate
      OrderTotal
      OrderDiscount
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      set
      title
      asin
      description
      image
      author
      brand
      featured
      price
      cost
      weight
      units
      unitsRemaining
      limit
      quantity
      variation {
        name
        quantity
        price
      }
      type
      subtype
      status
      allowPreorder
      salePrice
      salePriceEndDate
      moreDetails
      keyword
      releaseDate
      packs
      updatedAt
      msku
      upc
      condition
      cardId
      cardName
      cardType
      cardSubtype
      cardSubtypes
      ability {
        name
        text
        type
      }
      abilities {
        name
        text
        type
      }
      artist
      attacks {
        convertedEnergyCost
        cost
        damage
        name
        text
      }
      evolvesFrom
      evolvesTo
      ancientTrait
      level
      hp
      legalities {
        expanded
        standard
        unlimited
      }
      number
      text
      flavorText
      convertedRetreatCost
      imageUrl
      imageUrlHiRes
      imageUrlKey
      imageUrlHiResKey
      imageAsset
      imageThumbBase64
      nationalPokedexNumber
      nationalPokedexNumbers
      rarity
      regulationMark
      resistances {
        type
        value
      }
      retreatCost
      rules
      series
      setTotal
      setPrintedTotal
      setCode
      subsetCode
      supertype
      types
      weaknesses {
        type
        value
      }
      marketPrice
      marketPriceTimestamp
      medianPrice
      minPrice
      maxPrice
      autoPricing
      profit
      refunds {
        id
        paymentIntentId
        transactionId
        chargeId
        amount
        created
        status
      }
      liveOnAmazon
      liveOnWalmart
      liveOnTCGPlayer
      tcgplayer {
        updatedAt
        url
      }
      cardmarket {
        prices {
          averageSellPrice
          avg1
          avg30
          avg7
          germanProLow
          lowPrice
          lowPriceExPlus
          reverseHoloAvg1
          reverseHoloAvg30
          reverseHoloAvg7
          reverseHoloLow
          reverseHoloSell
          reverseHoloTrend
          suggestedPrice
          trendPrice
        }
        updatedAt
        url
      }
      createdAt
      orders {
        items {
          id
          product_id
          order_id
          price
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      set
      title
      asin
      description
      image
      author
      brand
      featured
      price
      cost
      weight
      units
      unitsRemaining
      limit
      quantity
      variation {
        name
        quantity
        price
      }
      type
      subtype
      status
      allowPreorder
      salePrice
      salePriceEndDate
      moreDetails
      keyword
      releaseDate
      packs
      updatedAt
      msku
      upc
      condition
      cardId
      cardName
      cardType
      cardSubtype
      cardSubtypes
      ability {
        name
        text
        type
      }
      abilities {
        name
        text
        type
      }
      artist
      attacks {
        convertedEnergyCost
        cost
        damage
        name
        text
      }
      evolvesFrom
      evolvesTo
      ancientTrait
      level
      hp
      legalities {
        expanded
        standard
        unlimited
      }
      number
      text
      flavorText
      convertedRetreatCost
      imageUrl
      imageUrlHiRes
      imageUrlKey
      imageUrlHiResKey
      imageAsset
      imageThumbBase64
      nationalPokedexNumber
      nationalPokedexNumbers
      rarity
      regulationMark
      resistances {
        type
        value
      }
      retreatCost
      rules
      series
      setTotal
      setPrintedTotal
      setCode
      subsetCode
      supertype
      types
      weaknesses {
        type
        value
      }
      marketPrice
      marketPriceTimestamp
      medianPrice
      minPrice
      maxPrice
      autoPricing
      profit
      refunds {
        id
        paymentIntentId
        transactionId
        chargeId
        amount
        created
        status
      }
      liveOnAmazon
      liveOnWalmart
      liveOnTCGPlayer
      tcgplayer {
        updatedAt
        url
      }
      cardmarket {
        prices {
          averageSellPrice
          avg1
          avg30
          avg7
          germanProLow
          lowPrice
          lowPriceExPlus
          reverseHoloAvg1
          reverseHoloAvg30
          reverseHoloAvg7
          reverseHoloLow
          reverseHoloSell
          reverseHoloTrend
          suggestedPrice
          trendPrice
        }
        updatedAt
        url
      }
      createdAt
      orders {
        items {
          id
          product_id
          order_id
          price
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      set
      title
      asin
      description
      image
      author
      brand
      featured
      price
      cost
      weight
      units
      unitsRemaining
      limit
      quantity
      variation {
        name
        quantity
        price
      }
      type
      subtype
      status
      allowPreorder
      salePrice
      salePriceEndDate
      moreDetails
      keyword
      releaseDate
      packs
      updatedAt
      msku
      upc
      condition
      cardId
      cardName
      cardType
      cardSubtype
      cardSubtypes
      ability {
        name
        text
        type
      }
      abilities {
        name
        text
        type
      }
      artist
      attacks {
        convertedEnergyCost
        cost
        damage
        name
        text
      }
      evolvesFrom
      evolvesTo
      ancientTrait
      level
      hp
      legalities {
        expanded
        standard
        unlimited
      }
      number
      text
      flavorText
      convertedRetreatCost
      imageUrl
      imageUrlHiRes
      imageUrlKey
      imageUrlHiResKey
      imageAsset
      imageThumbBase64
      nationalPokedexNumber
      nationalPokedexNumbers
      rarity
      regulationMark
      resistances {
        type
        value
      }
      retreatCost
      rules
      series
      setTotal
      setPrintedTotal
      setCode
      subsetCode
      supertype
      types
      weaknesses {
        type
        value
      }
      marketPrice
      marketPriceTimestamp
      medianPrice
      minPrice
      maxPrice
      autoPricing
      profit
      refunds {
        id
        paymentIntentId
        transactionId
        chargeId
        amount
        created
        status
      }
      liveOnAmazon
      liveOnWalmart
      liveOnTCGPlayer
      tcgplayer {
        updatedAt
        url
      }
      cardmarket {
        prices {
          averageSellPrice
          avg1
          avg30
          avg7
          germanProLow
          lowPrice
          lowPriceExPlus
          reverseHoloAvg1
          reverseHoloAvg30
          reverseHoloAvg7
          reverseHoloLow
          reverseHoloSell
          reverseHoloTrend
          suggestedPrice
          trendPrice
        }
        updatedAt
        url
      }
      createdAt
      orders {
        items {
          id
          product_id
          order_id
          price
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSubscriber = /* GraphQL */ `
  mutation CreateSubscriber(
    $input: CreateSubscriberInput!
    $condition: ModelSubscriberConditionInput
  ) {
    createSubscriber(input: $input, condition: $condition) {
      id
      name
      email
      subscribed
      secret
      usedCoupon
      rejected
      verified
      createdAt
      updatedAt
    }
  }
`;
export const updateSubscriber = /* GraphQL */ `
  mutation UpdateSubscriber(
    $input: UpdateSubscriberInput!
    $condition: ModelSubscriberConditionInput
  ) {
    updateSubscriber(input: $input, condition: $condition) {
      id
      name
      email
      subscribed
      secret
      usedCoupon
      rejected
      verified
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubscriber = /* GraphQL */ `
  mutation DeleteSubscriber(
    $input: DeleteSubscriberInput!
    $condition: ModelSubscriberConditionInput
  ) {
    deleteSubscriber(input: $input, condition: $condition) {
      id
      name
      email
      subscribed
      secret
      usedCoupon
      rejected
      verified
      createdAt
      updatedAt
    }
  }
`;
export const createProductOrder = /* GraphQL */ `
  mutation CreateProductOrder(
    $input: CreateProductOrderInput!
    $condition: ModelProductOrderConditionInput
  ) {
    createProductOrder(input: $input, condition: $condition) {
      id
      product_id
      order_id
      price
      amount
      createdAt
      updatedAt
      product {
        id
        set
        title
        asin
        description
        image
        author
        brand
        featured
        price
        cost
        weight
        units
        unitsRemaining
        limit
        quantity
        variation {
          name
          quantity
          price
        }
        type
        subtype
        status
        allowPreorder
        salePrice
        salePriceEndDate
        moreDetails
        keyword
        releaseDate
        packs
        updatedAt
        msku
        upc
        condition
        cardId
        cardName
        cardType
        cardSubtype
        cardSubtypes
        ability {
          name
          text
          type
        }
        abilities {
          name
          text
          type
        }
        artist
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }
        evolvesFrom
        evolvesTo
        ancientTrait
        level
        hp
        legalities {
          expanded
          standard
          unlimited
        }
        number
        text
        flavorText
        convertedRetreatCost
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        imageAsset
        imageThumbBase64
        nationalPokedexNumber
        nationalPokedexNumbers
        rarity
        regulationMark
        resistances {
          type
          value
        }
        retreatCost
        rules
        series
        setTotal
        setPrintedTotal
        setCode
        subsetCode
        supertype
        types
        weaknesses {
          type
          value
        }
        marketPrice
        marketPriceTimestamp
        medianPrice
        minPrice
        maxPrice
        autoPricing
        profit
        refunds {
          id
          paymentIntentId
          transactionId
          chargeId
          amount
          created
          status
        }
        liveOnAmazon
        liveOnWalmart
        liveOnTCGPlayer
        tcgplayer {
          updatedAt
          url
        }
        cardmarket {
          updatedAt
          url
        }
        createdAt
        orders {
          nextToken
        }
      }
      order {
        type
        id
        user
        owner
        date
        coupon
        customer
        number
        total
        discount
        subtotal
        shippingCost
        shippingPaid
        taxCost
        status
        shippingMethod
        authorizationId
        paymentMethod
        paymentIntentId
        paypalOrderId
        paymentType
        billingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        codes {
          id
          value
          name
          product_id
          notes
          emailed
        }
        packages {
          id
          status
          trackingNumber
          carrier
        }
        search
        promiseDateMin
        promiseDateMax
        history {
          createdAt
          description
        }
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
    }
  }
`;
export const updateProductOrder = /* GraphQL */ `
  mutation UpdateProductOrder(
    $input: UpdateProductOrderInput!
    $condition: ModelProductOrderConditionInput
  ) {
    updateProductOrder(input: $input, condition: $condition) {
      id
      product_id
      order_id
      price
      amount
      createdAt
      updatedAt
      product {
        id
        set
        title
        asin
        description
        image
        author
        brand
        featured
        price
        cost
        weight
        units
        unitsRemaining
        limit
        quantity
        variation {
          name
          quantity
          price
        }
        type
        subtype
        status
        allowPreorder
        salePrice
        salePriceEndDate
        moreDetails
        keyword
        releaseDate
        packs
        updatedAt
        msku
        upc
        condition
        cardId
        cardName
        cardType
        cardSubtype
        cardSubtypes
        ability {
          name
          text
          type
        }
        abilities {
          name
          text
          type
        }
        artist
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }
        evolvesFrom
        evolvesTo
        ancientTrait
        level
        hp
        legalities {
          expanded
          standard
          unlimited
        }
        number
        text
        flavorText
        convertedRetreatCost
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        imageAsset
        imageThumbBase64
        nationalPokedexNumber
        nationalPokedexNumbers
        rarity
        regulationMark
        resistances {
          type
          value
        }
        retreatCost
        rules
        series
        setTotal
        setPrintedTotal
        setCode
        subsetCode
        supertype
        types
        weaknesses {
          type
          value
        }
        marketPrice
        marketPriceTimestamp
        medianPrice
        minPrice
        maxPrice
        autoPricing
        profit
        refunds {
          id
          paymentIntentId
          transactionId
          chargeId
          amount
          created
          status
        }
        liveOnAmazon
        liveOnWalmart
        liveOnTCGPlayer
        tcgplayer {
          updatedAt
          url
        }
        cardmarket {
          updatedAt
          url
        }
        createdAt
        orders {
          nextToken
        }
      }
      order {
        type
        id
        user
        owner
        date
        coupon
        customer
        number
        total
        discount
        subtotal
        shippingCost
        shippingPaid
        taxCost
        status
        shippingMethod
        authorizationId
        paymentMethod
        paymentIntentId
        paypalOrderId
        paymentType
        billingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        codes {
          id
          value
          name
          product_id
          notes
          emailed
        }
        packages {
          id
          status
          trackingNumber
          carrier
        }
        search
        promiseDateMin
        promiseDateMax
        history {
          createdAt
          description
        }
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
    }
  }
`;
export const deleteProductOrder = /* GraphQL */ `
  mutation DeleteProductOrder(
    $input: DeleteProductOrderInput!
    $condition: ModelProductOrderConditionInput
  ) {
    deleteProductOrder(input: $input, condition: $condition) {
      id
      product_id
      order_id
      price
      amount
      createdAt
      updatedAt
      product {
        id
        set
        title
        asin
        description
        image
        author
        brand
        featured
        price
        cost
        weight
        units
        unitsRemaining
        limit
        quantity
        variation {
          name
          quantity
          price
        }
        type
        subtype
        status
        allowPreorder
        salePrice
        salePriceEndDate
        moreDetails
        keyword
        releaseDate
        packs
        updatedAt
        msku
        upc
        condition
        cardId
        cardName
        cardType
        cardSubtype
        cardSubtypes
        ability {
          name
          text
          type
        }
        abilities {
          name
          text
          type
        }
        artist
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }
        evolvesFrom
        evolvesTo
        ancientTrait
        level
        hp
        legalities {
          expanded
          standard
          unlimited
        }
        number
        text
        flavorText
        convertedRetreatCost
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        imageAsset
        imageThumbBase64
        nationalPokedexNumber
        nationalPokedexNumbers
        rarity
        regulationMark
        resistances {
          type
          value
        }
        retreatCost
        rules
        series
        setTotal
        setPrintedTotal
        setCode
        subsetCode
        supertype
        types
        weaknesses {
          type
          value
        }
        marketPrice
        marketPriceTimestamp
        medianPrice
        minPrice
        maxPrice
        autoPricing
        profit
        refunds {
          id
          paymentIntentId
          transactionId
          chargeId
          amount
          created
          status
        }
        liveOnAmazon
        liveOnWalmart
        liveOnTCGPlayer
        tcgplayer {
          updatedAt
          url
        }
        cardmarket {
          updatedAt
          url
        }
        createdAt
        orders {
          nextToken
        }
      }
      order {
        type
        id
        user
        owner
        date
        coupon
        customer
        number
        total
        discount
        subtotal
        shippingCost
        shippingPaid
        taxCost
        status
        shippingMethod
        authorizationId
        paymentMethod
        paymentIntentId
        paypalOrderId
        paymentType
        billingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        codes {
          id
          value
          name
          product_id
          notes
          emailed
        }
        packages {
          id
          status
          trackingNumber
          carrier
        }
        search
        promiseDateMin
        promiseDateMax
        history {
          createdAt
          description
        }
        createdAt
        updatedAt
        products {
          nextToken
        }
      }
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      type
      id
      user
      owner
      date
      coupon
      customer
      number
      total
      discount
      subtotal
      shippingCost
      shippingPaid
      taxCost
      status
      shippingMethod
      authorizationId
      paymentMethod
      paymentIntentId
      paypalOrderId
      paymentType
      billingAddress {
        firstName
        lastName
        name
        addressLine1
        addressLine2
        country
        postalCode
        companyName
        phoneNumber
        city
        state
        default
      }
      shippingAddress {
        firstName
        lastName
        name
        addressLine1
        addressLine2
        country
        postalCode
        companyName
        phoneNumber
        city
        state
        default
      }
      codes {
        id
        value
        name
        product_id
        notes
        emailed
      }
      packages {
        id
        status
        trackingNumber
        carrier
        products {
          amount
          product_id
        }
      }
      search
      promiseDateMin
      promiseDateMax
      history {
        createdAt
        description
      }
      createdAt
      updatedAt
      products {
        items {
          id
          product_id
          order_id
          price
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      type
      id
      user
      owner
      date
      coupon
      customer
      number
      total
      discount
      subtotal
      shippingCost
      shippingPaid
      taxCost
      status
      shippingMethod
      authorizationId
      paymentMethod
      paymentIntentId
      paypalOrderId
      paymentType
      billingAddress {
        firstName
        lastName
        name
        addressLine1
        addressLine2
        country
        postalCode
        companyName
        phoneNumber
        city
        state
        default
      }
      shippingAddress {
        firstName
        lastName
        name
        addressLine1
        addressLine2
        country
        postalCode
        companyName
        phoneNumber
        city
        state
        default
      }
      codes {
        id
        value
        name
        product_id
        notes
        emailed
      }
      packages {
        id
        status
        trackingNumber
        carrier
        products {
          amount
          product_id
        }
      }
      search
      promiseDateMin
      promiseDateMax
      history {
        createdAt
        description
      }
      createdAt
      updatedAt
      products {
        items {
          id
          product_id
          order_id
          price
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      type
      id
      user
      owner
      date
      coupon
      customer
      number
      total
      discount
      subtotal
      shippingCost
      shippingPaid
      taxCost
      status
      shippingMethod
      authorizationId
      paymentMethod
      paymentIntentId
      paypalOrderId
      paymentType
      billingAddress {
        firstName
        lastName
        name
        addressLine1
        addressLine2
        country
        postalCode
        companyName
        phoneNumber
        city
        state
        default
      }
      shippingAddress {
        firstName
        lastName
        name
        addressLine1
        addressLine2
        country
        postalCode
        companyName
        phoneNumber
        city
        state
        default
      }
      codes {
        id
        value
        name
        product_id
        notes
        emailed
      }
      packages {
        id
        status
        trackingNumber
        carrier
        products {
          amount
          product_id
        }
      }
      search
      promiseDateMin
      promiseDateMax
      history {
        createdAt
        description
      }
      createdAt
      updatedAt
      products {
        items {
          id
          product_id
          order_id
          price
          amount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSiteItem = /* GraphQL */ `
  mutation CreateSiteItem(
    $input: CreateSiteItemInput!
    $condition: ModelSiteItemConditionInput
  ) {
    createSiteItem(input: $input, condition: $condition) {
      type
      id
      data
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateSiteItem = /* GraphQL */ `
  mutation UpdateSiteItem(
    $input: UpdateSiteItemInput!
    $condition: ModelSiteItemConditionInput
  ) {
    updateSiteItem(input: $input, condition: $condition) {
      type
      id
      data
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteSiteItem = /* GraphQL */ `
  mutation DeleteSiteItem(
    $input: DeleteSiteItemInput!
    $condition: ModelSiteItemConditionInput
  ) {
    deleteSiteItem(input: $input, condition: $condition) {
      type
      id
      data
      status
      createdAt
      updatedAt
    }
  }
`;
export const createUserItem = /* GraphQL */ `
  mutation CreateUserItem(
    $input: CreateUserItemInput!
    $condition: ModelUserItemConditionInput
  ) {
    createUserItem(input: $input, condition: $condition) {
      owner
      type
      id
      name
      data
      createdAt
      status
      history {
        createdAt
        description
      }
      shippingAddress {
        firstName
        lastName
        name
        addressLine1
        addressLine2
        country
        postalCode
        companyName
        phoneNumber
        city
        state
        default
      }
      updatedAt
      links {
        items {
          owner
          type
          id
          name
          data
          createdAt
          status
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUserItem = /* GraphQL */ `
  mutation UpdateUserItem(
    $input: UpdateUserItemInput!
    $condition: ModelUserItemConditionInput
  ) {
    updateUserItem(input: $input, condition: $condition) {
      owner
      type
      id
      name
      data
      createdAt
      status
      history {
        createdAt
        description
      }
      shippingAddress {
        firstName
        lastName
        name
        addressLine1
        addressLine2
        country
        postalCode
        companyName
        phoneNumber
        city
        state
        default
      }
      updatedAt
      links {
        items {
          owner
          type
          id
          name
          data
          createdAt
          status
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUserItem = /* GraphQL */ `
  mutation DeleteUserItem(
    $input: DeleteUserItemInput!
    $condition: ModelUserItemConditionInput
  ) {
    deleteUserItem(input: $input, condition: $condition) {
      owner
      type
      id
      name
      data
      createdAt
      status
      history {
        createdAt
        description
      }
      shippingAddress {
        firstName
        lastName
        name
        addressLine1
        addressLine2
        country
        postalCode
        companyName
        phoneNumber
        city
        state
        default
      }
      updatedAt
      links {
        items {
          owner
          type
          id
          name
          data
          createdAt
          status
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAnalytics = /* GraphQL */ `
  mutation CreateAnalytics(
    $input: CreateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    createAnalytics(input: $input, condition: $condition) {
      owner
      type
      id
      createdAt
      updatedAt
      amazonPrice
      instockQty
      soldQty
      data
      product {
        id
        set
        title
        asin
        description
        image
        author
        brand
        featured
        price
        cost
        weight
        units
        unitsRemaining
        limit
        quantity
        variation {
          name
          quantity
          price
        }
        type
        subtype
        status
        allowPreorder
        salePrice
        salePriceEndDate
        moreDetails
        keyword
        releaseDate
        packs
        updatedAt
        msku
        upc
        condition
        cardId
        cardName
        cardType
        cardSubtype
        cardSubtypes
        ability {
          name
          text
          type
        }
        abilities {
          name
          text
          type
        }
        artist
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }
        evolvesFrom
        evolvesTo
        ancientTrait
        level
        hp
        legalities {
          expanded
          standard
          unlimited
        }
        number
        text
        flavorText
        convertedRetreatCost
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        imageAsset
        imageThumbBase64
        nationalPokedexNumber
        nationalPokedexNumbers
        rarity
        regulationMark
        resistances {
          type
          value
        }
        retreatCost
        rules
        series
        setTotal
        setPrintedTotal
        setCode
        subsetCode
        supertype
        types
        weaknesses {
          type
          value
        }
        marketPrice
        marketPriceTimestamp
        medianPrice
        minPrice
        maxPrice
        autoPricing
        profit
        refunds {
          id
          paymentIntentId
          transactionId
          chargeId
          amount
          created
          status
        }
        liveOnAmazon
        liveOnWalmart
        liveOnTCGPlayer
        tcgplayer {
          updatedAt
          url
        }
        cardmarket {
          updatedAt
          url
        }
        createdAt
        orders {
          nextToken
        }
      }
    }
  }
`;
export const updateAnalytics = /* GraphQL */ `
  mutation UpdateAnalytics(
    $input: UpdateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    updateAnalytics(input: $input, condition: $condition) {
      owner
      type
      id
      createdAt
      updatedAt
      amazonPrice
      instockQty
      soldQty
      data
      product {
        id
        set
        title
        asin
        description
        image
        author
        brand
        featured
        price
        cost
        weight
        units
        unitsRemaining
        limit
        quantity
        variation {
          name
          quantity
          price
        }
        type
        subtype
        status
        allowPreorder
        salePrice
        salePriceEndDate
        moreDetails
        keyword
        releaseDate
        packs
        updatedAt
        msku
        upc
        condition
        cardId
        cardName
        cardType
        cardSubtype
        cardSubtypes
        ability {
          name
          text
          type
        }
        abilities {
          name
          text
          type
        }
        artist
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }
        evolvesFrom
        evolvesTo
        ancientTrait
        level
        hp
        legalities {
          expanded
          standard
          unlimited
        }
        number
        text
        flavorText
        convertedRetreatCost
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        imageAsset
        imageThumbBase64
        nationalPokedexNumber
        nationalPokedexNumbers
        rarity
        regulationMark
        resistances {
          type
          value
        }
        retreatCost
        rules
        series
        setTotal
        setPrintedTotal
        setCode
        subsetCode
        supertype
        types
        weaknesses {
          type
          value
        }
        marketPrice
        marketPriceTimestamp
        medianPrice
        minPrice
        maxPrice
        autoPricing
        profit
        refunds {
          id
          paymentIntentId
          transactionId
          chargeId
          amount
          created
          status
        }
        liveOnAmazon
        liveOnWalmart
        liveOnTCGPlayer
        tcgplayer {
          updatedAt
          url
        }
        cardmarket {
          updatedAt
          url
        }
        createdAt
        orders {
          nextToken
        }
      }
    }
  }
`;
export const deleteAnalytics = /* GraphQL */ `
  mutation DeleteAnalytics(
    $input: DeleteAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    deleteAnalytics(input: $input, condition: $condition) {
      owner
      type
      id
      createdAt
      updatedAt
      amazonPrice
      instockQty
      soldQty
      data
      product {
        id
        set
        title
        asin
        description
        image
        author
        brand
        featured
        price
        cost
        weight
        units
        unitsRemaining
        limit
        quantity
        variation {
          name
          quantity
          price
        }
        type
        subtype
        status
        allowPreorder
        salePrice
        salePriceEndDate
        moreDetails
        keyword
        releaseDate
        packs
        updatedAt
        msku
        upc
        condition
        cardId
        cardName
        cardType
        cardSubtype
        cardSubtypes
        ability {
          name
          text
          type
        }
        abilities {
          name
          text
          type
        }
        artist
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }
        evolvesFrom
        evolvesTo
        ancientTrait
        level
        hp
        legalities {
          expanded
          standard
          unlimited
        }
        number
        text
        flavorText
        convertedRetreatCost
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        imageAsset
        imageThumbBase64
        nationalPokedexNumber
        nationalPokedexNumbers
        rarity
        regulationMark
        resistances {
          type
          value
        }
        retreatCost
        rules
        series
        setTotal
        setPrintedTotal
        setCode
        subsetCode
        supertype
        types
        weaknesses {
          type
          value
        }
        marketPrice
        marketPriceTimestamp
        medianPrice
        minPrice
        maxPrice
        autoPricing
        profit
        refunds {
          id
          paymentIntentId
          transactionId
          chargeId
          amount
          created
          status
        }
        liveOnAmazon
        liveOnWalmart
        liveOnTCGPlayer
        tcgplayer {
          updatedAt
          url
        }
        cardmarket {
          updatedAt
          url
        }
        createdAt
        orders {
          nextToken
        }
      }
    }
  }
`;
