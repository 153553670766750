/* eslint-disable */
// this is an auto generated file. This will be overwritten


export const processOrder = /* GraphQL */ `
  mutation ProcessOrder($input: ProcessOrderInput!) {
    processOrder(input: $input) {
      OrderStatus
      OrderNumber
      OrderDate
      OrderTotal
      OrderDiscount
    }
  }
`;


export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      title
      description
      image
      author
      featured
      price
      quantity
      orders {
        items {
          id
          product_id
          order_id
        }
        nextToken
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      title
      description
      image
      author
      featured
      price
      quantity
      series
      allowPreorder
      subtype
      variation {
        name
        quantity
        price
      }
      orders {
        items {
          id
          product_id
          order_id
        }
        nextToken
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      title
      description
      image
      author
      featured
      price
      quantity
      orders {
        items {
          id
          product_id
          order_id
        }
        nextToken
      }
    }
  }
`;
export const createProductOrder = /* GraphQL */ `
  mutation CreateProductOrder(
    $input: CreateProductOrderInput!
    $condition: ModelProductOrderConditionInput
  ) {
    createProductOrder(input: $input, condition: $condition) {
      id
      product_id
      order_id
      order {
        id
        user
        date
        total
        products {
          nextToken
        }
      }
      product {
        id
        title
        description
        image
        author
        featured
        price
        orders {
          nextToken
        }
      }
    }
  }
`;
export const updateProductOrder = /* GraphQL */ `
  mutation UpdateProductOrder(
    $input: UpdateProductOrderInput!
    $condition: ModelProductOrderConditionInput
  ) {
    updateProductOrder(input: $input, condition: $condition) {
      id
      product_id
      order_id
      order {
        id
        user
        date
        total
        products {
          nextToken
        }
      }
      product {
        id
        title
        description
        image
        author
        featured
        price
        orders {
          nextToken
        }
      }
    }
  }
`;
export const deleteProductOrder = /* GraphQL */ `
  mutation DeleteProductOrder(
    $input: DeleteProductOrderInput!
    $condition: ModelProductOrderConditionInput
  ) {
    deleteProductOrder(input: $input, condition: $condition) {
      id
      product_id
      order_id
      order {
        id
        user
        date
        total
        products {
          nextToken
        }
      }
      product {
        id
        title
        description
        image
        author
        featured
        price
        orders {
          nextToken
        }
      }
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      products {
        items {
          id
          product_id
          order_id
        }
        nextToken
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      number
      status
      history {
        createdAt
        description
      }
      packages {
        id
        status
        trackingNumber
        products {
          amount
          product_id
        }
      }
      products {
        items {
          id
          product_id
          order_id
        }
        nextToken
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      products {
        items {
          id
          product_id
          order_id
        }
        nextToken
      }
    }
  }
`;
