

import React, { useContext, useState, useEffect } from "react";
import aws_config from "../aws-exports";
import Storage from "@aws-amplify/storage";

function S3Image(props) {

    const [url, setUrl] = useState("");
    
    
    const getImageForKey = async (key) => {                

        if (key) {
            if (key && url == "") {
                var re = /(?:\.([^.]+))?$/;
                let extensionString = re.exec(key);
                let imageKey = key.replace(extensionString[0],""); 
                imageKey = imageKey + (props.suffix ? props.suffix : ".png");
                let result = await Storage.get(imageKey, { level: 'public', bucket: aws_config.aws_user_files_s3_bucket, 
                  region:aws_config.aws_user_files_s3_bucket_region});                       
                  if (result) {
                      setUrl(result);
                  } 
              }                    
        }      
      }
  
      
    useEffect(()=>{
        getImageForKey(props.imgKey)
    });

    return <>
        <img className={props.classNameKey} src={url} alt={"image" }/>                        
    </>
}

export default S3Image;