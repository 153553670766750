import React, { useState, useContext, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignIn, AmplifySignUp, AmplifyConfirmSignUp, AmplifyForgotPassword } from '@aws-amplify/ui-react';
import config from '../aws-exports'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import UiContext from "../context/ui";
import UserContext from "../context/user";
import ProductContext from "../context/products";
import TableObject from "../components/yayfun/TableObject";
import { ConvertStringToDollarsCents } from '../components/Helper'
import moment from 'moment';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import OrdersDraw from '../components/OrderDraw';
import AddressDraw from '../components/AddressDraw';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FetchMoreOrdersButton from '../components/FetchMoreOrdersButton';
import Seo from '../components/Seo';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config


const Account = () => {

    document.title = "Account";

    const [image, setImage] = useState(null);
    const { user, signIn, orders, fetchMoreOrders, fetchNewOrders, hasMoreOrdersToLoad, loading, shippingAddresses, updateUserShippingAddress, deleteUserItemWithId, addTempShippingAddress, deleteTempShippingAddress } = useContext(UserContext);
    const { products, getProductDataFromId } = useContext(ProductContext);
    const { sendAlertMessage } = useContext(UiContext);

    const [tabSelected, setTabSelected] = useState("orders");
    const [isEditing, setIsEditing] = useState(false);
    const [editingAddressId, setEditingAddressId] = useState(null);
    const [dirty, setDirty] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState(false);
    const [addressToDelete, setAddressToDelete] = useState(false);

    const setDirtyUpdate = () => {
        setDirty(!dirty);
    }

    useEffect (()=>{
        fetchNewOrders();
    }, [signIn]);


    useEffect (()=>{        
    }, [shippingAddresses]);

    const validateAddress = (address) => {

    var Namere = /[A-Za-z]{1}[A-Za-z]/;
    if (!Namere.test(address.firstName)) {
        // setInputError({...inputError, name: true});            
        sendAlertMessage("Please enter a valid name.")
        return false;
    }
    var mobilere = /[0-9]{10}/;

    if (!mobilere.test(address.phoneNumber)) {
        // setInputError({...inputError, phone: true});            
        // sendAlertMessage("Please enter a valid phone 10 digit number.")
        // return fals;
    }
    // if (address.email == "") {
    // //   setInputError({ ...inputError, email: true });
    //     sendAlertMessage("Please enter an email address.")
    //   return false;
    // }
    // var reeamil = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
    // if (!reeamil.test(address.email)) {
    //     sendAlertMessage("Please enter a valid email address.")
    //   return false;
    // }

     return true;
    }

    return (
        <section className="container-inner" style={{paddingTop:"1em"}}>

<Seo
                  title={"Account"}
                  pathSlug={"account"}
                  description={"Login to your account to view your past orders and to manage your addresses."}
                />

            <AmplifyAuthenticator
            style={{
                height:'auto'
              }}>

<AmplifyConfirmSignUp 
                slot="confirm-sign-up"
                formFields={[
                    { 
                        type: "username",
                        label: "Email *",
                        placeholder: "Enter your email"
                    },
                    {
                      type:'code'
                    }
                ]} 
              />


<AmplifyForgotPassword 
                slot="forgot-password"
                formFields={[
                    { 
                        type: "username",
                        label: "Email *",
                        placeholder: "Enter your email"
                    }
                ]} 
              />


            <AmplifySignIn
                slot="sign-in"
                formFields={[
                    { 
                        type: "username",
                        label: "Email *",
                        placeholder: "Enter your email"
                    },         
                    {
                        type: "password"
                    }
                ]} 
                />


            <AmplifySignUp
                slot="sign-up"
                formFields={[
                    { 
                        type: "username",
                        label: "Email",
                        placeholder: "Email"
                    },
                    { 
                        type: "email",
                        label: "Verify Email",
                        placeholder: "Verify your email"
                     },
                    {
                        type: "password"
                    }                    
                ]} 
                />


                <div className="account-signout">
                    {
                        user && user.email ? user.email : <></> 
                    }

                <AmplifySignOut/>
                
                </div>
                <section>
                      
                        <><div style={{ padding: "2em", margin: "1em", textAlign: "left" }}>

                            <div>
                                <div className={tabSelected == "orders" ? "account-tab-selected" : "account-tab"} onClick={() => {
                                    setTabSelected("orders")
                                }}>
                                    Orders
                            </div>

                            <div className={tabSelected == "addresses" ? "account-tab-selected" : "account-tab"} onClick={() => {
                                    setTabSelected("addresses")
                                }}>
                                    Addresses
                            </div>


                            </div>

                            {
                                tabSelected == "orders" ? <>

                                    <OrdersDraw orders={orders} />
                                    
                                    <FetchMoreOrdersButton />            

                                </> : <>

                                <div className="address-add-button">
                                                    {editingAddressId ? <></> : <Button size="Large" color="primary" variant="contained" onClick={async () => {
                                                        let address = await addTempShippingAddress();
                                                        address.temp = true;
                                                        setEditingAddressId(address.id);
                                                        setDirty(!dirty);
                                                    }}>
                                                        +
                                                </Button>}
                                                </div>
                                        {
                                            shippingAddresses &&  shippingAddresses.length > 0 ? <>{
                                                shippingAddresses.map(address => {

                                                    if (editingAddressId && address.id != editingAddressId) {
                                                        return (<></>)
                                                    }

                                                    return (<Card className={address.id == editingAddressId ? "address-card-edit" : "address-card"}>
                                                        <CardContent>
                                                            <div style={{ margin: "1em" }}>
                                                                <AddressDraw address={address.shippingAddress} isEditing={address.id == editingAddressId ? true : false} setDirty={setDirtyUpdate} />
                                                            </div>
                                                            <CardActions>
                                                                {
                                                                    address.id == editingAddressId ? <>
                                                                        <Button size="small" color="primary" onClick={async () => {
                                                                            if (address.temp) {
                                                                                await deleteTempShippingAddress(address.id);
                                                                            }
                                                                            setEditingAddressId(null);
                                                                            setDirty(!dirty);
                                                                        }}>
                                                                            Cancel
                                                                       </Button>

                                                                        <Button size="small" color="primary" variant="contained" onClick={async () => {
                                                                            if (!saving) {
                                                                                setSaving(true);
                                                                                if (validateAddress(address.shippingAddress)) {
                                                                                    await updateUserShippingAddress(address);
                                                                                    setEditingAddressId(null);
                                                                                }
                                                                                setSaving(false);
                                                                            }
                                                                        }}>
                                                                            {
                                                                                saving ? <CircularProgress /> : "Save"
                                                                            }
                                                                        </Button>
                                                                    </> : <>
                                                                            <Button size="small" color="primary" onClick={() => {
                                                                                setEditingAddressId(address.id)
                                                                            }}>
                                                                                Edit
                                                </Button>
                                                                            <Button size="small" color="red" onClick={() => {
                                                                                setAddressToDelete(address);
                                                                                setDeleteWarning(true);
                                                                            }}>
                                                                                Delete
                                                </Button></>
                                                                }
                                                            </CardActions>
                                                        </CardContent>
                                                    </Card>
                                                    )
                                                })
                                            }
                                            </> : <>
                                            <div className="container-inner"><div className="container-page"><h3>No addresses to show.</h3></div></div>
                                            
                                            </>
                                        }


                                    </>
                            }


                            <Dialog
                                open={deleteWarning}
                                // TransitionComponent={Transition}
                                style={{ padding: "0px !important" }}
                                keepMounted
                                onClose={() => {
                                    setAddressToDelete(null);
                                    setDeleteWarning(false);
                                }}
                            >
                                <DialogContent  >
                                    {/* className={classes.dialogContent} */}
                                    <p>
                                        Are you sure you want to delete this address?
                                </p>
                                    <DialogActions>

                                        <Button size="small" color="red" onClick={() => {
                                            setAddressToDelete(null);
                                            setDeleteWarning(false);
                                        }}>
                                            Cancel
                                                </Button>


                                        <Button size="small" color="red" onClick={async () => {
                                            await deleteUserItemWithId(addressToDelete);                                            
                                            setAddressToDelete(null);
                                            setDeleteWarning(false);
                                        }}>
                                            Yes
                                                </Button>

                                    </DialogActions>
                                </DialogContent>
                            </Dialog>
                        </div>
                        </>
                        
                    
                </section>
            </AmplifyAuthenticator>
        </section>
    )
}

export default Account