import React, { useState, useContext, useEffect, useRef } from 'react'
import moment from 'moment';
import Button from "@material-ui/core/Button";
import AdminContext from "../context/admin";
import CustomTextField from '../components/yayfun/CustomTextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import S3Image from "../components/S3Image";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

function AdminSetsPage(props) {
        
    const { createAnalyticsInput, analytics, orders, createSiteItemInput, deleteSiteItemInput,  updateSiteItemInput, fetchSiteItemsByType, cardSets} = useContext(AdminContext);
    const [startDateInput, setStartDateInput] = useState(moment());
    const [endDateInput, setEndDateInput] = useState(moment())
    const [addingSet, setAddingSet] = useState(false)
    const [graphType, setGraphType] = useState("Inventory");
    const ordersChartRef = useRef();
    const inventoryChartRef = useRef();
    const [setName, setSetName] = useState("")
    
    const [addSetProperties, setAddSetProperties] = useState({"temp":{}});
    const [updateSetProperties, setUpdateSetProperties] = useState({});
    const [selectedSetIndex, setSelectedSetIndex] = useState(false);
    const [selectedSetName, setSelectedSetName] = useState("");
    const [selectedSetCode, setSelectedSetCode] = useState("");

    const [sets, setSets] = useState([])

    const fetchSiteItems = async ()=>{
        let result = await fetchSiteItemsByType("Set");
        console.log("Fetch site items", result);
        if (result) {
            let updatedSetProperties = [];
            for (let i = 0; i < result.length; i++) {
                let r = result[i];
                if (r) {
                    if (r.data) {
                        let parsedData = JSON.parse(r.data);
                        updatedSetProperties[r.id] = {...r, ...parsedData};    
                    } else {
                        updatedSetProperties[r.id] = {...r};    
                    }
                }            
            }
    
            setUpdateSetProperties(updatedSetProperties)
            setSets(result);
        }    
    }


    useEffect(()=>{
         fetchSiteItems();
    },[addingSet]);


    const createSiteItem = async ()=>{
        
        let data = {...addSetProperties["temp"]};
        let dataString = JSON.stringify(data);

        let result = await createSiteItemInput({type:'Set', id:addSetProperties["temp"].id, data:dataString});
        return result;
    }


    const displaySetMenuItems = ()=> {      
        console.log("cardSets:", cardSets);  
        return cardSets.map((set, index) => {
                return (
                <MenuItem value={index + 1}>
                    { set ? displaySet(set) : <>N/A</> }
                </MenuItem>
                )
            })
        }

    
          
        const displaySet = (set)=> {
            return (<>
            <S3Image classNameKey={"card-set-logo"} imgKey={"sets/" + set.code + ".png"} />
            &nbsp; <p className="card-set-name">{set.name}</p></>)
        }
        
        const handleChange = (event) => {
            let selectionIndex = event.target.value;    
            
            // let selectSetCode = cardSets[Math.max(0, selectionIndex - 1)].code;            
            setSelectedSetIndex(selectionIndex);
            updateTempAdd(cardSets[Math.max(0, selectionIndex - 1)])
            // if (selectSetCode == "swsh45sv") {
            //     setSelectedSetName("Shining Fates: Shiny Vault");   
            // } else {
            //     setSelectedSetName(cardSets[Math.max(0, selectionIndex - 1)].name);   
            // }
            // setSelectedSetCode(selectSetCode);   
          };


        const displaySetSelector = ()=> {
            return (<div className="card-set-dropdown">
    
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Choose a Set</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedSetIndex}
                  onChange={handleChange}
                  label="Shipping Address"
                >
                  <MenuItem value={0} name="default">
                    <em>Select a set</em>
                  </MenuItem>
                  {
                    displaySetMenuItems()
                  }
                </Select>
             
              </FormControl>
        
            </div>);
        }


    const updateTempAdd = (set)=>{

        console.log("updateTempAdd:", set);

        setAddSetProperties({"temp":{
            id:set.name,
            code:set.code,
            logoUrl:set.logoUrl,
            releaseDate:set.releaseDate,
            totalCards:set.totalCards}            
        })
    }
        
    const displayAddSet = ()=>{

        console.log("addSetProperties:", addSetProperties);

        return (<>

                {
                    displaySetSelector()
                }

                {/* <CustomTextField properties={{
                    labelName:"Set Name",
                    currentValue:addSetProperties.id,
                    }} 
                    onChange={(event)=>{                                    
                        setAddSetProperties({...addSetProperties, id:event.target.value})
                }}/> */}

                { 
                        displayCardSetFields("temp", addSetProperties,  setAddSetProperties)
                }

             <Button color="primary" onClick={ async ()=>{
                    if (addingSet) 
                    {
                        let result = await createSiteItem();
                        console.log("Create site item result:", result);
                        setAddSetProperties({"temp":{}});
                        setAddingSet(false);
                    }
                }}>
                    Add
                </Button>
                <Button color="primary" onClick={ async ()=>{
                    if (addingSet) 
                    {
                        setAddingSet(false);
                    }
                }}>
                    Cancel
                </Button>
        </>)
    }


    const getPropertiesToData =(setId)=>{
        let data = {...updateSetProperties[setId]};
        return JSON.stringify(data);
    }


    const displayCardSetFields = (id, properties, updateProperties) =>{

        if (id) {

            let propertiesValueById = properties[id];
    
        return <> <CustomTextField properties={{
                labelName:"Set Name",
                currentValue:propertiesValueById ? propertiesValueById.id : "",
            }} 
            onChange={(event)=>{
                let updatedSetProperties = {...properties};
                updatedSetProperties[id].id = event.target.value;
                updateProperties({...updatedSetProperties})
        }}/>

<CustomTextField properties={{
                labelName:"Subset Name",
                currentValue:propertiesValueById ? propertiesValueById.subset : "", 
            }} 
            onChange={(event)=>{                                    
                let updatedSetProperties = {...properties};
                updatedSetProperties[id].subset = event.target.value;
                updateProperties({...updatedSetProperties})
        }}/>


        <CustomTextField properties={{
                labelName:"Set Image",
                currentValue:propertiesValueById ? propertiesValueById.logoUrl : "",
            }} 
            onChange={(event)=>{                                                                        
                let updatedSetProperties = {...properties};
                updatedSetProperties[id].logoUrl = event.target.value;
                updateProperties({...updatedSetProperties})
        }}/>

        <CustomTextField properties={{
                labelName:"Set Code",
                currentValue:propertiesValueById ? propertiesValueById.code : "", 
            }} 
            onChange={(event)=>{                                    
                let updatedSetProperties = {...properties};
                updatedSetProperties[id].code = event.target.value;
                updateProperties({...updatedSetProperties})
        }}/>


          

        <CustomTextField properties={{
                labelName:"Set Release Date",
                currentValue:propertiesValueById ? propertiesValueById.releaseDate : "", 
            }} 
            onChange={(event)=>{                                    
                let updatedSetProperties = {...properties};
                updatedSetProperties[id].releaseDate = event.target.value;
                updateProperties({...updatedSetProperties})
        }}/>

        <CustomTextField properties={{
                labelName:"Set Total Cards",
                currentValue:propertiesValueById ? propertiesValueById.totalCards : "",
            }} 
            onChange={(event)=>{                                    
                let updatedSetProperties = {...properties};
                updatedSetProperties[id].totalCards = event.target.value;
                updateProperties({...updatedSetProperties});
        }}/>
        
        <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox    
                color="primary"
                label="Hide From HomePage"
                labelPlacement="start"
                inputProps={{ 'aria-label': 'Hide From HomePage' }}
                checked={propertiesValueById ? propertiesValueById.hideFromHomePage : ""}
                onChange={e => {         
                    let updatedSetProperties = {...properties};
                    updatedSetProperties[id].hideFromHomePage = e.target.checked;
                    updateProperties({...updatedSetProperties});
                }
            }/>
        } 
        label="Hide From HomePage"

     />

        </FormGroup>
        
        </>
        }
        return <></>
    }


    const displaySets = ()=>{        
        
        return sets && sets.map(set => {

            return(<>
            <div>
                
                        <div className="set-card">          

                        <div className="set-card-left">      
                    
                        {displayCardSetFields(set.id, updateSetProperties, setUpdateSetProperties)}
                        
                        <Button color="primary" onClick={ async ()=>{
                            let data = getPropertiesToData(set.id);
                            console.log("updateSiteItemInput:", data)
                    let result = await updateSiteItemInput({id:set.id, data:data});
                            console.log("updateSiteItem RESULT:", result)
                        }}>
                    Update
                </Button>
            <Button color="primary" onClick={ async ()=>{
                        console.log("delete set:", set)
                        if(window.confirm("Are you sure you want to delete the item?")){
                        let result = await deleteSiteItemInput(set);
                        console.log("deleteSiteItemInput RESULT:", result)
                    }
                }}>
                    Delete
                </Button>
                </div>
                <div className="set-card-right">      
                    <img style={{maxWidth:"200px"}} src={updateSetProperties[set.id] ? updateSetProperties[set.id].logoUrl: ""} />                        
                </div>
                </div>                    
                </div>
            </>)
        })
        
    }

    return (<><div style={{paddingTop:"3em", paddingRight:"3em"}}>
            
                            <div style={{paddingBottom:"3em"}}>
                                <Button color="primary" onClick={ async ()=>{
                                    if (!addingSet) {
                                        setAddingSet(true);
                                    }
                                }}>
                                    Add Set
                                </Button>
                            </div>

                                <div style={{backgroundColor:"white"}}>
                                    {
                                        addingSet ? displayAddSet():<></>
                                    }
                                </div>
            
                            <div>{displaySets()}</div>
        </div>
    </>)
}

export default AdminSetsPage