import React, {useContext, useState} from "react"
import Grid from '@material-ui/core/Grid';
import ProductContext from '../context/products';

import ProductDraw from '../components/ProductDraw';
import ProductQuickView from '../components/ProductQuickView'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import UiContext from '../context/ui';
import Seo from '../components/Seo';
import {Helmet} from "react-helmet";


function HomePage() {

    const { getProductsBySeries, networkError } = useContext(ProductContext);

    const { clearMessages } = useContext(UiContext);

    const [openedModal, setOpenedModal] = useState(false);
    const [productInModal, setProductInModal] = useState(null);
  
    document.title = "Yay Pokemon - Buy Pokémon Trading Cards";

    const quickViewProduct = (product) =>{
        setProductInModal(product);
        setOpenedModal(true);
        clearMessages();
    }

    const endQuickViewProduct = (product) =>{
        setProductInModal(null);
        setOpenedModal(false);
        clearMessages();
    }

    return (<>

<Seo
                  title={"Yay Pokemon - Buy Pokémon Trading Cards"}
                  pathSlug={""}
                  description={"Buy the Pokémon trading card game products here. Get the latest Sword & Shield sets shipped to you, and collect Pokémon! Gotta Catch 'Em All. Yay Pokemon is the place to shop for sealed product and singles."}
                />
            <Helmet>
                <title>Yay Pokemon - Buy Pokémon Trading Cards</title>
                <link rel="canonical" href="https://www.yaypokemon.com/" />
            </Helmet>

            <div className="container-inner" style={{ paddingTop:"0em" }}>
              {
                  networkError ? <div className="oops-container">
                            <p className="large-font font-bold">SORRY!</p>
                            <p className="medium-font"> Something went wrong...</p>
                            <p className="medium-font"> Please check back with us later.</p>
                  </div> :   <>
                  
                  {/* <NavigatorSelector/> */}
                  
                  <Grid container justify="center" direction="row">
                  
                  {<ProductDraw products={getProductsBySeries()} quickViewProduct={quickViewProduct}/>}
                  
                </Grid></>
              }
              
            <Dialog onClose={()=>{
                setOpenedModal(false);
              }} aria-labelledby="simple-dialog-title" open={openedModal}>
                <DialogContent>
                  <ProductQuickView product={productInModal} endQuickViewProduct={endQuickViewProduct}/>
                </DialogContent>
              </Dialog> 

        </div>
    </>)
}


export default HomePage