import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Seo from "../components/Seo";

function PrivacyPolicy () {
  

  document.title = "Privacy Policy";

  
    return (
        <div className="container-inner">      

<Seo
                  title={"Privacy Policy"}
                  pathSlug={"privacy"}
                  description={"We (Yay Fun Things / Yay Pokemon) are committed to protecting your privacy. We only use the information we collect about you to process orders and to provide support and upgrades for our products. Please read on for more details about our privacy policy."}
                />

      <Paper className="container-page">        
      <h3>Privacy Policy</h3>      
        <div>
        <p>
        We (Yay Fun Things / Yay Pokemon) are committed to protecting your privacy. We only use the information we collect about you to process orders and to provide support and upgrades for our products. Please read on for more details about our privacy policy.
         </p>
<p>
<b>What information do we collect? How do we use it? </b> When you order, we need to know your name, e-mail address, mailing address, credit card number, and expiration date. This allows us to process and fulfill your order and to notify you of your order status. When you enter a contest or other promotional feature, we may ask for your name, address, and e-mail address so we can administer the contest and notify winners. You agree to be added to our mailing list for future promotions and deals. When you order through our website, you are opting in to receive newsletters about promotions, coupons, and important company announcements. All emails are sent through Amazon SES and you may opt out if you wish to no longer receive newsletters from us.
</p>

<p>
<b>How does Yay Fun Things protect customer information? </b>We use a secure server for collecting personal information. The secure server layer (SSL) encrypts all of the information you enter before it is transmitted over the Internet and sent to us. Furthermore, all of the customer data we collect is protected against unauthorized access and is encrypted at rest.
</p>
<p>
<b>What about "cookies"?</b> "Cookies" are small pieces of information that are stored by your browser on your computer's hard drive. We use cookies to help store preferences and facilitate logins on return visits to the site.
</p>
<p>
<b>Will Yay Fun Things disclose the information it collects to outside parties? </b>Yay Fun Things does not sell, trade, or rent your personal information to companies not affiliated with Yay Fun Things. Yay Fun Things may provide aggregate statistics about our customers, sales, traffic patterns, and related site information to reputable third-party vendors, but these statistics will not include personally identifying information.
</p>
<p>
Yay Fun Things may release account information when we believe, in good faith, that such release is reasonably necessary to (i) comply with law, (ii) enforce or apply the terms of any of our user agreements or (iii) protect the rights, property or safety of Yay Fun Things, our users, or others.
</p>
<p>
In Summary Yay Fun Things is committed to protecting your privacy. We use the information we collect on the site to make shopping at YayFunThings.com/YayPokemon.com as simple as possible and to enhance your overall shopping experience. We do not sell, trade, or rent your personal information to others.
</p>
<p>
Your Consent By using our Web site, you consent to the collection and use of this information by Yay Fun Things. If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.
</p>
<p>
Yay Fun Things also provides links to affiliated sites. The privacy policies of these linked sites are the responsibility of the linked site and Yay Fun Things has no control or influence over their policies. Please check the policies of each site you visit for specific information. Yay Fun, Inc. cannot be held liable for damage or misdoings of other sites linked or otherwise.
        </p>
   
   
   
   
      <p>
      
       This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase through yayfunthings.com/yaypokemon.com (the “Site”).
              </p>

      <p><b>Personal Information We Collect</b></p>
      <p>
When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”.
      </p>
<p>
We collect Device Information using the following technologies:
- “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
- "Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
</p>

<p>
We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).
</p>

<p>
We use technologies, such as cookies, to customise content and advertising, to provide social media features and to analyse traffic to the site. We also share information about your use of our site with our trusted social media, advertising and analytics partners.</p>


<p>
  
You can opt out of targeted advertising by using the links below:</p>
<ul>
<li>- Google: https://www.google.com/settings/ads/anonymous</li>
</ul>

<p>
Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: http://optout.aboutads.info/.
</p>

    </div>
       </Paper>
       
      </div>
        )
  
}

export default PrivacyPolicy;