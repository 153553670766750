import React, {useState} from 'react';

import { API } from '@aws-amplify/api';
import styled from 'styled-components';
import CustomTextField from '../components/yayfun/CustomTextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Seo from '../components/Seo';


import {
    Grid,
    Segment
} from 'semantic-ui-react'

const blueColor = "#296aac";
const darkBlue = "#013153";

const Styles = styled.div`

`

function Contact(props) {

    document.title = "Contact";

    const [, updateState] = React.useState();
    const [formData, setFormData] = React.useState({
        name: "",
        email: "",
        message: ""
    });

    const [inputError, setInputError] = React.useState({ name: false, email: false, phone: false, orderNumber:"",message: false });
    const [loading, setLoading] = React.useState(false);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [sentSuccess, setSentSuccess] = useState(false);

    function   validateFormData() {
        var Namere = /[A-Za-z]{1}[A-Za-z]/;
        if (!Namere.test(formData.name)) {
            setInputError({ ...inputError, name: true });
            return false;
        }
        // var mobilere = /[0-9]{10}/;
        // if (!mobilere.test(formData.phone)) {
        //     setInputError({ ...inputError, phone: true });
        //     return false;
        // }
        if (formData.email == "") {
            setInputError({ ...inputError, email: true });
            return false;
        }
        var reeamil = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
        if (!reeamil.test(formData.email)) {
            setInputError({ ...inputError, email: true });
            return false;
        }
        return true;
    }


    // properties={
    //     {
    //       name: name,                
    //       labelName: name,
    //       error: false,
    //       variant: 'outlined',
    //       key: "search-input",
    //       onKeyPressFunction:()=>{console.log("Pressed Enter Key")},
    //       currentValue:currentObject[key]
    //     }
    //   }


    const sendEmailWithParams = async (params) =>{
        
        console.log("sendEmailWithParams", params);

        if (params) {
        
        await API.post("yayfunthingsapi", "/items/sendEmail", { 
            body: 
            {  
                email:'contact@yayfuninc.com', 
                data:`<div><p>Order Number:${params.body.orderNumber}</p> <br/>${params.body.message} - ${params.body.email} </div>`, 
                subject:`Contact Form Message from ${params.body.name}` } }).then(result => {
                        console.log(result)
        }).catch(e=>{console.log(e)});
        }
    
    }
    

    return (
        <Styles>
            <Seo
                  title={"Contact"}
                  pathSlug={"contact"}
                  description={"Fill out the contact form to contact us."}
                />

            <div className="container-inner">
            <div className="container-page">
                <Segment vertical style={{ paddingTop: "0em" }}>

                    <Grid container stackable verticalAlign='middle'>

                        <Grid.Row style={{ paddingLeft: "", paddingTop: "1em", paddingBottom: "8em" }}>

        {
            sentSuccess ? <>
                        <h2 style={{textAlign:"center"}}>Thank you!</h2>
                        <p>We will try our best to get back to you as soon as we can.</p>
            </>:<>
            <Grid.Column>


            <h1>Contact Us</h1> 
            <h1>For help with your existing order, please email <a style={{color:"blue"}} href="mailto:contact@yaypokemon.com">contact@yaypokemon.com</a> from the email address the order was created and include as much information as possible.</h1>
            <h2>For general inquiries, fill out the contact form below.</h2>
            

<CustomTextField
    className="search-input"
    style={{ float: "left", maxWidth: "20em", width:"100%" }}
    properties={
        {
            name: "Name",
            labelName:"Name",
            variant: 'outlined',
            error: inputError.name,
            rows: 1
        }
    }
    defaultValue={formData.name}
    onKeyPress={(e) => {
        if (e.key === 'Enter') {
            console.log("Key enter Seller ID");
        }
    }}
    onChange={
        e => {
            setFormData({ ...formData, name: e.target.value });
            if (e.target.value) {
                setInputError({ ...inputError, name: false });
            }
        }
    } />

<CustomTextField
    className="search-input"
    style={{ float: "left", maxWidth: "20em" }}
    properties={
        {
            name: "Email",
            labelName:"Email",
            variant: 'outlined',
            error: inputError.email,
            rows: 1
        }
    }
    defaultValue={formData.email}
    onKeyPress={(e) => {
        if (e.key === 'Enter') {
            console.log("Key enter email");
        }
    }}
    onChange={
        e => {
            setFormData({ ...formData, email: e.target.value });
            if (e.target.value) {
                setInputError({ ...inputError, email: false });
            }
        }
    } />


<CustomTextField
    className="contact-input"
    style={{ float: "left", minWidth: "20em" }}
    properties={
        {
            name: "Order Number",
            labelName:"Order Number",
            variant: 'outlined',
            error: inputError.orderNumber,
            rows: 1
        }
    }
    defaultValue={formData.orderNumber}
    onKeyPress={(e) => {
        if (e.key === 'Enter') {
            // console.log("Key enter order number");
        }
    }}
    onChange={
        e => {
            setFormData({ ...formData, orderNumber: e.target.value });
            if (e.target.value) {
                setInputError({ ...inputError, orderNumber: false });
            }
        }
    } />


<CustomTextField
    className="search-input"
    style={{ float: "left", width:"100%", minWidth: "20em", minHeight: "30em" }}
    properties = {
        {
            name: "Message",
            labelName:"Message",
            variant: 'outlined',
            error: inputError.message,
            rows: 10,
            multiline:true
        }
    }
    defaultValue={formData.message}
    inputProps={{
        'aria-label': 'message',
    }}
    onKeyPress={(e) => {
        if (e.key === 'Enter') {
            console.log("Key enter message");
        }
    }}
    onChange={
        e => {
            setFormData({ ...formData, message: e.target.value });
            if (e.target.value) {
                setInputError({ ...inputError, message: false });
            }
        }
    } />

<form id="contact-form" method="post">

    <div data-sitekey="" className="form-control" style={{ width: "100%" }}></div>
    <Button variant="contained" size="large" color="primary" loading={loading ? 1 : 0} style={{ marginTop: "35px" }}
        onClick={async () => {
            if (!loading) {
                setLoading(true);
                if (validateFormData()) {
                    let params = { body: { ...formData } };
                    await sendEmailWithParams(params);
                    // const apiResponse = await API.post('Contact', '/item/submit', params);
                    setLoading(false);
                    setSentSuccess(true);
                    // if (apiResponse) {
                    //     setFormData({
                    //         name: "",
                    //         email: "",
                    //         message: ""
                    //     });
                    // }

                } else {
                    setLoading(false);
                }
            }
        }} >{loading ? <CircularProgress style={{scale:"0.8", padding:"0px", width:"1.7em", height:"1.7em", color:"white"}} color="inherit" />: "Submit"}</Button>
</form>
</Grid.Column>
            
            </>
        }

                       
                       
                       
                        </Grid.Row>
                    </Grid>

                    {/* <svg className="fill-svg-drk-blue" x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <polygon className="fill-inverse" points="2560 0 2560 100 0 100"></polygon></svg> */}

                </Segment>


            </div>
            </div>
        </Styles>
    )


}

export default Contact
