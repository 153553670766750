import React, { useContext, useState } from "react";
import Storage from "@aws-amplify/storage";
import { API } from '@aws-amplify/api';

import CartContext from "../context/cart";
import UiContext from "../context/ui";
import ProductContext from "../context/products";
import { FiChevronUp } from "react-icons/fi";
import { FiChevronDown } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { ConvertStringToDollarsCents, isMobile } from '../components/Helper'
import CloseIcon from '@material-ui/icons/Close';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {BeginCheckoutEvent} from "../components/yayfun/AnalyticsEvent";
import {SendRemoveFromCartEvent}  from "../components/yayfun/AnalyticsEvent";
import { productsBySet } from "../api/queries";
import ProductImage from "../components/ProductImage";
import { withRouter, useLocation } from 'react-router-dom';
import CouponInput from "../components/CouponInput";
import Seo from "../components/Seo";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  flexSpace: {
    flexGrow: 1
  },
  customBadge: {
    backgroundColor: "#ffb800",
    color: "black"
  }
}));


const Cart = (props) => {

  const history = useHistory();
  const { cart, total, increaseAmountToProduct, decreaseAmountToProduct, toggleSideCartOn , toggleDropdownOn, updateCoupon, coupon } = useContext(CartContext);
  const { alertMessage, closeAlertMessage, totalMessages } = useContext(UiContext);
  const { getProductValueFromId } = useContext(ProductContext);
  const location = useLocation();

  const isMobileCheckout = props.isMobileCheckout;
  const isCheckoutCart = props.isCheckoutCart;
  const isloadingShippingCost = props.loadingShipping;
  const shippingCost = props.shippingCost;
  const shippingDay = props.shippingDay;
  const taxCost = props.taxCost;
  
  const classes = useStyles();

  const [isMobileCheckoutOpen, setIsMobileCheckoutOpen] = useState(false);
  const [increasingAmount, setIncreasingAmount] = useState(false);
  const [discount, setDiscount ] = useState(0);
  const [mobileTotalMessage, setMobileTotalMessage] = useState("Click to show");

  const startIncreaseAmount = async (product) =>{
    setIncreasingAmount(true);
    await increaseAmountToProduct(product.id, 1);
    setIncreasingAmount(false);
  }

  const startDecreaseAmount = async (product)=> {    
    await decreaseAmountToProduct(product.id, 1);
    SendRemoveFromCartEvent(product);
  }


  

  const updateCouponUsed = async (couponEntry)=>{
   
   
    updateCoupon(couponEntry);
    if (couponEntry == null) {
      setDiscount(0);
    }

    let result = await API.post("yayfunthingsapi", "/items/getDiscountForCoupon", { 
      body:{coupon:couponEntry}
  }).then(async response => { 
      if (response.discount) {
        setDiscount(response.discount);
      }
  }).catch(e=>{console.log(e)});

  }



  const displayCouponInput = ()=> {
    if (props.activeStep >= 1 && props.isCheckoutCart ) {
      return (<>
        {/* <CouponInput email={props.email} updateCouponUsed={updateCouponUsed} /> */}
    </>)
    }
    
  }

  const calculateDiscount = ()=>{
    let totalDiscount = 0;
     if (discount && discount > 0) {
      totalDiscount = total * (discount/100);       
     }
     return totalDiscount;
  }

  const displayDiscount = ()=> {
    
    if (props.activeStep >= 1 && props.isCheckoutCart && discount > 0) {
    return (<div><div className="order-summary-item bold-item column-50">Discount</div> <div className="order-summary-item align-right column-50">{ConvertStringToDollarsCents(calculateDiscount())}</div></div>)
    }
    return (<></>)
  }

  const displayPreorderDetails = (product)=>{
    if (moment().valueOf() < moment(new Date(product.releaseDate)).valueOf()) {
      if (product.allowPreorder) {
        return <p><b>PREORDER</b> Releases {moment(product.releaseDate).format("MM/DD/YY")}</p>
      }      
    } else {
          return (<></>)
    }
  }

  function displaySummaryBlock() {
    return (<><div>
                  {
                  alertMessage && alertMessage != "" ?  <p >{alertMessage}</p> :<></>
                  }
              </div>
    
            <div className={increasingAmount ? "cart-wrapper order-summary-block white" : "cart-wrapper order-summary-block"}>
                {cart.map((product) => (
                  <article key={product.id} className="cart-item">                    
                    {
                      isCheckoutCart ?

                      <Badge classes={{ badge: classes.customBadge }} badgeContent={product.amount} max={99}>
                        <div className="cart-image-parent">
                          <ProductImage product={product} suffix="_120.png"/>
                        </div>

                      </Badge>
                      :                           
                      <div className="cart-image-parent">
                      {/* <img className="cart-image" src={product.image} alt="cart item" /> */}
                      <ProductImage product={product} suffix="_120.png"/>
                      </div>
                    }                    

                    <div className="order-summary-details" style={{ width: "255px" }}>
                      <div className="order-summary-item" style={{ width: "190px" }}>

                      <div onClick={() => {                        
                        toggleSideCartOn(false);
                        history.push(`/cards/${product.id}`);
                      }}>  {product.title} </div>

                        {/* <NavLink to={`/cards/${product.id}`}>
                         
                        </NavLink> */}
                      </div>

                      {/* <div className="order-summary-item order-summary-item-auto">{ConvertStringToDollarsCents(price)}</div> */}
                      
                      {
                     
                      isCheckoutCart ? <>
                                            
                      </> :<>
                      
                      <div className="order-summary-amount" style={{width:"65px"}}>
                        <Button disabled={increasingAmount} onClick={() => startIncreaseAmount(product)}><FiChevronUp /></Button>
                          <div style={{textAlign:"center"}}>                          
                            <p>{product.amount}</p>
                          </div>                         
                        <Button disabled={increasingAmount} onClick={() => startDecreaseAmount(product)}><FiChevronDown /></Button>
                      </div>
                      </>
                      }
                   
                      <div className="order-summary-item" >{ConvertStringToDollarsCents(product.price * product.amount)}</div>
                      <div className="order-summary-item block" >{displayPreorderDetails(product)}</div>

                    </div>
                 
                  </article>
                ))}
              
            
              </div>

    </>)
  }


  const getTotalText = () => {
    return props.activeStep  <= 1 ? "SUBTOTAL" : "TOTAL";
  }


  return (
    <div className={props.sideCart ? "container-side-cart container-column-flex": isMobile() ? isMobileCheckoutOpen ? "container-column-flex-mobile-nonsticky" : "container-column-flex-mobile" : "container-column-flex"}>
      
      {
        location.pathname.includes("cart") ?  <></> : <Seo
        title={"Cart"}
        pathSlug={"cart"}
        description={"View your cart products. Make adjustments to your cart here. "}
      />
      }
              

                {
        isMobileCheckout ? <>

       <div  onClick={(e)=>{

            setIsMobileCheckoutOpen(!isMobileCheckoutOpen);
            if (isMobileCheckoutOpen) {                        
              setMobileTotalMessage("Click to show");
            }else {
              setMobileTotalMessage("Click to collapse"); 
              window.scrollTo(0,0);
              
            }
          }}>

        <div className={isMobile() ? isMobileCheckoutOpen ? "cart-checkout-total-mobile-open" : "cart-checkout-total-mobile" : "cart-checkout-total-desktop"}> 

          <h3 className="cart-subtotal">
              <span style={{float:"left", textAlign:"left"}}>{getTotalText()} {isMobileCheckoutOpen ?  <span><ArrowDropDown/>  </span>: <span><ArrowRight/></span>  }   </span>             
          <span style={{float:"right", textAlign:"right"}}>
            
          {    isloadingShippingCost ? <>                          
          
            {
              !isMobileCheckoutOpen ?  <CircularProgress className="circular-progress-small"/> : <>--</>
            }
            
          
          </> : <>
          {ConvertStringToDollarsCents(total + shippingCost - calculateDiscount() + ( (total + shippingCost) * taxCost))}
          </>
          }                        
            </span>           
          {/* <span style={{float:"right", textAlign:"right"}}>{ConvertStringToDollarsCents(total)}</span> */}
              <span className="mobile-total-message">{mobileTotalMessage}</span>
          </h3>

          </div>
          

         </div> 

          {
            isMobileCheckoutOpen ? 
            
            <div className="order-summary-container">

            <div className="order-summary-mobile-details" >            
              <p style={{paddingBottom:"0em", paddingLeft:"1em", paddingRight:"1em", marginBottom:"0em"}}>In stock items ship in 1 - 2 business days.</p>
              <p style={{paddingBottom:"1em", paddingLeft:"1em", paddingRight:"1em"}}>{shippingDay ? 'Ships by ' + shippingDay : ""}</p>
            </div> 

             {
              displayCouponInput()
             }
            <div><div className="order-summary-item bold-item column-50">Subtotal</div> <div className="order-summary-item align-right column-50">{ConvertStringToDollarsCents(total)}</div></div>
            <div>{ displayDiscount() }</div>
            <div><div className="order-summary-item bold-item column-50">Shipping</div> <div className="order-summary-item align-right column-50">{isloadingShippingCost ? <>
            
            <CircularProgress className="circular-progress-small" /></> : <>{shippingCost ? shippingCost == "0.00" ? "Free" : ConvertStringToDollarsCents(shippingCost) : "--"} </>}</div></div>
            {
              taxCost && taxCost > 0 ? <div><div className="order-summary-item bold-item column-50">Tax</div> <div className="order-summary-item align-right column-50">{ConvertStringToDollarsCents((total + shippingCost) * taxCost)}</div></div> : <></>
            }
            
            <div style={{paddingTop:"1em"}}>
            {
            
            displaySummaryBlock()
            
            }
            </div>

            </div> :
            
            <>
            </>
          }

          
        </>:<>
        
        {
        cart.length <= 0 ?
          
          <div className={"container-inner container-column-flex"}>
             {
                props.sideCart ? 
                <div className="close-header-icon">
                    <CloseIcon style={{ scale: "2" }} onClick={() => { toggleSideCartOn(false) }} />
                 </div> :<></>
              } 
            
            <h3 className="cart-header">SHOPPING CART</h3>                        
             
            <section className="cart-details">
              <h3 className="cart-header">Your cart is currently empty.</h3>
            </section>           
            
            {/* <section>
              <p>Let's check out some other things we have around here!</p>
              <div>
                {
                  products.map((product, index) => {
                    return (
                    
                    <div key={index}>
                      <div onClick={() => {
                        toggleSideCartOn(false);
                        history.push(`cards/${product.id}`);
                      }}>
                        <img className="small-image" src={product.image} />
                        <p>{product.title}</p>
                      </div>
                    </div>)
                  })
                }
              </div>              
            </section> */}
            
            <div className="container-flex-space">&nbsp;</div>
          </div>
          :
          <div className="container-inner container-column-flex">          
            
                {

          isCheckoutCart ? <>
          
          
          <h3 className="cart-header">Order Summary</h3>                      
          </>:<>          
            <div style={{padding:"1em", paddingLeft:"2em"}}>
              <h3 className=" ">Shopping Cart</h3>                                  
              {
                props.sideCart ? 
                <div className="close-header-icon">
                <CloseIcon style={{ scale: "2" }} onClick={() => { toggleSideCartOn(false) }} />
                 </div> :<></>
              }
            </div>


          </>
                }
            
                <section className="cart-details" style={{ display: "inline-block" }}>
            
                {
                  displaySummaryBlock()
                }

                    {
                      isCheckoutCart ? <>
                        <div className="order-summary-block">
                          <div>
                            <p style={{paddingBottom:"1em", paddingLeft:"1em", paddingRight:"1em", marginBottom:"0em"}}>In stock items ship in 1 - 2 business days.</p>
                            <p style={{paddingBottom:"1em", paddingLeft:"1em", paddingRight:"1em"}}> {shippingDay ? 'Ships by ' + shippingDay : ""}</p>
                          </div> 
                          {
                                displayCouponInput()
                            }
                              <div><div className="order-summary-item bold-item column-50">Subtotal</div> <div className="order-summary-item align-right column-50">{ConvertStringToDollarsCents(total)}</div></div>
                              <div>{ displayDiscount() }</div>
                              <div><div className="order-summary-item bold-item column-50">Shipping</div> <div className="order-summary-item align-right column-50">{isloadingShippingCost ? <>                          
                          <CircularProgress style={{scale:"0.8"}} /></> : <>{shippingCost ? shippingCost == "0.00" ? "Free" : ConvertStringToDollarsCents(shippingCost) : "--"} </>}</div></div>
                          {
                            taxCost && taxCost > 0 ? <div><div className="order-summary-item bold-item column-50">Tax</div> <div className="order-summary-item align-right column-50">{ConvertStringToDollarsCents((total + shippingCost) * taxCost)}</div></div> : <></>
                          }
                          <div style={{ borderBottom: "1px solid #ccc" }}> &nbsp;</div>
                          <div><div className="order-summary-item bold-item column-50">Total</div> <div className="order-summary-item align-right column-50">{ConvertStringToDollarsCents(total + shippingCost - calculateDiscount() + ( (total + shippingCost - calculateDiscount()) * taxCost))} </div></div>
                   </div> </> :<>                     
                  </>
                }         
            </section>            
            {
              !isCheckoutCart ? 
              <>
              <div className="container-flex-space">&nbsp;</div>                                    
              <div className="order-summary-block order-btn-checkout">
                    <Button size="large" color="primary" variant="contained" onClick={() => {
                      toggleSideCartOn(false);
                      BeginCheckoutEvent({cart:cart, total:total});
                      toggleDropdownOn(false);
                      history.push("/checkout");
                    }}>CHECKOUT</Button>
              </div>
              <p>Shipping & taxes calculated at checkout </p>
              <h3 className="cart-subtotal"><span style={{float:"left", textAlign:"left"}}>SUBTOTAL</span> <span style={{float:"right", textAlign:"right"}}>{ConvertStringToDollarsCents(total)}</span></h3></> :              
              <>           
              </>
            }
          </div>
      }
        
        </>
      }
      
      
    </div>)
};

export default withRouter(Cart);
