import React, { useState, useEffect, useContext } from "react";

// REMOVED 02.13.21
// import {Analytics} from 'aws-amplify';

import { API } from '@aws-amplify/api';
import UiContext from "../context/ui";

const CartContext = React.createContext({});
const cardImageLocationPrefix = "/images/cards/";

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [showSideCart, setShowSideCart] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [lastAdded, setLastadded] = useState({product:null, quantity:0});
  const [showAlert, setShowAlert] = useState("");
  const { sendAlertMessage, clearMessages } = useContext(UiContext);
  const [initialSetup, setInitialSetup] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [approvedCodes, setApprovedCodes] = useState([]);

  useEffect(() => {
    
    if (!initialSetup) {
      setInitialSetup(true);
      //Save cart locally
      if (cart && cart.length == 0) {

        //load cart locally
        loadCartLocally()
      }
    }
    
    updateTotalForCart();

  }, [cart]);


  const updateTotalForCart = ()=>{

    const total = [...cart].reduce((total, { amount, price }) => {
      return (total += amount * price);
    }, 0);
    
    let updatedTotal = parseFloat(total.toFixed(2))
    setTotal(updatedTotal);
    saveCartLocally();
    return updatedTotal;
  }

  const loadCartLocally = () => {
    try  {    

      let cartItems = window.localStorage.getItem("cart");      
      if (cartItems) {
        let cartItemsParsed = JSON.parse(cartItems);
         if (cartItemsParsed && Array.isArray(cartItemsParsed) && cartItemsParsed.length > 0) {
           setCart(cartItemsParsed);
         }
      }
    }catch(e) {
      // console.log("Error loading local cart:", e);
    }
  }


  const saveCartLocally = () => {
    try {
      window.localStorage.setItem("cart", JSON.stringify(cart));
    }
    catch (e) {
      // console.log("Error saving cart locally:", e);
    }
  }


  const totalItems = () => {
      let total = 0;
      for (let i = 0; i < cart.length; i++) {
        total += cart[i].amount;
      }
      return total;
  } 


  const increaseAmount = (id, amount) => {

    const updatedCart = [...cart].map((item) => {
      return item.id === id ? { ...item, amount: item.amount + amount } : item;
    });

    // REMOVED 02.13.21
    // Analytics.updateEndpoint({
    //   attributes: {
    //       hasShoppingCart: ['true']
    //   },
    //   metrics: {
    //       itemsInCart: cart.length
    //   }
    // });

    setCart(updatedCart);
  };


  const updateCartPrice = (id, price) =>{
    let updatedCart = [];
    
    updatedCart = [...cart].map((item) => {
      return item.id === id ? { ...item, price:price } : item;
    });

    setCart(updatedCart);
  }


  const decreaseAmount = (id, amount) => {
    
    let updatedCart = [];
    
    updatedCart = [...cart].map((item) => {
      return item.id === id ? { ...item, amount:Math.max(0, item.amount - amount) } : item;
    });

    updatedCart = [...updatedCart].filter((item) => item.amount > 0);

    setCart(updatedCart);
    
    // REMOVED 02.13.21
    // Analytics.updateEndpoint({
    //   attributes: {
    //       hasShoppingCart: ['true']
    //   },
    //   metrics: {
    //       itemsInCart: cart.length
    //   }
    // });


  };


   const checkIfAvailable = async (id, quantity, totalInCart) => {    
    
    try {
      
      let result = await API.post("yayfunthingsapi", "/items/addToCart",{body:{id:id,quantity:quantity, totalInCart:totalInCart}}).then(result => {        
        //   if (result.data.processOrder == "SUCCESS") {
        //     Analytics.updateEndpoint({
        //       attributes: {
        //           hasShoppingCart: ['true'],
        //           completedOrder: ['false']
        //       },
        //       metrics: {
        //           itemsInCart: 0,
        //       }
        //   });
        //   return true;        
        // } else {
        //   return false;
        // }        
        return result;
      }).catch(e=>{
        
        // console.log(e); 
        return 0;});
      return result;
    }catch (e) {
      // console.log(e);
      return 0;
    }    
  }


  const increaseAmountToProduct = async(id) => {

    const cartItem = [...cart].find((product) => product.id === id);    

    let totalInCart = cartItem ? cartItem.amount : 0;

    let isAvailable = await checkIfAvailable(id, 1, totalInCart);

    if (isAvailable && isAvailable.data > 0) {
      
      increaseAmount(id, 1)
    } else {
      setShowDropdown(false);
      
      sendAlertMessage("Sorry! You can't add more to the cart");
    }

   }

   const decreaseAmountToProduct = async(id) => {    
    decreaseAmount(id, 1)
   }

  const addToCart = async (product) => {

    const { id, title, price, image, imageUrlKey, type, subtype, imageAsset, allowPreorder, releaseDate } = product;    
    const cartItem = [...cart].find((product) => product.id === id);    
    let totalInCart = cartItem ? cartItem.amount : 0;
    let isAvailable = await checkIfAvailable(id, 1, totalInCart);

    if (isAvailable && isAvailable.data > 0) {
      if (cartItem) {        
        increaseAmount(id, 1);
        setLastadded({product, quantity:1});
      } else {

        let imageUrl = image;
        if (product.imageUrlKey) {
          imageUrl = (cardImageLocationPrefix + product.imageUrlKey);              
        }      
        const cartItems = [...cart, { id, title, image:imageUrl, imageAsset, price, allowPreorder, releaseDate, amount: 1, type:type, subtype:subtype }];
        setCart(cartItems);
        setLastadded({product, quantity:1});
      }
      return true;
    } else {
      setShowDropdown(false);
      // setShowAlert(`Error adding ${product.title} to the cart`);
      sendAlertMessage("Sorry! You can't add more to the cart");
      return false;
      /// Todo: Notify the item did not add to the cart.      
    }
  };  

  const addToCartQuantity = async (product, quantity) => {
    const { id, title, price, image, type, subtype, imageAsset, allowPreorder, releaseDate } = product;

    const cartItem = [...cart].find((product) => product.id === id);

    let totalInCart = cartItem ? cartItem.amount : 0;
    let isAvailable = await checkIfAvailable(id, quantity, totalInCart);
    
    if (isAvailable && isAvailable.data > 0) {
      
      if (cartItem) {
        increaseAmount(id, parseInt(isAvailable.data));
        setLastadded({product, quantity:parseInt(isAvailable.data)});
      } else {
        let imageUrl = image;
        if (product.imageUrlKey) {
          imageUrl = (cardImageLocationPrefix + product.imageUrlKey);              
        }      
        const cartItems = [...cart, { id, title, image:imageUrl, imageAsset, price, type:type, subtype:subtype, allowPreorder, releaseDate, amount:parseInt(isAvailable.data) }];
        setCart(cartItems);
        setLastadded({product, quantity:parseInt(isAvailable.data)});
      }    
      return true;
    }else {
      return false;
    }   
  };

  const toggleSideCart = () => {
    clearMessages();
    setShowSideCart(!showSideCart)
  };

  const toggleSideCartOn = (turnon) => {
    setShowSideCart(turnon)
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  };

  const toggleDropdownOn = (turnon) => {
    setShowDropdown(turnon)
  };

  const clearCart = () => {
    setCart([]);
  };

  const updateCoupon = (couponUpdate) => {
    setCoupon(couponUpdate);
  }

const updateApprovedCodes = (codes)=>{
  setApprovedCodes([...codes]);
}
  return (
    <CartContext.Provider
      value={{ updateCartPrice, updateCoupon, updateTotalForCart, approvedCodes, updateApprovedCodes, cart, coupon, total, lastAdded, showSideCart, showDropdown, showAlert, setShowAlert, totalItems, addToCart, addToCartQuantity, decreaseAmountToProduct, increaseAmountToProduct, clearCart, toggleSideCart, toggleSideCartOn, toggleDropdown, toggleDropdownOn }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
export {CartProvider};
