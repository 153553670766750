import React, {useEffect, useContext} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import moment from "moment";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import {ConvertStringToDollarsCents} from "../components/Helper";
import { API } from "@aws-amplify/api";
import AdminContext from "../context/admin";
import UiContext from "../context/ui";
import UserContext from "../context/user";



const useStyles = makeStyles({
    root:{
      paddingTop: "1em",
      paddingBottom: "1em",
      paddingRight:"1em",
      '& .MuiPaper-root': {
        maxWidth:"none",
    }  
    },
});


function PreviewEmail (props) {
    
    const classes = useStyles();    
    const {updateOrderWithInput} = useContext(AdminContext);
    const {sendAlertMessage } = useContext(UiContext);
    const {updateCreateUserItem } = useContext(UserContext);
    
    // const [currentFormData, setCurrentFormData] = useState({email:props.details.email,subject:props.details.subject, title:props.details.title, html:getEmailHTMLContent(props.details)});

    useEffect( ()=> {

        console.log("PreviewEmail:", props);

        if (props.updatePreviewHtml) {
            props.updatePreviewHtml(getEmailHTMLContent(props.details))
        }
        if (props.emailHtml) {
            console.log("emailHtml:", props.emailHtml);
            props.emailHtml.current = getEmailHTMLContent(props.details);
        }
        
    },[props.details.email]);



    const displayEmail = ()=>{
        return(<>
                <div>
                    <p>id:{props.details.name}</p>
                    <p><b>To </b>{props.details.email}</p>
                    <p><b>Subject </b>{props.details.subject}</p>
                    {
                        props.isCampaign && !props.details.subscribed ?  <p style={{backgroundColor:"red", color:"white", textAlign:"center", padding:"1em"}}> This user is unsubscribed from promotional mailings.</p>: <></>
                    }
                </div>
                    {
                        <div style={{minWidth:"1024px"}} dangerouslySetInnerHTML={{ __html:getEmailHTMLContent(props.details)}} /> 
                    }          
        </>)
    }


    function isUPSTracking(trackingNumber) {
        const re = /\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/;
        const re2 = /^(1Z\s?[0-9A-Z]{3}\s?[0-9A-Z]{3}\s?[0-9A-Z]{2}\s?[0-9A-Z]{4}\s?[0-9A-Z]{3}\s?[0-9A-Z]$|[\dT]\d{3}\s?\d{4}s?\d{3})$/i;
    
        if (re.test(String(trackingNumber).toLowerCase())) {
            return true;
        } else if (re2.test(String(trackingNumber).toLowerCase())) {
            return true;
        }
        return false;
    }

    const getEmailHTMLContent = (details)=> {
        if (details.type == "Custom") {
            return getCustomEmailContent(details);
        } else {
         
            return getOrderShippedEmailContent(details);
        }
    }

    const getCustomEmailContent = (details)=> {
        console.log("details:", details);
        
        return getEmailBase(details.body, details.title);
    }

    const getOrderShippedEmailContent = (details)=> {
        
        let productsString  =  ""; 
        if (details.order) {
            for (let i = 0; i < details.order.products.items.length; i++) {                                        
                let orderItem = details.order.products.items[i];
                productsString += '<div className="cart-product">';
                productsString += '<p> '+ orderItem.amount + " x " + orderItem.product.title + " - " + ConvertStringToDollarsCents(orderItem.price) +'</p>';                    
                productsString +=  '</div>';
        }                                    
        
        }    

    let trackUrl = '';
    let carrierName = "";

        if (isUPSTracking(details.trackingNumber)) {
            carrierName = "UPS";
            trackUrl = 'https://wwwapps.ups.com/WebTracking/processInputRequest?AgreeToTermsAndConditions=yes&loc=en_US&tracknum='+details.trackingNumber+'&requester=ST/trackdetails';
        }else {
            carrierName = "USPS";
            trackUrl = 'https://tools.usps.com/go/TrackConfirmAction.action?tLabels='+details.trackingNumber
        }

    let innerContent =   `<div style="margin:0em">
            <div>
                <p style="font-weight:bold; margin-top:0.5em;margin-bottom:0.1em">Order No. <span style="font-weight:normal">${details.order.number}</span></p>
                <p style="font-weight:bold; margin-top:0.5em;margin-bottom:0.1em">Order Date: <span style="font-weight:normal">${moment(details.order.date).format("MMMM DD, YYYY")}</span></p>
            </div>
            
            <div style="padding-top:1em;margin-bottom:0em">
            <p style="margin-bottom:0.1em">Here's your tracking info.</p>        
            <p style="margin-top:0.1em">${carrierName} Tracking: <a href='${trackUrl}' target='_blank'>${details.trackingNumber}</a></p></div>        
            <p style="font-weight:bold; padding-top:1em">Shipping Address</p>
            <div>
                <div>
                
                    <p>${details.order.shippingAddress.name}<br/>
                       ${details.order.shippingAddress.addressLine1}<br/>
                       ${details.order.shippingAddress.addressLine2}
                       ${details.order.shippingAddress.addressLine2 ? "<br/>" : "" } 
                       ${details.order.shippingAddress.city}, ${details.order.shippingAddress.state}  ${details.order.shippingAddress.postalCode} ${details.order.shippingAddress.country}</p>
                
                </div>
            </div>
            <p style="font-weight:bold; padding-top:1em">Products Shipped</p>
            
        <div style="border-top:1px solid #ccc; font-size:0.8em;margin-top:1em">
        <p style="font-size:0.8em; margin-top:0em;">Prices do not include taxes and shipping costs.</p>
        ` + productsString;
   
    return getEmailBase(innerContent, "Your order shipped!");
}




const getStylesForClass = (className)=> {

let styles = {
    ".title" : `
        text-align:center; 
        margin:0em; 
        margin-top:0em !important;
        color:#fff; 
        font-size:1.5em; 
        font-weight:bold;
        display:inline-block;
        vertical-align:top;
        `,
        ".logo" : `
        margin:0em;    
        margin-left:10px;             
        height:60px;
        display:inline;
        `,
        "td" : `
            padding:0em;
        `
    }    
    return styles[className] ? styles[className] : "";
}


const getEmailBase = (innerContent, title)=> {

    console.log("innerContent:", innerContent);
    return  `
            <style>
             p {
                line-height:1em;
                margin:1em;
             }
             p:first-child {
                margin-top:1em;
             }
             
            </style>            
            <table style="border-collapse: collapse;" width="100%" border="0" cellspacing="0" cellpadding="0">
         <tbody style="background-color:#f0f0f0"> 
            <tr>
            <td>
            </td>
            <td align="center">
            <div style="text-align:left; background-color:#fff; border:1px solid #ccc; max-width:470px;"> 
                   <div style="height:60px; background-color:#2196f3;">         
                   <table border="0" cellspacing="0" cellpadding="0" style="border-collapse: collapse;">
                   <tr>
                   <td style="${getStylesForClass("td")}">
                        <img alt="yay" style="${getStylesForClass(".logo")}" src="https://www.yaypokemon.com/images/yay-fun-email-header.png">
                   </td>
                   <td style="${getStylesForClass("td")}">
                        <p  style="${getStylesForClass(".title")}" > ${title} </p>  
                    </td>
                   </tr>
                   </table>                    
                   </div>`+ '<div style="padding:0em"> <div style="min-height:200px;">' + innerContent +                   
                 
                 
                   '</div><div style="font-size:0.8em; margin-bottom:2em; margin-top:0em">'+                                   
                    getFooterContent()
                   +'</div></div>' +                   
                   '<div></div></td><td></td><tr></tbody></table>';

    }


    const getFooterContent = ()=> {
        if (props.isCampaign) {
            return  `
            <div style="color:#646464; text-align:center;">
            <p>© 2022 Yay Fun, Inc. All Right Reserved. YayPokemon.com is not produced, endorsed, supported, or affiliated with Nintendo, Creatures, GAME FREAK or The Pokémon Company.</p>
            <p>Yay Fun 3616 Far West Blvd 117-414 Austin, TX. 78731</p>
            <p>You received this email because you have signed up to our mailing list or have made an order in our store. <a target="_blank" href="https://www.yaypokemon.com/unsubscribe?email=${props.details.email}&secret=${props.details.secret}&">Unsubscribe</a> anytime.</p>
            <p>This email was sent to ${props.details.email}</p></div>
            `
        }
        return `        
        <p>Need help with your order?</p><p><a target="_blank" href="https://www.yaypokemon.com/contact/"><u>Contact us</u></a> and we will get back to you as soon as we can.</p>
        <p>Track your order <a target="_blank" href="https://www.yaypokemon.com/track/"><u>here</u></a>.</p>
        <p>This email was sent by <a target="_blank" href="https://www.yaypokemon.com/">yaypokemon.com</a></p>
        `;
    }



    const sendCustomEmail = async()=> {
        await API.post("yayfunthingsapi", "/items/sendEmail", { 
            body: 
            {  
               email:props.details.email, 
               data:getEmailHTMLContent(props.details), 
               subject:props.details.subject } }).then(async result => {

                await updateCreateUserItem({type:"EmailSent", id:result.MessageId, name:props.details.name, status:'Pending', data:JSON.stringify(props.details)}, true);    

            console.log(result);
            sendAlertMessage("Success sending email.")

        }).catch(e=>{
        
            sendAlertMessage("Error sending email");
            console.log(e);
        });
    }

//     Hello,
// We had to cancel your order. We could not verify the shipping address entered. 
// You will not be charged and should expect a full refund.
// If you have an account, you can fix your address in the account page. 


    const sendOrderShippedEmail = async ()=> {

        await API.post("yayfunthingsapi", "/items/sendEmail", { 
            body: 
            {  
               email:props.details.order.customer, 
               data:getOrderShippedEmailContent(props.details), 
               subject:props.details.subject } }).then(result => {
                        console.log(result);
                        if (props.insertOrderHistoryChange && props.details.order) {
                            props.insertOrderHistoryChange(props.details.order,  "Success Sending Order Shipped Email")
                        }
        }).catch(e=>{
        
            if (props.insertOrderHistoryChange && props.details.order) {
                props.insertOrderHistoryChange(props.details.order,  "Error Sending Order Shipped Email")
            }

            console.log(e);
        });


    }

    const updateOrderPackageShipped = async (order, packageItemId) => {
        
        let updatedPackages = [];
        for (let i = 0; i < order.packages.length; i++) {        
            let packageitem = order.packages[i];
            if (packageitem.id == packageItemId) {
                packageitem.status = "Shipped"
            }
            updatedPackages.push(packageitem);
        }
        await updateOrderWithInput({id:order.id, packages:updatedPackages})
    }


    const sendEmail = async ()=>{

        if (window.confirm("Are you sure you want to send?")) {                                    
            
            if (props.details.type == "Custom") {
                await sendCustomEmail();
            } else {
                await sendOrderShippedEmail();   
                await updateOrderWithInput({id:props.details.order.id, status:"Shipped"}).then(async result=>{                                    
                        await updateOrderPackageShipped(props.details.order, props.details.packageItemId);                                                
                    }).catch(e=>{console.log(e)})
                }
                if (props.setDisplayPreview) {
                props.setDisplayPreview(false);   
            }                             
        }
    }

    return(<>
                {

                    props.showInline  ? <>
                    
                    {
                                   displayEmail()
                               }
                    </> :
                    <>

<Dialog
                            style={{maxWidth:"none", padding: "0px !important" }}
                            open={true}
                            // TransitionComponent={Transition}
                            keepMounted
                            classes={{
                                root: classes.root
                              }}
                            onClose={() => {                                
                               if (props.setDisplayPreview) {
                                   props.setDisplayPreview(false);                                
                                    }
                            }}>
                                
                            <DialogContent>                                                           
                               {
                                   displayEmail()
                               }
                            </DialogContent>
                            <DialogActions>
                                             

                            <Button size="small" variant="contained" onClick={() => {
                                if (props.setDisplayPreview) {
                                        props.setDisplayPreview(false);                              
                                }  
                            }}>Close</Button>                                

                            <Button disabled={props.isCampaign && !props.details.subscribed ? true : false } size="small" variant="contained" onClick={async () => {

                                sendEmail();
                            }}>Send</Button>                               

                            </DialogActions>
                        </Dialog>     
                    </>
                }
            
    </>);
}

export default PreviewEmail;