
import React, {useContext, useState} from 'react';
import UserContext from "../context/user";
import Admin  from './Admin';
import Error from "./Error";
import ReCAPTCHA from "react-google-recaptcha";
import {API} from "@aws-amplify/api";

const AdminCheck = ()=> {   

    
    const [ reCaptchaVerified, setReCaptchaVerified ] = useState(false);

    async function onChange(token) {
    
        console.log("Onchange:", token);
    
        let postMsg = {
          body: {
            challengeAnswer:token
            }
        }
          
          //  const result = await API.post("LambdaAPIUser", "/app/VerifyMWSAuthToken", postMsg).then(async response => {
    
          try {
    
          
          const result = await API.post("yayfunthingsapi", "/items/VerifyReCaptcha", postMsg).then(async response => {                   
              if (response && response.result) {
                if (response.result.success == true ) {
                  setReCaptchaVerified(true);
                }
               }
              
              return response;
            });
    
          }
          catch (e) {
            console.log("Error:", e);
          }
      }

      
    const { isAdmin } = useContext(UserContext)
    return( isAdmin() ? 
    <> 
    {
        reCaptchaVerified ? <Admin/> :<div className="recaptcha-form-container">
          
        <div className="recaptcha-form">    
            <ReCAPTCHA
              sitekey="6Lcp5bwjAAAAAJwhPKVo1KxEW54QBGmDLGGq6G65"
              onChange={onChange}
            />          
        </div>
       </div>       
    }
    </> : 
    
    <Error/>   )
}

export default AdminCheck
