
import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootWhite: {
    '& .MuiOutlinedInput-root': { // main root working
      margin: theme.spacing(1),
      // width: '50%',
      padding: '0',
      // minWidth:'24em',
      // width:'100%',
      fontSize:"1.5em",      
      color:"white !important",
    },
    '& .MuiOutlinedInput-root.Mui-focused': { // main root working
      backgroundColor:"#0033 !important"
    }
  },
  root: {
    width:"100%",
    display:"inline-block",
    '& .MuiInputBase-root':{
      margin:"0em"
    },
    '& .MuiOutlinedInput-inputMultiline' : {
      margin: "0.6em",
    },
      //MuiFormControl-root, 
      '& .MuiOutlinedInput-input' : {
        padding: "0.5em",
        paddingTop:"1em",
        paddingBottom:"1em"
      },
      '& .MuiOutlinedInput-notchedOutline' : {
        borderColor:'transparent'
      },
    '& .MuiOutlinedInput-root': { // main root working
      margin:"0em",
      width:"100% !important",
      // width: '50%',
      padding: '0px !important',
      // minWidth:'24em',
      // width:'100%',
      fontSize:"1.5em",
      color:"black !important"
    }
  }    
}));


function TableInputCell(props) {

    const [inputValue, setInputValue] = useState(props.currentValue);
    const classes = useStyles();

    return ( <TextField        
        id="outlined-multiline-static"
        key={props.key+"-"+props.name}
        label={props.labelName}
        name={props.name}
        className={props.color == "white" ? classes.rootWhite : classes.root}      
        value={inputValue}
        rowsMax={10}
        rows={props.rows ? props.rows : 1}
        multiline={props.multiline ? props.multiline : false}
        margin="normal"
        padding="0"
        variant={props.variant}
        onChange= {
            e =>{                 
                setInputValue(e.target.value); 
                
                if (props.values) {
                  props.values[props.name] = e.target.value;
                }
                if (props.onChange) {
                  props.onChange(props.id, {[props.name]:e.target.value});
                }                
                
            }
        }
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            if (props.onKeyPressFunction) {
                props.onKeyPressFunction();
            }
          }
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
            focused: classes.focused,
          },
        }}
        InputProps={{
          classes: {    
            root: classes.outlinedInput,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
          },
        autoComplete: 'off',
        error:props.error ? props.error : false
        }
      }
      />)
  }
  
  export default TableInputCell