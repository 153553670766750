import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import UserContext from "../context/user";
import CartContext from "../context/cart";
import Button from "@material-ui/core/Button";
import { ConvertStringToDollarsCents, GetTrackingUrl, isMobile } from '../components/Helper'
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonAddToCart from '../components/ButtonAddToCart';
import moment from 'moment';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

const OrderDetailsPage = (props) => {

  document.title = "Order Details";
  
  const { id } = useParams();
  const history = useHistory();    
  const { getOrderById, updateOrderWithInput, sendUserCancelledEmail } = useContext(UserContext);
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(false);

  useEffect(()=>{
    if (!props.order) {
      getOrderData();   
    } else {
      setOrder(props.order);
    }
  }, []);

  
  const displayBackButton = ()=>{
    return (<Button          
                className="modal-button"
                size="large" color="primary" variant="outlined"
                onClick={()=>{
                  console.log("Go back");
                  history.push("/account");
                }}>Close</Button>)
}


  const cardImageLocationPrefix = "/images/cards/";

  const getImageForProduct = (product) => {
    if (product == undefined) 
      return "/images/yayfun-coming-soon.svg"
    if (product.type == "Single") {
      if (product.imageUrlKey) {
        return cardImageLocationPrefix + product.imageUrlKey
      } else {
        return "/images/yayfun-coming-soon.svg"
      }      
    }else {
      return product.image;      
    }    
  }


  const cancelOrder = async ()=>{
      if (order && order.id)   {
        await updateOrderWithInput({id:order.id, status:"Cancelled"}).then(result=>{                                    
          
          if (result.data && result.data.updateOrder) {
              // sendOrderCancelledEmail(order, 'We had a problem processing your payment with PayPal as a new customer. This is a problem we are working with PayPal on correcting. <br/> <br/> <b>Please place a new order so we can process and ship your order out as soon as possible.</b> We are very sorry for the inconvenience this may have caused.');   
              // sendOrderCancelledEmail(order, cancelReasonList()[cancelReasonSelectionIndex]);   
              sendUserCancelledEmail(order);
              
            }
        }).catch(e=>{console.log(e)});

      } 
  }

  const displayCancelButton = (status) =>{
    if (status == "Pending") {
      return<> <p style={{paddingTop:"1em"}}>You can cancel your order if it's still in Pending status.</p> <Button variant="contained" color="primary" onClick={()=>{
        if (window.confirm("Are you sure you want to cancel your order?")) {
          cancelOrder();
        }
      }}>
      Cancel Order
    </Button></>
    }
    
  }

  async function getOrderData() {
    if (id) {      
      let orderData = await getOrderById(id);
      if (orderData) {
        setOrder(orderData.getOrder);
      }else {
        setError(true);
      }
    }    
  }

  if (!order) {
    return <div style={{textAlign:"center"}}>
      
      {
       error ? <div className="oops-container">
       <p className="large-font font-bold">SORRY!</p>
       <p className="medium-font"> There's nothing here...</p>
</div> : <CircularProgress className="product-circle-loader" />
      }
      </div>;
  }

//   function showSpecialTag(product) {      
//     if (moment().valueOf() < moment(product.releaseDate).valueOf()) {
//       if (product.allowPreorder) {
//        return <span className="product-image-badge">Pre Order</span>
//       }else {
//       return <><span className="product-image-badge">Coming Soon: {moment(product.releaseDate).format("MM/DD/YYYY")}</span></>
//       }         
//     }else {
//      return <></>
//     }
//  }

  return (
    <div className="container-inner">       
       <div style={{marginTop:"1em"}}>{
        displayBackButton()
      }</div>
      <section className="order-details">      
     
      <div className="checkout-complete-block container-row-flex">
              <div className="checkout-complete-items container-flex-space">                
                {
                  order  ? <>
                    <p className="checkout-complete-total">
                    <span className="checkout-complete-span">Order #</span><span>{order.number}</span></p>
                    <p><span className="checkout-complete-span">Date: </span><span>{moment(order.date).format("MMMM DD, YYYY, hh:mm A")}</span></p>                    
                    <p><span className="checkout-complete-span">Status: </span><span>{order.status}</span></p>                    
                    {
                      displayCancelButton(order.status)
                    }
                    <div style={{display:"flex", flexDirection:isMobile() ? "column" : "row"}}>
                    <div className="checkout-complete-shipping">
                      <h3>Shipping Address</h3>
                      <div>
                        <div>
                          <ul>
                            {
                              order.shippingAddress ? <>
                              <li>{order.shippingAddress.name}</li>
                              <li>{order.shippingAddress.addressLine1}</li>
                              <li>{order.shippingAddress.addressLine2}</li>
                              <li>{order.shippingAddress.city}, {order.shippingAddress.state}  {order.shippingAddress.postalCode} {order.shippingAddress.country}</li>
                              </> :<>N/A</>
                            }                            
                          </ul>                          
                        </div>
                        
                        <div className="checkout-complete-shipping">                          
                         
                         
                          <h3>Tracking</h3>

                          {
                             order.packages ? <></>: <><p>Pending</p> </>
                          }

                            {
                              order.packages ? order.packages.map(packageItem =>{
                                return(<>
                                  <a href={GetTrackingUrl(packageItem.trackingNumber)} target="_blank">{packageItem.trackingNumber}</a>
                                </>)
                              }) : <></>
                            }
                          </div>
                      </div>                  
                    </div>
                    <div className="checkout-complete-shipping">

                    <h3>Shipping Method</h3>
                      <ul>
                        <li>{order.shippingMethod ? order.shippingMethod: "Standard Shipping" }</li>
                      </ul>
                    </div>

                    </div>

                    <div style={{height:"2em", borderBottom:"1px solid #ccc", marginBottom:"1em"}}>&nbsp;</div>
                    <h3>Products Ordered</h3>
                    {
                      order.products.items.map(orderItem => {
                        return (<div style={{padding:"1em"}}>                          
                          {/* <img style={{width:"6em", height:"auto"}} src={orderItem.product.image}/> */}
                          <img className="cart-image-order-complete" src={getImageForProduct(orderItem.product)} alt="cart item" />                          
                          <div className="cart-product">
                            <p> {orderItem.product.title}</p>
                            <p>Qty: {orderItem.amount}</p>
                            <p>Price: {ConvertStringToDollarsCents(orderItem.price)}</p>
                          </div>
                        </div>)
                      })
                    }
                  </> : <></>
                }

                <div style={{height:"1em", borderBottom:"1px solid #ccc", marginBottom:"3em"}}>&nbsp;</div>
                <div style={{display:"flex", flexDirection:isMobile() ? "column" : "row"}}>
                  <div className="checkout-complete-billing">
                  <h3>Payment Method</h3>
                  <ul>
                      <li>{order.paymentMethod ? order.paymentMethod: "Credit Card" }</li>
                      </ul>
                    <h3>Billing Address</h3>
                      <div>
                        <ul>
                            <li>{order.billingAddress ? order.billingAddress.name : ""}</li>
                            <li>{order.billingAddress ? order.billingAddress.addressLine1 : ""}</li>
                            <li>{order.billingAddress ? order.billingAddress.addressLine2 : ""}</li>
                            <li>{order.billingAddress ? order.billingAddress.city : ""}, {order.billingAddress ? order.billingAddress.state : ""}  {order.billingAddress ? order.billingAddress.postalCode : ""}  {order.billingAddress ? order.billingAddress.country : ""}</li>
                        </ul>
                      </div>                              
                  </div>
                  <div className="checkout-complete-total-grp">
                    <p className="checkout-complete-subtotal">Subtotal: <span className="checkout-complete-total-span">{ConvertStringToDollarsCents(order.subtotal)}</span></p>
                    <p className="checkout-complete-subtotal">Shipping:<span className="checkout-complete-total-span">{order.shippingCost ? ConvertStringToDollarsCents(order.shippingCost) : "$0.00"}</span></p>
                    <p className="checkout-complete-tax">Tax:<span className="checkout-complete-total-span">{order.taxCost ? ConvertStringToDollarsCents((order.subtotal + (order.shippingCost ? order.shippingCost : 0)) * order.taxCost) : "$0.00"}</span></p>
                    <p className="checkout-complete-total">Total:<span className="checkout-complete-total-span">{ConvertStringToDollarsCents(order.total)}</span></p>
                  </div>
                </div>
                </div>
                </div>
      </section>
      {/* <section className="product-details">

        <div style={{ 'marginLeft': 'auto', 'marginRight': 'auto', 'width': 'auto', maxWidth: '1117px' }}>
          <div className={isMobile() ? "detail-image-mobile" : "detail-image"}>
            <img style={{ maxWidth: "30em", maxHeight:"25em" }} src={url} alt={title} />
          </div>
          <div className={isMobile() ? "detail-description-mobile" : "detail-description"}>
            <h2>{title}</h2>            
            <div style={{position:"relative", width:"auto", height:"auto", paddingTop:"2em"}}>
                  {
                    product.quantity <= 0 ? <span className="product-image-badge">Sold Out</span>: <>{product.quantity <= 3? <span className="product-image-badge low">Low Stock</span>:                    
                    <>
                      {
                        showSpecialTag(product)
                      }
                    </>}</>
                  }
            </div>
            <p className="product-price align-left">                          
              {
                product.salePrice ? <> <span>{ConvertStringToDollarsCents(product.salePrice)}</span> <span className="price-strike">{ConvertStringToDollarsCents(product.price)}</span> </> :<> {ConvertStringToDollarsCents(product.price)} </>
              }
            </p>            
            <div className="product-details-description">            
            <ButtonAddToCart product={product} />
              <div style={{paddingTop:"1em"}} dangerouslySetInnerHTML={{ __html:description }} />
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};


const OrderDetails = (props)=> {

  return(
    <AmplifyAuthenticator>
      <OrderDetailsPage order={props.order} />
    </AmplifyAuthenticator>  
  )
}

export default OrderDetails