import React , {useRef, useContext} from 'react';
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import moment from 'moment';
import ConsoleLog from './ConsoleLog';
import AdminContext from '../../context/admin';
import {ConvertStringToDollarsCents} from '../Helper';


function ChartGraph(props) {

    
    const { updateAnalyticsInput } = useContext(AdminContext);

    const chartRef = React.useRef();
    
    const getSeriesDataForProduct = (productData)=> {
        let dataSeries = [];
        for (let i = 0; i < productData.length; i++) {
            let data = productData[i];
            dataSeries.push([moment(data.createdAt).valueOf(), parseFloat(data.amazonPrice)])
        }
        return dataSeries;
    }

    const groupSeriesDataByProduct = ()=> {
        if (props.data) {
            let groupedItems = {};
            for (let i = 0; i < props.data.length; i++) {
                let item = props.data[i];
                if (groupedItems[item.id] == null) {
                    groupedItems[item.id] = new Array();
                    groupedItems[item.id].push(item)
                }else {
                    if (Array.isArray(groupedItems[item.id])) {
                        groupedItems[item.id].push(item)
                    }else {
                        groupedItems[item.id] = new Array();
                        groupedItems[item.id].push(item)    
                    }                    
                }
            }
            return groupedItems;
        }        
        return {};
    }

    const getTotalInventoryForDay = (time)=> {
        let startOfDayTime = time; //moment(time).startOf('day').valueOf();
        let data = groupSeriesDataByTime();
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];        
             if (key == startOfDayTime) {
                 let dayData = data[key];
                 let parsedData = [];
                 for (let i = 0; i < dayData.length; i++) {
                     let dayEntry = dayData[i];
                     if (dayEntry && dayEntry.id) {
                        let alreadyAdded = false;
                        for(let i = 0; i < parsedData.length; i++) {
                            let parsedEntry = parsedData[i];
                            if (parsedEntry.id == dayEntry.id) {
                                alreadyAdded = true;
                            }
                        }
                        if (alreadyAdded) {

                        }else {
                            parsedData.push(dayEntry);
                        }
                     }
                 }
                 return parsedData;
             }
        }

    }


    const getSeriesDayCategories = ()=> {
        

        let seriesDays = [];
        if (props.seriesDays) {
            for (let i = 0; i < props.seriesDays.length; i++) {
                let seriesDay = props.seriesDays[i];
                let dayTimestamp = moment(seriesDay).startOf('day').format("MM/DD/YYYY");
                let isAlreadyAdded = false;
                for (let x = 0; x < seriesDays.length; x++) {
                    let seriesDay = seriesDays[x];
                    if (seriesDay == dayTimestamp) {
                        isAlreadyAdded = true;
                    }
                }
                if (!isAlreadyAdded || seriesDays.length == 0){
                    seriesDays.push(dayTimestamp);
                }
            } 

        }else  if (props.orders) {

            let sortedOrders = props.orders.sort((a, b) => moment(a.date).valueOf() >  moment(b.date).valueOf());
            let currentMonth = 0;
            let daysArray = Array.from(Array(moment('2021-05').daysInMonth()).keys());

            for (let i = 0; i < sortedOrders.length; i++) {
                let order = sortedOrders[i];
                let dayTimestamp = moment(order.date).startOf('day').format("MM/DD/YYYY");
                let isAlreadyAdded = false;
                
                for (let x = 0; x < seriesDays.length; x++) {
                    let seriesDay = seriesDays[x];
                    if (seriesDay == dayTimestamp) {
                        isAlreadyAdded = true;
                    }
                }

                if (!isAlreadyAdded || seriesDays.length == 0){
                    seriesDays.push(dayTimestamp);
                }
            } 
        }        
        
        return seriesDays;
    }




    const getOrderSeries = ()=> {

        let orderSeries = {Shipped:[], Pending:[], Cancelled:[], Refunded:[], Profit:[]};

        let orderByDay = {};
        let orderProfitByDay = {};
        let orderByDayPending = {};
        let orderByDayCancelled = {};
        let orderByDayRefunded = {};

        

        if (props.orders) {

            let sortedOrders = props.orders.sort((a, b) => moment(a.date).valueOf() >  moment(b.date).valueOf());

            const calculateOrderByDay = (orderByDayData, order) => {
                let dayTimestamp = moment(order.date).startOf('day').format("MM/DD/YYYY");
                let current = 0;               
                let total = parseFloat(order.total);
                if (orderByDayData[dayTimestamp]) {
                    current = orderByDayData[dayTimestamp];
                    current =  current + total;
                    orderByDayData[dayTimestamp] = current;
                    for (let x = 0; x < orderSeries[order.status].length; x++) {
                        let orderSeriesData = orderSeries[order.status][x];
                        if (orderSeriesData[0] == dayTimestamp) {
                            orderSeriesData[1] = current;
                        }
                    }
                } else {
                    current = total;
                    orderByDayData[dayTimestamp] = current;
                }                
            }


            const calculateOrderProfitByDay = (orderByDayData, order) => {
                let dayTimestamp = moment(order.date).startOf('day').format("MM/DD/YYYY");
                let current = 0;               
                let total = parseFloat(order.total);
                let shippingCost = parseFloat(order.shippingPaid ? order.shippingPaid : 0);
                let itemCost = 0;
                
                for (let i = 0; i < order.products.items.length; i++) {
                    itemCost += order.products.items[i].product.cost;
                }
                 
                let profit = total - shippingCost - itemCost;
                                
                if (orderByDayData[dayTimestamp]) {
                    current = orderByDayData[dayTimestamp];
                    current =  current + profit;
                    orderByDayData[dayTimestamp] = current;
                    for (let x = 0; x < orderSeries[order.status].length; x++) {
                        let orderSeriesData = orderSeries[order.status][x];
                        if (orderSeriesData[0] == dayTimestamp) {
                            orderSeriesData[1] = current;
                        }
                    }
                } else {
                    current = profit;
                    orderByDayData[dayTimestamp] = current;
                }                
            }
            

            for (let i = 0; i < sortedOrders.length; i++) {
              
                let order = sortedOrders[i];
                if (order.status == "Shipped") {              
                    calculateOrderByDay(orderByDay, order);                    
                }
                
                if (order.status == "Shipped") {              
                    calculateOrderProfitByDay(orderProfitByDay, order);                    
                }

                if (order.status == "Pending") {              
                    calculateOrderByDay(orderByDayPending, order)
                }


                if (order.status == "Pending") {              
                    calculateOrderProfitByDay(orderProfitByDay, order);                    
                }
                // if (order.status == "Shipped") {
                //     let dayTimestamp = moment(order.date).startOf('day').format("MM/DD/YYYY");
                //     let current = 0;               
                //     let total = parseFloat(order.total);
                //     if (orderByDay[dayTimestamp]) {
                //         current = orderByDay[dayTimestamp];
                //         current =  current + total;
                //         orderByDay[dayTimestamp] = current;
                //         for (let x = 0; x < orderSeries.Shipped.length; x++) {
                //             let orderSeriesData = orderSeries.Shipped[x];
                //             if (orderSeriesData[0] == dayTimestamp) {
                //                 orderSeriesData[1] = current;
                //             }
                //         }
                //     } else {
                //         current = total;
                //         orderByDay[dayTimestamp] = current;
                //     }
                // }
            }            

            for (let y = 0; y < props.seriesDays.length; y++ ) {
                let seriesDay = props.seriesDays[y];
                let seriesDayFormat = moment(seriesDay).format("MM/DD/YYYY");
              
                // SHIPPED
                let current = orderByDay[seriesDayFormat] ? orderByDay[seriesDayFormat] : 0;                
                orderSeries.Shipped.push({
                    name: seriesDayFormat,
                    y: current
                    });
                // PENDING
                let currentPending = orderByDayPending[seriesDayFormat] ? orderByDayPending[seriesDayFormat] : 0;                
                orderSeries.Pending.push({
                    name: seriesDayFormat,
                    y: currentPending
                    });
                
                    // PROFIT
                let currentProfit = orderProfitByDay[seriesDayFormat] ? orderProfitByDay[seriesDayFormat] : 0;
                orderSeries.Profit.push({
                    name: seriesDayFormat,
                    y: currentProfit
                    });

            }

        }
        
        return orderSeries;
    }

    const groupSeriesDataByTime = ()=> {

        if (props.data) {
            let groupedItems = {};
            for (let i = 0; i < props.data.length; i++) {
                let item = props.data[i];
                let createdAt = moment(item.createdAt).startOf('day').valueOf();
                if (groupedItems[createdAt] == null) {
                    groupedItems[createdAt] = new Array();
                    groupedItems[createdAt].push(item);
                }else {
                    if (Array.isArray(groupedItems[createdAt])) {
                        groupedItems[createdAt].push(item);
                    }else {
                        groupedItems[createdAt] = new Array();
                        groupedItems[createdAt].push(item);    
                    }                 
                }
            }
            return groupedItems;
        }        
        return {};
    }
    


    // let profitFillColor = {
    //     linearGradient: [0, 0, 0, 300],
    //     stops: [
    //         [0, 'rgba(65, 204, 77)'],
    //         [0.35, 'rgba(65, 204, 77)'],
    //         [1, 'rgba(255,255,255,0)']
    //     ]
    // };

    const updateSeries = async ()=> {
        for (let i = 0; i < props.data.length; i++) {
            let data = props.data[i];
            
            await updateAnalyticsInput({id:data.id, type:'Price', createdAt:data.createdAt, instockQty:data.product.unitsRemaining});
        }
    }



    const getSeries = () => {

        let seriesArray = [];

        // if (chartRef.current && chartRef.current.chart) {
        //     let chart = chartRef.current.chart;
        //     while( chart.series.length > 0 ) {
        //         chart.series[0].remove( false );
        //     }            
        //     chart.isDirtyBox = true;
        //     chart.redraw({duration: 1000});
        // }
        
        

        if (props.graphType == "Orders") {

            let orderSeries = getOrderSeries();

            
            
            seriesArray.push(
                {
                    type: "column",
                    // type: "column",
                    borderRadiusTopLeft: 4,
                    borderRadiusTopRight: 4,
                    zIndex: 0,
                    // color: "#b5e6d0", //light green
                    // color: "#99bdea", //light blue
                    name:'Shipped',
                    threshold: 0,
                    // fillColor: revenueFillColor,
                    // negativeColor: 'red',
                    // negativeFillColor: negativeFillColor,
                    data:orderSeries['Shipped'],
                    // pointRange: this.getPointRangeByDateType(),
                    groupPadding: 0,
                    // pointPadding: 0,
                    // pointPlacement: 0,
                    // marker: {
                    //     enabled: false
                    // }
                }
            );

            seriesArray.push(
                {
                    type: "column",
                    // type: "column",
                    borderRadiusTopLeft: 4,
                    borderRadiusTopRight: 4,
                    zIndex: 0,
                    color: "#f29339", //light green
                    // color: "#99bdea", //light blue
                    name:'Pending',
                    threshold: 0,
                    // fillColor: revenueFillColor,
                    // negativeColor: 'red',
                    // negativeFillColor: negativeFillColor,
                    data:orderSeries['Pending'],
                    // pointRange: this.getPointRangeByDateType(),
                    groupPadding: 0,
                    // pointPadding: 0,
                    // pointPlacement: 0,
                    // marker: {
                    //     enabled: false
                    // }
                }
            );

            seriesArray.push(
                {
                    type: "line",
                    // type: "column",
                    borderRadiusTopLeft: 4,
                    borderRadiusTopRight: 4,
                    zIndex: 0,
                    color: "#2be248", //light green
                    // color: "#99bdea", //light blue
                    name:'Profit',
                    threshold: 0,
                    // fillColor: revenueFillColor,
                    // negativeColor: 'red',
                    // negativeFillColor: negativeFillColor,
                    data:orderSeries['Profit'],
                    // pointRange: this.getPointRangeByDateType(),
                    groupPadding: 0,
                    // pointPadding: 0,
                    // pointPlacement: 0,
                    // marker: {
                    //     enabled: false
                    // }
                }
            );


        } else if (props.graphType == "Market") { 

            let seriesData = [];
            let seriesDataMarketPrice = [];
            let data = groupSeriesDataByTime();
            let keys = Object.keys(data);
    
            
            // myPrice:product.price,
            // minPrice:product.minPrice,
            // marketPrice:product.marketPrice,
            // calculatedPrice:calculatePrice(parseFloat(product.marketPrice), parseFloat(product.minPrice ? product.minPrice : product.price))

            let seriesDataCreated = {
                "My Price" : [],
                "Market Price" : [],
                "Calculated Price" : [],
                "Min Price" : [],
                "Total Offers" :[]
            }


            for (let i = 0; i < keys.length; i++) {    
                let key  = keys[i];

                
                
                if (data[key]) {
                    let items = data[key];
                    for (let i = 0; i < items.length; i++) {
                        let item = items[i];
                        
                        if (item.data) {
                            let dataEntry = JSON.parse(item.data)
                            if (item.id.toUpperCase() == props.selectedId) {
                                // seriesData.push([moment(item.createdAt).valueOf(), dataEntry.myPrice]);
                                seriesDataCreated["My Price"].push([moment(item.createdAt).valueOf(), dataEntry.myPrice ? parseFloat(dataEntry.myPrice) : null]);
                                seriesDataCreated["Min Price"].push([moment(item.createdAt).valueOf(), dataEntry.minPrice ? parseFloat(dataEntry.minPrice) : null]);
                                seriesDataCreated["Market Price"].push([moment(item.createdAt).valueOf(), dataEntry.marketPrice ? parseFloat(dataEntry.marketPrice) : null]);
                                seriesDataCreated["Calculated Price"].push([moment(item.createdAt).valueOf(), dataEntry.calculatedPrice ? parseFloat(dataEntry.calculatedPrice) : null]);
                                seriesDataCreated["Total Offers"].push([moment(item.createdAt).valueOf(), dataEntry.offerData ? parseInt(dataEntry.offerData.totalOfferCount) : null]);
                            }
                        }                         
                    }                    
                }
                // let totalInventory = getTotalInventoryForDay(key);
                // let totalCost = 0;
                // let totalMarketValue = 0;
                
                // for (let x = 0; x < totalInventory.length; x++) {
                //     let inventoryItem = totalInventory[x];
                //     totalCost += (inventoryItem.product.cost * inventoryItem.instockQty);
                //     totalMarketValue += (parseFloat(inventoryItem.amazonPrice) * inventoryItem.instockQty);
                // }


                // seriesData.push([parseFloat(key), totalCost]);
                // seriesDataMarketPrice.push([parseFloat(key), totalMarketValue]);

            }


            
            let dataKeys = Object.keys(seriesDataCreated);
         
            for (let i = 0; i < dataKeys.length; i++) {

                let keyName = dataKeys[i];

                seriesArray.push(   
                    {
                        type: "line",
                        // type: "column",
                        borderRadiusTopLeft: 4,
                        borderRadiusTopRight: 4,
                        zIndex: 0,
                        // color: "#b5e6d0", //light green
                        // color: "#99bdea", //light blue
                        name:keyName,
                        threshold: 0,
                        // fillColor: revenueFillColor,
                        // negativeColor: 'red',
                        // negativeFillColor: negativeFillColor,
                        data: seriesDataCreated[keyName],
                        // pointRange: this.getPointRangeByDateType(),
                        groupPadding: 0,
                        pointPadding: 0,
                        pointPlacement: 0,
                        marker: {
                            enabled: false
                        }
                    }
                );        
            
                
            }

          

        } else {


            let seriesData = [];
            let seriesDataMarketPrice = [];
            let data = groupSeriesDataByTime();
            let keys = Object.keys(data);
    
            for (let i = 0; i < keys.length; i++) {    
                let key  = keys[i];
                let totalInventory = getTotalInventoryForDay(key);
                let totalCost = 0;
                let totalMarketValue = 0;
                for (let x = 0; x < totalInventory.length; x++) {
                    let inventoryItem = totalInventory[x];
                    totalCost += (inventoryItem.product.cost * inventoryItem.instockQty);
                    totalMarketValue += (parseFloat(inventoryItem.amazonPrice) * inventoryItem.instockQty);
                }
                seriesData.push([parseFloat(key), totalCost]);
                seriesDataMarketPrice.push([parseFloat(key), totalMarketValue]);
            }

            // console.log("seriesData:", seriesData);
            // console.log("seriesDataMarketPrice:", seriesDataMarketPrice);

            seriesArray.push(
                {
                    type: "line",
                    // type: "column",
                    borderRadiusTopLeft: 4,
                    borderRadiusTopRight: 4,
                    zIndex: 0,
                    // color: "#b5e6d0", //light green
                    // color: "#99bdea", //light blue
                    name:'Inventory Cost',
                    threshold: 0,
                    // fillColor: revenueFillColor,
                    // negativeColor: 'red',
                    // negativeFillColor: negativeFillColor,
                    data: seriesData,
                    // pointRange: this.getPointRangeByDateType(),
                    groupPadding: 0,
                    pointPadding: 0,
                    pointPlacement: 0,
                    marker: {
                        enabled: false
                    }
                }
            );        
            seriesArray.push(
                {
                    type: "line",
                    // type: "column",
                    borderRadiusTopLeft: 4,
                    borderRadiusTopRight: 4,
                    zIndex: 0,
                    // color: "#b5e6d0", //light green
                    // color: "#99bdea", //light blue
                    name:'Market Value',
                    threshold: 0,
                    // fillColor: revenueFillColor,
                    // negativeColor: 'red',
                    // negativeFillColor: negativeFillColor,
                    data: seriesDataMarketPrice,
                    // pointRange: this.getPointRangeByDateType(),
                    groupPadding: 0,
                    pointPadding: 0,
                    pointPlacement: 0,
                    marker: {
                        enabled: false
                    }
                }
            );
        }

        //Orders
        // for (let i = 0; i < productKeys.length; i++) {
        //     let key = productKeys[i];
        //     seriesArray.push(
        //         {
        //             type: "line",
        //             // type: "column",
        //             borderRadiusTopLeft: 4,
        //             borderRadiusTopRight: 4,
        //             zIndex: 0,
        //             // color: "#b5e6d0", //light green
        //             // color: "#99bdea", //light blue
        //             name:key,
        //             threshold: 0,
        //             // fillColor: revenueFillColor,
        //             // negativeColor: 'red',
        //             // negativeFillColor: negativeFillColor,
        //             data: getSeriesDataForProduct(groupedProducts[key]),
        //             // pointRange: this.getPointRangeByDateType(),
        //             groupPadding: 0,
        //             pointPadding: 0,
        //             pointPlacement: 0,
        //             marker: {
        //                 enabled: false
        //             }
        //         }
        //     );
        // }
        
        // seriesArray.push(
        //     {
        //         type: "line",
        //         // type: "column",
        //         borderRadiusTopLeft: 4,
        //         borderRadiusTopRight: 4,
        //         zIndex: 0,
        //         color: "#b5e6d0", //light green
        //         // color: "#99bdea", //light blue
        //         name: "Revenue",
        //         threshold: 0,
        //         fillColor: revenueFillColor,
        //         negativeColor: 'red',
        //         negativeFillColor: negativeFillColor,
        //         data: getSeriesData(),
        //         // pointRange: this.getPointRangeByDateType(),
        //         groupPadding: 0,
        //         pointPadding: 0,
        //         pointPlacement: 0,
        //         marker: {
        //             enabled: false
        //         }
        //     }
        
        // );

        
        return seriesArray;
    }

    const getMinForSeries = () => {
        let min = -1;
        for (let i = 0; i < props.data.length; i++) {
            let current = moment(props.data[i].createdAt).valueOf();
            if (current < min || min == -1) {
                min = current;
            }
        }

        for (let i = 0; i < props.orders.length; i++) {
            let current = moment(props.orders[i].date).valueOf();
            if (current < min || min == -1) {
                min = current;
            }
        }

        
        return min;
    }

    const getMaxForSeries = () => {
        let max = 0;
        for (let i = 0; i < props.data.length; i++) {
            let current = moment(props.data[i].createdAt).valueOf();
            if (current > max) {
                max = current;
            }
        }

        for (let i = 0; i < props.orders.length; i++) {
            let current = moment(props.orders[i].date).valueOf();
            if (current > max) {
                max = current;
            }
        }


        return max;
    }


    const getMaxYForSeries = ()=>{
        let max = 0;
        for (let i = 0; i < props.data.length; i++) {
            let current = parseFloat(props.data[i].amazonPrice);
            if (current > max) {
                max = current;
            }
        }
        return max ? max : 1;
    }


    const getXAxisForSeries = () => {

        
        if (props.graphType == "Orders") {
            return {
                visible: true,
                type:'category',
                categories:getSeriesDayCategories()
            }
        }
        
        let xSeriesObject = {          
            visible: true,
            categories: null,
            type: 'datetime',
            min: moment(props.startDate).startOf('day').valueOf(),
            max: moment(props.endDate).endOf('day').valueOf(),
            tickInterval: (24 * 3600 * 1000), // 1 day    
            dateTimeLabelFormats: {
                millisecond: '%b %e %H:%M',
                second: '%b %e %H:%M',
                minute: '%H:%M',
                hour: '%l:%M %P',
                day: '%b %e', //(this.state.dateType == 'last3days' ||  this.state.dateType == 'last7days'  ? '%m %e': '%b %e'),
                week: '%b',
                month: '%b',
                year: '%Y'
            },
            // labels: {
            //   useHTML: true,
            //   formatter: function () {           
            //     return '<p>' + moment(new Date(this.value)).format('MM/DD') + '</p>'; // example for moment.js date library
            //  }
            // },
            // categories:getSeriesDayCategories()            
        }


        return xSeriesObject;
        // let xSeriesObject = {
        //     type: 'datetime',
        //     visible: self.chartRef.current && self.chartRef.current.chart ? true : false,
        //     min: this.getMinForDate(this.dateType),
        //     max: this.getMaxForDate(this.dateType),          
        //     tickInterval: this.getTickIntervalForDateType(), // 1 day
        //     labels: {
        //       useHTML: true,
        //       formatter: function () {                    
        //         let tickCount = this.axis.tickPositions.length;          
        //         let tickWidth = this.chart.chartWidth / tickCount;
        //         let style = "<span class=chartLabels style=text-align:center;position:relative;margin-left:" + tickWidth/2 + "px;width:" +  tickWidth + "px;display:inline-block;>" + moment(this.value).format("MMM DD")  + "</span>";              
        //         return style;
        //       },
        //       showLastLabel:true
        //      },              
        //     title: {
        //       text: ""
        //     },      
        //     crosshair: true,
        //  dateTimeLabelFormats: {
        //   millisecond: '%b %e %H:%M',
        //   second: '%b %e %H:%M',
        //   minute: '%H:%M',
        //   hour: '%l:%M %P',
        //   day: '%b %e', //(this.state.dateType == 'last3days' ||  this.state.dateType == 'last7days'  ? '%m %e': '%b %e'),
        //   week: '%b',
        //   month: '%b',
        //   year: '%Y'
        // },          
        //     plotLines:this.getPlotLinesForAdjustmentEvents()
        //   };
        //   return xSeriesObject;
    }

    const getYAxisForSeries = () => {
        return ([
            {
                title: {
                    text: " "
                },
                // softMax:100,
                min: null,
                crosshair: {
                    width: 1,
                    zIndex: 2,
                    // snap:false
                },
                tickInterval: 10,
                  labels: {
                    enabled: true,
                    formatter: function () {
                      let defaultLabel = this.value;
                      return (parseInt(defaultLabel)).toLocaleString("en-US", { style: "currency", currency: "USD" }).slice(0, -3);
                    }
                  },
                minorGridLineWidth: 0,
                gridLineWidth: 0
            }]);
    }

    return (<> <div className="chartContainer">
        {
            props.data ?  
            <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={
                {
                    credits: {
                        enabled: false
                    },
                    time: { useUTC: false },
                    chart: {
                        height: '490px',
                        style: {
                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
                        },
                        // renderTo: 'lineChart',
                        // type: 'line',
                        zoomType: 'x',
                        animation: false,
                        events: {
                            drilldown: function (e) {
                                if (!e.seriesOptions) {
                                    // let chart = e.target;                                
                                    // let status = "";
                                    // if (e.point.drilldown.includes("Shipped"))
                                    //     status = "Shipped";
                                    // if (e.point.drilldown.includes("Pending"))
                                    //    status = "Pending";
                                    // if (e.point.drilldown.includes("Canceled"))
                                    //     status = "Canceled";

                                    // let drilldownSeriesData = self.getDrilldownSeriesForKey(e.point.drilldown, status);

                                    // if (drilldownSeriesData) {

                                    //     console.log("drilldownSeriesData.name:" + drilldownSeriesData.name);
                                    //     let drilldownDate = new Date(drilldownSeriesData.name); 
                                    //     // drilldownDate.setHours(0, 0, 0, 0);                                                

                                    //     let startOfDay = moment(drilldownDate);//.startOf('day');

                                    //     let drilldownDateEnd = new Date(drilldownSeriesData.name);
                                    //     // drilldownDateEnd.setHours(23, 59, 59, 99);        
                                    //     let endOfDay = moment(drilldownDateEnd);//.endOf('day');

                                    //     startOfDay = startOfDay.startOf('day');
                                    //     startOfDay = startOfDay.utc();

                                    //     endOfDay = endOfDay.endOf('day');
                                    //     endOfDay = endOfDay.utc();

                                    //     self.updateTableRefOrdersWithStartEndDates(startOfDay.toISOString(), endOfDay.toISOString());

                                    //     console.log("SET CHART drilldownSeriesData");
                                    //     console.log(drilldownSeriesData);

                                    //     chart.addSeriesAsDrilldown(e.point, drilldownSeriesData );
                                    //     chart.applyDrilldown();                        

                                    //     console.log("START OF DAY DRILLDOWN:");

                                    //     console.log(moment(startOfDay).format())

                                    //     setTimeout(function () {
                                    //         chart.xAxis[0].update({
                                    //             min: startOfDay.valueOf(),
                                    //             max: endOfDay.valueOf(),
                                    //             tickInterval:(3600 * 1000)
                                    //         });
                                    //     }, 0);                                    
                                    // }                                
                                }
                            },
                            drillup: function (e) {
                                let chart = e.target;
                                // self.startDrillUp(chart);            
                            }
                        }
                    },
                    // plotOptions: this.getPlotOptionsForSeries(),
                    title: {
                        text: ""
                    },

                    tooltip: {
                        useHTML: true,
                        backgroundColor: "rgba(255,255,255,255)",
                        split: false,
                        borderRadius: 0,
                        zIndex: 9999,
                        style: {
                            fontSize: "11px"
                        },
                        
                        // shared:this.state.showSalesGraph ? true : false
                        // ,
                        formatter: function (event) {
                            let defaultLabel = this.y;   
                            if (this.series.name == "Total Offers") {
                                return `<p>${moment(this.x).format("ddd DD MMMM")}</p>` + '<p class="chart-price-label">'+ (defaultLabel)  + ' Offers</p>';              
                            }else {
                                return `<p>${moment(this.x).format("ddd DD MMMM")}</p>` + '<p class="chart-price-label">'+ ConvertStringToDollarsCents(defaultLabel) +'</p>';              
                            }
                            
                        //     if (this.points && this.points.length > 1 ) {
                        //         // shared tip
                        //         let dateName = moment(this.x).format('dddd, MM/DD/YYYY');
                        //         let profit = "" + this.points[1] ? this.points[1].y.toFixed(2) : "";
                        //         let revenue = "" + this.points[0] ? this.points[0].y.toFixed(2) : "";
                        //         let cumulativeProfit = "" + (this.points.length > 2 && this.points[2] ? this.points[2].y.toFixed(2) : "");
                        //         let cumulativeProfitString = cumulativeProfit ? "" + (this.points.length > 2) : ""? 
                        //         "<p><b>Total Profit:&nbsp;</b><span class=salesTooltipAmount>" + ConvertStringToDollarsCents(cumulativeProfit) + "</span></p>" : "";   

                        //         defaultLabel = " " + dateName + "<div class=salesTooltip></p><p><b>Revenue:&nbsp;</b><span class=salesTooltipAmount>" + ConvertStringToDollarsCents(revenue) 
                        //         + "</span></p>"

                        //         + (profit ? "<p><b>Profit:&nbsp;</b><span class=salesTooltipAmount>" + ConvertStringToDollarsCents(profit) + "</span></p>" : "" ) 

                        //         + cumulativeProfitString + "</div>";

                        //     } else if (self.state.showSalesGraph) {
                        //     return ConvertStringToDollarsCents(defaultLabel);
                        //     }

                        //     if (this.series) {
                        //     if (this.series.name == "Canceled") {
                        //         return defaultLabel + " Canceled";
                        //     }
                        //     if (this.series.name == "Revenue" || this.series.name == "Profit") {

                        //         let defaultLabelFormatted = (parseFloat(defaultLabel)).toLocaleString("en-US", { style: "currency", currency: "USD" });
                        //         // let orderDetailsString = componentScope.getOrderDetailsForDay(this.x);
                        //         return defaultLabelFormatted;
                        //     }

                        //     if (this.series.name == "Charged") {
                        //         let defaultLabelFormatted = (parseFloat(defaultLabel)).toLocaleString("en-US", { style: "currency", currency: "USD" });
                        //         return defaultLabelFormatted;
                        //     }

                        //     if (this.series.name == "Shipped") {
                        //         return "Shipped Units: " + defaultLabel;
                        //     }

                        //     if (this.series.name == "Pending") {
                        //         return "Pending Units: " + defaultLabel;
                        //     }
                        //     if (this.series.name == "Canceled") {
                        //         return "Canceled Units: " + defaultLabel;
                        //     }
                        //     }
                                // console.log("DEFAULT LABEL:", this);
                                // let totalInventory = getTotalInventoryForDay(this.x);
                                // console.log("Total Inv:", totalInventory);
                                // let totalCost = 0;
                                // for (let i = 0; i < totalInventory.length; i++) {
                                //     let inventoryItem = totalInventory[i];
                                //     totalCost += (inventoryItem.product.cost * inventoryItem.product.unitsRemaining);
                                // }
                                // let htmlLabel = `<p>${this.series.name}</p><p>Total Cost:${totalCost.toFixed(2)}</p><p>$${this.y}</p>`;
                                // return htmlLabel;
                            }
                        } 
                        ,

                    plotOptions: {
                            line: {
                                linecap: "square"
                            },
                            area: {
                                linecap: "square"
                            },
                            candlestick: {
                                lineColor: '#404048'
                            },
                            column: {
                                stacking: 'normal',
                                grouping: false,
                                pointPlacement: 'on'//,
                                // dataLabels: {
                                //     enabled: true,
                                //     crop: false,
                                //     overflow: 'none',
                                // }              ,
                                // ,borderRadiusTopLeft: 20,
                                // borderRadiusTopRight: 20                             
                            },
                            spline: {
                                connectNulls: true,
                                marker: {
                                    enabled: false
                                }
                            },
                            areaspline: {
                                threshold: null
                            },
                            // area: {
                            //   step: 'left'
                            // },
                            series: {
                                states: {
                                    inactive: {
                                        opacity: 1
                                    }
                                },
                                turboThreshold: 1000,
                                threshold: null
                            }
                    },
                    
                    series: getSeries(),
                        // drillup:{                  
                        //     animation:{
                        //         duration:0
                        //     }
                        // },
                        // drilldown:{                  
                        //     animation:{
                        //         duration:0
                        //     },
                        //     activeAxisLabelStyle: {
                        //         textDecoration: 'none',
                        //         fontWeight: 'regular',
                        //         color: '7f7f7f'
                        //     },
                        //     activeDataLabelStyle: {
                        //         textDecoration: 'none',
                        //         fontWeight: 'semi-bold',
                        //         color: '4c4c4c'
                        //     },
                        //     drillUpButton: {
                        //         relativeTo: 'spacingBox',
                        //         position: {
                        //             align: 'right',
                        //             x: 0,
                        //             y: 0,
                        //             verticalAlign: 'top'
                        //     }
                        // },
                        // series:getSeries()//[]//this.getDrilldownSeries()                  
                        // },
                    xAxis: getXAxisForSeries(),
                    yAxis: getYAxisForSeries()                    
                }} /> :<></>
        }
       
    </div>
    </>)
}

export default ChartGraph;