import React, { useLayoutEffect, useEffect, useState, Suspense } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { HomePage, Login, CardCondition, Cart, Checkout, Search, AdminCheck, Account, Track,About,PrivacyPolicy, Contact,  Returns, Pokemon, Unsubscribe} from '../pages';
import Footer from './Footer';
import ProductDetails from '../pages/ProductDetails';
import OrderDetails from '../pages/OrderDetails';
import Error from "../pages/Error";
import ConsoleLog from "../components/yayfun/ConsoleLog";
import Seo from "../components/Seo";
// import VRScene from '../pages/VRScene';

export default function Main() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);    
  const [search, setSearch] = useState(params.get('k') ? params.get('k') : '')

  useEffect(()=> {
    const s = params.get('k');  
    if (s && s.length >= 0) {
      setSearch(s);      
    } else if (search != '' ){
      setSearch('');
    }
  }, [location]);

  useLayoutEffect(() => {
    ConsoleLog("[Main useLayoutEffect]");
    window.scrollTo(0, 0);
  }, [location.pathname]);

    return (
      <>    
      <div className="container-body">
            <Switch>             
              <Route exact path="/admin" component={AdminCheck} />          
              <Route exact path="/account" component={Account} />
              <Route exact path="/cards" component={CardCondition} />
              <Route exact path="/cart" component={Cart} />
              <Route exact path="/checkout" component={Checkout} />
              <Route exact path="/privacy" component={PrivacyPolicy} />                                                        
              <Route exact path="/returns" component={Returns} />     
              <Route exact path="/track" component={Track} />     
              <Route exact path="/pokemon" component={Pokemon} />   
              <Route exact path="/unsubscribe" component={Unsubscribe} />   
              <Route exact path="/contact" component={Contact} />                   
              {/* <Route exact path="/product" component={Product} />             */}
              {/* <Route exact path="/vr" component={VRScene} />                                         */}
              {
                search && search.length > 0 ? <Route exact path="/s" component={props => 
                <Search {...props} search={search} />} /> :
                <Route exact path="/" component={props => <HomePage {...props} />} />
              }
              {/* <Route exact path="/products/*" component={Product} />         */}
              <Route path="/cards/:id" children={<ProductDetails> </ProductDetails>}></Route>
              <Route path="/order/:id" children={<OrderDetails></OrderDetails>}></Route>              
              <Route exact path="/" component={props => <HomePage {...props} />} />              
              <Route path="*"><Error /></Route>

            </Switch>
          </div>          
          <div style={{flexGrow:0, flexShrink:0, backgroundColor:"transparent", height:"0em"}}>&nbsp;</div>
        
        {
          location.pathname.includes("/admin") ? <></> :<Footer/>      
        }
        

      </>
    );

    
  
}