import React, {  useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import {Link} from 'react-router-dom';
import EmailIcon from '@material-ui/icons/Email';
import SubscribePrompt  from "../components/SubscribePrompt";

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'black', 
    color:'white'  
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  gridItem: {
    padding:'10px'
  },
  paper: {
    minHeight:200,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
    control: {
    padding: 0,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  
  
  },
 button: {
    margin: theme.spacing(1),
    color:"#fff",
    backgroundColor: "#2787f4",
    '&:hover': {
      backgroundColor: blue[700],
    }
  },
  input: {
    display: 'none',
  }, 
  paperSignup: {
    padding: 12
  },
  signupBox : {
    minHeight: 200,
    color:'black'
  }
});


function Footer() {

  const [showSubscribePopupToggle, setShowSubscribePopupToggle] = useState(false);  
  const [showSubscribePopupTogglePrev, setShowSubscribePopupTogglePrev] = useState(false);  

  const restToggles = ()=>{
    setShowSubscribePopupToggle(false);
    setShowSubscribePopupTogglePrev(false);
  }

  return(
    
    <div className="footer">
  
      <SubscribePrompt shouldShow={showSubscribePopupToggle != showSubscribePopupTogglePrev} reset={restToggles}/>

      <div>
      
          <div className="footer-item-center">
          
          <div className="footer-item-content">
            {/* <h5>Pages</h5> */}
            <ul className="grid-item-list">
            <li>
              <span style={{color:"white", cursor:"pointer"}} onClick={()=>{
                setShowSubscribePopupToggle(!showSubscribePopupToggle);
                setShowSubscribePopupTogglePrev(showSubscribePopupToggle);
              }}>Mailing List</span>
             </li>
              <li><Link to='/pokemon'>Pokemon Distributors</Link></li>
       
              <li><Link to='/cards'>Card Condition Guide</Link></li>
              <li>
              {/* <Link to='/educational'>Educational Materials</Link> */}
             </li>

            {/* <li><a href="popsockets">Educational </a> </li>             */}
            {/* <li><a href="shirts">Merch</a></li> */}
            </ul>
          </div>

          <div className="footer-item-content">
            {/* <h5>Other</h5> */}
            <ul className="grid-item-list">
            <li>
              <Link to='/contact'>Contact</Link>
             </li>          
             <li>
              <Link to='/track'>Track Order</Link>
             </li>          
            <li>              
              <Link to='/returns'>Store Policy</Link>
              </li>
              <li>              
              <Link to='/privacy'>Privacy Policy</Link>
              </li>
            </ul>
          </div>

          </div>
      
     <div style={{display:"block"}}>
            <div className="" style={{textAlign:"center", paddingRight:"1em"}}>              
            <div><p>We Accept:</p>
            <img className="checkout-card-img" src="/images/visa.png" />
            <img className="checkout-card-img" src="/images/amex.png" />
            <img className="checkout-card-img" src="/images/mastercard.png" />
            <img className="checkout-card-img" src="/images/discover.png" />
            <img className="checkout-card-img" src="/images/Online_Primary_Acceptance_Mark_RGB_V2.jpg" />
            
            </div>
            <div className="footer-item-content">
            <p className="footer-disclaimer" style={{padding:"0em"}}>
            Yay Fun / Yay Pokemon<br/>              
            3616 Far West Blvd 117-414 <br/>
            Austin, TX. 78731
            </p>


            </div>

       
         
       <p className="footer-disclaimer">

          © 2022 Yay Fun, Inc. All Right Reserved. This website is not produced, endorsed, supported, or affiliated with Nintendo, Creatures, GAME FREAK or The Pokémon Company.
       
       </p>
       </div>

     </div>
    </div>


    </div>
    )
  
}


export default withStyles(styles)(Footer);