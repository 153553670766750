
import React, {useEffect, useState, useContext} from 'react'
import AdminContext from "../context/admin";
import moment from 'moment';
import Button from "@material-ui/core/Button";
import CustomTextField from '../components/yayfun/CustomTextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

function CouponsPage (props) {

    const { createSiteItemInput, fetchSiteItemsByType } = useContext(AdminContext);

    const [currentCodeEntry, setCurrentCodeEntry] = useState("");
    const [currentDiscountEntry, setCurrentDiscountEntry] = useState("");
    const [currentCoupons, setCurrentCoupons] = useState([]);
    
    useEffect(()=>{
        fetchCoupons();
    }, []);

    const fetchCoupons = async ()=>{
        let coupons = await fetchSiteItemsByType("Coupon");
        console.log("Fetch coupons result:", coupons);
        setCurrentCoupons(coupons);
    }

    const createCoupon = async ()=>{
    
        let result = await createSiteItemInput({type:'Coupon', id:currentCodeEntry, data:currentDiscountEntry});

        console.log("createSiteItemInput: ", result);
        fetchCoupons();

    }


    const listCoupons = ()=> {
        if (currentCoupons) {
            return currentCoupons.map(coupon => {
                return(<>
                    <p>{coupon.id} - Amount: {coupon.data}</p>
                </>)
            })
        }
    }


    return (<>
    
    <CustomTextField properties={{
                labelName:"Coupon Code",
                currentValue:currentCodeEntry, 
            }} 
            onChange={(event)=>{                                    
                if (event.target.value) {
                    setCurrentCodeEntry(event.target.value)
                }else {
                    setCurrentCodeEntry("")
                }                
        }}/>

             <CustomTextField properties={{
                labelName:"Discount Amount",
                currentValue:currentDiscountEntry, 
            }} 
            onChange={(event)=>{                                    
                if (event.target.value) {
                    setCurrentDiscountEntry(event.target.value)
                }else {
                    setCurrentDiscountEntry(0)
                }                
        }}/>

                <Button color="primary" onClick={ async ()=>{                        
                        createCoupon()
                }}>Create</Button>

                <div>
                {
                    listCoupons()
                }
                </div>
    </>);
}

export default CouponsPage;