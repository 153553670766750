import React, {useContext, useState, useEffect} from 'react'
import ProductContext from "../context/products";
import { InView } from 'react-intersection-observer';
import moment from 'moment';
import {GetSetForSeries, GetSetImageByName} from './Helper';


function ProductSetHeader(props){

    const { fetchProductsForSets, getProductsBySet, products, hasFetchedSet } = useContext(ProductContext);

    const [setLength, setSetLength] = useState(0);
    const [entryTime, setEntryTime] = useState(moment().valueOf());
    const [triggered, setTriggered] = useState(false);
    const [loadingSet, setLoadingSet] = useState(false);

    const getDelayForSet = (series, setName) => {

        if (setName == "25th Anniversary") {
            return 1;
        }

        if (setName == "Featured") {
            return 1;
        }

        if (setName == "Evolving Skies") {
            return 0;
        }
        
                
        if (setName == "Scarlet & Violet") {
            return 0;
        }

        if (setName == "Brilliant Stars") {
            return 0;
        }
        if (setName == "Crown Zenith") {
            return 0;
        }
        if (setName == "Silver Tempest") {
            return 0;
        }
        if (setName == "Lost Origin") {
            return 0;
        }

        if (setName == "Pokémon GO") {
            return 0;
        }

        if (setName == "Astral Radiance") {
            return 0;
        }


        if (setName == "Fusion Strike") {
            return 1;
        }

        if (setName == "Celebrations") {
            return 1;
        }


        if (series == "Sword and Shield" || series == "Sword & Shield") {
            let sets = GetSetForSeries(series);
            for (let i = 0; i < sets.length; i++) {
                if (sets[i] == setName) {
                    return (200 * i);
                }
            }            
        }
        if (series == "Sun and Moon" || series == "Sun & Moon") {
            let sets = GetSetForSeries(series);
            for (let i = 0; i < sets.length; i++) {
                if (sets[i] == setName) {
                    return ((1000 * i));
                }
            }            
        }        
        if (series == "XY") {
            let sets = GetSetForSeries(series);
            for (let i = 0; i < sets.length; i++) {
                if (sets[i] == setName) {
                    return ((1000 * i));
                }
            }            
        }

        return 1500;
    }

    const finishedLoadingSet = (result) =>{       
        setLoadingSet(false);
        setSetLength(result.length ? result.length : 0);        
    }

    return (<>
       
       {
           GetSetImageByName(props.setKey) ? 
                 <img className="product-set-image" src={GetSetImageByName(props.setKey)} alt={props.setKey} /> 
                 :
                 <> <p className="fun-title">{props.setKey}</p></>
        }
        
      <InView as="div" style={{ width:"100%"}} triggerOnce={false} onChange={ async (inView, entry) => {                    
            
            if (!triggered) {            
               
                let delayForSet = getDelayForSet(props.series, props.setKey);                
                
                if (inView && moment().valueOf() - entryTime >= delayForSet) {

                    let set = getProductsBySet(props.setKey);                
                    let fetchedSet = hasFetchedSet(props.setKey);   

                    if (set && set.length > 0) {
               
                   } else if (!fetchedSet) {
                    setTriggered(true);
                    if (!props.isSearch) {                        
                        setLoadingSet(true);
                        await fetchProductsForSets([props.setKey], finishedLoadingSet);
                        }    
                    }
                }
            } 
        }}>
        
        { loadingSet && !props.isSearch ? <><div style={{paddingTop:"5em", position:'absolute', left:'50%'}}></div></> :<></> }

      </InView>
    </>)
}

export default ProductSetHeader;