import React, { useState, useContext} from "react";
import AdminContext from "../context/admin";
import ProductCard from '../components/ProductCard';
import CustomTextField from './yayfun/CustomTextField';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import JSZip from "jszip";
import S3Image from "../components/S3Image";

import pokemon from 'pokemontcgsdk'

function AddSingleDisplay(props) {

    const [, updateState] = React.useState();
    const [cards, setCards] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const {cardsSelected, setCardsSelected, cardSets, resetSets, setCardSetSelected} = useContext(AdminContext);
    const [cardZoomed, setCardZoomed] = useState(null);
    const [selectedSetIndex, setSelectedSetIndex] = useState(false);
    const [selectedSetName, setSelectedSetName] = useState("");
    const [selectedSetCode, setSelectedSetCode] = useState("");


    // const [cardImageUrls, setCardImageUrls] = useState();

    // const downloadFile = (url, onSuccess) => {
    //     console.log("downloadFile", url)
    //     var xhr = new XMLHttpRequest();
    //     xhr.onprogress = calculateAndUpdateProgress;
    //     xhr.open('GET', url, true);
    //     xhr.responseType = "blob";
    //     xhr.onreadystatechange = function () {
    //         console.log("xhr.readyState:", xhr.readyState);
    //         if (xhr.readyState == 4) {
    //             if (onSuccess) {
    //                 onSuccess(xhr.response);
    //             }
    //         }
    //     }
    // }
    

    const toggleCardSelected = (card) => {
        let isAlreadyIncluded = false;
    
        for (let i = 0; i < cardsSelected.length; i++) {
            if (cardsSelected[i] == card) {
                isAlreadyIncluded = true;
            }
        }
        
        let updatedCardsSelected = [];
        for (let i = 0; i < cardsSelected.length; i++) {
            if (cardsSelected[i] == card) {

            } else {
                updatedCardsSelected.push(cardsSelected[i]);
            }
        }     

        if (!isAlreadyIncluded) {
            updatedCardsSelected.push(card);
        }

        setCardsSelected(updatedCardsSelected);
    }

    const isCardSelected = (card) => {
        for (let i = 0; i < cardsSelected.length; i++) {
            if (cardsSelected[i] == card) {
                return true;
            }
        }
        return false;
    }

    const drawCard = (card) => {
        return(<div> <img className="card-image" src={card.imageUrlHiRes} onClick={()=>{setCardZoomed(null);}}/></div>)
    }

    
    const drawCardsBySeries = (cards) => {
        if (cards) {
            return (<>
                <div>
                    {
                    cards.map(card => {
                        return (<div className={isCardSelected(card) ? "card-search-result selected" : "card-search-result"} onClick={()=> {
                            toggleCardSelected(card)
                        }}>
                            <img className="card-search-image" src={card.images.small} onClick={()=>{
                                setCardZoomed(card);                                
                            }}/>
                            <div className="card-search-details">
                                <p className="card-search-name">{card.name}</p>
                                <p className="card-search-series">{card.set.series}</p>
                                <p className="card-search-set">{card.set.id}</p>
                                <p className="card-search-number">{card.number}</p>
                                <p className="card-search-rarity">{card.rarity}</p>
                            </div>
                        </div>)
                    })
                    }
                </div>
            </>
            )
        }
        return (<></>);
    }


    const handleChange = (event) => {
        let selectionIndex = event.target.value;    
        let selectSetCode = cardSets[Math.max(0, selectionIndex - 1)].id;
        
        setSelectedSetIndex(selectionIndex);
        
        if (selectSetCode == "swsh45sv") {
            setSelectedSetName("Shining Fates: Shiny Vault");   
        } else {
            setSelectedSetName(cardSets[Math.max(0, selectionIndex - 1)].name);   
        }
        

        setSelectedSetCode(selectSetCode);   
        setCardSetSelected(selectSetCode);
      };

    const displaySet = (set)=> {
        return (<>
        <S3Image classNameKey={"card-set-logo"} imgKey={"sets/" + set.code + ".png"} />
        &nbsp; <p className="card-set-name">{set.name}</p></>)
    }
      
    const displaySetMenuItems = ()=> {
    return cardSets.map((set, index) => {
            return (
            <MenuItem value={index + 1}>
                { set ? displaySet(set) : <>N/A</> }
            </MenuItem>
            )
        })
    }

    const displaySetSelector = ()=> {
        return (<div className="card-set-dropdown">

          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">Choose a Set</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedSetIndex}
              onChange={handleChange}
              label="Shipping Address"
            >
              <MenuItem value={0} name="default">
                <em>Select a set</em>
              </MenuItem>
              {
                displaySetMenuItems()
              }
            </Select>
         
          </FormControl>
    
        </div>);
    }

    const displayTools = ()=>{
return(<>
                <Button
                          className="modal-button-view"
                          size="large" color="primary" variant="contained"
                          onClick={async () => {
                            setCardZoomed(null);

                            console.log("selectedSetName:", selectedSetName);
                            console.log("selectedSetCode:", selectedSetCode);

                            let queryString = `id:${selectedSetCode}`; 

                            console.log("queryString:", queryString);

                            await pokemon.card.where({ q:queryString, pageSize:500})
                                .then(cards => {
                                    console.log("CARDS:", cards.data);
                                    if (cards && cards.data) {
                                        cards.data.sort((a, b)=> parseInt(a.number) > parseInt(b.number))
                                        setCards(cards.data);                                        
                                    }
                                    
                                })                    
                        }}>
                    View {selectedSetName} Cards
                  
                  </Button>
                  
                  <Button
                          className="modal-button-view"
                          size="large" color="primary" variant="contained"
                          onClick={async () => {

                            resetSets();

                        }}>
                    Reset Sets
                  </Button> 
                        {

                            cards && cards.length > 0 ? <>
                              <div className="card-set-selection-tools">
                                <Button
                                    className="modal-button-view"
                                    size="large" color="primary" 
                                    onClick={async () => {
                                        // cards 
                                        let currentCardsSelected = cardsSelected;
                                        // currentCardsSelected
                                        let updatedCardsSelected = [];
                                        updatedCardsSelected.push(...cards);
                                        updatedCardsSelected.push(...cardsSelected);
                                        console.log("updatedCardsSelected", updatedCardsSelected);
                                        setCardsSelected(updatedCardsSelected)
                                    }}>
                                Select All
                                </Button>


                                <Button
                                    className="modal-button-view"
                                    size="large" color="primary" 
                                    onClick={async () => {
                                        setCardsSelected([]);                                                 
                                    }}>
                                Unselect All
                                </Button>
                                {/* <Button
                                    className="modal-button-view"
                                    size="large" color="primary" 
                                    onClick={async () => {
                                        downloadCardImages();                                                 
                                    }}>
                                Download Images
                                </Button> */}



                        </div>
                            </>:<></>
                        }

                  


</>)

    }
      

    const performSearch = async () => {
        console.log("Perform search input", searchInput);
        let cardsFound = []
        props.setDisplaySelected(false);
        setCardZoomed(null);
        await pokemon.card.where({ name: searchInput })
            .then(cards => {
                // console.log(cards[0].name) // "M Sceptile-EX"
                setCards(cards);
                console.log("FINISHED", cards);
            })

        // await pokemon.card.all({ name: searchInput, pageSize:1 }).on('data', card => {
        //     // console.log("card", card);
        //     cardsFound.push(card);
        // });


        // console.log("cardsFound", cardsFound);
        // setCards(cardsFound)
        //setSearchInput('');


        //        await pokemon.card.find('Venusaur')
        // .then(result => {
        //     console.log("RESULT:", result);
        //     console.log(result.card.name) // "Charizard"
        // }).catch(e=>{console.log(e)});

        //         PokemonTCG.Card.find('xy1').then(card => {
        //     console.log("card", card);
        //     // do stuff with the card
        //   })
        //   .catch(error => {
        //     // do something with the error
        //   });


        //         let params: PokemonTCG.IQuery[] = [{ name: 'name', value: searchInput }];
        // PokemonTCG.Card.where(params)
        //   .then(cards => {
        //     // do stuff with the cards
        //   })
        //   .catch(error => {
        //     // do something with the error
        //   });

        // PokemonTCG.Card.all()
        // .then(cards => {
        //   // do stuff with the cards
        //   console.log("cards", cards);
        // })
        // .catch(error => {
        //   // do something with the error
        // });


        //         PokemonTCG.Card.all()
        //   .then(cards => {
        //     // do stuff with the cards
        //     console.log("cards", cards);
        //   })
        //   .catch(error => {
        //     // do something with the error
        //   });

        //         await PokemonTCG.Card.find(searchInput).then(card => {
        //       console.log("CARD::", card);
        //     // do stuff with the card
        //   })
        //   .catch(error => {
        //     console.log("ERror:", error);
        //     // do something with the error
        //   });
    }

    return (<>
        <div>
            <div className="card-search-parent">
                <CustomTextField
                    className="custom-input"
                    style={{ float: "left", minWidth: "20em" }}
                    key={"search-input"}
                    color={"black"}
                    properties={
                        {
                            name: "Search",
                            labelName: "Search",
                            error: false,
                            variant: 'outlined',
                            key: "search-input",
                            onKeyPressFunction: performSearch,
                            currentValue: searchInput
                        }
                    }
                    defaultValue={""}
                    value={searchInput}
                    // inputProps={{className:classes.input}}
                    // onKeyPress={(e) => {
                    //   if (e.key === 'Enter') {
                    //     console.log("Key enter ");
                    //     //TODO: End propagation
                    //     performSearch();
                    //   }
                    // }}
                    onChange={
                        e => {
                            if (e.target.value) {
                                setSearchInput(e.target.value);
                            } else {
                                setSearchInput('');
                            }
                        }
                    } />
            </div>
        </div>
        {
                displaySetSelector()
        }        

        {
            displayTools()
        }
            
        {
           
            <>
            {
                props.displaySelected ? <>
                {cards && cards.length > 0 ? drawCardsBySeries(cardsSelected) : <></>}
                </> :<>

                {cardZoomed ? <>{ drawCard(cardZoomed) } </>:<>                
                {cards && cards.length > 0 ? drawCardsBySeries(cards) : <></>}
                </> }

                </>                     
            }</>


          
        }
        
        </>)
}

export default AddSingleDisplay
