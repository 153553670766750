
import React, { useContext, useState, useEffect } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

function AdminOrderFilters(props) {

    const [filters, setFilters] = useState(props.currentFilters);

    const displayCheckbox = (name, label)=>{
        return(<FormControlLabel
      control={
    <Checkbox    
    color="primary"
    checked={filters[name]}
    inputProps={{ 'aria-label': 'filter active' }}
    onChange={e => { 
        console.log("On Change:", e.target.checked);
        let updatefilter = {...filters};
        updatefilter[name] = e.target.checked;
        setFilters(updatefilter);
        if (props.updateFilters) {
            props.updateFilters(updatefilter);
        }
      }}
    />}     
    label={label}/>)
    };

    return<div style={{paddingLeft:"5em"}}> 
    <FormGroup row>
    {displayCheckbox("showPending", "Show pending only")}
    {displayCheckbox("showShippingPaidMissing", "Show shipping paid missing")}

    </FormGroup>
    </div>
}

export default AdminOrderFilters;