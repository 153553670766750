import React from 'react';
import Seo from "../components/Seo";




function CardCondition() {

    document.title = "Card Condition Guide";

    return (<>
    <Seo
                  title={"Card Condition Guide"}
                  pathSlug={"cards"}
                  description={"Singles cards are not always in mint condition. Use this helpful guide to categorize the condition of your cards."}
                />
        <div className="container-inner">
        <div className="container-page">    
            

            <div className="container-group"> 
            <h3>Near Mint (NM)</h3>
            <p>Near Mint condition cards show minimal or no wear from play or handling and will have an unmarked surface, crisp corners, and otherwise pristine edges outside of minimal handling.</p>
            </div>

            <div className="container-group"> 
            <h3>Lightly Played (LP)</h3>
            <p>Lightly Played condition cards can have slight border or corner wear, or possibly minor scratches. No major defects are present. Lightly Played condition foils may have slight fading or indications of wear on the card face.</p>
            </div>

            <div className="container-group"> 
            <h3>Lightly Played (LP)</h3>
            <p>Lightly Played condition cards can have slight border or corner wear, or possibly minor scratches. No major defects are present. Lightly Played condition foils may have slight fading or indications of wear on the card face.</p>
            </div>

            <div className="container-group"> 
            <h3>Moderately Played (MP)</h3>
            <p>Moderately Played condition cards have moderate wear, or flaws apparent to the naked eye. Moderately Played condition cards can show moderate border wear, mild corner wear, water damage, scratches , creases or fading, light dirt buildup, or any combination of these defects.</p>
            </div>

            <div className="container-group"> 
            <h3>Heavily Played (HP)</h3>
            <p>Heavily Played condition cards exhibit signs of heavy wear. Heavily Played condition cards may include cards that have significant creasing, folding, severe water damage, heavy whitening, heavy border wear, and/or tearing.</p>
            </div>

            <div className="container-group"> 
            <h3>Damaged (D)</h3>
            <p>Damaged condition cards show obvious tears, bends, or creases that could make the card illegal for tournament play, even when sleeved. Damaged condition cards have massive border wear, possible writing or major inking (ex. white-bordered cards with black-markered front borders), massive corner wear, prevalent scratching, folds, creases or tears.</p>
            </div>

        </div>
        </div>    
    </>)
}

export default CardCondition