
import blue from '@material-ui/core/colors/blue';
const drawerWidth = 240;

export const useStyles = theme => ({
    root: {
      /*     height: 180, */
      flexGrow: 1,
      padding:'0.6em',
      paddingTop: 0,
      paddingBottom: 150,
    },
    container: {
      display: 'flex',
    },
    paper: {
      minHeight: 285,
      minWidth: 125,
      padding: 10,
      textAlign: 'center',
    },
    svg: {
      width: 100,
      height: 100,
    },
    polygon: {
      strokeWidth: 1,
    },
    subscribeButton: {
      margin: theme.spacing(1),
      color: "#fff",
      backgroundColor: "#2787f4",
      '&:hover': {
        backgroundColor: blue[700],
      }
    },
    avatar: {
      minHeight: 130,
      minWidth: 100,
      width: 130,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    dialogContent: {
      minHeight: 400,
      textAlign: 'center',
      padding:'0em !important',    
    },
    input: {
      display: 'none',
    },
    textField: {
  
      cssFocused: {},
      '&:after': {
        borderBottomColor: blue[500]
      },
  
  
    },
    button: {
      color: "#fff",
      backgroundColor: "#2787f4",
      '&:hover': {
        backgroundColor: blue[700],
      }
    },
    paperSignup: {
      padding: 12
    },
    signupBox: {
      minHeight: 200
    },
    heroImg: {
      maxWidth: 1200
    },
    linkStyle: {
      border: 'none !important',
      margin: '0px !important'
    }
  });