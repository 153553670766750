
import React from 'react'
import CustomTextField from '../components/yayfun/CustomTextField';

function AddressDraw(props){

    const displayInputField = (key, color, name, currentObject)=>{    
        return (
         
        <CustomTextField
           className="custom-input"
           key={key}
           color={color}
           properties={
             {
               name: name,                
               labelName: name,
               error: false,
               variant: 'outlined',
               key: "search-input",
               onKeyPressFunction:()=>{console.log("Pressed Enter Key")},
               currentValue:currentObject[key]
             }
           }
           defaultValue={""}
           value={currentObject[key]}
           onChange={
             e => {
               if (e.target.value) {
                   currentObject[key] = e.target.value;
                   props.setDirty();
                // setValue(currentObject, e.target.value);
               } else {
                // setValue('');
                currentObject[key] = '';
                props.setDirty();
               }
             }
           } />)        
       }

    //    firstName: String
    //    lastName: String
    //    name: String
    //    addressLine1:String
    //    addressLine2:String
    //    country:String
    //    postalCode:String
    //    companyName:String
    //    phoneNumber:String
    //    city:String
    //    state:String
    //    default:Boolean

       
    function displayEditingShippingAddress(address) {
        return(<>
                {displayInputField("firstName", "black", "First Name", address)}
                {displayInputField("lastName", "black", "Last Name", address)}
                {/* {displayInputField("companyName", "black", "Company Name", address)} */}
                {displayInputField("addressLine1", "black", "Adress Line 1", address)}
                {displayInputField("addressLine2", "black", "Adress Line 2", address)}
                {displayInputField("city", "black", "City", address)}
                {displayInputField("state", "black", "State", address)}
                {displayInputField("postalCode", "black", "Postal Code", address)}
                {displayInputField("country", "black", "Country", address)}
                {displayInputField("phoneNumber", "black", "Phone Number", address)}
        </>)
    }

  
    function displayShippingAddress(address) {
      
    if (address == null) {
       return (<div className="shipping-address"></div>)
    }

    return (
      <div className="shipping-address">
        <p>{ address.firstName + " " + address.lastName } </p>
        <p>{ address.companyName ? <>{address.companyName}</> : ''} </p>
        <p>{ address.addressLine1 } </p>
        <p>{ address.addressLine2 } </p>
        <p>{ address.city} {address.city ? ',' :'' } &nbsp; { address.state }&nbsp; { address.postalCode } &nbsp; { address.country }</p>
        <p>{ address.phoneNumber }
        </p>
      </div>)

    }


  // function validateFormData() {

  //   console.log("validateFormData:", formData);

  //   var Namere = /[A-Za-z]{1}[A-Za-z]/;
  //   // if (!Namere.test(formData.name)) {
  //   //     setInputError({...inputError, name: true});            
  //   //     return false;
  //   // }
  //   // var mobilere = /[0-9]{10}/;
  //   // if (!mobilere.test(formData.phone)) {
  //   //     setInputError({...inputError, phone: true});            
  //   //     return false;
  //   // }
  //   if (formData.email == "") {
  //     setInputError({ ...inputError, email: true });
  //     return false;
  //   }
  //   var reeamil = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
  //   if (!reeamil.test(formData.email)) {
  //     setInputError({ ...inputError, email: true });
  //     return false;
  //   }
  //   return true;
  // }


    return (<>{        
            props.isEditing ? displayEditingShippingAddress(props.address) : displayShippingAddress(props.address)
        }</>)
}

export default AddressDraw