/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "yayfunthings.com-prod",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d2qez0lg2jmbqh.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-west-2:261157fe-e1e2-4912-bfbd-da018e4ce132",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_lw8h85KoK",
    "aws_user_pools_web_client_id": "s7i4i0l7ol52b0b5fh3hnrn6i",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "yaypokemonstoragefiles-prod",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://2q5dupdvqfb4rnrlapfyli3kzu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-5eb436ggcvbibfxl6im52tlic4",
    "aws_cloud_logic_custom": [
        {
            "name": "yayfunthingsapi",
            "endpoint": "https://furg2uyn47.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "yayfunthingsadmin",
            "endpoint": "https://b625kelzvd.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "XR": {
        "scenes": {
            "shop": {
                "sceneConfig": {
                    "sceneId": "5d4a38a1f24e4c28ac8fc691f77c673c.scene",
                    "region": "us-west-2",
                    "projectName": "Drafts",
                    "url": "https://sumerian.us-west-2.amazonaws.com/20180801/projects/Drafts/release/authTokens?sceneId=5d4a38a1f24e4c28ac8fc691f77c673c.scene"
                }
            }
        }
    }
};


export default awsmobile;
