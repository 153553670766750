import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from "react-router-dom";

function Banner(props){
    const history = useHistory();
    return(<>
    <div>
        <div className="grow" style={{maxWidth:"800px", marginLeft:"auto", marginRight:"auto", padding:"2em"}} onClick={()=>{
            history.push(props.url);
        }}>        
            <img className="banner-image" src={props.imgSrc} width="auto"/>      
        </div>
    </div>
    </>)
}

export default Banner;