import React, { useState, useContext} from "react";
import AdminContext from "../context/admin";
import CustomTextField from './yayfun/CustomTextField';
import { API } from '@aws-amplify/api';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import moment from "moment";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { createProduct } from '../graphql/mutations'
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import { GetSetForSeries } from "./Helper";


const useStyles = makeStyles({
    root:{
      paddingTop: "1em",
      paddingBottom: "1em",
      paddingRight:"1em"
    },
    formControlRoot:{
        marginBottom:"8px"
    },
    rootCheckbox:{
      paddingTop: "0.5px",
      paddingBottom: "0.5px",
      paddingRight:"0.1em",
      paddingLeft:"0.4em"
    },
  
    MuiInputBase:{
      padding: 0,
      marginBottom:"8px"
    },
    
    MuiButtonLabel:{
      fontFamily:"Roboto",
      fontSize:"1em",
      fontWeight:"400",
      color:"#3e4244"
    },
  
    MuiButtonLabelGlow:{
      fontFamily:"Roboto",
      fontSize:"1em",
      fontWeight:"400",
      color:"#3e4244"
    },
    MuiIconButton: {
      padding:"0em",
      paddingRight:"0.1em",
      margin:"0em"
    },
    MuiButtonLabelDisabled:{
      fontFamily:"Roboto",
      fontSize:"1em",
      fontWeight:"400",
      color:"#ccc"
  
    }
  
  });

function UpdatePopupDisplay(props) {

    const [searchInput, setSearchInput] = useState("");
    const [idInput, setIdInput] = useState("");
    const [priceInput, setPriceInput] = useState("");
    const [titleInput, setTitleInput] = useState("");


    const [productInput, setProductInput] = useState({});

    const [shippingInput, setShippingInput] = useState("");
    const [loading, setLoading] = useState(false);
    
    const [existingProduct, setExistingProduct] = useState("");

    const [displayAddAsin, setDisplayAddAsin] = useState(false);
    // const [asinToAdd, setAsinToAdd] = useState({asin:''});
    const { inventorySheet, getSheetByTitle, cardSets } = useContext(AdminContext);
    const [searchResult, setSearchResult] = useState({});
    const [feesResult, setFeesResult] = useState({});
    const [lowOfferResult, setLowOfferResult] = useState({});

    const [selectedFulfillmentIndex, setSelectedFulfillmentIndex] = useState(0);
    const classes = useStyles();    

    const performSearch = async () => {
        console.log("Perform search input", searchInput);
        
        // let cardsFound = []
        // props.setDisplaySelected(false);
        // setCardZoomed(null);
        // await pokemon.card.where({ name: searchInput })
            // .then(cards => {
            //     // console.log(cards[0].name) // "M Sceptile-EX"
            //     setCards(cards);
            //     console.log("FINISHED", cards);
            // })


    }



    const getFees = async (asin)=> {

        const path3 = "/items/GetMyFeesEstimate";
        let myInit3 = {
            body: {
                marketplaceId:"ATVPDKIKX0DER", 
                idType:"ASIN", 
                idValue:asin,
                isAmazonFulfilled:selectedFulfillmentIndex == 0 ? true : false, 
                identifier:asin, 
                currency:"USD", 
                price:priceInput, 
                shippingCurrency:"USD", 
                shippingPrice:"0", 
                pointsNumber:"0", 
                pointsValue:"0", 
                pointsCurrency:"USD"
            }
          }

          console.log("myInit3:", myInit3);


        const resultMyFeeForAsin = await API.post("yayfunthingsapi", path3, myInit3).then(responseMyFeeEstimate => {                             
            console.log("responseMyFeeEstimate:", responseMyFeeEstimate);
            if (responseMyFeeEstimate.data.GetMyFeesEstimateResponse && responseMyFeeEstimate.data.GetMyFeesEstimateResponse.GetMyFeesEstimateResult) {
                let list = responseMyFeeEstimate.data.GetMyFeesEstimateResponse.GetMyFeesEstimateResult.FeesEstimateResultList;                
                let feeObjects = [];
                const createFeeObject = (feedItem)=>{
                    let feeObject = {...feedItem}
                    return feeObject;
                }
                if (Array.isArray(list)) {
                    for (let i = 0; i < list.length; i++) {
                        feeObjects.push(createFeeObject(list[i].FeesEstimateResult));
                    }                    
                } else {
                    feeObjects.push(createFeeObject(list.FeesEstimateResult))
                }
                setFeesResult(feeObjects);
            }
        }).catch(e=>{console.log(e)});
        
    }


    const getProductImagesForAsin = async (asin)=>{

        let finalResult =  await API.post("yayfunthingsapi", "/items/getProductImagesForAsin", { 
            body:{asin:asin}
        }).then(async result=>{
            console.log("Product image", result);

            if (result) {

            }
            return result;
        }).catch(e =>{console.log(e);            
            console.log("Error");
            return "";
        });            


        console.log("finalResult:", finalResult)
        return finalResult;
    }


const searchAsin = async (asin)=>{

    console.log("searchAsin");

    let asinList  = [asin];
    const path = "/items/GetMatchingProductForASIN";
    let myInit = {
      body: {
        asinList: asinList
      }
    }
  
    const processLowestOffer = (result)=>{
        console.log("processLowestOffer:", result);
        if (result && result.data && result.data.GetLowestOfferListingsForASINResponse) {
            let data = result.data.GetLowestOfferListingsForASINResponse;
            if (data && data.GetLowestOfferListingsForASINResult && data.GetLowestOfferListingsForASINResult.Product) {
                return data.GetLowestOfferListingsForASINResult.Product.LowestOfferListings;
            }
        }
    }
      
    let result = await API.post("yayfunthingsapi", path, myInit).then(async response => { 
            
            console.log(response);        
            const path2 = "/items/GetMyPriceForASIN";
            let myInit2 = {
                body: {
                  asinList: asinList,
                  itemCondition:"New"
                }
              }
          
            const resultMyPriceForAsin = await API.post("yayfunthingsapi", path2, myInit2).then(responseMyPrice => { 
                console.log("responseMyPrice:", responseMyPrice);
                if (responseMyPrice.data && responseMyPrice.data.GetMyPriceForASINResponse && responseMyPrice.data.GetMyPriceForASINResponse.GetMyPriceForASINResult) {
                        let productResponse = responseMyPrice.data.GetMyPriceForASINResponse.GetMyPriceForASINResult.Product;
                        const convertProductResponse = (offer)=>{
                            if (offer) {
                                let updatedOffer = {
                                    buyingPrice: offer.BuyingPrice,
                                    FulfillmentChannel:offer.FulfillmentChannel,
                                    ItemCondition:offer.ItemCondition,
                                    ItemSubCondition:offer.ItemSubCondition,
                                    SellerSKU:offer.SellerSKU,
                                    SellerId:offer.SellerId,
                                    RegularPrice:offer.RegularPrice
                                }
                                return updatedOffer;    
                            }
                            return null;
                        }
                        
                        let totalExistingOffers = [];
                        if (Array.isArray(productResponse.Offers)) {
                            for (let i = 0; i < productResponse.Offers.length; i++) {
                                let offer = productResponse.Offers[i];
                                let offerConvert = convertProductResponse(offer);
                                if (offerConvert) {
                                    totalExistingOffers.push(offerConvert);                                
                                }
                            }
                        } else {

                           let offerConvert = convertProductResponse(productResponse.Offers.Offer);
                            if (offerConvert) {
                                totalExistingOffers.push(offerConvert);                                
                            }
                        }
                        if (totalExistingOffers && totalExistingOffers.length > 0) {
                            setPriceInput(totalExistingOffers[0].RegularPrice.Amount);
                            setIdInput((""+totalExistingOffers[0].SellerSKU).toLowerCase())                            
                        }
                    
                        setExistingProduct(totalExistingOffers)
                }
            }).catch(e=>{console.log(e)});

            await API.post("yayfunthingsapi", "/items/GetLowestOfferListingsForASIN", { 
                body:{asinList:asinList}
            }).then(async result=>{
                let data = processLowestOffer(result);
                console.log("LOW OFFER DATA", data);
                if (data) {
                    setLowOfferResult(data);        
                    response['lowestOffer'] = data;
                }
            }).catch(e =>{console.log(e);            
                console.log("Error");
            });            
        return response;
    }).catch(error => console.log(error));

    let resultJson = {};

    if (result && result.data && result.data.GetMatchingProductResponse ) {


        let scrapeImageResult = await getProductImagesForAsin(asin);
        console.log("scrapeImageResult:", scrapeImageResult);

        let data = result.data.GetMatchingProductResponse;
        let productResult = data.GetMatchingProductResult;
        console.log("productResult:", productResult)
        let attributeSets = productResult.Product.AttributeSets;
        let itemAttributes = attributeSets['ns2:ItemAttributes'];
        resultJson.title = itemAttributes['ns2:Title'];
        resultJson.smallImageURL = itemAttributes['ns2:SmallImage']['ns2:URL'];
        resultJson.imageURL = resultJson.smallImageURL.replace("._SL75_","");
        resultJson.brand = itemAttributes['ns2:Brand'];
        resultJson.salesRank = productResult.Product.SalesRankings.SalesRank;
        resultJson.listPrice = itemAttributes['ns2:ListPrice'] ? itemAttributes['ns2:ListPrice']['ns2:Amount'] : ""; 
        resultJson.productGroup = itemAttributes['ns2:ProductGroup']; 
        resultJson.variationChildren = productResult.Product.Relationships["ns2:VariationChild"];
        resultJson.variationParent = productResult.Product.Relationships.VariationParent;                
        resultJson.itemDimensions = itemAttributes['ns2:ItemDimensions'] ? itemAttributes['ns2:ItemDimensions'] : ""; 
        resultJson.packageDimensions = itemAttributes['ns2:PackageDimensions'] ? itemAttributes['ns2:PackageDimensions'] : ""; 
        resultJson.packageQuantity = itemAttributes['ns2:PackageQuantity'];
        resultJson.manufacturer =  itemAttributes['ns2:Manufacturer'];
        resultJson.studio =  itemAttributes['ns2:Studio'];
        resultJson.releaseDate =  itemAttributes['ns2:ReleaseDate'] ? moment(itemAttributes['ns2:ReleaseDate']).toISOString() : "";
        resultJson['asin'] = asin;
        resultJson['lowestOffer'] = result['lowestOffer'];

        console.log("resultJson:::::", resultJson);
    } else {
        console.log("NO DATA:", result)
    }


    updateProductInputWithResult(resultJson);
    setSearchResult(resultJson);
    console.log("RESULT:", resultJson);
    console.log("Where  more rows would be added")
    // const moreRows = await inventorySheet.addRows([resultJson]).then(results=>{console.log(results)}).catch(e=>{ console.log(e)});
    // await addProductFromResult(resultJson);

}


const createInputObjectFromResult = (input) => {

    console.log("createInputObjectFromResult:", input);


    let item = {
        id:idInput,
        // id:result[("id_")], // Updated from id to id_ , id used by google
        asin:input.asin,
        title:input.title,
        brand:input.brand,
        unitsRemaining:0,
        price:priceInput, // Updated from price to price_ , price used by google
        // units:result[("units")],
        type:'Sealed',
        subtype:'Product',
        // status:result[("status")],            
        // salePrice:result[("sale_price_compare")],
        // salePriceEndDate:result[("sale_price_end_date")],
        // moreDetails:result[("more_details")],
        // keyword:result[("keyword")],
        packs:0, 
        limit:1,
        releaseDate:moment(input.releaseDate).format("MM/DD/YYYY"),
        // allowPreorder:result[("allow_preorder")]
     }
     return item;
}


const addProductFromResult = async (result)=>{
    try {
        // let inputObject = createInputObjectFromResult(result);
        // console.log("Input Object:", inputObject);
        //Get all rows and use item 1 as example
        let productInputEdit = {...productInput};

        console.log("addProductFromResult", productInput);

        productInputEdit['id_'] = productInputEdit['id'];
        productInputEdit['action'] = 'I';
        productInputEdit['quantity'] = 0;
        productInputEdit['price_number'] = productInputEdit['price'];
        productInputEdit['availability_date'] =  productInputEdit['releaseDate'] ? moment(productInputEdit['releaseDate']).format('YYYY-MM-DD') : "";
        productInputEdit['series'] = getSeriesForIndex(productInputEdit['series'] ? productInputEdit['series'] : 0);
        productInputEdit['set'] = GetSetForSeries(productInputEdit['series'])[productInputEdit['set'] ? productInputEdit['set'] : 0];

        productInputEdit['type'] = getTypes()[productInputEdit['type'] ? productInputEdit['type'] : 0];
        productInputEdit['subtype'] = getSubTypes()[productInputEdit['subtype'] ? productInputEdit['subtype'] : 0];


        productInputEdit['condition'] = (getAllConditionsList()[productInputEdit['condition'] ? productInputEdit['condition']: 0]).toLocaleLowerCase();

        const moreRows = await inventorySheet.addRows([{...productInputEdit}]).then(results=>{console.log(results)}).catch(e=>{ console.log(e)});

        // const rows = await inventorySheet.getRows(); 
        // console.log("rows[0]", rows[0]);
        // // Load the cell range first
        // let loadCellsString = 'A2:AV';
        // await inventorySheet.loadCells(loadCellsString);

        // // console.log("CELLS:", cells);
    
        // // cell.value == '1'
        // // cell.numericValue == 1;
        // // cell.formula == '=ROW()';

        // for (let i = 0; i < rows.length; i++) {
        //     let row = rows[i];
        //     if (i == 0) {
        //         console.log("ROW 1:", row);

        //         console.log("Add more rows:", moreRows);
        //     }
            // for (let x = 0; x < products.length; x++) {
        //         let product = products[x];
        //         if (product.id == row["id_"]) {
        //             // await row.saveUpdatedCells();
        //             let getCellString = 'C'+(i+2);
        //             // console.log("getCellString:", getCellString);   
        //             const yourcell = await sheet.getCellByA1(getCellString);
        //             yourcell.value = parseInt(product.quantity ? product.quantity : 0);
        //             let getCellPrice = 'D'+(i+2);
        //             // console.log("getCellString:", getCellString);   
        //             const yourcellPrice = await sheet.getCellByA1(getCellPrice);
        //             yourcellPrice.value = parseFloat(product.price ? product.price : 0);

        //             let getCellUnitsRemaining = 'E'+(i+2);
        //             const yourcellUnitsRemaining = await sheet.getCellByA1(getCellUnitsRemaining);
        //             yourcellUnitsRemaining.value = parseFloat(product.unitsRemaining ? product.unitsRemaining : 0);

                // }
            // }                    
        // }

        // inventorySheet.saveUpdatedCells();



        // inventorySheet.getR
        // let result = await API.graphql(graphqlOperation(createProduct, { input:inputObject}));        
        // if (result) {
        //     console.log("RESULT:",result);
        //     // addRows.push(inputObject);
        //     // results.push({
        //     //     name:"Added Item Successfully",
        //     //     result:"Success",
        //     //     item:inputObject
        //     // })
        // }else {
        //     console.log("RESULT:",result);
        // }
        }catch (e){
            console.log("ERROR::::", e);
        }   



}


const displayExistingProducts = ()=> {

    if (existingProduct && existingProduct.length > 0) {
        console.log("existingProduct.length:", existingProduct);
        return(<>
        <h3>Existing Inventory</h3>
        <div className="fee-input-group">
        {
        existingProduct.map(product=>{
                    return <> 
                    <p className="product-price">SKU:{product.SellerSKU}</p>
                    <p className="product-price">Condition:{product.ItemCondition}</p>
                    <p className="product-price">Your Price:{product.RegularPrice.Amount}</p></>})
                   }           
                   </div>    </>)

        
    }

}


const displayFeeObjects = ()=>{

    if (feesResult && feesResult.length > 0) {

        //Show first one for now...
        return(<>
        <p className="fee-estimate">
            Fee Estimate:${feesResult[0].FeesEstimate.TotalFeesEstimate.Amount}
        </p></>)
    }
}

const createDropdownFieldInput = (name, labelName, menuItems, inputValue, editInputValue )=>{

  return <FormGroup row>
    <FormControl classes={{
          root: classes.formControlRoot
        }}
    variant="outlined">
    <InputLabel id="demo-simple-select-outlined-label">{labelName}</InputLabel>
      <Select
        classes={{
          root: classes.root
        }}
        labelId="demo-simple-select-outlined-label"
        value={inputValue[name.toLowerCase()] ? inputValue[name.toLowerCase()] : 0}  
        onChange={(event) => {
          let selectionIndex = event.target.value;      
          editInputValue({...inputValue,[name.toLowerCase()]:selectionIndex});
        }}
        label="Series Selection">     
        {
            menuItems.map((item, index) => {
                return (<MenuItem
                    key={index}
                    value={index}>
                        {item}
                    </MenuItem>)
            })
        }                 
      </Select>         
    </FormControl>
  </FormGroup> 
}


const createTextFieldInput = (name, id, inputValue, editInputValue) =>{
    console.log("createTextFieldInput:", name, "CURRENT:", inputValue[name.toLowerCase()])
    return <CustomTextField
        className="custom-input"
        style={{ float: "left", minWidth: "20em"}}
        key={name.toLowerCase() + "-input"}
        color={"black"}
        properties={
            {
                name: name,
                labelName:name,
                error: false,
                variant: 'outlined',
                currentValue: inputValue[id]
            }
        }
        defaultValue={""}
        value={inputValue[id]}
        onChange={
            e => {
                if (e.target.value) {
                    editInputValue({...inputValue,[id]:e.target.value});
                } else {
                    editInputValue({...inputValue,[id]:''});
                }
            }
        } />
}

const getTypes = ()=> {
    return  ["Sealed","Single"];
}

const getSubTypes = ()=> {
    return [];
}


const getSeries = ()=>{
    return ["Sword and Shield","Sun and Moon","XY"]
}

const getSeriesForIndex = (index)=>{
    return getSeries()[index];
}


const getAllConditionsList = ()=>{
    return ["New","Used","Collectible - Like New", "Collectible - Very Good", "NM", ];
}


const displayCreateProductInput = ()=>{    
    return(<>
    { createTextFieldInput("ID", "id", productInput, setProductInput) }
    { createTextFieldInput("Brand", "brand", productInput, setProductInput) }
    { createTextFieldInput("Title", "title", productInput, setProductInput) }
    { createTextFieldInput("ASIN", "asin", productInput, setProductInput) }
    { createTextFieldInput("Price", "price", productInput, setProductInput) }
    { createTextFieldInput("MarketPrice", "marketPrice", productInput, setProductInput) }
    { createTextFieldInput("Cost", "cost", productInput, setProductInput) }
    { createTextFieldInput("MSKU", "msku", productInput, setProductInput) }
    { createDropdownFieldInput("Condition", "Condition", getAllConditionsList(), productInput, setProductInput) }
    { createTextFieldInput("UPC", "upc", productInput, setProductInput) }
    { createTextFieldInput("Length", "length", productInput, setProductInput) }
    { createTextFieldInput("Width", "width", productInput, setProductInput) }
    { createTextFieldInput("Height", "height", productInput, setProductInput) }
    { createTextFieldInput("Weight", "weight", productInput, setProductInput) }
    { createTextFieldInput("ReleaseDate", "releaseDate", productInput, setProductInput) }
    { createDropdownFieldInput("Series", "Series", getSeries(), productInput, setProductInput) }
    { createDropdownFieldInput("Set", "Set",  GetSetForSeries(getSeriesForIndex(productInput['series'] ? productInput['series'] : 0)), productInput, setProductInput) }
    { createDropdownFieldInput("Type", "Type",getTypes(), productInput, setProductInput) }
    { createDropdownFieldInput("Subtype", "Subtype", ["Booster Box","Collection Box", "Blister", "Pack", "Tin", "Deck Box", "Pin Collection", "Elite Trainer Box"], productInput, setProductInput) }
    { createTextFieldInput("Nunber of Packs", "packs", productInput, setProductInput) }
</>);
}


const updateProductInputWithResult = (resultJson) => {    

    let updatedInput = {
        ...resultJson,
        length:resultJson['itemDimensions'] ? resultJson['itemDimensions']['ns2:Length']["_"] : 0,
        width:resultJson['itemDimensions'] ? resultJson['itemDimensions']['ns2:Width']["_"] : 0,
        height:resultJson['itemDimensions'] ? resultJson['itemDimensions']['ns2:Height']["_"] : 0,
        weight:resultJson['itemDimensions'] ? resultJson['itemDimensions']['ns2:Weight']["_"] : 0, 
    }

    let marketPrice = 0;
    const getLowestOfferFromListings = (listing)=>{
        return listing['Price']['LandedPrice']['Amount'] ? listing['Price']['LandedPrice']['Amount'] : 0;
    }


    let lowestOffer = resultJson['lowestOffer'];
    if(Array.isArray(lowestOffer.LowestOfferListing)) {
        let lowest = 0;
        for (let i = 0; i < lowestOffer.LowestOfferListing.length; i++) {
            let listing = lowestOffer.LowestOfferListing[i];
            let amount = parseFloat(getLowestOfferFromListings(listing));
            if (i == 0 || amount < lowest) {
                lowest = amount;
            }            
        }
        marketPrice = lowest;
    }else {
        marketPrice = parseFloat(getLowestOfferFromListings(lowestOffer.LowestOfferListing));        
    }

    updatedInput['marketPrice'] = marketPrice;
    setProductInput(updatedInput);
}


const displayFeeInput = ()=>{
return(<>
    {
        displayFulfillmentDropdown()    
    }
    <CustomTextField
        className="custom-input"
        style={{ float: "left", minWidth: "20em" }}
        key={"price-input"}
        color={"black"}
        properties={
            {
                name: "Price",
                labelName: "Price",
                error: false,
                variant: 'outlined',
                currentValue: priceInput
            }
        }
        defaultValue={""}
        value={priceInput}
        onChange={
            e => {
                if (e.target.value) {
                    setPriceInput(e.target.value);
                } else {
                    setPriceInput('');
                }
            }
        } />

    <CustomTextField
        className="custom-input"
        style={{ float: "left", minWidth: "20em" }}
        key={"shipping-input"}
        color={"black"}
        properties={
            {
                name: "Shipping",
                labelName: "Shipping",
                error: false,
                variant: 'outlined',
                currentValue: shippingInput
            }
        }
        defaultValue={""}
        value={shippingInput}
        onChange={
            e => {
                if (e.target.value) {
                    setShippingInput(e.target.value);
                } else {
                    setShippingInput('');
                }
            }
        } />


{searchResult && searchResult.asin ? <>
        <Button size="small" variant="contained" onClick={async() => {
        await getFees(searchInput)
    }}>Get Fees</Button>     

    </> :<></>}      
    </>)
}

const displayFulfillmentDropdown = ()=> {

    return (<div className="fulfillment-dropdown">
        <FormGroup row>
          <FormControl classes={{
          root: classes.formControlRoot
        }} variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">Choose Fulfillment</InputLabel>
            <Select
              classes={{
                root: classes.root
              }}
              labelId="demo-simple-select-outlined-label"
              value={selectedFulfillmentIndex}  
              onChange={(event) => {
                let selectionIndex = event.target.value;           
                setSelectedFulfillmentIndex(selectionIndex);
              }}
              label="Series Selection">        
               <MenuItem
                key={0}
                value={0}>
                    Amazon
                </MenuItem>
                <MenuItem
                key={1}
                value={1}>
                    Merchant
                </MenuItem>
            </Select>         
          </FormControl>
        </FormGroup>      
      </div>);

}
    const displayResult = ()=>{
            return  searchResult && searchResult.asin? <>
                        
                    <div className="fee-input-group">
                        <p className="result-title">
                            
                            <a href={"https://amazon.com/dp/" + searchResult.asin} target="_blank">
                            {searchResult.title}
                            </a>
                            </p>
                            
                        <div>
                            <img style={{maxWidth:"300px", maxHeight:"300px"}} src={searchResult.imageURL} />
                        {
                            displayExistingProducts()
                        }                       
                        </div>
                        
                        
                        <h3>Fee Calculator</h3>
                        <div className="fee-input-group">
                            {displayFeeInput()}
                            {displayFeeObjects()}
                        </div>

                        <h3>Create Product</h3>
                        <div className="fee-input-group">
                            {displayCreateProductInput()}
                        </div>
                    </div>




                </>                
                :<>
                
                </>
        
    }

    
    const displayLoading = ()=>{
        if (loading) {
        return (<>
            <div style={{paddingTop:"5em"}}>
                <CircularProgress />
            </div>
         </>)
        }
        return (<></>)
    }


    const searchInputField = () => {
        return (
            <div>
            <div className="card-search-parent">
                <CustomTextField
                    className="custom-input"
                    style={{ float: "left", minWidth: "20em" }}
                    key={"search-input"}
                    color={"black"}
                    properties={
                        {
                            name: "Search",
                            labelName: "Search",
                            error: false,
                            variant: 'outlined',
                            key: "search-input",
                            onKeyPressFunction: performSearch,
                            currentValue: searchInput
                        }
                    }
                    defaultValue={""}
                    value={searchInput}
                    // inputProps={{className:classes.input}}
                    // onKeyPress={(e) => {
                    //   if (e.key === 'Enter') {
                    //     console.log("Key enter ");
                    //     //TODO: End propagation
                    //     performSearch();
                    //   }
                    // }}
                    onChange={
                        e => {
                            if (e.target.value) {
                                setSearchInput(e.target.value);
                            } else {
                                setSearchInput('');
                            }
                        }
                    } />

<Button size="small" variant="contained" onClick={async() => {
                                setLoading(true);
                                await searchAsin(searchInput)
                                setLoading(false);
                            }}>Search</Button>                                

            </div>
        </div>
        )
    }
           return (<>
       
                        <Dialog
                            open={true}
                            // TransitionComponent={Transition}
                            style={{ padding: "0px !important" }}
                            keepMounted
                            onClose={() => {                                
                                props.setDisplayAddAsin(false);                                
                            }}                        
                        >
                            <DialogContent>                                                                                              
                                {
                                    searchInputField()    
                                }

                                {
                                    displayResult()
                                }

                                {
                                    displayLoading()
                                }   
                            </DialogContent>
                        
                            <DialogActions>
                                             


                          

{searchResult && searchResult.asin ? <>
                            
    <CustomTextField
                    className="custom-input"
                    style={{ float: "left", minWidth: "20em" }}
                    key={"id-input"}
                    color={"black"}
                    properties={
                        {
                            name: "Id",
                            labelName: "Id",
                            error: false,
                            variant: 'outlined',
                            currentValue: idInput
                        }
                    }
                    defaultValue={""}
                    value={idInput}
                    onChange={
                        e => {
                            if (e.target.value) {
                                setIdInput(e.target.value);
                            } else {
                                setIdInput('');
                            }
                          }
                    } />
                            <Button size="small" variant="contained" onClick={async() => {
                            await addProductFromResult(searchResult)
                        }}>Add</Button>     

                        </> :<></>}   


                            <Button size="small" variant="contained" onClick={() => {
                                props.setDisplayAddAsin(false);                                
                            }}>Close</Button>                                
                            
                            </DialogActions>
                        </Dialog>   

                        
        </>)
}

export default UpdatePopupDisplay
