import React from 'react';
import Seo from "../components/Seo";


function Pokemon() {

    document.title = "Pokemon Distributors";

    return (<>

            <Seo
                  title={"Pokemon Distributors"}
                  pathSlug={"pokemon"}
                  description={"We work with authorized distributors of Pokémon TCG to bring you authentic cards and products."}
                />

        <div className="container-inner">
            <div className="container-page">    

            <h3 className="">Pokemon Distributors</h3>

            <p>                
                <span>We work with authorized distributors of Pokémon TCG to bring you authentic cards and products.</span>
            </p>

            <p>                
                <span>If you have any questions on how we work with our distributors please use the contact form and we will do our best to answer your questions.</span>
            </p>

            <p>
                You can find a list of pokemon distributors on the official Pokemon site.
            </p>

            
            <div>
                <img src="images/pokemon-logo.png" />
            </div>
            <a style={{textDecoration:"underline"}} href="https://support.pokemon.com/hc/en-us/articles/360001004653-Who-are-your-wholesale-distributors-">
                Who are your wholesale distributors?
            </a>
            </div>

        </div>
    </>)
}

export default Pokemon