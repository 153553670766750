import React from 'react';
import Seo from "../components/Seo";

function Returns() {
    document.title = "Store Policy | Returns";
    return (<>
                <Seo
                  title={"Store Policy | Returns"}
                  pathSlug={"returns"}
                  description={"Full refunds can be obtained by returning an item within 30 days of receipt of the item, and the item must be in mint factory sealed condition or the condition in which the item was listed. Up to a 50% restocking fee will be assessed on new items that were open and used.   Returns that are a result of our error (you received an incorrect or defective item) will not be assessed a restocking fee and return shipping is on us."}
                />

        <div className="container-inner">
            <div className="container-page">    
            <h3>Refunds</h3>
            <p>                
             Full refunds can be obtained by returning an item within 30 days of receipt of the item, and the item must be in mint factory sealed condition or the condition in which the item was listed. Up to a 50% restocking fee will be assessed on new items that were open and used.   Returns that are a result of our error (you received an incorrect or defective item) will not be assessed a restocking fee and return shipping is on us.             
            </p>

            <h3>FAQs</h3>
            <p>                
             Do you ship internationally?             
            </p>
            <p>
                We can only ship physical products within the United States. Codes can be purchased from anywhere in the world and delivered via email.
            </p>
            <p> When will you ship my order? </p>
            <p> Orders ship Monday through Friday. Orders will ship the same day if the order is placed before 2pm CST. All other orders will ship next business day. If the products are pre orders then the expected ship date will be included in your order detail information and displayed at the time of purchase.</p>
            </div>
        </div>    
    </>)
}

export default Returns