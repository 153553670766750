/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      user
      date
      total
      customer
      history {
        createdAt
        description
      }
      packages {
        trackingNumber
        products {
          amount
          product_id
        }
      }

      codes {
        id
        value
        name
        product_id
        notes
        emailed
      }

      products {
        items {
            id
            product {              
            title
            id
            image
            type
            subtype
            imageUrlKey
          }
        nextToken
      }
    }
  }
`;

export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        date
        status
        subtotal
        shippingCost
        taxCost
        total
        number
        search
        codes {
        id
        value
        name
        product_id
        notes
        emailed
      }
        packages {
        trackingNumber
        products {
          amount
          product_id
        }
        }
        billingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        products {
          items {
            id
            product_id
            order_id
            price
            amount
            product {
            title
            id
            image
            type
            imageUrlKey
           }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        allowPreorder
        description
        image
        author
        brand
        cardName
        featured        
        price
        marketPrice
        medianPrice
        units
        quantity
        type
        limit
        number
        subtype
        status
        salePrice
        series
        set
        packs        
        releaseDate
        imageAsset
        imageUrl
        imageUrlKey
        imageUrlHiRes        
        imageUrlHiResKey
        imageThumbBase64
      }
      nextToken
    }
  }
`;



export const listProductSingles = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        image
        author
        brand
        featured
        price
        units
        quantity
        type
        subtype
        status
        salePrice
        series
        set
        setTotal
        packs        
        releaseDate
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        imageThumbBase64
        rarity
        number
        artist
        evolvesFrom
        hp
        text
        convertedRetreatCost
        nationalPokedexNumber
        retreatCost
        setCode
        cardType
        cardSubtype
        cardName        
        supertype
        types
        weaknesses {
          type
          value
        }
        resistances {
          type
          value
        }
        ability {
          name
          text
          type          
        }
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }        
        marketPrice
        medianPrice
        # ability
        # weaknesses
        # resistances
      }
      nextToken
    }
  }
`;


export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
        id
        title
        description
        image
        author
        brand
        featured
        price
        units
        quantity
        msku
        allowPreorder
        type
        subtype
        status
        cardId
        allowPreorder
        salePrice
        series
        set
        setTotal
        packs        
        releaseDate
        imageThumbBase64
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        rarity
        number
        artist
        evolvesFrom
        hp
        text
        convertedRetreatCost
        nationalPokedexNumber
        retreatCost
        setCode
        cardType
        cardSubtype
        cardName        
        supertype
        types
        weaknesses {
          type
          value
        }
        resistances {
          type
          value
        }
        ability {
          name
          text
          type          
        }
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }        
    }
  }
`;


export const listOrdersByOwnerDate = /* GraphQL */ `
  query ListOrdersByOwnerDate(
    $owner: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByOwnerDate(
      owner: $owner
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        owner
        date
        customer
        number
        total
        subtotal
        shippingCost
        taxCost
        status
        shippingMethod
        authorizationId
        paymentMethod
        paymentIntentId
        paymentType
        billingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        codes {
        id
        value
        name
        product_id
        notes
        emailed
      }
        packages {
          id
          status
          trackingNumber
        }
        search
        promiseDateMin
        promiseDateMax
        products {
          items {
            id
            product_id
            order_id
            price
            amount
            product {
              title
              id
              image
              type
              subtype
              imageUrlKey
           }

          }
          nextToken
          }
      }
      nextToken
    }
  }
`;

export const listOrdersByDate = /* GraphQL */ `
  query ListOrdersByDate(
    $type: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByDate(
      type: $type
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        type
        id
        user
        owner
        date
        customer
        number
        total
        subtotal
        shippingCost
        taxCost
        status
        shippingMethod        
        paymentMethod
        authorizationId
        paymentIntentId
        paymentType
        billingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        codes {
        id
        value
        name
        product_id
        notes
        emailed
      }
        packages {
          id
          status
          trackingNumber
        }
        history {
          createdAt
          description
        }
        search
        promiseDateMin
        promiseDateMax
        products {
          items {
            id
            product_id
            order_id
            price
            amount
            product {
              title
              id
              image
              type
              imageUrlKey
           }

          }
          nextToken
          }
      }
      nextToken
    }
  }
`;

export const listOrdersByUserDate = /* GraphQL */ `
  query ListOrdersByUserDate(
    $user: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByUserDate(
      user: $user
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        date
        number
        total
        subtotal
        shippingCost
        taxCost
        status
        paymentMethod
        billingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
        search
        products {
          items {
            id
            product_id
            order_id
            price
            amount
            product {
              title
              id
              image
              type
              subtype
              imageUrlKey
           }
          }
          nextToken
          }
      }
      nextToken
    }
  }
`;

export const productsBySet = /* GraphQL */ `
  query ProductsBySet(
    $set: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsBySet(
      set: $set
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        set
        title
        asin
        description
        image
        author
        brand
        featured
        price
        units
        limit
        quantity
        type
        subtype
        status
        allowPreorder
        salePrice
        salePriceEndDate
        moreDetails
        keyword
        releaseDate
        packs
        updatedAt
        upc
        condition
        cardId
        cardName
        cardType
        cardSubtype
        marketPrice
        medianPrice
        ability {
          name
          text
          type
        }
        artist
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }
        evolvesFrom
        hp
        number
        text
        convertedRetreatCost
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        nationalPokedexNumber
        rarity
        resistances {
          type
          value
        }
        retreatCost
        series
        setTotal
        setCode
        supertype
        types
        weaknesses {
          type
          value
        }
      }
      nextToken
    }
  }
`;

export const productsBySetType = /* GraphQL */ `
  query ProductsBySetType(
    $set: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsBySetType(
      set: $set
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        set
        title      
        image
        author
        brand
        featured
        price
        units
        limit
        quantity
        type
        subtype
        status
        allowPreorder
        salePrice
        salePriceEndDate
        moreDetails
        releaseDate        
        updatedAt        
        condition
        cardId
        cardName
        cardType
        cardSubtype
        marketPrice
        medianPrice
        ability {
          name
          text
          type
        }
        artist
        attacks {
          convertedEnergyCost
          cost
          damage
          name
          text
        }
        evolvesFrom
        hp
        number
        text
        convertedRetreatCost
        imageUrl
        imageUrlHiRes
        imageUrlKey
        imageUrlHiResKey
        nationalPokedexNumber
        rarity
        resistances {
          type
          value
        }
        retreatCost
        series
        setTotal
        setCode
        supertype
        types
        weaknesses {
          type
          value
        }
      }
      nextToken
    }
  }
`;

export const listSiteItems = /* GraphQL */ `
  query ListSiteItems(
    $filter: ModelSiteItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        type
        id
        data
        status
      }
      nextToken
    }
  }
`;


export const listUserItemByOwnerType = /* GraphQL */ `
  query ListUserItemByOwnerType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserItemByOwnerType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        type
        id                
        data
        createdAt
        status
        history {
          createdAt
          description
        }
        links {
          nextToken
        }
        shippingAddress {
          firstName
          lastName
          name
          addressLine1
          addressLine2
          country
          postalCode
          companyName
          phoneNumber
          city
          state
          default
        }
      }
      nextToken
    }
  }
`;